import React, { useEffect, useRef, useState } from "react";
import { InputFile } from "./InputFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "rsuite";
import PdfViewer from "../../utils/view_downloadPrescription/PDFViewer";
import { UploadIcon } from "@heroicons/react/outline";
import { CloseOutlined } from "@mui/icons-material";
import { Dialog } from "@mui/material";

export const FileUploader = ({
  field,
  params,
  setState,
  error,
  setErrors,
  label = "Attachment",
  required,
  request,
  isRequest = false,
  valuePath = null,
  maxSize = 5242880, //max File size allowed
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isRequest && request) {
      getDocument(valuePath);
    }
  }, [isRequest, request, valuePath]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const deleteImg = () => {
    setState(field + "Pdf", "");
    setState(field + "Show", "");
    inputRef.current.value = null;
    params[field] = "";
    setState(field, "");
  };

  const onChangeHandlerImg = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let mimeType = file["type"];
      let acceptMimeType = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
      ];
      setErrors(field, "");
      setState(field + "Pdf", "");
      setState(field + "Show", "");
      setState(field, "");
      if (!acceptMimeType.includes(mimeType)) {
        setErrors(field, "please select (png, jpg, jpeg and pdf)");
        return;
      }
      let fileSize = parseFloat(file.size);
      if (maxSize < fileSize) {
        setErrors(
          field,
          "Attachment size is more than 5 MB, Please ensure the size is or below 5 MB"
        );
        if (inputRef.current) {
          inputRef.current.value = null;
        }
        return;
      }
      if (mimeType == "application/pdf") {
        let pdfFileToShow = URL.createObjectURL(file);
        setState(field + "Pdf", pdfFileToShow);
      } else {
        let imageFileToShow = URL.createObjectURL(file);
        setState(field + "Show", imageFileToShow);
      }
      setState(field, file);
    }
  };

  const getDocument = async (value) => {
    setLoading(true);
    let payload = { path: value };
    setState(field + "Pdf", "");
    setState(field + "Show", "");
    const response = await request(payload);
    if (response.code === 200) {
      if (response?.responseObject) {
        if (value.includes(".pdf")) {
          let pdfFileToShow = getPreviewUrl(response.responseObject, value);
          setState(field + "Pdf", pdfFileToShow);
          let fetchData = await fetch(pdfFileToShow);
          let blob = await fetchData.blob();
          const blobFile = new File([blob], value, { type: blob.type });
          setState(field, blobFile);
        } else {
          let imageFileToShow = getPreviewUrl(response.responseObject, value);
          setState(field + "Show", imageFileToShow);
          let fetchData = await fetch(imageFileToShow);
          let blob = await fetchData.blob();
          const blobFile = new File([blob], value, { type: blob.type });
          setState(field, blobFile);
        }
      }
    } else {
      //   this.MessageRef.current.show(response.message);
    }
    setLoading(false);
  };

  const getPreviewUrl = (fileName, image) => {
    const fileType = getFileType(image);
    return `data:${fileType};base64,${fileName}`;
  };

  const getFileType = (fileName) => {
    const splittedFileType = fileName?.split(".");
    const len = splittedFileType?.length;
    if (len) {
      const ext = splittedFileType[len - 1];
      if (["jpg", "jpeg", "png"].includes(ext)) {
        return `image/${ext}`;
      }
      return "application/pdf";
    }
    return "";
  };

  return (
    <>
      <div className="flex mt-4 w-80 justify-between">
        <span>
          {label} {required && <span className="text-lg text-red-500">* </span>}
          <span className="text-sm">(png, jpg, jpeg and pdf)</span>
        </span>
      </div>
      {loading ? (
        <div className="text-center relative items-center flex-col w-80 inline-flex">
          <Loader size="md" />
        </div>
      ) : (
        <InputFile
          error={error}
          inputRef={inputRef}
          placeholder={
            <div className="text-center relative items-center flex-col w-60 md:w-80 inline-flex">
              {params[field] && (
                <div>
                  {params[field + "Pdf"] && (
                    <div
                      className="absolute rounded-full right-8 text-white w-6 bg-red-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        className="primary"
                      ></FontAwesomeIcon>
                    </div>
                  )}
                  {params[field + "Show"] && (
                    <div
                      className="absolute rounded-full right-8 text-white w-6 bg-red-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        className="primary"
                      ></FontAwesomeIcon>
                    </div>
                  )}
                  <div
                    className="absolute rounded-full right-0 text-white w-6 bg-red-400"
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteImg();
                    }}
                  >
                    X
                  </div>
                </div>
              )}
              {params[field + "Show"] ? (
                <img
                  alt={field}
                  className="w-80 h-40"
                  src={params[field + "Show"]}
                />
              ) : params[field + "Pdf"] ? (
                <span className="text-5xl text-red-500 cursor-pointer">
                  <FontAwesomeIcon
                    icon={faFilePdf}
                    className="primary"
                  ></FontAwesomeIcon>
                </span>
              ) : (
                <>
                  <UploadIcon className="text-primary" width={24} />
                  <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                    Upload attachment
                    <br />
                    max 5 MB
                  </p>
                </>
              )}
            </div>
          }
          attrs={{
            onChange: (e) => {
              onChangeHandlerImg(e);
            },
            accept: "image/*,.pdf",
          }}
        />
      )}
      <Dialog
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={isModalOpen}
      >
        <div
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: "111",
            backgroundColor: "#db2228",
          }}
          // className="flex justify-end"
        >
          <p>
            {params[field + "Pdf"] && (
              <span className="text-white m-4 float-left">PDF</span>
            )}{" "}
            {params[field + "Show"] && (
              <span className="text-white m-4 float-left">Image</span>
            )}
            <CloseOutlined
              className="text-white m-4 cursor-pointer float-right"
              onClick={closeModal}
            />
          </p>
        </div>
        {params[field + "Pdf"] && <PdfViewer myFile={params[field + "Pdf"]} />}
        {params[field + "Show"] && (
          <img
            alt={field}
            className="w-full h-1/2"
            src={params[field + "Show"]}
          />
        )}
      </Dialog>
    </>
  );
};
