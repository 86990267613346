import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { timeConvert } from "../../helpers/Helper";
import StatusWiseActionButton from "./StatusWiseActionButton";
import Button from "../../components/Button";
import { Pagination } from "rsuite";
import { convertToDays, downloadPrescription } from "../../utils/commom";
import {
  fetchAllAppointment,
  fetchDownloadPrescription,
  getResellerId,
  postJoinCallAction,
  removeData,
} from "../../lib/AppHelper";
import { memo } from "react";
import { CloseOutlined } from "@mui/icons-material";
import { CircularProgress, Dialog, Icon } from "@mui/material";
import PdfViewer from "../../utils/view_downloadPrescription/PDFViewer";
import { useSelector } from "react-redux";
import { DownloadIcon, EyeIcon } from "@heroicons/react/outline";
import { AppStore } from "../../lib/AppStore";
import TimerFromStartDate from "../../utils/TimerFromStartDate";
import setColor from "../../utils/textColor";

const AppointmentTable = ({ id = true, startDate, endDate }) => {
  const permissions = useSelector((state) => state?.user?.permissions);
  const [appointmentData, setAppointmentData] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [myFile, setMyFile] = useState(null);
  const [show, setShow] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const loading = useSelector((state) => state.appointmentLoading);

  const fetchAppointment = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    fetchAllAppointment(
      page,
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    ).then((result) => {
      setAppointmentData(result?.data?.results);
      setTotalCount(result?.data?.count);
      AppStore.dispatch({ type: "LOADING", loading: false });
      AppStore.dispatch({
        type: "REFRESH_TABLE",
        loading: false,
      });
    });
  };

  useEffect(() => {
    fetchAppointment();
  }, [startDate, endDate, page]);

  useEffect(() => {
    if (loading) fetchAppointment();
  }, [loading]);

  const frequencyData = {
    Daily: 1,
    Weekly: 7,
    Alternative: 2,
  };

  const handleMedicationColorCode = (medicine) => {
    if (medicine?.length) {
      return (
        <div>
          {medicine?.map((item, index) => {
            let value = convertToDays(item);
            let quantity = 0;
            item.time_data.map((elem) => {
              quantity = quantity + parseInt(elem.quantity);
            });

            let totalDuration = Math.ceil(
              value / frequencyData[item.frequency]
            );
            totalDuration = totalDuration * quantity;
            return (
              <div key={index} className="flex gap-1">
                <div
                  style={{
                    color: item.color_code ? setColor(item.color_code) : "",
                    width: "80px",
                    backgroundColor: item.color_code,
                  }}
                  className="pl-4 pr-4 m-1"
                >
                  {item?.unique_code ? item?.unique_code : ""}
                </div>

                <div style={{ margin: 1 }}>
                  {item?.medication_name.length > 20
                    ? item.medication_name.substring(0, 20) + "..."
                    : item?.medication_name}
                </div>
                <div style={{ margin: 1 }}>x{totalDuration}</div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  const viewPrescriptionHandler = (id, patient) => {
    setViewLoading(id);
    fetchDownloadPrescription(id, patient).then((res) => {
      setMyFile(res);
      setViewLoading(false);
      setShow(true);
    });
  };

  const downloadPrescriptionHandler = (id, patient) => {
    setDownloadLoading(id);
    fetchDownloadPrescription(id, patient).then((res) => {
      downloadPrescription(res);
      setDownloadLoading(false);
    });
  };

  const handleViewClose = () => {
    setShow(false);
  };

  const handleJoinCall = (item) => {
    const data = {
      appointment_id: item.id,
      aiqa_center_joined: true,
      aiqa_center_id: getResellerId(),
    };
    postJoinCallAction(data);
    window.open(
      item?.meet_url,
      "popUpWindow",
      "height=300,width=700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
  };

  return (
    <>
      <div className="w-full hidden md:block overflow-scroll no-scrollbar">
        <table className="min-w-full shadow-lg " id="table-to-xls">
          <thead className="border-b-2">
            <tr style={{ backgroundColor: "#db2228", color: "#fff" }}>
              <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-center">
                Id
              </th>

              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Name
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                {id ? "Appointment Date" : "Appointment Time"}
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center">
                Medicine
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Status
              </th>
              <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center ">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {appointmentData && appointmentData.length ? (
              appointmentData.map((item, index) => {
                return (
                  <tr className="border-b-2" key={index}>
                    <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                      {item?.id}
                    </td>
                    <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                      {item?.patient?.name}
                    </td>
                    <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                      {id && (
                        <>
                          {moment(item?.appointment_date).format("DD-MM-YYYY")}
                          <br />
                        </>
                      )}
                      {timeConvert(item?.appointment_time)}
                    </td>
                    <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center">
                      {handleMedicationColorCode(item?.prescription_medicines)}
                    </td>
                    <td className=" text-gray-900  px-6 py-4 whitespace-nowrap text-center ">
                      <StatusWiseActionButton status={item?.status} />
                    </td>
                    <td className="text-red-600  px-6 py-4 whitespace-nowrap text-center   ">
                      {item?.status == "completed" ? (
                        <div className="gap-2 flex justify-center">
                          {viewLoading == item.id ? (
                            <div className="h-6 w-6 flex justify-center items-center">
                              <CircularProgress
                                style={{ padding: "12px", display: "flex" }}
                              />
                            </div>
                          ) : (
                            <EyeIcon
                              onClick={() =>
                                viewPrescriptionHandler(
                                  item.id,
                                  item.patient.patient
                                )
                              }
                              className="h-6 w-6 text-red-500"
                            />
                          )}
                          {downloadLoading == item.id ? (
                            <div className="h-6 w-6 flex justify-center items-center">
                              <CircularProgress
                                style={{ padding: "12px", display: "flex" }}
                              />
                            </div>
                          ) : (
                            <DownloadIcon
                              onClick={() =>
                                downloadPrescriptionHandler(
                                  item.id,
                                  item.patient.patient
                                )
                              }
                              className="h-6 w-6 text-red-500"
                            />
                          )}
                        </div>
                      ) : item?.meet_url &&
                        item?.is_aiqa_center &&
                        item?.status == "pending" &&
                        permissions.center_coordinator ? (
                        <div className=" flex items-center w-full justify-center">
                          <Button
                            size={"sm"}
                            title="Join Call"
                            attrs={{
                              onClick: () => handleJoinCall(item),
                              style: { backgroundColor: "#564CC8" },
                            }}
                          />
                        </div>
                      ) : item?.meet_url &&
                        item?.is_aiqa_center &&
                        item.status == "call_initiated" &&
                        permissions?.center_coordinator ? (
                        <div className="w-full flex flex-col items-center text-center">
                          <div
                            style={{ backgroundColor: "#F7B500" }}
                            className="p-1 w-40 text-white rounded-2xl flex items-center justify-center cursor-pointer"
                            onClick={() => handleJoinCall(item)}
                          >
                            <div className="flex md:block justify-between md:justify-center md:w-28 w-full pl-2 pr-2">
                              <div
                                style={{ fontWeight: "800", fontSize: "15px" }}
                              >
                                On Going Call
                              </div>
                              <span
                                className="text-xs"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "650",
                                }}
                              >
                                {
                                  <TimerFromStartDate
                                    startDate={item?.call_join_time}
                                  />
                                }
                              </span>
                            </div>
                          </div>
                          <div className="flex gap-2">
                            <div className="flex justify-center items-center">
                              <p
                                className={`rounded-full ${
                                  item?.doctor_joined
                                    ? "bg-green-600"
                                    : "bg-gray-400"
                                } h-3 w-3 mr-1`}
                              />
                              <span className="text-sm">Doctor</span>
                            </div>
                            <div className="flex justify-center items-center">
                              <p
                                className={`rounded-full ${
                                  item?.pharmacist_joined
                                    ? "bg-green-600"
                                    : "bg-gray-400"
                                } h-3 w-3 mr-1`}
                              />
                              <span className="text-sm">Pharmacist</span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan="9"
                  height="50"
                  className="text-red-500 font-light text-center border"
                >
                  Your result is empty
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="block md:hidden">
        {appointmentData && appointmentData.length ? (
          appointmentData.map((item, index) => {
            return (
              <div
                key={index}
                className="border border-red-500 rounded-xl w-full p-2 mt-3"
              >
                {permissions?.aiqa_center &&
                  permissions?.center_coordinator && (
                    <div className="m-1 mb-3 w-full flex justify-between items-center">
                      <div
                        style={{
                          fontSize: "20px",
                          color: "#9C9393",
                          fontWeight: 700,
                        }}
                      >
                        {item.id}
                      </div>
                      <div className="flex justify-end  mt-1">
                        <StatusWiseActionButton status={item?.status} />
                      </div>
                    </div>
                  )}
                <div className="font-bold  m-1 flex justify-between">
                  <p className="font-bold text-lg flex items-center">
                    {item?.patient?.name}
                  </p>
                </div>
                <div className="text-red-500 m-1">
                  {id && moment(item?.appointment_date).format("DD/MM/YYYY")}{" "}
                  {timeConvert(item?.appointment_time)}
                </div>
                {item?.status == "completed" && (
                  <div className="mt-6 mb-4">
                    {handleMedicationColorCode(item?.prescription_medicines)}
                  </div>
                )}
                <div className="flex justify-end  mt-1">
                  {item?.status == "completed" ? (
                    <div className="gap-2 flex">
                      {viewLoading == item.id ? (
                        <div className="h-6 w-6 flex justify-center items-center">
                          <CircularProgress
                            style={{ padding: "12px", display: "flex" }}
                          />
                        </div>
                      ) : (
                        <EyeIcon
                          onClick={() =>
                            viewPrescriptionHandler(
                              item.id,
                              item.patient.patient
                            )
                          }
                          className="h-6 w-6 text-red-500"
                        />
                      )}
                      {downloadLoading == item.id ? (
                        <div className="h-6 w-6 flex justify-center items-center">
                          <CircularProgress
                            style={{ padding: "12px", display: "flex" }}
                          />
                        </div>
                      ) : (
                        <DownloadIcon
                          onClick={() =>
                            downloadPrescriptionHandler(
                              item.id,
                              item.patient.patient
                            )
                          }
                          className="h-6 w-6 text-red-500"
                        />
                      )}
                    </div>
                  ) : item?.meet_url &&
                    item?.is_aiqa_center &&
                    item?.status == "pending" &&
                    permissions?.center_coordinator ? (
                    <Button
                      size={"sm"}
                      title="Join Call"
                      attrs={{
                        onClick: () => handleJoinCall(item),
                        style: { backgroundColor: "#564CC8" },
                      }}
                    />
                  ) : item?.meet_url &&
                    item?.is_aiqa_center &&
                    item?.status == "call_initiated" &&
                    permissions?.center_coordinator ? (
                    <div className="flex-col items-center justify-center">
                      <div
                        style={{ backgroundColor: "#F7B500" }}
                        className=" text-white rounded-2xl flex items-center justify-center cursor-pointer p-1"
                        onClick={() => handleJoinCall(item)}
                      >
                        <div className="pl-2 pr-2 ">
                          <div
                            className="text-sm"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "800",
                              fontSize: "15px",
                            }}
                          >
                            On Going Call
                          </div>
                          <span
                            className="text-xs"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              color: "black",
                              fontSize: "12px",
                              fontWeight: "650",
                            }}
                          >
                            {
                              <TimerFromStartDate
                                startDate={item.call_join_time}
                              />
                            }
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-2">
                        <div className="flex justify-center items-center">
                          <p
                            className={`rounded-full ${
                              item?.doctor_joined
                                ? "bg-green-600"
                                : "bg-gray-400"
                            } h-3 w-3 mr-1`}
                          />
                          <span className="text-sm">Doctor</span>
                        </div>
                        <div className="flex justify-center items-center">
                          <p
                            className={`rounded-full ${
                              item?.pharmacist_joined
                                ? "bg-green-600"
                                : "bg-gray-400"
                            } h-3 w-3 mr-1`}
                          />
                          <span className="text-sm">Pharmacist</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex justify-center mb-5">No appointment</div>
        )}
      </div>
      <div className="mt-2">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={4}
          size="xs"
          layout={["total", "-", "pager", "skip"]}
          total={totalCount}
          limit={10}
          activePage={page}
          onChangePage={setPage}
        />
      </div>
      <Dialog
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <div
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: "111",
            backgroundColor: "#db2228",
            // height: "10px",
          }}
          className="flex justify-end"
        >
          <CloseOutlined
            className="text-white m-4 cursor-pointer"
            onClick={handleViewClose}
          />
        </div>
        <PdfViewer myFile={myFile} setmyFile={setMyFile} />
      </Dialog>
    </>
  );
};

export default memo(AppointmentTable);
