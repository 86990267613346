import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import { AppStore } from "../../lib/AppStore";
import AdminMain from "./AdminMain";
import { ApiGet } from "../../lib/AppHelper";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

export class AdminResellerReports extends Component {
  MessageRef = React.createRef();
  state = {
    reports: [],
    policyCount: 0,
    policyAmount: 0,
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      status: "",
    },
    employees: [],
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  render() {
    const { params, reports, policyCount, policyAmount, employees } =
      this.state;

    return (
      <AdminMain MessageRef={this.MessageRef}>
        <div className="grid md:grid-cols-2 gap-2">
          <div>
            <AppBackButton to="/">Back</AppBackButton>
          </div>
          <div className="text-right">
            <div style={{ maxWidth: "20%" }} className="float-right">
              <button
                style={{ fontSize: "12px" }}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
              >
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Download"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-5 gap-4">
          {/* <div>
                        <label className="flex text-sm text-gray-700 justify-left pb-2">
                            Select Team
                        </label>
                        <select
                            onChange={(e) => this.onChangeHandler(e, "employee_id")}
                            className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                        >
                            <option value="">All</option>
                            {employees.map((option, index) => {
                                return (
                                    <option value={option.id}>
                                        {option.name} ({option.mobile})
                                    </option>
                                );
                            })}
                        </select>
                    </div> */}
          <div>
            <InputGroup
              label="From"
              type="date"
              placeholder=""
              name="from"
              attrs={{
                value: params.from,
                onChange: (e) => this.onChangeHandler(e, "from"),
              }}
            />
          </div>
          <div>
            <InputGroup
              label="To"
              type="date"
              placeholder=""
              name="to"
              attrs={{
                value: params.to,
                onChange: (e) => this.onChangeHandler(e, "to"),
              }}
            />
          </div>
          <div style={{ maxWidth: "50%", paddingTop: "22px" }}>
            <Button
              attrs={{ type: "button", onClick: this.handleClick }}
              size="md"
              title={"Search"}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-x-auto">
                {reports ? (
                  <table className="min-w-full" id="table-to-xls">
                    <thead>
                      <tr>
                        <td>
                          <table className="min-w-full border text-center">
                            <thead className="border-b">
                              <tr>
                                <th
                                  scope="col"
                                  className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                                >
                                  Total Policy
                                </th>
                                <th
                                  scope="col"
                                  className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                                >
                                  Total Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b">
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                  {policyCount}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                  {policyAmount}
                                </td>
                              </tr>
                            </tbody>
                          </table>{" "}
                          <table className="min-w-full border">
                            <tbody>
                              <tr>
                                <td>
                                  <table className="min-w-full">
                                    <thead className="border-b">
                                      <tr
                                        className="bg-primary"
                                        style={{ color: "white" }}
                                      >
                                        <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                          Name
                                        </th>
                                        <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                          Total Policy
                                        </th>
                                        <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {reports.map((item, index) => {
                                        return (
                                          <tr className="border-b" key={index}>
                                            <td
                                              className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              {item.reseller.reseller_name}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {item.reseller.policy_count}
                                            </td>
                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {item.reseller.policy_amount}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </thead>
                  </table>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </AdminMain>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getTeam();
  }
  handleClick = () => {
    this.getReports(this.state.params);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/resellerReport", request);
    if (response.status === "success") {
      if (response.data) {
        const items = Object.values(response.data.items);
        this.setState({ reports: items });
        this.setState({ policyCount: response.data.total_policy_count });
        this.setState({ policyAmount: response.data.total_policy_amount });
      } else {
        this.state.reports = [];
      }
    } else {
      // this.MessageRef.current.show(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};
export default withRouter(AdminResellerReports);
