import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import { inputTypes } from "./inputTypes";

const claimSlabType = [
  {
    id: "",
    name: "Select",
  },
  {
    id: "ICU",
    name: "ICU",
  },
  {
    id: "OPCASH",
    name: "OPCASH",
  },
];

export const HospitalizationReasonForm = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  isMobile,
}) => {
  const columns = useMemo(
    () => [
      {
        field: "hospitalId",
        label: "Name of the hospital",
        type: "dropdown",
        labelField: "name",
        valueField: "id",
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
      {
        field: "hospitalName",
        label: "Hospital name",
        requiredMark: true,
        type: "text",
        placeholder: "Enter hospital name",
        visible: ({ values }) => {
          return values?.hospitalId === "6666" ? true : false;
        },
      },
      {
        field: "hospitalCity",
        label: "City",
        requiredMark: true,
        type: "text",
        placeholder: "Enter city ",
        visible: ({ values }) => {
          return values?.hospitalId === "6666" ? true : false;
        },
      },
      {
        field: "hospitalState",
        label: "State",
        requiredMark: true,
        type: "text",
        placeholder: "Enter state ",
        visible: ({ values }) => {
          return values?.hospitalId === "6666" ? true : false;
        },
      },
      {
        field: "reason",
        label: "Reason for hospitalization/Diagnosis",
        requiredMark: true,
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
      {
        field: "claimSlabType",
        label: "Claim slab type",
        requiredMark: true,
        type: "dropdown",
        labelField: "name",
        valueField: "id",
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
      {
        field: "claimStartDate",
        label: "Date of admission",
        requiredMark: true,
        formate: "dd-MM-yyyy",
        type: "date",
        disabledDateFunc: (date, state) => {
          if (
            date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate() ||
            date > new Date()
          ) {
            return true;
          }
        },
        attrs: {
          value: state?.claimStartDate,
        },
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
      {
        field: "claimEndDate",
        label: "Date of discharge",
        requiredMark: true,
        formate: "dd-MM-yyyy",
        type: "date",
        disabledDateFunc: (date, state) => {
          const date1 = new Date(moment(date).startOf("day").toDate());
          const date2 = new Date(
            moment(state?.claimStartDate).startOf("day").toDate()
          );
          if (
            date < new Date(state?.claimStartDate) ||
            date > new Date() ||
            date1.getTime() === date2.getTime()
          ) {
            return true;
          }
        },
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
        disableField: ({ values }) => {
          return values?.claimStartDate ? false : true;
        },
      },
      {
        field: "dateOfaccident",
        label: "Date of accident",
        formate: "dd-MM-yyyy",
        requiredMark: true,
        type: "date",
        disabledDateFunc: (date, state) => {
          if (
            date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate() ||
            date > new Date()
          ) {
            return true;
          }
        },
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH", "CLOAN"];
          return !allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
      {
        field: "accidentCause",
        label: "Cause of accident",
        requiredMark: true,
        type: "textarea",
        visible: ({ values }) => {
          return values?.isPatientDied;
        },
      },
      {
        field: "dateOfDeath",
        label: "Date of death",
        requiredMark: true,
        formate: "dd-MM-yyyy",
        type: "date",
        disabledDateFunc: (date, state) => {
          if (
            date > new Date() ||
            date < new Date(state?.dateOfaccident) ||
            date < moment(state?.policyStartDate, "DD-MM-YYYY").toDate()
          ) {
            return true;
          }
        },
        visible: ({ values }) => {
          return values?.productSpecification === "CLOAN" ||
            values?.isPatientDied === true
            ? true
            : false;
        },
        disableField: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH", "CLOAN"];
          return (
            !allowedProductSpecifications.includes(
              values?.productSpecification
            ) && !values.dateOfaccident
          );
        },
      },

      {
        field: "amount",
        label: "Claim amount",
        requiredMark: true,
        visible: true,
        type: "number",
        max: 2000000,
        disableField: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH"];
          return (
            values?.maxClaimAmount &&
            !allowedProductSpecifications.includes(values.productSpecification)
          );
        },
      },

      {
        field: "deathCause",
        label: "Cause of death",
        requiredMark: true,
        type: "textarea",
        visible: ({ values }) => {
          return values?.productSpecification === "CLOAN" ? true : false;
        },
      },
      {
        field: "isPatientDied",
        label: "Is Patient Died",
        type: "toggle",
        leftLabel: "NO",
        rightLabel: "YES",
        required: false,
        visible: ({ values }) => {
          let allowedProductSpecifications = ["OP", "HCH", "CLOAN"];
          return !allowedProductSpecifications.includes(
            values?.productSpecification
          );
        },
      },
    ],
    [state]
  );

  const [errors, setErrors] = useState({});
  const errorCheck = useCallback(
    (field, visible) => {
      let error;
      if (typeof visible === "function") {
        visible = visible({ values: state });
      }
      if (visible) {
        if (!state[field] || (state[field] && state[field].length < 1)) {
          error = true;
          setErrors((errors) => {
            return {
              ...errors,
              [field]: "Please Fill this field",
            };
          });
        } else {
          setErrors((errors) => {
            return {
              ...errors,
              [field]: void 0,
            };
          });
        }
      } else {
        setErrors((errors) => {
          return {
            ...errors,
            [field]: void 0,
          };
        });
      }
      return error;
    },
    [state]
  );

  const onClick = useCallback(() => {
    let main = false;
    let error = false;
    columns.map((column) => {
      const { field, visible = true, required = true } = column;
      if (required) error = errorCheck(field, visible);
      if (error) {
        main = true;
      }
    });

    if (!main) return onSubmit();
  }, [columns, onSubmit, errorCheck]);

  useEffect(() => {
    let allowedProductSpecifications = ["OP", "HCH"];
    if (
      state?.maxClaimAmount &&
      !allowedProductSpecifications.includes(state.productSpecification)
    )
      setValue({ amount: state.maxClaimAmount });

    if (state.dateOfDeath || state.dateOfaccident) {
      setValue({
        claimStartDate: state.dateOfDeath || state.dateOfaccident,
        claimEndDate: state.dateOfDeath || state.dateOfaccident,
      });
    }
  }, [state.dateOfDeath, state.dateOfaccident]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: isMobile ? "0px 0px" : "0px 30px",
        }}
      >
        {columns.map((column, index) => {
          let {
            label,
            field,
            requiredMark,
            type = "text",
            visible,
            placeholder,
            max,
            formate,
            disableField,

            ...rest
          } = column;
          let disabled = false;
          let RenderComponent = inputTypes[type];
          if (typeof visible === "function") {
            visible = visible({ values: state });
          }
          if (!visible) {
            return null;
          }
          if (typeof disableField === "function") {
            disabled = disableField({ values: state });
          }
          return (
            <div style={{ width: "350px" }}>
              <RenderComponent
                key={index}
                formate={formate}
                label={label}
                requiredMark={requiredMark}
                error={[errors[field]]}
                state={state}
                options={
                  field === "hospitalId" ? state.hospitals : claimSlabType
                }
                field={field}
                setValue={(date) => {
                  if (type === "date") {
                    setValue({
                      [field]:
                        date === null
                          ? null
                          : moment(date).format("YYYY-MM-DD"),
                    });
                  } else {
                    setValue(date);
                  }
                  setErrors((errors) => {
                    return {
                      ...errors,
                      [field]: void 0,
                    };
                  });
                }}
                attrs={{
                  onChange: (e) => {
                    if (field === "amount" && e.target.value > max) {
                      setErrors((errors) => {
                        return {
                          ...errors,
                          [field]: `Amount should be less than ${max}`,
                        };
                      });
                    } else {
                      setValue({
                        [field]: field === "isPatientDied" ? e : e.target.value,
                      });
                      setErrors((errors) => {
                        return {
                          ...errors,
                          [field]: void 0,
                        };
                      });
                    }
                  },
                  onBlur: (e) => {
                    errorCheck(field);
                  },
                  value: state[field],
                  disabled: disabled,
                }}
                {...rest}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
