export const AbclHeaders = [
  {
    name: "PARENT_POLICY_ID",
    mandatory: true,
    type: "number",
    dataKey: "PARENT_POLICY_ID",
  },
  {
    name: "SUB_POLICY_ID",
    mandatory: true,
    type: "number",
    dataKey: "SUB_POLICY_ID",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const AckoHeaders = [
  {
    name: "PARENT_POLICY_ID",
    mandatory: true,
    type: "number",
    dataKey: "PARENT_POLICY_ID",
  },
  {
    name: "SUB_POLICY_ID",
    mandatory: true,
    type: "number",
    dataKey: "SUB_POLICY_ID",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const CareHeaders = [
  {
    name: "LOAN_CODE",
    mandatory: true,
    type: "string",
    dataKey: "LOAN_CODE",
  },
  { name: "RELATION", mandatory: true, type: "string", dataKey: "RELATION" },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];

export const LibertyHeaders = [
  {
    name: "UNIQUE_REF_NUMBER",
    mandatory: true,
    type: "string",
    dataKey: "UNIQUE_REF_NUMBER",
  },
  {
    name: "POLICY_NO",
    mandatory: true,
    type: "string",
    dataKey: "POLICY_NO",
  },
];
