import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OtpInputGroup from "../../../components/form/OtpInputGroup";
import Button from "../../../components/Button";
import { ApiGet, ApiPost } from "../../../lib/AppHelper";
import { toast } from "react-toastify";
import { DialogTitle } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    width: theme.spacing(45),
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));

export default function OtpDialog({ open, setOpen, getAllDevice }) {
  const [otp, setOtp] = useState("");

  const doVerify = async (e) => {
    e.preventDefault();
    let data = {
      otp: otp,
      session_id: open.id,
    };
    let response = await ApiPost("session-logout", data);
    if (response.status === "success") {
      toast.success(response.message);
      setOpen(false);
      getAllDevice();
    } else {
      toast.error("Please Enter Valid Otp");
      setOtp("");
    }
  };

  const callBackApi = async (item) => {
    const response = await ApiGet(`auth-otp`);
    if (response.status === "success") {
      toast.success(response.message);
    }
  };

  useEffect(() => {
    setOtp("");
  }, [open]);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <h3 className="mt-1 text-xl font-medium text-primary mb-6">
            Verify OTP,
          </h3>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <OtpInputGroup
            value={otp}
            onEnter={doVerify}
            onResend={() => callBackApi()}
            onChange={(value) => {
              const integerRegex = /^-?\d+$/;
              const isValidInteger = integerRegex.test(value);
              if (isValidInteger || value == "") {
                setOtp(value);
              }
            }}
            canResend={true}
          />

          <div style={{ marginTop: "20px" }}>
            <Button
              attrs={{
                disabled: otp.length !== 4,
                type: "button",
                onClick: doVerify,
              }}
              size="lg"
              title="Verify"
            />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
