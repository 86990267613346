import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const SampleHeader = ({samplefile}) => {
  return (
    <p className="text-center m-5">
      <FontAwesomeIcon className="arrow1" icon={faArrowRight}></FontAwesomeIcon>
      Download a{" "}
      <a
        href={samplefile}
        download="Samplefile.xlsx"
        className="text-blue cursor-pointer"
      >
        sample xlsx file{" "}
      </a>{" "}
      and compare it to your import file to ensure you have the file perfect for
      the import.
    </p>
  );
};

export default SampleHeader;
