import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  AppBackButton,
  AppHeading,
  DocumentView,
} from "../components/AppComponents";
import { withRouter } from "react-router";
import Main from "./Main";
import { ApiGet, setError } from "../lib/AppHelper";

import { AppStore } from "../lib/AppStore";
import {
  faCalendarDay,
  faHospital,
  faRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export class ClaimDetail extends Component {
  BasicBtn = React.createRef();

  state = {
    claim: [],
  };

  render() {
    const { claim } = this.state;
    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to="/claims">Back to Claims</AppBackButton>
        <div className="w-full px-6 md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <AppHeading>
            {claim.account_holder_name
              ? claim.account_holder_name
              : "Name not available"}
          </AppHeading>

          <div className="pt-2 text-sm">
            <div className="pb-1 w-100">
              <span className="font-semibold">Reason Of Claim : </span>
              {claim.claim_reason}
            </div>
            <div className="pb-1">
              <span className="font-semibold">Hospitan Name :</span>{" "}
              {claim.hospital ? claim.hospital.name : "Hospital name is empty"}
            </div>
            {claim?.claim_summary &&
              claim.claim_summary.map((item) => {
                return (
                  <div key={item}>
                    <span className="font-semibold">Remarks : </span>
                    {item.remarks}
                  </div>
                );
              })}
          </div>

          <AppHeading size="2">Claim Type</AppHeading>
          <div className="mt-0 text-sm">
            {claim.documents &&
              claim.claim_summary.map((item, i, { length }) => (
                <span size="2">
                  {item.product_clasification} {i + 1 !== length ? ", " : ""}{" "}
                </span>
              ))}
          </div>

          <hr />

          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            <div className="col-span-1 md:border-r-4 border-red-400">
              <AppHeading className="pt-2" size="2">
                <FontAwesomeIcon icon={faRupeeSign} /> Claim Amount
              </AppHeading>

              <p> {claim.total_claim_amount}</p>
            </div>
            <div className="col-span-1 md:border-r-4 border-red-400">
              <AppHeading className="pt-2" size="2">
                <FontAwesomeIcon icon={faCalendarDay} /> Admit Detail
              </AppHeading>

              <div className="text-sm m-0">
                Admit On: {moment(claim.start_date).format("DD MMM YYYY")}
              </div>
              <div className="m-0 text-sm">
                Discharge On: {moment(claim.end_date).format("DD MMM YYYY")}
              </div>
            </div>

            <div className="col-span-1">
              <AppHeading className="pt-2" size="2">
                <FontAwesomeIcon icon={faHospital} /> Reason for Hospitalization
              </AppHeading>

              <p>
                {claim.claim_reason
                  ? claim.claim_reason
                  : "Reason not available"}
              </p>
            </div>
          </div>
          <hr></hr>

          <AppHeading className="pt-2" size="2">
            Report files
          </AppHeading>

          {claim.documents ? (
            <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
              {claim.documents.map((item) => (
                <div className="col-span-1">
                  <DocumentView item={item} status={item.status} />
                </div>
              ))}
            </div>
          ) : (
            false
          )}
        </div>
      </Main>
    );
  }

  async componentDidMount() {
    const id = this.props.match.params.claimId;
    await this.getClaim(id);
  }

  getClaim = async (id) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`claim/${id}`);
    if (response.status === "success") {
      this.setState({ claim: response.data });
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    loading: loading,
  };
};

const ClaimDetailConnect = connect(mapStateToProps)((props) => {
  return <ClaimDetail {...props} />;
});

export default withRouter(ClaimDetailConnect);
