import { CheckIcon } from '@heroicons/react/outline';
import React from 'react'
import { connect } from 'react-redux'

export function InputToggle({ loading = false, label = "", value = false, attrs = {}, children }) {

   let loading_disabled = loading ? { disabled: true } : {};

   return (
      <div className="mb-5">
         <button className={["focus:outline-none focus:ring-0 focus:border-primary block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100 border flex items-center py-2", value ? "bg-primary-50" : ""].join(" ")} {...attrs} {...loading_disabled}>
            {value ? <div className="inline-block w-5 h-5 rounded-full mr-3 bg-green-500 flex items-center justify-center">
               <CheckIcon color={'white'} width={14} />
            </div> : children}
            {label}
         </button>
      </div>
   )
}

const mapStateToProps = (state) => {
   const { loading } = state
   return { loading: loading }
};

export default connect(mapStateToProps)(props => {
   return <InputToggle {...props} />
})