export const excelUploaderLimit = 20001;
export const creditExcelUploaderLimit = 20001;
export const policyExcelUploaderLimit = 20001;
export const groupExcelUploaderLimit = 20001;
export const groupExcelUploaderFileSize = 4194304; // 4MB
export const RESELLER_ENCRYPTION_KEY = "1234567887654321";

export const appointmentType = [
  "appointment_created",
  "pharmacist_joined_call",
  "doctor_joined_call",
  "aiqa_center_joined_call",
];
