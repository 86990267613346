import React, { useEffect, useState } from "react";
import Main from "../../Main";
import { AppBackButton } from "../../../components/AppComponents";
import { Placeholder } from "rsuite";
import SaleCountCard from "./SaleCountCard";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import UserIcon from "../../../images/user.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  getResellerId,
  resellerChildById,
  resellerPolicyCount,
  salesResellerData,
} from "../../../lib/AppHelper";
import { toIndianNumberFormat } from "../../../utils/formatNumber";
import { CheckBox } from "../../../utils/checkBoxField";
import Table from "../../../components/common/Table";
import moment from "moment";
import SalesHeader from "./SalesHeader";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SalesResellerReport = () => {
  const permissions = useSelector((state) => state?.user?.permissions);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [salesInfo, setSalesInfo] = useState({
    activePolicy: 0,
    leadPolicy: 0,
    totalActivePolicy: 0,
    totalLeadPolicy: 0,
  });
  const [cardLoading, setCardLoading] = useState(false);
  const [onSearch, setOnSearch] = useState(false);

  const [resellerSales, setResellerSales] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [saleCountPage, setSaleCountPage] = useState(0);
  const [loadMore, setLoadMore] = useState({
    loadMore: false,
    error: null,
  });

  const checkBoxWidth = { width: 225 };
  const [resellerChild, setResellerChild] = useState([]);
  const [childID, setChildID] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [page, setPage] = useState(0);

  let timer = 1000;
  const [salesData, setSalesData] = useState([]);
  const [count, setCount] = useState(0);
  const [loadSales, setLoadSales] = useState({ size: 5, page: 1 });
  const [salesSearch, setSalesSearch] = useState(false);

  const saleCardInfo = [
    { label: "Active Policies", count: salesInfo.activePolicy },
    { label: "Lead Policies", count: salesInfo.leadPolicy },
    {
      label: "Active Amount",
      count: salesInfo.totalActivePolicy,
      rupeeSymbol: true,
    },
    {
      label: "Lead Amount",
      count: salesInfo.totalLeadPolicy,
      rupeeSymbol: true,
    },
  ];

  const salesTableHeaders = [
    { label: "Name", dataKey: "resellerName" },
    {
      label: "Mobile",
      dataKey: "mobile",
    },
    {
      label: "Amount",
      dataKey: "amount",
      cell: (row) => amountFormat(row, "amount"),
    },
    {
      label: "Booking Date",
      dataKey: "bookingDate",
      cell: (row) => dateFormate(row, "bookingDate"),
    },
    {
      label: "Disbursed Date",
      dataKey: "startDate",
      cell: (row) => dateFormate(row, "startDate"),
    },
    {
      label: "Product",
      dataKey: "productDisplayName",
    },
    {
      label: "Member ID",
      dataKey: "insuredMemberId",
    },
    {
      label: "Payment Status",
      dataKey: "type",
      cell: (row) => paymentStatus(row, "type"),
    },
  ];

  const dateFormate = (row, key) => {
    return row[key] ? moment(row[key]).format("DD-MM-YYYY") : "";
  };
  const amountFormat = (row, key) => {
    return toIndianNumberFormat(row[key]);
  };
  const paymentStatus = (row, key) => {
    if (row[key] === "Policy") {
      return "Received";
    }
    return "Pending";
  };

  const handleDateChange = (value, key) => {
    let dateValue = moment(value).format("YYYY-MM-DD");
    if (key === "from") {
      setDateRange({ ...dateRange, startDate: dateValue });
    } else if (key === "to") {
      setDateRange({ ...dateRange, endDate: dateValue });
    }
  };

  const handleRefresh = () => {
    setDateRange({ startDate: new Date(), endDate: new Date() });
    setResellerSales([]);
    setSalesInfo([]);
    setResellerChild([]);
    setExpanded("");
    setSalesData([]);
    setSaleCountPage(0);
  };

  const onSearchHandle = () => {
    setResellerSales([]);
    setSalesInfo([]);
    setResellerChild([]);
    setExpanded("");
    setSalesData([]);
    setSaleCountPage(0);
    setLoadMore({ ...loadMore, loadMore: false, error: null });
    fetchResellerSalesCount();
  };

  const onAccordClick = (isExpanded, id) => {
    if (isExpanded) {
      setExpanded(id);
      setLoadSales({ size: 5, page: 1 });
      fetchResellerChild(id);
      setChildID([id]);
    } else {
      setExpanded("");
    }
  };

  const handleLoadMore = () => {
    setSaleCountPage((pg) => pg + 1);
  };

  const onCheckListChange = (id) => {
    setChildID(id);
  };

  const onSalesSearch = () => {
    setLoadSales({ size: 5, page: 1 });
  };

  const salesDataChange = (page) => {
    setLoadSales({ ...loadSales, page: page });
  };

  const fetchResellerSalesCount = async (startDate, endDate) => {
    setOnSearch(true);
    if (saleCountPage == 0) {
      setCardLoading(true);
    }

    let data = {
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(dateRange.endDate).format("YYYY-MM-DD"),
      resellerId: [getResellerId()],
      page: saleCountPage,
      size: 5,
      mode: "",
    };

    const response = await resellerPolicyCount(data);
    if (response?.code === 200) {
      if (response?.responseObject) {
        if (saleCountPage == 0) {
          setSalesInfo({
            activePolicy: response?.responseObject?.totalActivePolicy,
            leadPolicy: response?.responseObject?.totalLeadCount,
            totalActivePolicy:
              response?.responseObject?.totalActivePolicyAmount,
            totalLeadPolicy: response?.responseObject?.totalLeadPolicyAmount,
          });
        }
        if (
          response?.responseObject?.policyCountDto.length > 0 &&
          response?.responseObject?.policyCountDto.length < 6
        ) {
          setResellerSales((resellerSales) => [
            ...resellerSales,
            ...response?.responseObject?.policyCountDto,
          ]);
          setOnSearch(false);
        } else {
          setLoadMore({ ...loadMore, loadMore: true });
          if (saleCountPage != 0) {
            toast.error("No more records to display");
          }
        }
      } else {
        setResellerSales([]);
      }
    } else {
      setResellerSales([]);
    }
    setCardLoading(false);
    setOnSearch(false);
  };

  const fetchResellerChild = async (resellerId) => {
    let data = {
      startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
      resellerId: resellerId,
      page: page,
      size: 5,
    };

    const response = await resellerChildById(data);
    if (response?.code === 200) {
      if (response?.responseObject && response?.responseObject?.length !== 0) {
        setResellerChild([...resellerChild, ...response?.responseObject]);
      } else {
        setShowMore(false);
      }
    } else {
      setResellerChild([]);
    }
  };

  const fetchSalesData = async (pg) => {
    setSalesSearch(true);

    let interval = null;
    clearInterval(interval);
    let data = {
      startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
      resellerId: childID,
      page: pg ? pg - 1 : loadSales.page - 1,
      size: loadSales.size,
      mode: "",
    };

    const response = await salesResellerData(data);
    if (response?.code === 200) {
      if (response?.responseObject?.salesReportDtoForChildData) {
        setSalesData(response?.responseObject?.salesReportDtoForChildData);
        setCount(response?.count);
        setSalesSearch(false);
      } else {
        setSalesSearch(false);
        setCount(response?.count);
        setSalesData([]);
      }
      timer = 1000;
    } else if (response?.code === 502 || response?.code === 503) {
      interval = setInterval(() => {
        fetchSalesData(pg ? pg - 1 : loadSales.page - 1);
      }, timer);

      timer = timer * 2;
    } else {
      timer = 1000;
      toast.error(response?.message);
    }
  };

  useEffect(() => {
    fetchResellerSalesCount();
  }, [saleCountPage]);

  useEffect(() => {
    if (childID?.length) {
      fetchSalesData();
    }
  }, [loadSales]);

  return (
    <div>
      <Main>
        <div className="grid md:grid-cols-2 gap-2">
          <div>
            <AppBackButton to="/">Back</AppBackButton>
          </div>
        </div>
        {permissions && permissions?.reseller_sales_report ? (
          <>
            <SalesHeader
              dateRange={dateRange}
              onSearch={onSearch}
              handleDateChange={handleDateChange}
              handleRefresh={handleRefresh}
              onSearchHandle={onSearchHandle}
              request={fetchResellerSalesCount}
            />
            <div className="w-full flex flex-wrap justify-around mt-5">
              <SaleCountCard cardInfo={saleCardInfo} loading={cardLoading} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "10px",
              }}
            >
              {resellerSales?.length > 0 ? (
                resellerSales?.map((saleInfo, index) => {
                  return (
                    <>
                      <Accordion
                        key={index}
                        className="mb-5"
                        style={{
                          backgroundColor: "#FDEDEC",
                          color: "black",
                          borderColor: "black",
                        }}
                        expanded={expanded == saleInfo?.resellerId}
                        onChange={(e, expanded) => {
                          onAccordClick(expanded, saleInfo?.resellerId);
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ArrowDropDownIcon />}
                          aria-controls="panel1a-content"
                        >
                          <div className="w-full flex flex-wrap justify-between">
                            <div className="flex flex-wrap justify-evenly items-center">
                              <img
                                src={UserIcon}
                                alt="profile.jpg"
                                className="h-9 w-9 mr-2"
                              />
                              <h4 style={{ textTransform: "capitalize" }}>
                                {saleInfo?.resellerName}
                              </h4>
                            </div>
                            <div className="flex flex-wrap items-center">
                              <div className="flex gap-1 flex-wrap items-center">
                                <h3 className="text-center">
                                  Active Policies :{" "}
                                </h3>
                                <h5 className="ml-1 mr-2">
                                  &#8377;
                                  {saleInfo.activePolicyAmount
                                    ? toIndianNumberFormat(
                                        Math.round(saleInfo.activePolicyAmount)
                                      )
                                    : "0"}
                                </h5>
                              </div>
                              <div className="flex gap-1 flex-wrap items-center">
                                <h3 className="text-center">
                                  Lead Policies :{" "}
                                </h3>
                                <h5 className="ml-1 mr-2">
                                  &#8377;
                                  {saleInfo.leadPolicyAmount
                                    ? toIndianNumberFormat(
                                        Math.round(saleInfo.leadPolicyAmount)
                                      )
                                    : "0"}
                                </h5>
                              </div>
                              <div className="flex gap-1 flex-wrap items-center">
                                <h3 className="text-center">Total : </h3>
                                <h5 className="ml-1 mr-2">
                                  &#8377;
                                  {saleInfo.totalPolicyAmount
                                    ? toIndianNumberFormat(
                                        Math.round(saleInfo.totalPolicyAmount)
                                      )
                                    : "0"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="m-2 flex items-center">
                            <CheckBox
                              id={saleInfo?.resellerId}
                              data={resellerChild}
                              handleDataChange={onCheckListChange}
                              value={childID}
                              setValue={(id) => setChildID(id)}
                              showItems={page}
                              setShowItems={setPage}
                              showMore={true}
                              noMore={showMore}
                              fetchData={fetchResellerChild}
                              style={checkBoxWidth}
                            />
                            <div className="h-8 mr-2 ml-2">
                              <button
                                className="w-full
                           shadow whitespace-nowrap
                            flex items-center justify-center
                             px-2 py-1 border border-transparent
                              text-sm rounded-xl text-white
                               bg-primary hover:bg-primary-700
                                active:bg-primary-800 focus:outline-none
                                 focus:ring-2 focus:ring-offset-2
                                  focus:ring-primary-500"
                                onClick={() => onSalesSearch()}
                                disabled={salesSearch}
                              >
                                {"Search"}
                              </button>
                            </div>
                          </div>

                          <div className="relative overflow-auto">
                            <Table
                              headers={salesTableHeaders}
                              data={salesData}
                              total={count}
                              loader={salesSearch}
                              page={loadSales.page}
                              limit={loadSales.size}
                              onPageChange={salesDataChange}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {index + 1 == resellerSales?.length &&
                      !loadMore.loadMore ? (
                        <div className="flex flex-row-reverse">
                          <div
                            style={{
                              padding: "5px",
                              cursor: "pointer",
                              color: "#db2228",
                              fontWeight: "700",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              handleLoadMore();
                            }}
                          >
                            Load More
                          </div>
                        </div>
                      ) : (
                        false
                      )}
                    </>
                  );
                })
              ) : onSearch ? (
                <>
                  <Placeholder.Paragraph rows={8} />
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  <h2 style={{ fontSize: "18px" }}>No records to display</h2>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="text-red-500">
            <h4>You don't have permission to view Team Performance Report</h4>
          </div>
        )}
      </Main>
    </div>
  );
};

export default SalesResellerReport;
