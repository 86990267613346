import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { CSVLink } from "react-csv";
import { RefreshIcon } from "@heroicons/react/solid";

export class ResellerDataReport extends Component {
    MessageRef = React.createRef();
    state = {
        reports: [],
        headers: [
            { label: "Reseller Name", key: "reseller_name" },
            { label: "Product Name", key: "product_name" },
            { label: "Customer Name", key: "customer_name" },
            { label: "Customer Mobile", key: "customer_mobile" },
            { label: "Policy Start Date", key: "policy_start_date" },
            { label: "Policy End Date", key: "policy_end_date" },
            { label: "Uploaded Date", key: "created_on" },
        ],
        params: {
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            employee_id: "",
        },
        employees: [],
        toMaxDate: new Date(),
    };

    onChangeHandler = (e, key) => {
        const { params } = this.state;
        params[key] = e.target.value;
        this.setState({ params: params });
    };

    onChangeHandlerDate = (e, key) => {
        const { params } = this.state;
        if (key === 'from') {
            let date = new Date(e).toISOString().split("T")[0];
            const date1 = new Date(date);
            const date2 = new Date(params.to);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 31) {
                let todate = new Date(moment(date1).add(30, 'd')).toISOString().split("T")[0];
                params['to'] = todate;
                let todateNew = new Date(todate);
                this.setState({ toMaxDate: todateNew });
            }
        }
        params[key] = new Date(e).toISOString().split("T")[0];
        this.setState({ params: params });
        this.setState({ params: { ...this.state.params, page: 1 } });
    };

    handleClickReset = () => {
        const { params } = this.state;
        params['to'] = moment().format("YYYY-MM-DD");
        params['from'] = moment().format("YYYY-MM-DD");
        params['employee_id'] = '';
        this.setState({ toMaxDate: new Date() });
        this.setState({ params: params });
    };

    render() {
        const { params, reports, headers, employees, toMaxDate } = this.state;
        const { user } = this.props;
        const { permissions } = user;
        return (
            <Main MessageRef={this.MessageRef}>
                {permissions && permissions?.upload_date_report ? (
                    <>
                        <div className="grid md:grid-cols-2 gap-2">
                            <div>
                                <AppBackButton to="/">Back</AppBackButton>
                            </div>
                            <div className="text-right">
                                <div style={{ maxWidth: "20%" }} className="float-right">
                                    <button
                                        style={{ fontSize: "12px" }}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                                    >
                                        <CSVLink
                                            data={reports}
                                            headers={headers}
                                            filename={"reseller-excel-report.csv"}
                                        >
                                            Download
                                        </CSVLink>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-6 gap-4">
                            <div>
                                <label className="flex text-sm text-gray-700 justify-left pb-2">
                                    Select Team
                                </label>
                                <select
                                    onChange={(e) => this.onChangeHandler(e, "employee_id")}
                                    value={params.employee_id} className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                                >
                                    <option value="">Me</option>
                                    {employees.map((option, index) => {
                                        return (
                                            <option value={option.id} key={index}>
                                                {option.name} ({option.mobile})
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div>
                                <InputDate
                                    label="From"
                                    value={params.from}
                                    placeholder="from"
                                    maxDate={new Date(params.to)}
                                    attrs={{
                                        onChange: (e) => this.onChangeHandlerDate(e, "from"),
                                        value: new Date(params.from)
                                    }}
                                />
                            </div>
                            <div>
                                <InputDate
                                    label="To"
                                    placeholder="To"
                                    minDate={new Date(params.from)}
                                    maxDate={new Date()}
                                    attrs={{
                                        onChange: (e) => this.onChangeHandlerDate(e, "to"),
                                        value: new Date(params.to)
                                    }}
                                />
                            </div>

                            <div className="flex flex-row" style={{ maxWidth: "50%", paddingTop: "22px", }}>
                                <button
                                    onClick={this.handleClickReset}
                                    className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                                >
                                    <RefreshIcon width={25} className="m-1" />
                                </button>

                                <Button
                                    className="ml-5"
                                    attrs={{ type: "button", onClick: this.handleClick }}
                                    size="md"
                                    title={"Search"}
                                />
                            </div>

                            <div style={{ paddingTop: "22px" }}>
                                <h2>Total Row: {reports.length ? reports.length : 0}</h2>
                            </div>

                        </div>

                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full border" id="table-to-xls">
                                            <thead className="border-b">
                                                <tr>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Reseller Name
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Product Name
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Customer Name
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Customer Mobile
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Policy Start Date
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Policy End Date
                                                    </th>
                                                    <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                                        Uploaded Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {reports.map((record, index) => {
                                                    return (
                                                        <tr className="border-b" key={index}>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                {record?.reseller_name}
                                                            </td>
                                                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                                {record?.product_name}
                                                            </td>
                                                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                                                {record?.customer_name}
                                                            </td>
                                                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                                                {record?.customer_mobile}
                                                            </td>
                                                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                                                {record?.policy_start_date}
                                                            </td>
                                                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                                                {record?.policy_end_date}
                                                            </td>
                                                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                                                {record.created_on}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                {reports.length === 0 ? (
                                                    <tr>
                                                        <td
                                                            colSpan="9"
                                                            height="50"
                                                            className="text-red-500 font-light text-center"
                                                        >
                                                            Your result is empty
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    false
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <AppBackButton to="/">Back</AppBackButton>
                        <div className="text-red-500">
                            <h4>You do not have permission to view Upload Date Report</h4>
                        </div>
                    </div>
                )}
            </Main>
        );
    }

    componentDidMount() {
        this.getReports();
        this.getTeam();
    }
    handleClick = () => {
        this.getReports(this.state.params);
    };

    getTeam = async () => {
        const response = await ApiGet(`team`);
        if (response.status === "success") {
            this.setState({ employees: response.data });
        }
    };

    getReports = async (request) => {
        this.MessageRef.current.hide();

        AppStore.dispatch({ type: "LOADING", loading: true });
        const response = await ApiGet("report/reseller-excel-report", request);
        if (response.status === "success") {
            if (response.data) {
                const items = response.data;
                this.setState({ reports: items });
            } else {
                this.state.reports = [];
            }
        } else {
            this.MessageRef.current.show(response.message);
        }

        AppStore.dispatch({ type: "LOADING", loading: false });
    };
}

const mapStateToProps = (state) => {
    const { loading, user } = state;
    return { loading: loading, user: user };
};

const ResellerDataReportConnect = connect(mapStateToProps)((props) => {
    return <ResellerDataReport {...props} />;
});

export default withRouter(ResellerDataReportConnect);
