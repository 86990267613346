import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { ApiGet, getApiClaim } from "../../lib/AppHelper";
import moment from "moment";
import { AppStore } from "../../lib/AppStore";

const keyObj = {
  memberId: "Member Id",
  name: "Name",
  mobileNumber: "Mobile number",
  gender: "Gender",
  dob: "D.O.B",
  policyStartDate: "Policy start date",
  policyEndDate: "Policy end date",
};

const UserInformation = ({
  onSubmit,
  setValue,
  state,
  prevStep,
  isMobile,
  claimId,
  subClaimId,
}) => {
  const [userInfo, setUserInfo] = useState([]);

  const fetchUserInfo = async (id) => {
    const response = await getApiClaim(`customerDetails/${id}`);
    if (response.code === 200) {
      let policyEndDate = moment(response?.data[0]?.policyEndDate).format(
        "DD-MM-YYYY"
      );
      let policyStartDate = moment(response?.data[0]?.policyStartDate).format(
        "DD-MM-YYYY"
      );
      setUserInfo(response.data);
      setValue({
        policyEndDate: policyEndDate,
        policyStartDate: policyStartDate,
      });
    } else {
      console.log("failed to fetch user");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  const fetchProductSpecification = async (claimId, subClaimId) => {
    const response = await ApiGet(
      `claim?claim_id=${claimId}&sub_claim_id=${subClaimId}`
    );
    const { sub_policy_detail } = response.data;
    setValue({
      productSpecification:
        sub_policy_detail?.product_specification?.product_specification,
    });
  };

  function capitalizeFirstLetter(inputString) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }
  useEffect(() => {
    if (state?.memberId) {
      if (claimId && subClaimId) fetchProductSpecification(claimId, subClaimId);
      AppStore.dispatch({ type: "LOADING", loading: true });
      fetchUserInfo(state.memberId);
    }
  }, []);

  return userInfo.length > 0 ? (
    <div>
      <table className="text-left" style={{ width: "100%" }}>
        <tbody>
          {Object.keys(userInfo[0]).map((key, index) => {
            return (
              <tr
                className={
                  Object.keys(userInfo[0]).length - 1 !== index && "border-b"
                }
              >
                <td className="text-sm font-medium text-gray-900 py-4 whitespace-nowrap ">
                  {keyObj[key]}&nbsp;:
                </td>
                <td
                  className="font-medium py-4 whitespace-nowrap"
                  style={{ textTransform: "capitalize", opacity: 0.7 }}
                >
                  {key === "policyStartDate" || key === "policyEndDate"
                    ? moment(userInfo[0][key]?.split(" ")[0]).format(
                        "DD-MM-YYYY"
                      )
                    : key === "dob"
                    ? moment(userInfo[0][key]).format("DD-MM-YYYY")
                    : capitalizeFirstLetter(userInfo[0][key])}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              //   ref: this.BasicBtn,
              type: "button",
              onClick: () => onSubmit(),
              disabled: false,
            }}
            // options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{ height: "200px", display: "grid", placeItems: "center" }}
    ></div>
  );
};

export default UserInformation;
