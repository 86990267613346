import { CheckIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function InputTextArea({
    loading = false,
    requiredMark = "",
    starMark = false,
    label = "",
    labelLink = null,
    placeholder = "",
    prefix = "",
    attrs = {},
    onEnter = () => { },
    error = "",
    action = null,
    checked = false,
    isError = false,
    validateError,
    valdiateOnChange,
    name = "",
    disabled = false,
}) {
    const [showError, setShowError] = useState(isError);
    let loading_disabled = loading ? { disabled: true } : {};
    const handleOnBlur = () => {
        attrs.onBlur && attrs.onBlur();
        if (requiredMark && !attrs.value) {
            setShowError(true);
        } else if (validateError && typeof validateError === "function") {
            const err = validateError(attrs.value);
            if (err) {
                setShowError(err);
            } else {
                setShowError(false);
            }
        } else {
            setShowError(false);
        }
    };
    const handleOnChange = (e) => {
        attrs.onChange && attrs.onChange(e);
        if (valdiateOnChange) {
            if (requiredMark && !attrs.value) {
                setShowError(true);
            } else if (validateError && typeof validateError === "function") {
                const err = validateError(attrs.value);
                if (err) {
                    setShowError(err);
                } else {
                    setShowError(false);
                }
            } else {
                setShowError(false);
            }
        }
    };
    useEffect(() => {
        setShowError(isError);
    }, [isError]);

    return (
        <div className="mb-5">
            <InputLabel
                label={label}
                labelLink={labelLink}
                requiredMark={requiredMark}
                starMark={starMark}
            />
            <div className="mt-2 relative">
                {prefix ? (
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-800 text-base">{prefix}</span>
                    </div>
                ) : (
                    false
                )}
                <div className="flex">
                    <textarea 
                        maxLength={256}
                        name={name}
                        disabled={disabled}
                        className={[
                            `focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 block w-full ${prefix ? "px-12" : "px-4"
                            } text-base border-gray-300 rounded-xl disabled:bg-gray-100`,
                            attrs?.readOnly ? "border-primary bg-primary-50" : "",
                        ].join(" ")}
                        placeholder={placeholder}
                        onKeyPress={(e) => e.key === "Enter" && onEnter()}
                        {...attrs}
                        {...loading_disabled}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                    {action !== null ? action : false}
                    {checked === true ? (
                        <div
                            className="bg-green-500 w-5 h-5 rounded-full inline-flex items-center justify-center"
                            style={{ position: "absolute", top: 10, right: 10 }}
                        >
                            <CheckIcon width={14} height={14} className="text-white" />
                        </div>
                    ) : (
                        false
                    )}
                </div>
            </div>
            {error ? (
                <p className="mt-2 text-xs text-red-500">{error.join(" ")}</p>
            ) : (
                false
            )}
            {showError ? (
                <p className="mt-2 text-xs text-red-500">
                    {typeof showError === "string" ? showError : `${label} is required`}
                </p>
            ) : (
                false
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    const { loading } = state;
    return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
    return <InputTextArea {...props} />;
});
