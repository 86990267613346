import React, { Component } from "react";
import { AppBackButton } from "../../components/AppComponents";
import Main from "../Main";
import Button from "../../components/Button";
import {
  XIcon,
  TrashIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import * as XLSX from "xlsx";
import { AppStore } from "../../lib/AppStore";
import { headers } from "./Headers";
import sampleFile from "./sampleFile.xlsx";
import FileSaver from "file-saver";
import {
  excelUploder,
  setError,
  setMessage,
  validateEmail,
  ApiGet,
  getReportingEmail,
} from "../../lib/AppHelper";
import ErrorDataTable from "./ErrorDataTable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Encryption } from "../../helpers/Helper";
import { PlanOption } from "../../components/PlanOption";
import _ from "lodash";
import { InputRadio } from "../../components/form/InputRadio";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputGroup } from "../../components/form/InputGroup";
import moment from "moment";
import Stepper from "../../components/common/Stepper";
import {
  RESELLER_ENCRYPTION_KEY,
  excelUploaderLimit,
} from "../../lib/constants";

const steps = [
  { title: "Select Product" },
  { title: "Upload Excel File" },
  { title: "Preparation" },
  { title: "Upload" },
];
export class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        product_id: null,
        file: "",
        uploadExcelDtoList: "",
        emailRecipients: "",
      },
      email: "",
      file: "",
      errorDataRow: [],
      errorData: [],
      data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
      cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */,
      validate: false,
      errMsg: "",
      filename: "",
      current: 0,
      totalStep: 2,
      toggleErrData: false,
      encResellerId: "",
      userEmail: "",
      reseller_id: null,
      reportingEmail: getReportingEmail(),
    };
    this.fileRender = this.fileRender.bind(this);
    this.onChangeHandle = this.onChangeHandle.bind(this);
    this.validateHeader = this.validateHeader.bind(this);
    this.validateExcelData = this.validateExcelData.bind(this);
    this.onSubmitHandle = this.onSubmitHandle.bind(this);
    this.onProductChangeHandler = this.onProductChangeHandler.bind(this);
    // this.reseller_id = props.reseller_id;
  }

  // get resaller id
  getResellerId() {
    ApiGet(`me`).then((res) => {
      if (res.status === "success") {
        this.setState({
          reseller_id: res.data.basic.reseller_id,
          userEmail: res.data.email,
          params: {
            ...this.state.params,
            emailRecipients: res.data.email,
          },
        });
        const encData = Encryption(
          this.state.reseller_id,
          RESELLER_ENCRYPTION_KEY
        );
        this.setState({ encResellerId: encData });
      }
    });
  }

  // componentDidMount() {
  //   this.getResellerId();
  // }

  // submit handle
  onSubmitHandle() {
    AppStore.dispatch({ type: "LOADING", loading: true });
    if (this.state.params.uploadExcelDtoList?.length > 0) {
      // json to xlsx
      const ws = XLSX.utils.json_to_sheet(this.state.params.uploadExcelDtoList);
      // remove blank row

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      const wbout = XLSX.write(wb, {
        bookSST: true,
        type: "binary",
        compression: true,
      });

      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };
      const file1 = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
      });

      // blob to xlsx file
      const file = new File([file1], this.state.filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      excelUploder(
        this.state.encResellerId,
        file,
        this.state.params.emailRecipients
      )
        .then((res) => {
          if (res.code === 200) {
            setMessage("Excel file uploaded successfully", () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/excelUploader");
            });
          } else {
            setError(res.message, () => {
              AppStore.dispatch({ type: "LOADING", loading: false });
              this.props.history.push("/excelUploader");
            });
          }
        })
        .catch(() => {
          setError("Something went wrong", () => {
            AppStore.dispatch({ type: "LOADING", loading: false });
            this.props.history.push("/excelUploader");
          });
        });
    } else {
      setError("no data found for the upload", () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  }

  // header match validation
  validateHeader = async () => {
    const { data, cols } = this.state;
    const header = data[0];
    const headerLength = header.length;
    const headersLength = headers.length;

    if (headerLength === headersLength) {
      for (let i = 0; i < headerLength; i++) {
        const headerName = header[i];
        const headerObj = headers[i].name;
        if (headerName?.toLowerCase() === headerObj?.toLowerCase()) {
          cols.push(headerObj);
        } else {
          return `${headerName} is not match with ${headerObj}`;
        }
      }
      return true;
    } else {
      return "headers not match please download sample excel file for reference";
    }
  };

  validateExcelData = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { data } = this.state;
    data[0].push("Error Message");
    const errDataRow = [data[0]];
    const errData = [];
    const succData = [];
    let errMessage = [];

    let errCount = 0;
    data.map((row, index) => {
      if (index > 0) {
        let count = 0;
        let obj = {};
        headers.map((header, i) => {
          if (header.name === "Product_id") {
            if (
              row[i] === "" ||
              row[i] === null ||
              row[i] === undefined ||
              row[i] == "0"
            ) {
              row[i] = this.state.params.product_id;
            }
          }
          if (header.name === "Coinsured_Name") {
            if (row[i]) {
              headers[i + 1].mandatory = true;
              headers[i + 2].mandatory = true;
              headers[i + 3].mandatory = true;
              headers[i + 4].mandatory = true;
              headers[i + 5].mandatory = true;
            } else {
              headers[i + 1].mandatory = false;
              headers[i + 2].mandatory = false;
              headers[i + 3].mandatory = false;
              headers[i + 4].mandatory = false;
              headers[i + 5].mandatory = false;
            }
          }
          if (
            header.name === "Family_1_name" ||
            header.name === "Family_2_name" ||
            header.name === "Family_3_name" ||
            header.name === "Family_4_name"
          ) {
            if (row[i]) {
              headers[i + 1].mandatory = true;
              headers[i + 2].mandatory = true;
              headers[i + 3].mandatory = true;
            } else {
              headers[i + 1].mandatory = false;
              headers[i + 2].mandatory = false;
              headers[i + 3].mandatory = false;
            }
          }
          if (header.name === "Reseller_id") {
            row[i] = this.state.reseller_id;
          }

          if (header.mandatory) {
            if (row[i] === undefined || row[i] === "") {
              errCount = 1;
              errMessage.push("please fill " + header.name);
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i] === undefined ? "" : row[i],
                err: "please fill " + header.name,
              });
            } else if (
              header.type &&
              header.type === "number" &&
              /^[0-9]+$/.test(row[i]) === false
            ) {
              errCount = 1;
              errMessage.push("please fill valid " + header.name);
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Please enter a valid value in " + header.name,
              });
            }
            // check dob is valid
            else if (
              header.name === "Cust_Dob" &&
              moment(row[i], "DD/MM/YYYY", true).isValid() === false
            ) {
              errCount = 1;
              errMessage.push("please fill valid " + header.name);
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Please enter a valid DOB in format DD/MM/YYYY",
              });
            } else if (
              header.name === "Disbursementdate" &&
              moment(row[i], "DD/MM/YYYY", true).isValid() === false
            ) {
              errCount = 1;
              errMessage.push("please fill valid Disbursementdate");
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Please enter a valid disbursementdate in " + header.name,
              });
            } else if (header.name === "Mobile" && `${row[i]}`.length != 10) {
              errCount = 1;
              errMessage.push("mobile number must contain 10 digit");
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Please enter a valid mobile number",
              });
            } else if (
              header.name === "Cust_Name" &&
              /[0-9!@#$%*()]/.test(`${row[i]}`)
            ) {
              errCount = 1;
              errMessage.push("Please enter a valid customer name");
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Please enter a valid customer name",
              });
            } else if (
              header.name === "Disbursementdate" &&
              moment(row[i], "DD/MM/YYYY", true).isBefore(
                moment(row[9], "DD/MM/YYYY", true)
              )
            ) {
              errCount = 1;
              errMessage.push(
                "disbursement date should be greater then customer date of birth"
              );
              errData.push({
                header: header.name,
                index: index + 1,
                data: moment(row[i]).format("DD/MM/YYYY"),
                err: "disbursement date should be greater then customer date of birth",
              });
            } else if (header.name === "Pincode" && `${row[i]}`.length != 6) {
              errCount = 1;
              errMessage.push("Pincode must contain 6 digits");
              errData.push({
                header: header.name,
                index: index + 1,
                data: row[i],
                err: "Pincode must be 6 digits",
              });
            } else {
              count++;
              Object.assign(obj, { [header.name]: row[i] });
            }
          } else {
            count++;
            Object.assign(obj, { [header.name]: row[i] });
          }
        });
        if (count === headers.length) {
          succData.push(obj);
        }
        if (errCount === 1) {
          row[headers.length + 1] = errMessage
            .map((err) => {
              return err;
            })
            .join(", ");
          errDataRow.push(row);
          errCount = 0;
          errMessage = [];
        }
      }
      // count = 0;
    });
    const { params } = this.state;
    this.setState({ errorData: errData });
    this.setState({ params: { ...params, uploadExcelDtoList: succData } });
    AppStore.dispatch({ type: "LOADING", loading: false });
    this.setState({ errorDataRow: errDataRow });
  };

  //  xls file validation

  fileRender = async (file) => {
    /* Boilerplate to set up FileReader */
    let res = false;
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
    // return promise function
    return new Promise((resolve, reject) => {
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? "binary" : "array",
          cellDates: true,
          dateNF: "dd/mm/yyyy;@",
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: false,
        });

        /* Update state */
        this.setState({ data: data });
        if (data.length > excelUploaderLimit + 1) {
          setError(
            `Excel file should not be more than ${excelUploaderLimit} rows`,
            () => {}
          );
          return resolve(false);
        } else if (data.length === 0) {
          setError("Excel file should not be empty", () => {});
          return resolve(false);
        } else if (data[0][4] === "Loan_Code") {
          let valueArr = data.map(function (item, i) {
            if (item[4]) {
              return item[4];
            } else {
              return i + 199991;
            }
          });
          let isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx;
          });

          if (isDuplicate) {
            setError("Loan Code should be unique", () => {});
            return resolve(false);
          } else {
            return resolve(true);
          }
        } else {
          return resolve(true);
        }
      };
    });
  };

  onProductChangeHandler = (e, key) => {
    const { params } = this.state;
    this.setState({ params: { ...params, [key]: e.target.value } });
  };

  onChangeHandle(e) {
    const { params } = this.state;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const file = e.target.files[0];
    // get file size
    const fileSize = file.size;

    const ext = file.name.split(".").pop();

    // updated size to 5 MB
    if (fileSize > 5100000) {
      setError("File size should not be more than 5 MB", () => {});
      // this.setState({ errMsg: "File size should be less than 4MB" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else if (ext === "xlsx") {
      this.fileRender(file).then((res) => {
        if (res) {
          this.setState({
            params: { ...params, file: file },
            filename: file.name,
            errMsg: "",
          });
          AppStore.dispatch({ type: "LOADING", loading: false });
        } else {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      });
    } else {
      setError("Please select only xlsx file", () => {});
      // this.setState({ errMsg: "Please select only xlsx file" });
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  }

  handleButtonTitle() {
    const { current } = this.state;
    if (current === 0 || current === 1 || current === 2) {
      return "Next";
    } else if (current === 3) {
      return "Upload";
    }
  }

  // create and download xlsx file from array of data

  downloadFile = () => {
    const { errorDataRow } = this.state;
    const ws = XLSX.utils.json_to_sheet(errorDataRow, {
      skipHeader: true,
      cellStyles: true,
    });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(data, "MismatchDataFile.xlsx");
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getResellerId();
  }

  resize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    const { params } = this.state;
    const { products, user } = this.props;
    const { permissions } = user;
    const items = [];
    if (this.state.current === 0) {
      _.each(products, (prod) => {
        items.push({
          value: prod.id,
          label: (
            <PlanOption
              amount={prod.price}
              title={prod.name}
              features={[
                `${prod.product.max_members} Members`,
                `${prod.product.description ? prod.product.description : ""}`,
                ..._.map(prod.inclusions, "title"),
              ]}
            />
          ),
        });
      });
    } else {
      _.each(products, (prod) => {
        prod.id == this.state.params.product_id &&
          items.push({
            value: prod.id,
            label: (
              <PlanOption
                amount={prod.price}
                title={prod.name}
                features={[
                  `${prod.product.max_members} Members`,
                  `${prod.product.description ? prod.product.description : ""}`,
                  ..._.map(prod.inclusions, "title"),
                ]}
              />
            ),
          });
      });
    }
    const steps = [
      { title: "Select Product" },
      { title: "Upload Excel File" },
      { title: "Preparation" },
      { title: "Upload" },
    ];

    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.upload_excel ? (
          <>
            <AppBackButton to="/" />
            <p className="text-center m-5">
              <FontAwesomeIcon
                className="arrow1"
                icon={faArrowRight}
              ></FontAwesomeIcon>
              Download a{" "}
              <a
                href={sampleFile}
                download="sampleFile.xlsx"
                className="text-blue cursor-pointer"
              >
                sample xlsx file{" "}
              </a>{" "}
              and compare it to your import file to ensure you have the file
              perfect for the import.
            </p>

            <div className="mb-14">
              {this.state.isMobile ? (
                <p className="flex flex-col border-b-2 items-center">
                  <span className="flex justify-center text-center border-2 rounded-xl h-8 w-8">
                    {this.state.current + 1}
                  </span>
                  {steps[this.state.current].title}
                </p>
              ) : (
                <Stepper activeStep={this.state.current} steps={steps} />
              )}
            </div>

            <div>
              {this.state.current === 1 && (
                <div>
                  <div className="flex flex-col justify-center items-center mt-10">
                    <label>
                      {!this.state.params.file ? "Upload File " : "File Name"}
                      <code className="text-red">*</code>
                    </label>
                    <div className="flex items-center justify-center bg-grey-lighter mt-5">
                      <label
                        className={`relative flex pl-3 items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-red ${
                          !this.state.filename ? "cursor-pointer" : ""
                        } hover:bg-red hover:text-white`}
                      >
                        {!this.state.filename ? (
                          <>
                            <svg
                              className="w-8 h-8 mr-2"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                            >
                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                            </svg>
                            <span className="mt-1 mr-2 text-base leading-normal">
                              Select a file
                            </span>
                            <input
                              type="file"
                              value={this.state.params.file}
                              onChange={this.onChangeHandle}
                              className="hidden"
                              name="file"
                            />
                          </>
                        ) : (
                          <>
                            <div className="m-1 mr-3 flex relative">
                              {this.state.filename.length > 30 ? (
                                <span className="text-sm">
                                  {this.state.filename.substring(0, 30)}...
                                </span>
                              ) : (
                                <span className="text-sm">
                                  {this.state.filename}
                                </span>
                              )}
                            </div>
                          </>
                        )}
                      </label>
                      {this.state.filename ? (
                        <span>
                          <XIcon
                            className="h-6 w-6 ml-3 text-red cursor-pointer"
                            onClick={() => {
                              this.setState({
                                filename: "",
                                params: {
                                  ...params,
                                  file: "",
                                },
                                errMsg: "",
                                data: [],
                              });
                            }}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center mt-2">
                    {this.state.errMsg ? <span>{this.state.errMsg}</span> : ""}
                  </div>
                </div>
              )}

              {this.state.current === 2 && (
                <div className="flex flex-col space-y-6 border-2 p-3">
                  <p className="ml-4">
                    Total No. of data ready to be import :{" "}
                    <span className="text-green-500">
                      {this.state.params.uploadExcelDtoList.length}
                    </span>
                  </p>

                  {this.state.errorDataRow.length > 1 ? (
                    <>
                      <hr />
                      <div className="flex flex-wrap justify-between mt-3 items-center p-1 pr-2 pl-2 rounded-md">
                        <div
                          className="flex cursor-pointer"
                          onClick={() =>
                            this.setState({
                              toggleErrData: !this.state.toggleErrData,
                            })
                          }
                        >
                          <ChevronRightIcon
                            className={`h-5 w-6 pt-1 cursor-pointer text-red ${
                              this.state.toggleErrData
                                ? "transform rotate-90 duration-200"
                                : "transform rotate-0 duration-200"
                            }`}
                          />

                          <p>
                            Total No. of data skipped :{" "}
                            <span className="text-yellow-400">
                              {this.state.errorData.length}
                            </span>
                          </p>
                        </div>
                        <div>
                          <span className="flex">
                            Download Unmatched Data Excel File{" "}
                            <DownloadIcon
                              onClick={this.downloadFile}
                              className=" h-5 w-5 text-red ml-2 cursor-pointer"
                            />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.toggleErrData ? (
                    <ErrorDataTable data={this.state.errorData} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            {this.state.current === 3 && (
              <>
                By default messages are sent to the users email{" "}
                {<span className="text-blue-500">{this.state.userEmail}</span>}.
                You can also send them to a specific email address.
                <div className="flex mt-4">
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      maxLength: 64,
                      value: this.state.email,
                      onChange: (e) => {
                        this.setState({
                          email: e.target.value,
                        });
                      },
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                  />
                  <Button
                    className="m-2 mt-7"
                    outline={true}
                    title={"Add"}
                    attrs={{
                      onClick: this.emailChangeHandler,
                    }}
                  />
                </div>
                {this.state.params.emailRecipients.length > 0 && (
                  <div className="grid md:grid-cols-4 gap-4">
                    {
                      // comma separated list of email recipients
                      this.state.params.emailRecipients
                        .split(",")
                        .map((item, index) => (
                          <div className="flex" key={index}>
                            <span className="text-sm">{item}</span>
                            <TrashIcon
                              className="h-5 w-6 text-red cursor-pointer"
                              onClick={() => {
                                this.removeEmail(index);
                              }}
                            />
                          </div>
                        ))
                    }
                  </div>
                )}
                {this.state.reportingEmail &&
                  this.state.reportingEmail.length > 0 && (
                    <div
                      style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}
                    >
                      {" "}
                      <span className="text-sm">
                        {this.state.reportingEmail}
                      </span>
                    </div>
                  )}
              </>
            )}

            <div className={this.state.current !== 0 ? "border-t-2 mt-4" : ""}>
              <p
                style={{
                  fontSize: "18px",
                  marginLeft: "13px",
                  marginTop: "30px",
                }}
              >
                {this.state.current === 0 ? "Please select a " : "Selected "}
                product -
              </p>
              {items.length > 0 ? (
                <InputRadio
                  value={params.product_id}
                  buttonHidden={true}
                  attrs={{
                    onChange: (e) =>
                      this.onProductChangeHandler(e, "product_id"),
                  }}
                  inline={true}
                  label={false}
                  options={items}
                />
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center text-gray-500">No products found</p>
                </div>
              )}
            </div>

            <div className="relative md:mr-10 flex justify-around mt-10 bottom-0 mb-6 right-0">
              {this.state.current > 0 ? (
                <Button
                  title="Previous"
                  outline={true}
                  attrs={{
                    onClick: () => {
                      if (this.state.current > 0) {
                        this.setState({
                          current: this.state.current - 1,
                        });
                      }
                      if (this.state.current === 1) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: this.state.current - 1,
                        });
                      } else if (this.state.current === 2) {
                        this.setState({
                          filename: "",
                          file: "",
                          params: {
                            ...params,
                            file: "",
                          },
                          current: this.state.current - 1,
                        });
                      }
                    },
                  }}
                />
              ) : (
                <div></div>
              )}

              <Button
                outline={true}
                title={this.handleButtonTitle()}
                attrs={{
                  onClick: this.onClickHandler,
                  disabled: this.disabledHandler(),
                }}
              />
            </div>

            {this.state.current === 0 ? (
              <div className="border-2 m-3 mt-10">
                <span className="flex m-2 text-red-500">Notes -</span>
                <ul className="list-disc list-inside m-2">
                  <li>
                    <span className="text-sm justify-center">
                      The file should be in xlsx format.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm text-right">
                      The file should have the same structure as the sample
                      file.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm justify-center">
                      The file should have the same date format as dd/mm/yyyy.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm justify-center">
                      Customer id only contain numeric values.
                    </span>
                  </li>
                  <li>
                    <span className="text-sm justify-center">
                      Family Relation Only Be (SELF, SPOUSE, DAUGHTER, SON,
                      PARENT, MOTHER, FATHER, BROTHER, SIBLING, HUSBAND,
                      SPOUSE_PARENT, SPOUSE_PARENT_1, SPOUSE_PARENT_2, SISTER)
                    </span>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to upload Records</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  disabledHandler = () => {
    if (this.state.current === 0 && this.state.params.product_id) {
      return false;
    } else if (
      this.state.current === 1 &&
      this.state.params.product_id &&
      this.state.params.file
    ) {
      return false;
    } else if (
      this.state.current === 2 &&
      this.state.params.file &&
      this.state.params.uploadExcelDtoList.length > 0
    ) {
      return false;
    } else if (
      this.state.current === 3 &&
      this.state.params.file &&
      this.state.params.emailRecipients.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  onClickHandler = (e) => {
    const { current } = this.state;
    if (current == 1) {
      this.validateHeader().then((res) => {
        if (res == true) {
          this.validateExcelData().then((res) => {
            this.setState({ current: this.state.current + 1 });
          });
        } else {
          setError(res, () => {});
          // this.setState({
          //   errMsg: res,
          // });
        }
      });
    } else if (current == 0 || current == 2) {
      this.setState({ current: this.state.current + 1 });
    } else if (current == 3) {
      this.onSubmitHandle();
    }
  };

  emailChangeHandler = () => {
    if (validateEmail(this.state.email)) {
      // check if email is already present in the list
      if (
        this.state.params.emailRecipients
          .split(",")
          .indexOf(this.state.email) === -1
      ) {
        let email = this.state.params.emailRecipients;
        if (email.length > 0) {
          email = email + "," + this.state.email;
        } else {
          email = this.state.email;
        }
        this.setState({
          params: {
            ...this.state.params,
            emailRecipients: email,
          },
          email: "",
        });
      } else {
        this.setState({
          email: "",
        });
      }
    }
  };

  removeEmail = (index) => {
    let email = this.state.params.emailRecipients;
    let emailArray = email.split(",");
    emailArray.splice(index, 1);
    email = emailArray.join(",");
    this.setState({
      params: {
        ...this.state.params,
        emailRecipients: email,
      },
    });
  };
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    reseller_id: state.reseller_id,
    products: state.products,
    user: user,
  };
};
export default withRouter(connect(mapStateToProps)(Index));
