import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "../components/Button";
import InputGroup from "../components/form/InputGroup";
import OtpInputGroup from "../components/form/OtpInputGroup";
import Logo from "../components/Logo";
import { ApiPost, ApiPostWithOutToken, getError } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import { toast } from "react-toastify";
import Main from "./Main";

export class ForgotPassword extends Component {
  state = {
    mobile: "",
    otp: "",
    otp_sent: false,
    token: null,
    authToken: null,
    email: "",
    password: "",
    rePassword: "",
    forgot_password: false,
    errors: {},
    t_c_accepted: false,
    tnc: "",
    authT2Token: null,
  };

  mobileInput = React.createRef();

  getErrors = (errors = {}) => {
    let error = { ...errors };
    return error;
  };

  isReady = () => {
    const { mobile, otp_sent } = this.state;
    if (!otp_sent && mobile.length !== 10) return false;
    return true;
  };

  isReadyOtp = () => {
    const { otp, otp_sent } = this.state;
    if (!otp_sent) {
      return false;
    }
    if (otp.length !== 4) {
      return false;
    }
    return true;
  };

  MessageRef = React.createRef();

  render() {
    const {
      mobile,
      email,
      password,
      rePassword,
      otp,
      otp_sent,
      forgot_password,
    } = this.state;
    const ready = this.isReady();
    const readyOtp = this.isReadyOtp();
    const errors = this.getErrors(this.state.errors);
    return (
      <Main MessageRef={this.MessageRef}>
        <div className="w-full px-6 py-10 max-w-sm md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6 mx-auto rounded-xl shadow-2xl">
          <div>
            <Logo />
            <h3 className="mt-1 text-xl font-medium text-primary mb-6">
              Welcome,
            </h3>
            {forgot_password ? (
              <>
                <InputGroup
                  error={errors.email}
                  label="Your email"
                  disabled={true}
                  attrs={{
                    maxLength: 64,
                    value: email,
                    onChange: (e) => {
                      this.onChangeHandler(e.target.value, "email");
                    },
                    autoFocus: true,
                  }}
                  onEnter={this.doResetPassword}
                />

                <InputGroup
                  error={errors.password}
                  label="New password"
                  attrs={{
                    value: password,
                    onChange: (e) => {
                      this.onChangeHandler(e.target.value, "password");
                    },
                    type: "password",
                  }}
                />

                <InputGroup
                  error={errors.rePassword}
                  label="Confirm password"
                  attrs={{
                    value: rePassword,
                    onChange: (e) => {
                      this.onChangeHandler(e.target.value, "rePassword");
                    },
                    type: "password",
                  }}
                  onEnter={this.doResetPassword}
                />
              </>
            ) : (
              <>
                {!otp_sent ? (
                  <InputGroup
                    label="Please enter your phone"
                    placeholder="0000000000"
                    prefix="+91"
                    attrs={{
                      maxLength: 10,
                      value: mobile,
                      onChange: (e) => {
                        const integerRegex = /^-?\d+$/;
                        const isValidInteger = integerRegex.test(
                          e.target.value
                        );
                        if (isValidInteger || e.target.value == "") {
                          this.onChangeHandler(
                            e.target.value.replace(/\D/, ""),
                            "mobile"
                          );
                        }
                      },
                      autoFocus: true,
                      ref: this.mobileInput,
                    }}
                    onEnter={this.onGenerateOTP}
                  />
                ) : (
                  <OtpInputGroup
                    value={otp}
                    mobile={mobile}
                    onEdit={this.onEdit}
                    onEnter={this.doVerify}
                    onResend={this.onResend}
                    onChange={this.onOTPChangeHandler}
                  />
                )}
              </>
            )}
          </div>
          <div>
            {forgot_password ? (
              <div>
                <Button
                  attrs={{
                    disabled: !ready,
                    type: "button",
                    onClick: this.doResetPassword,
                  }}
                  size="md"
                  title="Reset Password"
                />
              </div>
            ) : (
              <>
                <div className={`${otp_sent ? "hidden" : ""}`}>
                  <Button
                    attrs={{
                      disabled: !ready,
                      type: "button",
                      onClick: this.onGenerateOTP,
                    }}
                    size="md"
                    title="Generate OTP"
                  />
                </div>
                <div className={`${!otp_sent ? "hidden" : ""} mt-2`}>
                  <Button
                    attrs={{
                      disabled: !readyOtp,
                      type: "button",
                      onClick: this.doVerify,
                    }}
                    size="md"
                    title="Verify OTP"
                  />
                </div>
                <div>
                  <Link to="login">
                    <Button
                      outline={true}
                      className="mt-2"
                      attrs={{
                        type: "button",
                      }}
                      size="sm"
                      title="Login Screen"
                    />
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </Main>
    );
  }

  onChangeHandler = (value, key) => {
    const state = this.state;
    _.set(state, key, value);
    this.setState(state);
  };

  onOTPChangeHandler = (value) => {
    const integerRegex = /^-?\d+$/;
    const isValidInteger = integerRegex.test(value);
    if (isValidInteger || value == "") {
      this.setState({ otp: value });
    }
  };

  onGenerateOTP = async () => {
    const ready = this.isReady();
    if (!ready) return false;

    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("send-otp", { mobile: this.state.mobile });
    if (response.status === "success") {
      toast.success(response.message);
      this.setState(
        {
          otp_sent: true,
          token: response.data.token,
        },
        () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      );
    } else {
      toast.error(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  onEdit = () => {
    this.setState({ tnc: "" });
    this.setState({ otp_sent: false, token: null }, () => {
      this.mobileInput.current.focus();
    });
  };

  onResend = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("send-otp", { mobile: this.state.mobile });

    if (response.status === "success") {
      toast.success(response.message);
      this.setState({ otp_sent: true, token: response.data.token }, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    } else {
      toast.error(response.message);
      this.setState({ otp_sent: false, token: null }, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  };

  doVerify = async (e) => {
    e.preventDefault();
    const ready = this.isReady();
    if (!ready) return false;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { mobile, otp, token } = this.state;
    let queryParams = new URLSearchParams(window.location.search);
    let reseller_token = queryParams.get("token") || null;
    let product_id = queryParams.get("id") || null;

    var obj = eval("(" + product_id + ")");
    var res = [];
    for (var i in obj) res.push(obj[i]);

    const response = await ApiPost("otp/verify", {
      mobile: mobile,
      otp: otp,
      token: token,
      reseller_token: reseller_token,
      t_c_accepted: true,
    });

    if (response.status === "success") {
      this.setState({
        forgot_password: true,
        email: response.data.user?.email,
        authToken: response.data?.token,
        authT2Token: response.data?.authToken,
      });
      toast.success("OTP Verified Succesfully");
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else {
      toast.error(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });

      if (response.code === 403) {
        this.setState({ otp_sent: false, token: null });
      }
    }
  };

  async componentDidMount() {
    if (this.props.token) {
      this.props.history.replace("/");
      return;
    }

    const error = await getError();
    if (error) {
      toast.error(error);
    }
  }

  doResetPassword = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPostWithOutToken(
      "register/reset-password",
      {
        email: this.state.email,
        password: this.state.password,
        rePassword: this.state.rePassword,
      },
      this.state.authToken,
      this.state.authT2Token
    );
    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      toast.success(response.message);
      this.props.history.push({
        pathname: "/login",
        login_with_credentials: true,
      });
    } else {
      this.setState({ errors: response.errors }, () => {
        toast.error(response.message);
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  };
}

const mapStateToProps = (state) => {
  const { token } = state;
  return { token: token };
};

const LoginConnect = connect(mapStateToProps)((props) => {
  return <ForgotPassword {...props} />;
});

export default withRouter(LoginConnect);
