import React from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";

export function InputFile({
  label = "",
  placeholder = false,
  attrs = {},
  error = "",
  className = "",
  starMark = false,
  inputRef = React.createRef(),
}) {
  return (
    <div className="mb-5">
      <InputLabel label={label} starMark={starMark} />
      <div className="mt-2 relative">
        <div
          onClick={() => inputRef.current.click()}
          tabIndex={0}
          className={[
            "focus:outline-none focus:ring-0 border border-dashed focus:border-primary focus:bg-primary-50 block w-full shadow-sm px-4 py-4 text-base border-gray-300 rounded-xl inline-flex flex-col items-center disabled:bg-gray-100 w-min min-w-max",
            attrs?.disabled ? "border-primary bg-primary-50" : "cursor-pointer",
            className,
          ].join(" ")}
        >
          {placeholder !== false ? placeholder : false}
        </div>

        <input type="file" className="hidden" ref={inputRef} {...attrs} />
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500 max-w-lg md:max-w-xs text-center">
          {error}
        </p>
      ) : (
        false
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputFile {...props} />;
});
