/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { CheckPicker, SelectPicker } from "rsuite";
import "./style.css";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Chip, FormControlLabel } from "@mui/material";
import { RefreshIcon } from "@heroicons/react/outline";
import {
  PaymentPartner,
  ProductType,
  ResellerType,
  ViewData,
  allProductValue,
  dashboard,
  dateBlock,
  dateFormate,
  filterData,
  findLabelByValue,
  primaryColor,
  reportType,
  salesPersonList,
  setFormDate,
  tenureType,
  yearCalendarData,
} from "./constant";
import Button from "../../components/Button";
import { getResellerId, postApiClaimSales } from "../../lib/AppHelper";
import CustomSelect from "../../components/CustomSelect";
import { AppStore } from "../../lib/AppStore";
import { toast } from "react-toastify";
import useMobileDetect from "../../utils/useMobileDetect";
import Checkbox from "@mui/material/Checkbox";
import CustomAccordian from "./CustomAccordian";
import DashboardTable from "./DashboardTable";
import { useApiFilter } from "./CustomHooks";

const Dashboard = ({ view = dashboard }) => {
  const { isMobile } = useMobileDetect();
  const lastApiCallTimeRef = useRef(null);
  const lastFiltersRef = useRef(null);
  const toggleFiltersRef = useRef(null);
  const toggleTotalCount = useRef(null);

  const [filter, setFilter] = useState({
    from: new Date(),
    to: "",
    viewData: "",
    productType: [],
    resellerType: "ALL",
    paymentPartner: "ALL",
    salesPersonId: getResellerId(),
    tenure: [],
    comparisonCriteria: "year",
    mockSalesResellerId: null,
  });
  const [list, setList] = useState([]);
  const [requestFilter, setRequestFilter] = useState([]);
  const [error, setError] = useState({});
  const [expanded, setExpanded] = React.useState({
    panel: "panel1",
    panel2disable: true,
  });
  const [recordCount, setRecordCount] = useState({});
  const [tenureData, setTenureData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [last90Days, setLast90Days] = useState({ header: [], data: [] });
  const [comparisonData, setComparisonData] = useState({
    header: [],
    data: [],
  });
  const { salesRequestFilterData, tenureList, salesPerson, mockReseller } =
    useApiFilter(filter);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, panel: isExpanded ? panel : false });
  };

  useEffect(() => {
    let fDate;
    let tDate;

    if (filter.comparisonCriteria === "year") {
      fDate = moment().subtract(1, "year").year();
      tDate = moment().year();
    } else {
      fDate = setFormDate(filter.comparisonCriteria);
      tDate = new Date(moment().subtract(1, "day"));
    }

    setFilter((prevFilter) => ({
      ...prevFilter,
      from: fDate,
      to: tDate,
    }));
  }, [filter.comparisonCriteria]);
  useEffect(() => {
    const fetchData = async (
      tenureList,
      salesRequestFilterData,
      salesPerson
    ) => {
      try {
        setList(salesPerson?.data);
        setRequestFilter(salesRequestFilterData?.data);
        setTenureData(tenureList?.data);
        setFilter({
          ...filter,
          viewData: ViewData(salesRequestFilterData?.data)[0]?.value,
          productType: allProductValue(
            ProductType(salesRequestFilterData?.data)
          ),
          tenure: allProductValue(tenureType(tenureList?.data)),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (
      salesPerson?.data.length > 0 &&
      salesRequestFilterData?.data &&
      tenureList?.data
    )
      fetchData(tenureList, salesRequestFilterData, salesPerson);
  }, [tenureList, salesRequestFilterData, salesPerson]);

  useEffect(() => {
    if (filter.viewData && filter.productType && filter.tenure) {
      handelSubmit(
        "RESELLER_NAME",
        "ASC",
        filter.productType,
        filter.viewData,
        filter.tenure
      );
    }
  }, [filter.viewData, filter.productType, filter.tenure]);

  const checkError = (pType, vData, tData) => {
    setError({});
    let { productType, salesPersonId, viewData, tenure } = filter;
    productType = pType && pType.length > 1 ? pType : productType;
    viewData = vData ? vData : viewData;
    tenure = tData && tData.length > 1 ? tData : tenure;
    let errorFields = [];
    if (productType?.length === 0) errorFields.push("productType");

    if (!salesPersonId) {
      errorFields.push("salesPerson");
    }
    if (!viewData?.trim()) errorFields.push("viewData");

    if (tenure?.length === 0) errorFields.push("tenure");
    if (errorFields?.length > 0) {
      setError((prevError) => ({
        ...prevError,
        ...errorFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}),
      }));
    }
    return { errorFields, productType, viewData, tenure };
  };

  const setDashboardData = (res, filter) => {
    setComparisonData({
      ...comparisonData,
      header: res?.data?.allPotentialDynamicHeaders,
      data: res?.data?.salesIntervalWise,
    });
    setRecordCount({
      totalPolicyAmount: res?.data?.totalPolicyAmount,
      totalPolicyCount: res?.data?.totalPolicyCount,
      policyCount: res?.data?.policyCount,
    });
    setExpanded({ panel: "panel2", panel2disable: false });
    lastApiCallTimeRef.current = moment();
    lastFiltersRef.current = filter;
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  async function handelSubmit(
    sortby = "RESELLER_NAME",
    sortOrder = "ASC",
    pType = [],
    vData = "",
    tData = [],
    enpoint = "sales_data_yearly_comparison"
  ) {
    const { errorFields, productType, viewData, tenure } = checkError(
      pType,
      vData,
      tData
    );

    if (errorFields?.length > 0) {
      toast.error("Please fill mandatory fields");
      return;
    }
    let data = {};
    AppStore.dispatch({ type: "LOADING", loading: true });
    data = {
      startDate:
        filter.comparisonCriteria === "year"
          ? `${filter?.from}`
          : moment(filter?.from).format("YYYY-MM-DD"),
      endDate:
        filter.comparisonCriteria === "year"
          ? `${filter?.to}`
          : moment(filter?.to).format("YYYY-MM-DD"),
      comparisonCriteria: filter.comparisonCriteria.toUpperCase(),
      lastNinetyDayActiveToggle: "off",
      productTypes: productType,
      viewedBy: viewData,
      paymentPartners:
        filter?.paymentPartner === "ALL"
          ? allProductValue(PaymentPartner)
          : [filter?.paymentPartner],
      resellerType:
        filter?.resellerType === "ALL" ? ["GOLD"] : [filter?.resellerType],
      resellerId: filter?.salesPersonId,
      sortBy: sortby,
      sortingType: sortOrder,
      tenures: tenure,
      mockSalesResellerId: filter.mockSalesResellerId,
    };

    const filtersChanged =
      JSON.stringify(lastFiltersRef.current) !== JSON.stringify(data);

    const fiveMinutesPassed =
      !lastApiCallTimeRef.current ||
      moment().diff(moment(lastApiCallTimeRef.current), "minutes") >= 5;

    if (!filtersChanged && !fiveMinutesPassed) {
      AppStore.dispatch({ type: "LOADING", loading: false });
      return;
    }
    await postApiClaimSales(enpoint, data)
      .then((res) => {
        if (res.code === 200) {
          setDashboardData(res, data);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
  }
  const handleCheckAll = (checked) => {
    setFilter({
      ...filter,
      productType: checked ? allProductValue(ProductType(requestFilter)) : [],
    });
  };
  const handleCheckTenureAll = (checked) => {
    setFilter({
      ...filter,
      tenure: checked ? allProductValue(tenureType(tenureData)) : [],
    });
  };

  async function handel90days(sortby = "RESELLER_NAME", sortOrder = "ASC") {
    const { errorFields, productType, viewData, tenure } = checkError();
    if (errorFields?.length > 0) {
      toast.error("Please fill mandatory fields");
      return;
    }
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      startDate:
        filter.comparisonCriteria === "year"
          ? `${filter?.from}`
          : moment(filter?.from).format("YYYY-MM-DD"),
      endDate:
        filter.comparisonCriteria === "year"
          ? `${filter?.to}`
          : moment(filter?.to).format("YYYY-MM-DD"),
      comparisonCriteria: filter.comparisonCriteria.toUpperCase(),
      lastNinetyDayActiveToggle: "off",
      productTypes: productType,
      viewedBy: viewData,
      paymentPartners:
        filter?.paymentPartner === "ALL"
          ? allProductValue(PaymentPartner)
          : [filter?.paymentPartner],
      resellerType:
        filter?.resellerType === "ALL" ? ["GOLD"] : [filter?.resellerType],
      resellerId: filter?.salesPersonId,
      sortBy: sortby,
      sortingType: sortOrder,
      tenures: tenure,
      mockSalesResellerId: filter.mockSalesResellerId,
    };

    const filtersChanged =
      JSON.stringify(toggleFiltersRef.current) !== JSON.stringify(data);

    if (!filtersChanged) {
      AppStore.dispatch({ type: "LOADING", loading: false });
      return;
    }
    await postApiClaimSales("sales_data_yearly_comparison", data)
      .then((res) => {
        if (res.code === 200) {
          setLast90Days({
            ...last90Days,
            header: res?.data?.allPotentialDynamicHeaders,
            data: filterData(comparisonData.data, res?.data?.salesIntervalWise),
          });
          toggleTotalCount.current = res?.count;
          toggleFiltersRef.current = data;
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
          });
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
  }

  return (
    <>
      <div
        className={`focus:outline-none focus:ring-0 relative py-3 pl-4 my-3 pr-10 leading-normal 
              ${false ? "text-green-700" : "text-primary-700"} ${
          false ? "bg-green-100" : "bg-primary-100"
        }`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          Viewing as &nbsp;
          <span style={{ fontWeight: "600" }}>
            {!filter.mockSalesResellerId
              ? findLabelByValue(
                  salesPerson?.data,
                  getResellerId(),
                  "name",
                  "id"
                )
              : findLabelByValue(
                  mockReseller,
                  filter.mockSalesResellerId,
                  "name",
                  "id"
                )}
          </span>
          &nbsp; if you want to change Please select from dropdown
        </div>
        <div>
          <SelectPicker
            data={salesPersonList(mockReseller)}
            style={{ width: 224, marginLeft: "10px" }}
            onChange={(value) =>
              setFilter({ ...filter, mockSalesResellerId: value })
            }
            value={filter.mockSalesResellerId}
          />
        </div>
      </div>
      <CustomAccordian
        header="Filters"
        onChange={handleChange("panel1")}
        expanded={expanded}
        panel="panel1"
      >
        <div
          className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 gap-4"
          style={{ marginBottom: "30px" }}
        >
          <div>
            <CustomSelect
              label="Frequency"
              value={filter.comparisonCriteria}
              onChange={(e) => {
                setFilter({ ...filter, comparisonCriteria: e.target.value });
              }}
              data={reportType}
              style={{ width: isMobile() ? "300px" : "230px" }}
              isMandatory={true}
            />
          </div>

          {filter.comparisonCriteria === "year" ? (
            <>
              <div>
                <CustomSelect
                  label="From"
                  onChange={(e) => {
                    setFilter({ ...filter, from: e.target.value });
                  }}
                  data={yearCalendarData()}
                  style={{ width: isMobile() ? "300px" : "230px" }}
                  isMandatory={true}
                  value={filter.from}
                />
              </div>
              <div>
                <CustomSelect
                  label="To"
                  onChange={(e) => {
                    setFilter({ ...filter, to: e.target.value });
                  }}
                  data={yearCalendarData()}
                  style={{ width: isMobile() ? "300px" : "230px" }}
                  isMandatory={true}
                  value={filter.to}
                />
              </div>
            </>
          ) : (
            <>
              {" "}
              <div>
                <InputDate
                  label="From"
                  formate={dateFormate[filter.comparisonCriteria]}
                  value={filter.from}
                  placeholder="from"
                  maxDate={new Date(moment().format("YYYY-MM-DD"))}
                  attrs={{
                    onChange: (e) =>
                      setFilter({
                        ...filter,
                        from: e,
                        to: dateBlock(filter.comparisonCriteria, e)?.toDate(),
                      }),
                    value: new Date(filter.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  formate={dateFormate[filter.comparisonCriteria]}
                  placeholder="To"
                  maxDate={
                    new Date(
                      dateBlock(
                        filter.comparisonCriteria,
                        filter.from
                      )?.toDate()
                    ) && new Date(moment().format("YYYY-MM-DD"))
                  }
                  minDate={new Date(filter.from)}
                  attrs={{
                    onChange: (e) => setFilter({ ...filter, to: e }),
                    value: new Date(filter.to),
                  }}
                />
              </div>
            </>
          )}

          <div>
            <CustomSelect
              label="View data by"
              value={filter.viewData}
              onChange={(e) => {
                setFilter({ ...filter, viewData: e.target.value });
              }}
              data={ViewData(requestFilter)}
              style={{ width: isMobile() ? "300px" : "230px", border: "" }}
            />
            {error.viewData && filter.viewData === "" && (
              <p class="error-message">Please select this field</p>
            )}
          </div>

          <div>
            <label className="flex text-sm text-gray-700 justify-left pb-2">
              Sales person
            </label>
            <SelectPicker
              data={salesPersonList(list)}
              style={{ width: 224 }}
              onChange={(value) =>
                setFilter({ ...filter, salesPersonId: value })
              }
              value={filter.salesPersonId}
            />

            {error.salesPerson && !filter?.salesPersonId && (
              <p class="error-message">Please select this field</p>
            )}
          </div>
          <div>
            <label className="flex text-sm text-gray-700 justify-left pb-2">
              Products
            </label>
            <CheckPicker
              data={ProductType(requestFilter)}
              searchable={true}
              value={filter.productType}
              style={{ width: isMobile() ? 300 : 224, borderRadius: 12 }}
              onChange={(value) => setFilter({ ...filter, productType: value })}
              renderExtraFooter={() => (
                <div class="footerStyles">
                  <FormControlLabel
                    label="Select all"
                    control={
                      <Checkbox
                        sx={{
                          color: "#DB2228",
                          "&.Mui-checked": {
                            color: "#DB2228",
                          },
                        }}
                        checked={
                          filter?.productType?.length ===
                          allProductValue(ProductType(requestFilter))?.length
                        }
                        onChange={(e) => handleCheckAll(e.target.checked)}
                      />
                    }
                  />
                </div>
              )}
            />
            {error.productType && filter.productType.length === 0 && (
              <p class="error-message">Please select this field</p>
            )}
          </div>
          <div>
            <label className="flex text-sm text-gray-700 justify-left pb-2">
              Tenure
            </label>
            <CheckPicker
              data={tenureType(tenureData) || []}
              searchable={true}
              value={filter?.tenure}
              style={{ width: isMobile() ? 300 : 224, borderRadius: 12 }}
              onChange={(value) => setFilter({ ...filter, tenure: value })}
              renderExtraFooter={() => (
                <div class="footerStyles">
                  <FormControlLabel
                    label="Select all"
                    control={
                      <Checkbox
                        sx={{
                          color: primaryColor,
                          "&.Mui-checked": {
                            color: primaryColor,
                          },
                        }}
                        checked={
                          filter?.tenure?.length ===
                          allProductValue(tenureType(tenureData))?.length
                        }
                        onChange={(e) => handleCheckTenureAll(e.target.checked)}
                      />
                    }
                  />
                </div>
              )}
            />
            {error.tenure && filter.tenure.length === 0 && (
              <p class="error-message">Please select this field</p>
            )}
          </div>

          <div>
            <CustomSelect
              label="Reseller"
              value={filter.resellerType}
              onChange={(e) => {
                setFilter({ ...filter, resellerType: e.target.value });
              }}
              data={ResellerType}
              style={{ width: isMobile() ? "300px" : "230px" }}
              isMandatory={false}
            />
          </div>

          <div>
            <CustomSelect
              label="Payment partner"
              value={filter.paymentPartner}
              onChange={(e) => {
                setFilter({ ...filter, paymentPartner: e.target.value });
              }}
              data={PaymentPartner}
              style={{ width: isMobile() ? "300px" : "230px" }}
              isMandatory={false}
            />
          </div>
          <div className="flex flex-row Report-button items-center">
            <button
              onClick={() => {
                setFilter({
                  duration: "today",
                  from: moment().format("YYYY-MM-DD"),
                  to: moment().format("YYYY-MM-DD"),
                  viewData: "",
                  productType: [],
                  resellerType: "",
                  paymentPartner: "",
                  salesPerson: "",
                });

                setError({});
                setExpanded({ panel: "panel1", panel2disable: true });
              }}
              className="text-white mb-2 mt-2 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
            >
              <RefreshIcon width={25} className="m-1" />
            </button>

            <Button
              className="ml-5"
              attrs={{
                type: "button",
                onClick: () => {
                  setToggle(true);
                  handelSubmit("RESELLER_NAME", "ASC", [], "", []);
                },
              }}
              size="md"
              title={"Search"}
            />
          </div>
        </div>
      </CustomAccordian>
      <CustomAccordian
        header="Selected Filter(s)"
        onChange={handleChange("panel2")}
        expanded={expanded}
        panel="panel2"
        disable={expanded.panel2disable}
      >
        <div className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center grid md:grid-cols-3 my-2 gap-5">
          <div>
            Data from range : &nbsp;&nbsp;
            <span className="text-base text-red-500">
              {filter.comparisonCriteria === "year"
                ? `01-04-${filter.from} - 31-03-${filter.to}`
                : filter?.from && filter?.to
                ? `${moment(filter?.from).format("DD-MM-YYYY")} - ${moment(
                    filter?.to
                  ).format("DD-MM-YYYY")}`
                : ""}{" "}
            </span>
          </div>
          {filter?.viewData &&
            filter?.viewData !== "" &&
            view === "Dashboard" && (
              <div>
                View data by :&nbsp;&nbsp;
                <span
                  className="text-base text-red-500"
                  style={{ textTransform: "capitalize" }}
                >
                  {findLabelByValue(
                    ViewData(requestFilter),
                    filter?.viewData,
                    "label"
                  )}
                </span>
              </div>
            )}
          {filter?.salesPerson !== null &&
            Object?.keys(filter?.salesPerson || {}) &&
            Object?.keys(filter?.salesPerson || {})?.length > 0 && (
              <div>
                Sales person:&nbsp;
                <span className="text-base text-red-500">
                  {filter?.salesPerson?.name}
                </span>
              </div>
            )}
          {filter?.resellerType &&
            filter?.resellerType !== "" &&
            view === "Dashboard" && (
              <div>
                Reseller :&nbsp;&nbsp;
                <span className="text-base text-red-500">
                  {filter?.resellerType === "ALL"
                    ? allProductValue(ResellerType)?.map((item) => (
                        <Chip
                          label={item}
                          style={{
                            marginLeft: "5px",
                            color: primaryColor,
                            borderColor: primaryColor,
                          }}
                          variant="outlined"
                        />
                      ))
                    : filter?.resellerType}
                </span>
              </div>
            )}
          {filter?.paymentPartner && filter?.paymentPartner !== "" && (
            <div>
              Payment partner :&nbsp;&nbsp;
              <span className="text-base text-red-500">
                {filter?.paymentPartner === "ALL"
                  ? allProductValue(PaymentPartner)?.map((item) => (
                      <Chip
                        label={item}
                        style={{
                          marginLeft: "5px",
                          color: "#DB2228",
                          borderColor: "#DB2228",
                        }}
                        variant="outlined"
                      />
                    ))
                  : filter?.paymentPartner}
              </span>
            </div>
          )}
          <div>
            Sales Person :&nbsp;&nbsp;
            <span className="text-base text-red-500">
              {findLabelByValue(
                salesPersonList(list),
                filter?.salesPersonId,
                "label"
              )}
            </span>
          </div>
          <div
            className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center flex gap-2"
            style={{ marginBottom: "30px", marginTop: "20px" }}
          >
            {filter?.productType && filter?.productType?.length > 0 && (
              <div>
                Products :&nbsp;&nbsp;
                <span className="text-base text-red-500">
                  {filter?.productType.map((item) => (
                    <Chip
                      label={item}
                      style={{
                        margin: "2px",
                        color: "#DB2228",
                        borderColor: "#DB2228",
                      }}
                      variant="outlined"
                    />
                  ))}
                </span>
              </div>
            )}
          </div>
        </div>

        <div
          className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center flex gap-2"
          style={{ marginBottom: "30px", marginTop: "20px" }}
        >
          {filter?.tenure &&
          filter?.tenure?.length > 0 &&
          tenureData?.length !== filter.tenure.length ? (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div>Tenure :&nbsp;&nbsp;</div>
              <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                {filter?.tenure?.map((item) => (
                  <span className="text-base text-red-500">
                    <Chip
                      label={item}
                      style={{
                        marginLeft: "5px",
                        color: "#DB2228",
                        borderColor: "#DB2228",
                      }}
                      variant="outlined"
                    />
                  </span>
                ))}

                {/* {filter?.productType?.join(", ")} */}
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <div>Tenure:</div>
              <span className="text-base text-red-500">
                <Chip
                  label={"ALL"}
                  style={{
                    marginLeft: "5px",
                    color: primaryColor,
                    borderColor: primaryColor,
                  }}
                  variant="outlined"
                />
              </span>
            </div>
          )}
        </div>
      </CustomAccordian>

      <DashboardTable
        salesData={comparisonData}
        recordCount={recordCount}
        handel90days={handel90days}
        setToggle={setToggle}
        toggle={toggle}
        last90Days={last90Days}
      />
    </>
  );
};

export default Dashboard;
