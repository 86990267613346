import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppCard, AppHeading, AppRow } from "../components/AppComponents";
import Button from "../components/Button";
import ProductsList from "../components/home/ProductsList";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import insurance from "./../insurance.svg";
import shield from "./../shield.svg";
import { AiqaIcon } from "../components/AppIcons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArchive,
  faRupeeSign,
  faUserAstronaut,
  faUserFriends,
  faUserMd,
  faFileExcel,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { GetUserDashboardData } from "../lib/AppHelper";
import AiqaCenter from "./aiqaCenter";
import { checkReportPermission } from "../utils/constants";

export class Home extends Component {
  MessageRef = React.createRef();
  state = {
    teamCount: 0,
    salesAmount: 0,
    claimCount: 0,
    unsoldPolicy: 0,
    activePolicy: 0,
    expirePolicy: 0,
    teamCountLoader: false,
    salesAmountLoader: false,
    claimCountLoader: false,
    unsoldPolicyLoader: false,
    activePolicyLoader: false,
    expirePolicyLoader: false,
  };

  componentDidMount() {
    this.getTeamCount();
    this.getSalesAmount();
    this.getClaimCount();
    this.getUnsoldPolicy();
    this.getActivePolicy();
    this.getExpirePolicy();
  }

  getTeamCount = async () => {
    this.setState({ teamCountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/teamCount"
    );
    if (response.code === 200) {
      this.setState({ teamCount: response?.responseObject });
      this.setState({ teamCountLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ teamCountLoader: false });
    }
  };

  getSalesAmount = async () => {
    this.setState({ salesAmountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getSaleAmount"
    );
    if (response.code === 200) {
      this.setState({ salesAmount: response?.responseObject });
      this.setState({ salesAmountLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ salesAmountLoader: false });
    }
  };

  getClaimCount = async () => {
    this.setState({ claimCountLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/claimCount"
    );
    if (response.code === 200) {
      this.setState({ claimCount: response?.responseObject });
      this.setState({ claimCountLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ claimCountLoader: false });
    }
  };

  getUnsoldPolicy = async () => {
    this.setState({ unsoldPolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getUnsoldPolicy"
    );
    if (response.code === 200) {
      this.setState({ unsoldPolicy: response?.responseObject });
      this.setState({ unsoldPolicyLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ unsoldPolicyLoader: false });
    }
  };

  getActivePolicy = async () => {
    this.setState({ activePolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/getActivePolicy"
    );
    if (response.code === 200) {
      this.setState({ activePolicy: response?.responseObject });
      this.setState({ activePolicyLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ activePolicyLoader: false });
    }
  };

  getExpirePolicy = async () => {
    this.setState({ expirePolicyLoader: true });
    const response = await GetUserDashboardData(
      "enrollment-service/stats_queries/expirePolicy"
    );
    if (response.code === 200) {
      this.setState({ expirePolicy: response?.responseObject });
      this.setState({ expirePolicyLoader: false });
    } else {
      this.MessageRef.current.show(response.message);
      this.setState({ expirePolicyLoader: false });
    }
  };

  getNumber = function (number) {
    let amount = number;
    if (number >= 10000000) {
      amount = (number / 10000000).toFixed(2) + " Cr";
    } else if (number >= 100000) {
      amount = (number / 100000).toFixed(2) + " Lakh";
    } else {
      amount = new Intl.NumberFormat("en-IN").format(number || 0);
    }
    return amount.toString();
  };

  render() {
    const { user } = this.props;
    const { permissions } = user;
    const {
      teamCount,
      salesAmount,
      claimCount,
      unsoldPolicy,
      activePolicy,
      expirePolicy,
      teamCountLoader,
      salesAmountLoader,
      claimCountLoader,
      unsoldPolicyLoader,
      activePolicyLoader,
      expirePolicyLoader,
    } = this.state;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions?.center_coordinator || permissions?.aiqa_center ? (
          <AiqaCenter />
        ) : (
          <AppRow>
            <div className="flex-auto">
              <div className="max-w-4xl">
                <AppRow>
                  <div className="flex-1 pt-10">
                    <AppHeading className="mb-6">Hello, {user.name}</AppHeading>
                    <AppCard title={false} className="mb-6">
                      <div className="flex justify-between">
                        <img alt="" src={insurance} />
                        <div className="flex flex-col justify-between">
                          <AiqaIcon size={30} className="text-primary" />
                          {permissions?.application_add ? (
                            <Link to="/subscribe">
                              <Button
                                size="sm"
                                display="inline-block"
                                title="Add New Member"
                                className="px-6"
                              />
                            </Link>
                          ) : (
                            false
                          )}
                        </div>
                        <img alt="" className="shield-image" src={shield} />
                      </div>
                    </AppCard>
                  </div>
                </AppRow>

                <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
                  {permissions?.application_view && (
                    <div className="col-span-1">
                      <Link to="/subscriptions">
                        <AppCard
                          className="cursor-pointer"
                          title="Active Policy"
                          loader={activePolicyLoader}
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                            {this.getNumber(activePolicy)}
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  )}
                  {permissions?.expire_policy_view && expirePolicy > 0 && (
                    <div className="col-span-1">
                      <AppCard
                        className="cursor-pointer"
                        title="Expire Policy"
                        loader={expirePolicyLoader}
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                          {this.getNumber(expirePolicy)}
                        </AppHeading>
                      </AppCard>
                    </div>
                  )}
                  {permissions?.unpaid_policy_view && unsoldPolicy > 0 ? (
                    <div className="col-span-1">
                      <Link to="/leads">
                        <AppCard
                          className="cursor-pointer"
                          title="Unpaid Policy"
                          loader={unsoldPolicyLoader}
                        >
                          <AppHeading color="primary">
                            <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                            {this.getNumber(unsoldPolicy)}
                          </AppHeading>
                        </AppCard>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-span-1">
                    <AppCard
                      className="cursor-pointer"
                      title="Total Sales"
                      loader={salesAmountLoader}
                    >
                      <AppHeading color="primary">
                        <FontAwesomeIcon icon={faRupeeSign} />{" "}
                        {this.getNumber(salesAmount)}
                      </AppHeading>
                    </AppCard>
                  </div>
                </div>
                <AppHeading className="mb-6 mt-6" size="xl" font="normal">
                  Our Products
                </AppHeading>
                <ProductsList />
              </div>
            </div>
            <div className="flex-2">
              <div className="grid md:grid-cols-1 grid-cols-2 gap-2">
                {permissions?.upload_excel ? (
                  <Link
                    to={{
                      pathname: "/excel-uploader",
                      // state: { componentTo: "/excel-uploader" },
                    }}
                  >
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard className="cursor-pointer" title="Uploader">
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileExcel} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
                {permissions?.group_upload ? (
                  <Link
                    to={{
                      pathname: "/excel-history",
                      state: { componentTo: "/group-excel-uploader" },
                    }}
                  >
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard
                        className="cursor-pointer"
                        title="Group Uploader"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileExcel} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
                {permissions?.policy_uploader ? (
                  <Link to="/policy-uploader">
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard
                        className="cursor-pointer"
                        title="Policy Uploader"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileExcel} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
                {permissions?.credit_upload ? (
                  <Link
                    to={{
                      pathname: "/excel-history",
                      state: { componentTo: "/credit-life-uploader" },
                    }}
                  >
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard
                        className="cursor-pointer"
                        title="Credit Life Uploader"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileExcel} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
                {permissions?.reseller_add ? (
                  <Link to="/employees">
                    <div
                      className="col-span-1 mt-3 w-400-px"
                      style={{ minWidth: 180 }}
                    >
                      <AppCard
                        className="cursor-pointer"
                        title="View Team"
                        loader={teamCountLoader}
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faUserFriends} />{" "}
                          {this.getNumber(teamCount)}
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}

                {permissions?.claim_view ? (
                  <div className="col-span-1 mt-3">
                    <Link to="/claims">
                      <AppCard
                        className="cursor-pointer"
                        title="Claim"
                        loader={claimCountLoader}
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faUserMd} />{" "}
                          {this.getNumber(claimCount)}
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                ) : (
                  false
                )}
                {checkReportPermission() && (
                  <div className="col-span-1 mt-3" style={{ minWidth: 180 }}>
                    <Link to="/reports">
                      <AppCard className="cursor-pointer" title="Reports">
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileArchive} />
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                )}
                {permissions?.customer_lead_capture ? (
                  <div className="col-span-1 mt-3" style={{ minWidth: 180 }}>
                    <Link to="/lead-capture">
                      <AppCard
                        className="cursor-pointer"
                        title="Customer Lead Capture"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileArchive} />
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                ) : (
                  false
                )}
                {permissions?.reseller_lead_capture ? (
                  <div className="col-span-1 mt-3" style={{ minWidth: 180 }}>
                    <Link to="/reseller-lead-capture">
                      <AppCard
                        className="cursor-pointer"
                        title="Reseller Lead Capture"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileArchive} />
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                ) : (
                  false
                )}
                {permissions?.aiqa_center ? (
                  <div className="col-span-1">
                    <Link to="/chemist">
                      <AppCard className="cursor-pointer" title="Chemist">
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faUserAstronaut} />{" "}
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {permissions?.reseller_document_view ? (
                  <div className="col-span-1">
                    <Link to="/reseller-document-upload">
                      <AppCard
                        className="cursor-pointer"
                        title="Upload Document"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faFileArchive} />{" "}
                        </AppHeading>
                      </AppCard>
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {permissions?.sales_dashboard ? (
                  <Link to="/sales-dashboard">
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard
                        className="cursor-pointer"
                        title="Sale Dashboard"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faDatabase} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
                {permissions?.sales_dashboard ? (
                  <Link to="/previous-dashboard">
                    <div className="col-span-1 mt-3 w-400-px ">
                      <AppCard
                        className="cursor-pointer"
                        title="Previous Sale Dashboard"
                      >
                        <AppHeading color="primary">
                          <FontAwesomeIcon icon={faDatabase} />
                        </AppHeading>
                      </AppCard>
                    </div>
                  </Link>
                ) : (
                  false
                )}
              </div>
            </div>
          </AppRow>
        )}
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;

  return { loading: loading, user: user };
};

const HomeConnect = connect(mapStateToProps)((props) => {
  return <Home {...props} />;
});

export default withRouter(HomeConnect);
