import React from "react";
import {
  AppHeading,
  AppSidebarMenu,
  AppSidebarMenuItem,
} from "../../components/AppComponents";
import { AiOutlineBank } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { BiKey } from "react-icons/bi";
import { MdDevices } from "react-icons/md";

export default function ProfileSidebar() {
  return (
    <div className="w-80">
      <AppHeading className="mb-6">Profile</AppHeading>
      <AppSidebarMenu>
        <div>
          <AppSidebarMenuItem link="/profile" title="Basic Profile">
            <FiUser size={20} />
          </AppSidebarMenuItem>

          <AppSidebarMenuItem last={true} link="/banking" title="Banking">
            <AiOutlineBank size={20} />
          </AppSidebarMenuItem>
          {/* <AppSidebarMenuItem last={true} link="/manage-tokens" title="Manage Tokens">
           */}

          <AppSidebarMenuItem
            last={true}
            link="/change-password"
            title="Change Password"
          >
            <BiKey size={20} />
          </AppSidebarMenuItem>
          <AppSidebarMenuItem
            last={true}
            link="/active-session"
            title="Active Session"
          >
            <MdDevices size={20} />
          </AppSidebarMenuItem>
        </div>
      </AppSidebarMenu>
    </div>
  );
}
