import React, { Component } from "react";
import Table from "../../components/common/Table";

export class ErrorDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      limit: 20,
      header: [],
      data: [],
    };
  }
  onPageChange = (page) => {
    this.setState({ activePage: page });
  };

  componentDidMount() {
    let { header, data } = this.props;
    let myHeaders = header?.filter((item) => item.name !== "Error Message");
    myHeaders.splice(0, 0, {
      name: "Error Message",
      dataKey: "Error_Message",
      cell: (row) => {
        const itemList = row?.Error_Message?.split(",").map((item) =>
          item.trim()
        );
        return (
          <ol className="list-decimal text-left capitalize">
            {itemList?.map((item, index) => (
              <li key={index}>{item}.</li>
            ))}
          </ol>
        );
      },
    });
    this.setState({ header: myHeaders, data: data });
  }

  render() {
    const { data } = this.props;
    const totalData = parseInt(data.length);
    return (
      <>
        <div className="relative shadow-md sm:rounded-lg w-full">
          <Table
            data={data}
            headers={this.state.header}
            total={totalData}
            limit={this.state.limit}
            page={this.state.activePage}
            onPageChange={this.onPageChange}
          />
        </div>
      </>
    );
  }
}

export default ErrorDataTable;
