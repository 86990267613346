import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "../components/Button";
import InputGroup from "../components/form/InputGroup";
import OtpInputGroup from "../components/form/OtpInputGroup";
import { toast } from "react-toastify";
import Logo from "../components/Logo";
import { setAuthToken, setResellerToken } from "../helpers/Helper";
import { ApiPost, getError, setData } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import { getFcmToken } from "../utils/commom";

export class Login extends Component {
  state = {
    mobile: "",
    otp: "",
    otp_sent: false,
    token: null,
    email: "",
    password: "",
    login_with_credentials: this.props.history.location?.login_with_credentials
      ? true
      : false,
    errors: {},
    t_c_accepted: false,
    tnc: "",
    auth_token: null,
  };

  mobileInput = React.createRef();

  validateEmail = (email) => {
    // const re =
    //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // return re.test(String(email).toLowerCase());
    if (email.length < 5) return false;
    return true;
  };

  getErrors = (errors = {}) => {
    let error = { ...errors };
    if (this.state.email !== "" && !this.validateEmail(this.state.email)) {
      const e = _.get(error, "email", []);
      _.set(error, "email", [...e, "Please enter a valid email address."]);
    }
    return error;
  };

  isReady = () => {
    const { mobile, email, password, otp_sent, login_with_credentials } =
      this.state;

    if (login_with_credentials) {
      if (email === "" || !this.validateEmail(email) || password === "")
        return false;
      return true;
    }
    if (!otp_sent && mobile.length !== 10) return false;
    return true;
  };

  isReadyOtp = () => {
    const { otp, otp_sent, t_c_accepted } = this.state;
    if (!otp_sent) {
      return false;
    }
    if (otp.length !== 4) {
      return false;
    }
    if (!t_c_accepted) {
      return false;
    }
    return true;
  };

  MessageRef = React.createRef();

  render() {
    const {
      mobile,
      email,
      password,
      otp,
      otp_sent,
      login_with_credentials,
      t_c_accepted,
      tnc,
      auth_token,
    } = this.state;
    const ready = this.isReady();
    const readyOtp = this.isReadyOtp();
    const errors = this.getErrors(this.state.errors);
    const { history } = this.props;
    return (
      <Main MessageRef={this.MessageRef}>
        <div className="w-full px-6 py-10 max-w-sm md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6 mx-auto rounded-xl shadow-2xl">
          <div>
            <Logo />
            <h3 className="mt-1 text-xl font-medium text-primary mb-6 mt-6">
              Welcome,
            </h3>
            {login_with_credentials ? (
              <>
                <InputGroup
                  error={errors.email}
                  label="Please enter your email or Branch Name"
                  attrs={{
                    maxLength: 64,
                    value: email,
                    onChange: (e) => {
                      this.onChangeHandler(e.target.value, "email");
                    },
                    autoFocus: true,
                  }}
                  onEnter={this.doSimpleLogin}
                />

                <InputGroup
                  error={errors.password}
                  label="Your password"
                  attrs={{
                    value: password,
                    onChange: (e) => {
                      this.onChangeHandler(e.target.value, "password");
                    },
                    type: "password",
                  }}
                  onEnter={this.doSimpleLogin}
                />
              </>
            ) : (
              <>
                <div className={`${otp_sent ? "hidden" : ""}`}>
                  <InputGroup
                    label="Please enter your phone"
                    placeholder="0000000000"
                    type="tel"
                    prefix="+91"
                    attrs={{
                      maxLength: 10,
                      value: mobile,
                      onChange: (e) => {
                        const integerRegex = /^-?\d+$/;
                        const isValidInteger = integerRegex.test(
                          e.target.value
                        );
                        if (isValidInteger || e.target.value == "") {
                          this.onChangeHandler(e.target.value, "mobile");
                        }
                      },
                      autoFocus: true,
                      ref: this.mobileInput,
                    }}
                    onEnter={this.onGenerateOTP}
                  />
                </div>
                <div className={`${!otp_sent ? "hidden" : ""}`}>
                  <OtpInputGroup
                    value={otp}
                    mobile={mobile}
                    onEdit={this.onEdit}
                    onEnter={this.doVerify}
                    onResend={this.onResend}
                    onChange={this.onOTPChangeHandler}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            {login_with_credentials ? (
              <>
                <div>
                  <Button
                    attrs={{
                      disabled: !ready,
                      type: "button",
                      onClick: this.doSimpleLogin,
                    }}
                    size="md"
                    title="Login"
                  />
                </div>
                <div>
                  <p className="text-center my-2 text-sm">OR</p>
                  <Button
                    outline={true}
                    attrs={{
                      type: "button",
                      onClick: () =>
                        this.setState({ login_with_credentials: false }),
                    }}
                    size="sm"
                    title="login with mobile"
                  />
                </div>{" "}
                <div>
                  <Link to="forgot-password">
                    <Button
                      outline={true}
                      className="mt-2"
                      attrs={{
                        type: "button",
                      }}
                      size="sm"
                      title="Forgot Password"
                    />
                  </Link>
                </div>
              </>
            ) : (
              <>
                {tnc == null && (
                  <p className="text-xs text-left mb-5 rounded-sm px-1">
                    <input
                      onChange={(e) => {
                        this.setState({ t_c_accepted: e.target.checked });
                      }}
                      type="checkbox"
                      className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                    />{" "}
                    <span>By clicking this, I agree to </span>
                    <span
                      className="cursor-pointer text-primary hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary"
                      onClick={() => this.openTerms()}
                    >
                      {" "}
                      Terms &amp; Condition
                    </span>
                  </p>
                )}
                <div className={`${otp_sent ? "hidden" : ""}`}>
                  <Button
                    attrs={{
                      disabled: !ready,
                      type: "button",
                      onClick: this.onGenerateOTP,
                    }}
                    size="md"
                    title="Generate OTP"
                  />
                </div>
                <div className={`${!otp_sent ? "hidden" : ""} mt-2`}>
                  <Button
                    attrs={{
                      disabled: !readyOtp,
                      type: "button",
                      onClick: this.doVerify,
                    }}
                    size="md"
                    title="Verify OTP"
                  />
                </div>
                <div>
                  <p className="text-center my-2 text-sm">OR</p>
                  <Button
                    outline={true}
                    attrs={{
                      type: "button",
                      onClick: () =>
                        this.setState({ login_with_credentials: true }),
                    }}
                    size="sm"
                    title="login with email or Branch"
                  />
                </div>

                {tnc && (
                  <p className="text-sm text-center mt-5 rounded-sm px-1">
                    <span
                      className="cursor-pointer underline text-primary hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary"
                      onClick={() => this.openTerms()}
                    >
                      {" "}
                      Terms &amp; Condition
                    </span>
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </Main>
    );
  }

  openTerms = () => {
    var w = "500";
    var h = "500";
    var url = "/terms";
    var title = "Terms and Conditions";
    var y = window.outerHeight / 2 + window.screenY - h / 2;
    var x = window.outerWidth / 2 + window.screenX - w / 2;
    return window.open(
      url,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        y +
        ", left=" +
        x
    );
  };

  onChangeHandler = (value, key) => {
    const state = this.state;
    _.set(state, key, value);
    this.setState(state);
  };

  onOTPChangeHandler = (value) => {
    const integerRegex = /^-?\d+$/;
    const isValidInteger = integerRegex.test(value);
    if (isValidInteger || value == "") {
      this.setState({ otp: value });
    }
  };

  onGenerateOTP = async () => {
    const ready = this.isReady();
    if (!ready) return false;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiPost("otp/send", { mobile: this.state.mobile });

    if (response.status === "success") {
      toast.success(response.data.message);
      this.setState(
        {
          otp_sent: true,
          token: response.data.token,
          t_c_accepted: response.data.t_c_accepted === "Y" ? true : false,
          tnc: response.data.t_c_accepted === "Y" ? "Y" : null,
          auth_token: response.data.auth_token,
        },
        () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        }
      );
    } else {
      toast.error(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  onEdit = () => {
    this.setState({ tnc: "" });
    this.setState({ otp_sent: false, token: null }, () => {
      this.mobileInput.current.focus();
    });
  };

  onResend = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("otp/resend", { mobile: this.state.mobile });

    if (response.status === "success") {
      toast.success(response.data.message);
      this.setState({ otp_sent: true, token: response.data.token }, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    } else {
      toast.error(response.message);
      this.setState({ otp_sent: false, token: null }, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  };

  doVerify = async (e) => {
    const fcmToken = getFcmToken();
    e.preventDefault();
    const ready = this.isReady();
    if (!ready) return false;
    if (!this.state.t_c_accepted) return false;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const { mobile, otp, token, t_c_accepted } = this.state;
    let queryParams = new URLSearchParams(window.location.search);
    let reseller_token = queryParams.get("token") || null;
    let product_id = queryParams.get("id") || null;

    var obj = eval("(" + product_id + ")");
    var res = [];
    for (var i in obj) res.push(obj[i]);

    const response = await ApiPost("new-login", {
      mobile: mobile,
      otp: otp,
      token: token,
      t_c_accepted: t_c_accepted,
      reseller_token: reseller_token,
      product_id: res,
      fcm_token_id: fcmToken,
    });

    if (response.status === "success") {
      await setData("auth_token", response.data?.authToken);
      await setData("is_admin", response.data.user?.is_admin ? 1 : 0);
      await setData("permissions", response.data.user?.permissions);
      await setData("is_secured_login", response.data.user?.is_secured_login);
      await setData(
        "reporting_email",
        response.data.user?.reporting_data?.email
      );
      await setData("reporting_name", response.data.user?.reporting_data?.name);
      await setData("reseller_pin", response.data.user?.basic?.pincode);
      await setData("reseller_language", response.data.user?.basic?.language);
      setResellerToken(response.data.user?.reseller_id_by_token || null);
      setAuthToken(response.data?.authToken || null);
      await setData("access_token", response.data?.token, () => {
        AppStore.dispatch({
          type: "SIGN_IN",
          user: response.data?.user,
          token: response.data?.token,
          auth_token: response.data?.authToken,
        });
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/");
      });
    } else {
      toast.error(response.message);
      AppStore.dispatch({ type: "LOADING", loading: false });

      if (response.code === 403) {
        this.setState({ otp_sent: false, token: null });
      }
    }
  };

  async componentDidMount() {
    if (this.props.token) {
      this.props.history.replace("/");
      return;
    }

    const error = await getError();
    if (error) {
      toast.error(error);
    }
  }

  doSimpleLogin = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    let payload = {
      branch_code: this.state.email,
      password: this.state.password,
    };
    if (this.state.email.includes("@")) {
      payload = {
        email: this.state.email,
        password: this.state.password,
      };
    }
    const response = await ApiPost("new-login", payload);
    if (response.status === "success") {
      await setData("is_admin", response.data.user?.is_admin ? 1 : 0);
      await setData("permissions", response.data.user?.permissions);
      await setData("is_secured_login", response.data.user?.is_secured_login);
      await setData("access_token", response.data.token, () => {
        AppStore.dispatch({
          type: "SIGN_IN",
          user: response.data.user,
          token: response.data.token,
        });
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/");
      });
      await setData("auth_token", response.data?.authToken);
    } else {
      this.setState({ errors: response.errors }, () => {
        toast.error(response.message, "error");
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
    }
  };
}

const mapStateToProps = (state) => {
  const { token } = state;
  return { token: token };
};

const LoginConnect = connect(mapStateToProps)((props) => {
  return <Login {...props} />;
});

export default withRouter(LoginConnect);
