import React, { useState } from "react";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
const columns = [
  {
    field: "bankName",
    label: "Bank Name",
    requiredMark: true,
  },
  {
    field: "accountName",
    label: "Account Holder Name",
    requiredMark: true,
  },
  {
    field: "accountNumber",
    label: "Account Number",
    type: "number",
    requiredMark: true,
  },
  {
    field: "ifscCode",
    label: "IFSC Code",
    requiredMark: true,
  },
];

export const BankDetailsForm = ({
  step,
  members,
  prevStep,
  setValue,
  onSubmit,
  value,
  state,
  isMobile,
}) => {
  const [errors, setErrors] = useState({});
  const onClick = () => {
    let error = false;
    columns.map((column) => {
      const { field } = column;
      if (!state[field] || (state[field] && state[field].length < 1)) {
        error = true;
        setErrors((errors) => {
          return {
            ...errors,
            [field]: "Please Fill this field",
          };
        });
      } else {
        setErrors((errors) => {
          return {
            ...errors,
            [field]: void 0,
          };
        });
      }
    });
    if (!error) {
      setErrors({});
      return onSubmit();
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          padding: isMobile ? "0px 0px" : "0px 30px",
        }}
      >
        {columns.map((column, Index) => {
          const { label, field, requiredMark } = column;
          return (
            <React.Fragment key={Index}>
              <div style={{ width: "350px" }}>
                <InputGroup
                  label={label}
                  error={[errors[field]]}
                  requiredMark={requiredMark}
                  attrs={{
                    onChange: (e) => {
                      if (field === "accountNumber") {
                        setValue({
                          [field]: e.target.value.replace(/[^0-9 ]/g, ""),
                        });
                      } else if (field === "accountName") {
                        setValue({
                          [field]: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                        });
                      } else if (field === "bankName") {
                        setValue({
                          [field]: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                        });
                      } else {
                        setValue({ [field]: e.target.value });
                      }
                      setErrors((errors) => {
                        return {
                          ...errors,
                          [field]: void 0,
                        };
                      });
                    },
                    value: state[field],
                  }}
                />
              </div>
            </React.Fragment>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <button
          onClick={prevStep}
          className="flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500 border border-2 border-red-500"
        >
          Back
        </button>
        <div>
          <Button
            attrs={{
              //   ref: this.BasicBtn,
              type: "button",
              onClick,
            }}
            options={members}
            size="md"
            className={"mt-5"}
            title={"Continue"}
          />
        </div>
      </div>
    </div>
  );
};
