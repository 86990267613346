import React, { useEffect, useRef, useState } from "react";
import {
  AppBackButton,
  AppHeading,
  AppTeamStatus,
} from "../../components/AppComponents";
import { AppDataElement } from "../../components/AppDataElement";
import Main from "../Main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faPlusSquare,
  faUserFriends,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { DuplicateIcon } from "@heroicons/react/outline";
import { FiSend } from "react-icons/fi";
import { InputGroup } from "../../components/form/InputGroup";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";
import { useSelector } from "react-redux";
import { ApiGet, getData } from "../../lib/AppHelper";
import { getResellerToken, setResellerToken } from "../../helpers/Helper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";

export const Employees = () => {
  const TinyURL = require("tinyurl");
  const [permissions, setPermission] = useState({});
  const [url, setUrl] = useState("");
  const MessageRef = useRef();
  const history = useHistory();
  const params = useParams();
  const [open, setOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const product = useSelector((state) => state.products);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [addProductId, setAddProductId] = React.useState([]);

  useEffect(() => {
    (async () => {
      const per = await getData("permissions");
      if (per) {
        setPermission(per);
      }
      if (
        getResellerToken() === "" ||
        getResellerToken() === null ||
        getResellerToken() === undefined
      ) {
        const me = await ApiGet("me");
        if (me.status === "success") {
          setResellerToken(me.data?.reseller_id_by_token || null);
        }
      }
    })();
  }, []);

  const generateUrl = () => {
    let HOST = window.location.host;
    HOST = `https://${HOST}`;
    let productJson = JSON.stringify(addProductId);
    TinyURL.shorten(
      HOST + "/login?token=" + getResellerToken() + "&id=" + productJson,
      function (res, err) {
        if (err) console.log(err);
        setUrl(res);
      }
    );
  };

  const sendWhatsapp = async () => {
    let WhatsappUrl = "https://api.whatsapp.com/send?text=" + url;
    window.open(WhatsappUrl);
  };

  const sendGmail = () => {
    let urls =
      "mailto:someone@example.com?subject=aiqahealth%20Referral%20Link&body=You%20are%20invited%20aiqahealth%20via%20this%20link!" +
      url;
    window.open(urls);
  };

  const sendFacebook = () => {
    let sendFacebookUrl = "https://www.facebook.com/share.php?u=" + url;
    window.open(sendFacebookUrl);
  };

  const productAdd = (e) => {
    let newData = e.target.value;
    let oldData = addProductId;
    if (e.target.checked) {
      setAddProductId([...addProductId, newData]);
    } else {
      const data = oldData.filter((e) => {
        if (e === newData) {
        } else {
          return e;
        }
      });
      setAddProductId(data);
    }
  };

  return (
    <Main MessageRef={MessageRef}>
      <div className="grid md:grid-cols-2">
        <div>
          <AppBackButton to="/">Back to Dashboard </AppBackButton>
          <AppHeading>Teams</AppHeading>
        </div>
        {permissions.reseller_add === true ? (
          <div>
            <button
              className="float-right ml-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded "
              onClick={handleOpen}
            >
              Generate Link
            </button>
            <Link to="/add-employee">
              <button
                title="Add Team Member"
                className="float-right bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded flex"
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                <p className="px-2">Add Team</p>
              </button>
            </Link>
          </div>
        ) : (
          false
        )}
      </div>

      <AppDataElement
        MessageRef={MessageRef}
        endpoint={"members"}
        header={[]}
        render={(item) => {
          return (
            <>
              <div
                onClick={() => {
                  if (item.team_cnt > 0) {
                    const path = "/employee/" + item.id;
                    history.push(path);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="my-2 max-w-sm rounded overflow-hidden shadow-lg">
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-1 capitalize">
                      <span className="text-ellipsis">
                        {`${item?.name?.length > 31
                            ? item.name.substring(0, 25) + "..."
                            : item.name
                          }`}
                      </span>
                      <span className="float-right text-sm text-primary">
                        <FontAwesomeIcon icon={faUserFriends} /> {item.team_cnt}{" "}
                      </span>
                    </div>
                  </div>
                  <div className="px-3 pt-4 pb-3">
                    <a
                      href={"tel:" + item.mobile}
                      className="text-sm text-primary"
                    >
                      <FontAwesomeIcon icon={faPhone} /> {item.mobile}{" "}
                    </a>
                    {/* <AppLabel title='Level' color={'yellow'} /> */}
                    <span className="float-right">
                      {item.status !== 1 ? (
                        <AppTeamStatus status={item.status} />
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </>
          );
        }}
        params={{ id: params.id ? params.id : '' }}
        filters={<></>}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Refferral Link
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faWindowClose} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={true}>
          <div>
            Products
            {product.length &&
              product.map((item, index) => (
                <div className="flex" key={index}>
                  <input
                    className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    onChange={productAdd}
                    value={item.id}
                    id={item.id}
                  />
                  <label
                    className="form-check-label inline-block text-gray-800"
                    htmlFor={item.id}
                  >
                    {item.name}
                  </label>
                </div>
              ))}
            <button
              className="my-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded "
              onClick={generateUrl}
            >
              Generate referral link{" "}
            </button>
            <div>
              <InputGroup
                placeholder=""
                attrs={{
                  value: url.replace(/(^\w+:|^)\/\//, ""),
                  readOnly: true,
                }}
                action={
                  <div
                    tabIndex={0}
                    data-clipboard-text={url}
                    className="focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none rounded-xl cursor-pointer text-primary focus:bg-primary-50 px-3 flex items-center justify-center border-gray-300 border ml-3 shadow-sm position-relative"
                  >
                    <Transition
                      show={popup}
                      enter="transform transition duration-75"
                      enterFrom="opacity-0 scale-50"
                      enterTo="opacity-100 scale-100"
                      leave="transform duration-75 transition"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-50"
                    >
                      <span
                        className="bg-gray-700 text-white px-2 py-1 rounded-md text-xs transition-opacity"
                        style={{ position: "absolute", top: -30 }}
                      >
                        Copied!
                      </span>
                    </Transition>
                    <DuplicateIcon
                      width={20}
                      onClick={() => {
                        setPopup(true);
                        navigator.clipboard.writeText(url);
                        setTimeout(() => {
                          setPopup(false);
                        }, 2000);
                      }}
                    />
                  </div>
                }
              />
            </div>
            <div className="flex space-x-3">
              <button
                onClick={sendGmail}
                title="Send Link On Gmail"
                className="w-12 h-12 rounded-full bg-gray-600 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <FiSend size={24} className="text-white" />
              </button>
              <button
                onClick={sendWhatsapp}
                title="Send Link On Whatsapp"
                className="w-12 h-12 rounded-full bg-green-500 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <FaWhatsapp size={24} className="text-white" />
              </button>
              <button
                onClick={sendFacebook}
                title="Send Link On Facebook"
                className="w-12 h-12 rounded-full bg-blue-500 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                <FaFacebook size={24} className="text-white" />
              </button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Main>
  );
};

export default withRouter(Employees);
