import React from "react";
import InputGroup from "../../components/form/InputGroup";

const TextInput = ({
  label,
  error,
  setValue,
  field,
  onBlur,
  value,
  attrs,
  requiredMark,
}) => {
  return (
    <InputGroup
      label={label}
      error={error}
      attrs={attrs}
      requiredMark={requiredMark}
    />
  );
};
export default TextInput;
