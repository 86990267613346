import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton } from "../../components/AppComponents";
import Button from "../../components/Button";
import { ApiGet, downloadExcelFile, getResellerId } from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/solid";
export class ResellerProductWiseSalesReport extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      page: 1,
      count: 10,
      employee_id: getResellerId(),
      product_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
    products: [],
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerTeam = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
    this.getProduct(this.state.params.employee_id);
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
    this.setState({ params: { ...this.state.params, page: 1 } });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = getResellerId();
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  isReady = () => {
    const { product_id } = this.state.params;
    if (!product_id) {
      return false;
    }
    return true;
  };

  render() {
    const { params, reports, employees, products, toMaxDate } = this.state;
    const ready = this.isReady();
    const { user } = this.props;
    const { permissions } = user;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.reseller_product_wise_sale_report ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/"> Back </AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "20%" }} className="float-right">
                  <Button
                    attrs={{
                      disabled: !ready,
                      type: "button",
                      onClick: this.downloadClick,
                    }}
                    size="md"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandlerTeam(e, "employee_id")}
                  value={this.state.params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={index}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>

              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Product
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "product_id")}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  {products.map((option, index) => {
                    if (option?.product_master)
                      return (
                        <option
                          value={option.product_master?.product_id}
                          key={index}
                        >
                          {option.product_master?.product_display_name}
                        </option>
                      );
                  })}
                </select>
              </div>

              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>

              <div>
                <InputDate
                  label="To"
                  value={params.to}
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>

              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table className="min-w-full" id="table-to-xls">
                      <thead>
                        <tr>
                          <td>
                            <table className="min-w-full border">
                              <tbody>
                                <tr>
                                  <td>
                                    <table className="min-w-full">
                                      <thead className="border-b">
                                        <tr>
                                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                            Policy id
                                          </th>
                                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                                            Application id
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Payment Status
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Reseller Name
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Customer Name
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Customer Mobile
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Product Name
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Policy Amount
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Created on
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Policy Start Date
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Policy End Date
                                          </th>
                                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                                            Policy Renewal Date
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {reports.map((item, index) => {
                                          return (
                                            <tr
                                              className="border-b"
                                              key={index}
                                            >
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.policy_id}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 hitespace-nowrap">
                                                {item?.application_id}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.payment_status}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.reseller_name}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.customer_name}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.customer_mobile}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.product_name}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.policy_amount}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.created_on}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.policy_start_date}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.policy_end_date}
                                              </td>
                                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                {item?.policy_renewal_date}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                        {reports.length === 0 ? (
                                          <tr>
                                            <td
                                              colSpan="9"
                                              height="50"
                                              className="text-red-500 font-light text-center"
                                            >
                                              Your result is empty
                                            </td>
                                          </tr>
                                        ) : (
                                          false
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>
                You do not have permission to view Reseller Product Wise Sale
                Report
              </h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  componentDidMount() {
    //   this.getReports();
    this.getTeam();
    this.getProduct(this.state.params.employee_id);
  }

  getProduct = async (employee_id) => {
    const response = await ApiGet(`reseller-product/${employee_id}`);
    if (response.status === "success") {
      const obj = response.data;
      this.setState({ products: obj });
      this.setState({
        params: {
          ...this.state.params,
          product_id: obj[0]?.product_master?.product_id,
        },
      });
    } else {
      this.setState({ products: [] });
    }
  };

  handleClick = () => {
    this.setState({ params: { ...this.state.params, page: 1 } });
    this.getReports(this.state.params);
  };

  downloadClick = () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      reportType: "download",
      employee_id: this.state.params.employee_id,
      product_id: this.state.params.product_id,
    };
    this.DownloadReports(payload);
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  getReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(
      "report/individual-product-wise-sales-report",
      request
    );
    if (response.status === "success") {
      if (response.data.collection) {
        const items = Object.values(response?.data?.collection);
        this.setState({ reports: items });
        this.setState({ total: response?.data?.data?.total });
      } else {
        this.setState({ reports: [] });
        this.setState({ total: 0 });
      }
    } else {
      this.MessageRef.current.show(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  DownloadReports = async (request = "") => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/individual-product-wise-sales-report",
      request,
      "productReport.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ResellerProductWiseSalesReportConnect = connect(mapStateToProps)(
  (props) => {
    return <ResellerProductWiseSalesReport {...props} />;
  }
);

export default withRouter(ResellerProductWiseSalesReportConnect);
