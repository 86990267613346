import React from "react";
import { useSelector } from "react-redux";
import { AppRow } from "../AppComponents";
import ProductInfoBlock from "./ProductInfoBlock";
import { Placeholder } from "rsuite";

export default function ProductsList() {
  const products = useSelector((state) => state.products);
  const productLoading = useSelector((state) => state.productLoading);
  const LoaderShow = [1, 2, 3];
  return productLoading ? (
    <div className="flex-1 grid lg:grid-cols-3 grid-cols-1 gap-4">
      {LoaderShow.map((index) => {
        return (
          <div
            className="bg-orange-200 flex flex-col justify-between rounded-xl p-4"
            key={index}
          >
            <Placeholder.Paragraph
              rows={7}
              rowHeight={10}
              rowMargin={12}
              active
            />
          </div>
        );
      })}
    </div>
  ) : (
    <div>
      <AppRow>
        <div className="flex-1">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
            {products.length > 0 &&
              products.map((product) => {
                return (
                  <ProductInfoBlock
                    key={`${product.id}-${product.product_type}`}
                    product={product}
                  />
                );
              })}
          </div>
        </div>
      </AppRow>
    </div>
  );
}
