import React, { useEffect, useRef, useState } from "react";
import { CheckPicker, Pagination, SelectPicker, Toggle } from "rsuite";
import "./style.css";
import moment from "moment";
import { InputDate } from "../../components/form/InputDate";
import { Chip, FormControlLabel } from "@mui/material";
import { RefreshIcon } from "@heroicons/react/outline";
import {
  LastWeekDates,
  PaymentPartner,
  ProductType,
  ResellerType,
  TotalSale,
  ViewData,
  WeekDates,
  allProductValue,
  downloadExcel,
  findLabelByValue,
  salesPersonList,
  tenureType,
} from "./constant";
import Button from "../../components/Button";
import {
  getApiClaimSales,
  getResellerId,
  postApiClaimSales,
} from "../../lib/AppHelper";
import CustomSelect from "../../components/CustomSelect";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import { toast } from "react-toastify";
import useMobileDetect from "../../utils/useMobileDetect";
import Checkbox from "@mui/material/Checkbox";
import CustomAccordian from "./CustomAccordian";
import CustomTable from "./CustomTable";
import Main from "../Main";

const SalesDashboard = () => {
  const { isMobile } = useMobileDetect();
  const MessageRef = React.createRef();
  const lastApiCallTimeRef = useRef(null);
  const lastFiltersRef = useRef(null);
  const toggleFiltersRef = useRef(null);
  const toggleTotalCount = useRef(null);

  const [filter, setFilter] = useState({
    duration: "thisMonth",
    from: WeekDates("month")[0],
    to: WeekDates("month")[1],
    viewData: "",
    productType: [],
    resellerType: "ALL",
    paymentPartner: "ALL",
    salesPersonId: getResellerId(),
    tenure: [],
  });
  const [list, setList] = useState([]);
  const [requestFilter, setRequestFilter] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState({});
  const [expanded, setExpanded] = React.useState({
    panel: "panel1",
    panel2disable: true,
  });
  const [recordCount, setRecordCount] = useState({});
  const [tenureData, setTenureData] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [last90Days, setLast90Days] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, panel: isExpanded ? panel : false });
  };

  const fetchData = async () => {
    try {
      const [salesRequestFilterData, tenureData, salesPerson] =
        await Promise.all([
          getApiClaimSales("getSalesRequestFilter").catch((error) => {
            console.error("Error fetching sales request filter data:", error);
            throw error;
          }),
          getApiClaimSales("getProductTenure").catch((error) => {
            console.error("Error fetching data from another API:", error);
            throw error;
          }),
          getApiClaimSales(`getSalesPerson/${getResellerId()}`).catch(
            (error) => {
              console.error("Error fetching data from another API:", error);
              throw error;
            }
          ),
        ]);
      setList(salesPerson?.data);
      setRequestFilter(salesRequestFilterData?.data);
      setTenureData(tenureData?.data);
      setFilter({
        ...filter,
        viewData: ViewData(salesRequestFilterData?.data)[0]?.value,
        productType: allProductValue(ProductType(salesRequestFilterData?.data)),
        tenure: allProductValue(tenureType(tenureData?.data)),
      });
      handelSubmit(
        1,
        10,
        "RESELLER_NAME",
        "ASC",
        allProductValue(ProductType(salesRequestFilterData?.data)),
        ViewData(salesRequestFilterData?.data)[0]?.value,
        allProductValue(tenureType(tenureData?.data))
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handelSubmit(
    pg = 1,
    size = 100,
    sortby = "RESELLER_NAME",
    sortOrder = "ASC",
    pType = [],
    vData = "",
    tData = []
  ) {
    setError({});
    let { productType, salesPersonId, viewData, tenure } = filter;
    productType = pType && pType?.length > 0 ? pType : productType;
    viewData = vData !== "" ? vData : viewData;
    tenure = tData && tData?.length > 0 ? tData : tenure;
    let errorFields = [];
    if (productType?.length === 0) errorFields.push("productType");

    if (!salesPersonId) {
      errorFields.push("salesPerson");
    }
    if (!viewData?.trim()) errorFields.push("viewData");

    if (tenure?.length === 0) errorFields.push("tenure");

    if (errorFields?.length > 0) {
      setError((prevError) => ({
        ...prevError,
        ...errorFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}),
      }));
      toast.error("Please fill mandatory fields");
      return;
    }
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      startDate: moment(filter?.from).format("YYYY-MM-DD"),
      endDate: moment(filter?.to).format("YYYY-MM-DD"),
      productType: productType,
      viewedBy: viewData,
      paymentPartner: filter?.paymentPartner,
      resellerType: filter?.resellerType,
      salesPersonId: filter?.salesPersonId,
      sortBy: sortby,
      sortingType: sortOrder,
      tenure: tenure,
    };
    const filtersChanged =
      JSON.stringify(lastFiltersRef.current) !== JSON.stringify(data);

    const fiveMinutesPassed =
      !lastApiCallTimeRef.current ||
      moment().diff(moment(lastApiCallTimeRef.current), "minutes") >= 1;

    if (!filtersChanged && !fiveMinutesPassed) {
      AppStore.dispatch({ type: "LOADING", loading: false });
      return;
    }
    await postApiClaimSales("report", data)
      .then((res) => {
        if (res.code === 200) {
          setSalesData(res?.data?.salesReportResponses || []);
          setRecordCount({
            totalPolicyAmount: res?.data?.totalPolicyAmount,
            totalPolicyCount: res?.data?.totalPolicyCount,
            totalResellerCount: res?.data?.totalResellerCount,
          });
          setTotalCount(res?.count);
          setExpanded({ panel: "panel2", panel2disable: false });
          lastApiCallTimeRef.current = moment();
          lastFiltersRef.current = data;
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
  }

  const handleCheckAll = (checked) => {
    setFilter({
      ...filter,
      productType: checked ? allProductValue(ProductType(requestFilter)) : [],
    });
  };
  const handleCheckTenureAll = (checked) => {
    setFilter({
      ...filter,
      tenure: checked ? allProductValue(tenureType(tenureData)) : [],
    });
  };

  async function handel90days(
    pg = 1,
    size = 100,
    sortby = "RESELLER_NAME",
    sortOrder = "ASC"
  ) {
    setError({});
    let { productType, salesPersonId, viewData, tenure } = filter;
    let errorFields = [];
    if (productType?.length === 0) errorFields.push("productType");

    if (!salesPersonId) {
      errorFields.push("salesPerson");
    }
    if (!viewData.trim()) errorFields.push("viewData");

    if (tenure?.length === 0) errorFields.push("tenure");

    if (errorFields.length > 0) {
      setError((prevError) => ({
        ...prevError,
        ...errorFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}),
      }));
      toast.error("Please fill mandatory fields");
      return;
    }

    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = {
      startDate: moment(filter?.from).format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      productType: productType,
      viewedBy: viewData,
      paymentPartner: filter?.paymentPartner,
      resellerType: filter?.resellerType,
      salesPersonId: filter?.salesPersonId,
      sortBy: sortby,
      sortingType: sortOrder,
      tenure: tenure,
    };
    const filtersChanged =
      JSON.stringify(toggleFiltersRef.current) !== JSON.stringify(data);

    if (!filtersChanged) {
      AppStore.dispatch({ type: "LOADING", loading: false });
      return;
    }
    await postApiClaimSales("salesReportDashboardSortedData", data)
      .then((res) => {
        if (res.code === 200) {
          setLast90Days(res?.data);
          toggleTotalCount.current = res?.count;
          toggleFiltersRef.current = data;
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => AppStore.dispatch({ type: "LOADING", loading: false }));
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Main MessageRef={MessageRef}>
        <AppBackButton to="/" />
        <p
          className="text-center w-full m-2 font-bold text-xl "
          style={{ color: "#db2228" }}
        >
          Sales Dashboard
        </p>

        <CustomAccordian
          header="Filters"
          onChange={handleChange("panel1")}
          expanded={expanded}
          panel="panel1"
          // icon={<FilterAltIcon />}
        >
          <div
            className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-3 gap-4"
            style={{ marginBottom: "30px" }}
          >
            <div>
              <CustomSelect
                label="Total sales"
                value={filter.duration}
                onChange={(e) => {
                  let value = e.target.value;
                  let dates;
                  if (value === "custom") {
                    setFilter((state) => ({ ...state, duration: value }));
                    return;
                  }
                  if (value === "today") dates = WeekDates("day");
                  if (value === "yesterday") dates = LastWeekDates("day");
                  if (value === "thisWeek") dates = WeekDates("week");
                  if (value === "previousWeek") dates = LastWeekDates("week");
                  if (value === "thisMonth") dates = WeekDates("month");
                  if (value === "previousMonth") dates = LastWeekDates("month");
                  if (value === "thisYear") dates = WeekDates("year");
                  if (value === "previousYear") dates = LastWeekDates("year");
                  setFilter((state) => ({
                    ...state,
                    from: new Date(dates[0]),
                    to: new Date(dates[1]),
                    duration: value,
                  }));
                }}
                data={TotalSale}
                style={{ width: isMobile() ? "300px" : "230px" }}
              />
            </div>
            {filter.duration === "custom" && (
              <>
                <div>
                  <InputDate
                    label="From"
                    value={filter.from}
                    placeholder="from"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    attrs={{
                      onChange: (e) => setFilter({ ...filter, from: e }),
                      value: new Date(filter.from),
                    }}
                  />
                </div>
                <div>
                  <InputDate
                    label="To"
                    placeholder="To"
                    maxDate={new Date(moment().format("YYYY-MM-DD"))}
                    minDate={new Date(filter.from)}
                    attrs={{
                      onChange: (e) => setFilter({ ...filter, to: e }),
                      value: new Date(filter.to),
                    }}
                  />
                </div>
              </>
            )}
            <div>
              <CustomSelect
                label="View data by"
                value={filter.viewData}
                onChange={(e) => {
                  setFilter({ ...filter, viewData: e.target.value });
                }}
                data={ViewData(requestFilter)}
                style={{ width: isMobile() ? "300px" : "230px", border: "" }}
              />
              {error.viewData && filter.viewData === "" && (
                <p class="error-message">Please select this field</p>
              )}
            </div>
            <div>
              <label className="flex text-sm text-gray-700 justify-left pb-2">
                Sales person
              </label>
              <SelectPicker
                data={salesPersonList(list)}
                style={{ width: 224 }}
                onChange={(value) =>
                  setFilter({ ...filter, salesPersonId: value })
                }
                value={filter.salesPersonId}
              />

              {error.salesPerson && !filter?.salesPersonId && (
                <p class="error-message">Please select this field</p>
              )}
            </div>
            <div>
              <label className="flex text-sm text-gray-700 justify-left pb-2">
                Products
              </label>
              <CheckPicker
                data={ProductType(requestFilter)}
                searchable={true}
                value={filter.productType}
                style={{ width: isMobile() ? 300 : 224, borderRadius: 12 }}
                onChange={(value) =>
                  setFilter({ ...filter, productType: value })
                }
                renderExtraFooter={() => (
                  <div class="footerStyles">
                    <FormControlLabel
                      label="Select all"
                      control={
                        <Checkbox
                          sx={{
                            color: "#DB2228",
                            "&.Mui-checked": {
                              color: "#DB2228",
                            },
                          }}
                          checked={
                            filter?.productType?.length ===
                            allProductValue(ProductType(requestFilter))?.length
                          }
                          onChange={(e) => handleCheckAll(e.target.checked)}
                        />
                      }
                    />
                  </div>
                )}
              />
              {error.productType && filter.productType.length === 0 && (
                <p class="error-message">Please select this field</p>
              )}
            </div>
            <div>
              <label className="flex text-sm text-gray-700 justify-left pb-2">
                Tenure
              </label>
              <CheckPicker
                data={tenureType(tenureData)}
                searchable={true}
                value={filter.tenure}
                style={{ width: isMobile() ? 300 : 224, borderRadius: 12 }}
                onChange={(value) => setFilter({ ...filter, tenure: value })}
                renderExtraFooter={() => (
                  <div class="footerStyles">
                    <FormControlLabel
                      label="Select all"
                      control={
                        <Checkbox
                          sx={{
                            color: "#DB2228",
                            "&.Mui-checked": {
                              color: "#DB2228",
                            },
                          }}
                          checked={
                            filter?.tenure?.length ===
                            allProductValue(tenureType(tenureData))?.length
                          }
                          onChange={(e) =>
                            handleCheckTenureAll(e.target.checked)
                          }
                        />
                      }
                    />
                  </div>
                )}
              />
              {error.tenure && filter.tenure.length === 0 && (
                <p class="error-message">Please select this field</p>
              )}
            </div>
            <div>
              <CustomSelect
                label="Reseller"
                value={filter.resellerType}
                onChange={(e) => {
                  setFilter({ ...filter, resellerType: e.target.value });
                }}
                data={ResellerType}
                style={{ width: isMobile() ? "300px" : "230px" }}
                isMandatory={false}
              />
            </div>

            <div>
              <CustomSelect
                label="Payment partner"
                value={filter.paymentPartner}
                onChange={(e) => {
                  setFilter({ ...filter, paymentPartner: e.target.value });
                }}
                data={PaymentPartner}
                style={{ width: isMobile() ? "300px" : "230px" }}
                isMandatory={false}
              />
            </div>
            <div className="flex flex-row Report-button items-center">
              <button
                onClick={() => {
                  setFilter({
                    duration: "today",
                    from: moment().format("YYYY-MM-DD"),
                    to: moment().format("YYYY-MM-DD"),
                    viewData: "",
                    productType: [],
                    resellerType: "",
                    paymentPartner: "",
                    salesPerson: "",
                  });

                  setSalesData([]);
                  setError({});
                  setTotalCount(0);
                  setExpanded({ panel: "panel1", panel2disable: true });
                }}
                className="text-white mb-2 mt-2 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
              >
                <RefreshIcon width={25} className="m-1" />
              </button>

              <Button
                className="ml-5"
                attrs={{
                  type: "button",
                  onClick: () => {
                    setToggle(true);
                    handelSubmit();
                  },
                }}
                size="md"
                title={"Search"}
              />
            </div>
          </div>
        </CustomAccordian>
        <CustomAccordian
          header="Selected Filter(s)"
          onChange={handleChange("panel2")}
          expanded={expanded}
          panel="panel2"
          disable={expanded.panel2disable}
        >
          <div className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center grid md:grid-cols-3 my-2 gap-5">
            <div>
              Data from range : &nbsp;&nbsp;
              <span className="text-base text-red-500">
                {moment(filter?.from).format("DD-MM-YYYY")} &nbsp;-&nbsp;
                {moment(filter?.to).format("DD-MM-YYYY")}
              </span>
            </div>
            {filter?.viewData && filter?.viewData !== "" && (
              <div>
                View data by :&nbsp;&nbsp;
                <span
                  className="text-base text-red-500"
                  style={{ textTransform: "capitalize" }}
                >
                  {findLabelByValue(ViewData(requestFilter), filter?.viewData)}
                </span>
                {/* <span className="text-base text-red-500">{filter?.viewData}</span> */}
              </div>
            )}
            {filter?.salesPerson !== null &&
              Object?.keys(filter?.salesPerson || {}) &&
              Object?.keys(filter?.salesPerson || {})?.length > 0 && (
                <div>
                  Sales person :&nbsp;&nbsp;
                  <span className="text-base text-red-500">
                    {filter?.salesPerson?.name}
                  </span>
                </div>
              )}
            {filter?.resellerType && filter?.resellerType !== "" && (
              <div>
                Reseller :&nbsp;&nbsp;
                <span className="text-base text-red-500">
                  {filter?.resellerType === "ALL"
                    ? allProductValue(ResellerType)?.map((item) => (
                        <Chip
                          label={item}
                          style={{
                            marginLeft: "5px",
                            color: "#DB2228",
                            borderColor: "#DB2228",
                          }}
                          variant="outlined"
                        />
                      ))
                    : filter?.resellerType}
                </span>
              </div>
            )}
            {filter?.paymentPartner && filter?.paymentPartner !== "" && (
              <div>
                Payment partner :&nbsp;&nbsp;
                <span className="text-base text-red-500">
                  {filter?.paymentPartner === "ALL"
                    ? allProductValue(PaymentPartner)?.map((item) => (
                        <Chip
                          label={item}
                          style={{
                            marginLeft: "5px",
                            color: "#DB2228",
                            borderColor: "#DB2228",
                          }}
                          variant="outlined"
                        />
                      ))
                    : filter?.paymentPartner}
                </span>
              </div>
            )}
          </div>
          <div
            className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center flex gap-2"
            style={{ marginBottom: "30px", marginTop: "20px" }}
          >
            {filter?.productType && filter?.productType?.length > 0 && (
              <div>
                Products :&nbsp;&nbsp;
                <span className="text-base text-red-500">
                  {filter?.productType.map((item) => (
                    <Chip
                      label={item}
                      style={{
                        marginLeft: "5px",
                        color: "#DB2228",
                        borderColor: "#DB2228",
                      }}
                      variant="outlined"
                    />
                  ))}
                  {/* {filter?.productType?.join(", ")} */}
                </span>
              </div>
            )}
          </div>
          <div
            className="w-full text-md font-medium text-black pl-3 pt-5 md:pt-0 justify-between items-center flex gap-2"
            style={{ marginBottom: "30px", marginTop: "20px" }}
          >
            {filter?.tenure &&
            filter?.tenure?.length > 0 &&
            tenureData.length !== filter.tenure.length ? (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div>Tenure :&nbsp;&nbsp;</div>
                <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
                  {filter?.tenure?.map((item) => (
                    <span className="text-base text-red-500">
                      <Chip
                        label={item}
                        style={{
                          marginLeft: "5px",
                          color: "#DB2228",
                          borderColor: "#DB2228",
                        }}
                        variant="outlined"
                      />
                    </span>
                  ))}

                  {/* {filter?.productType?.join(", ")} */}
                </div>
              </div>
            ) : (
              <div
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <div>Tenure:</div>
                <span className="text-base text-red-500">
                  <Chip
                    label={"ALL"}
                    style={{
                      marginLeft: "5px",
                      color: "#DB2228",
                      borderColor: "#DB2228",
                    }}
                    variant="outlined"
                  />
                </span>
              </div>
            )}
          </div>
        </CustomAccordian>
        <div
          style={{ textAlign: "center", fontSize: "20px", marginBottom: "5px" }}
        >
          <label>Summary</label>
        </div>
        <div className="w-full md:block overflow-scroll no-scrollbar">
          <table className="min-w-full shadow-lg">
            <thead className="border-b-2">
              <tr style={{ backgroundColor: "#db2228", color: "#fff" }}>
                <th className="text-sm font-large px-6 py-2 whitespace-nowrap text-center">
                  Total resellers count
                </th>
                <th className="text-sm  font-large px-6 py-2 whitespace-nowrap text-center ">
                  Total policy count
                </th>
                <th className="text-sm  font-large px-6 py-2 whitespace-nowrap text-center ">
                  Total policy amount (Rs/-)
                </th>
              </tr>
            </thead>
            <tbody>
              {salesData && salesData?.length > 0 ? (
                <tr className="border-b-2" key={10}>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {recordCount?.totalResellerCount || 0}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {recordCount?.totalPolicyCount?.toLocaleString("en-IN") ||
                      0}
                  </td>
                  <td className=" text-gray-900 px-6 py-4 whitespace-nowrap text-center ">
                    {recordCount?.totalPolicyAmount?.toLocaleString("en-IN") ||
                      0}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td
                    colSpan="3"
                    height="50"
                    className="text-red-500 font-light text-center border"
                  >
                    Your result is empty
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "30px",
            marginBottom: "5px",
            padding: "0px 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile() ? "column" : "row",
              justifyContent: "space-between",
              gap: "15px",
            }}
          >
            <div>
              <label style={{ fontSize: "16px", marginRight: "3px" }}>
                Active
              </label>
              <Toggle
                onChange={(value) => {
                  handel90days();
                  setToggle(value);
                }}
                checked={toggle}
              />
            </div>
            <div>
              <label style={{ fontSize: "16px", marginRight: "3px" }}>
                Not Active
              </label>
              <Toggle
                onChange={(value) => {
                  handel90days();
                  setToggle(!value);
                }}
                checked={!toggle}
              />
            </div>
          </div>
          <div>
            <button
              class="Btn"
              onClick={(e) => {
                if (salesData?.length > 0) {
                  const filteredData = toggle
                    ? salesData?.filter((item) =>
                        last90Days?.includes(item?.resellerId)
                      )
                    : salesData;

                  downloadExcel(filteredData);
                }
              }}
              style={{
                background: salesData?.length > 0 ? "" : "grey",
                cursor: salesData?.length > 0 ? "" : "not-allowed",
              }}
            >
              <div style={{ display: "flex", gap: "5px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 384 512"
                    class="svgIcon"
                  >
                    <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                  </svg>
                  <span class="icon2"></span>
                </div>
                <div class="btn-span">
                  <span>Download</span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div
          style={{ textAlign: "center", fontSize: "20px", marginBottom: "5px" }}
        >
          <label style={{}}>Reseller wise sales</label>
        </div>
        <div id="table-to-xls">
          <CustomTable
            data={
              !toggle
                ? salesData?.filter((item) =>
                    last90Days?.includes(item?.resellerId)
                  )
                : salesData
            }
          />
        </div>

        <div className="mt-2">
          <Pagination
            size="xs"
            layout={["total"]}
            total={
              salesData?.length > 0 && toggle.active
                ? salesData?.filter((item) =>
                    last90Days?.includes(item?.resellerId)
                  ).length
                : totalCount
            }
            onChangePage={(page) => handelSubmit(page)}
          />
        </div>
      </Main>
    </div>
  );
};

export default SalesDashboard;
