import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Register from "./../screens/Register";
import Home from "./../screens/Home";
import BasicProfile from "./../screens/profile/BasicProfile";
import BankingProfile from "./../screens/profile/BankingProfile";
import ChangePassword from "./../screens/profile/ChangePassword";
import Subscription from "./../screens/Subscription";
import Logout from "./../screens/Logout";
import Subscriptions from "./../screens/Subscriptions";
import Application from "./../screens/Application";
import KYCUpdate from "./../screens/KYCUpdate";
// import AccessTokens from './../screens/profile/AccessTokens';
import AdminDashboard from "./../screens/admin/AdminDashboard";
import { connect } from "react-redux";
import AdminResellers from "../screens/admin/AdminResellers";
import AddMLM from "../screens/admin/AddMLM";
import AddMFI from "../screens/admin/AddMFI";
import AddEmployee from "../screens/admin/AddEmployee";
import AdminResellerProducts from "../screens/admin/AdminResellerProducts";
import AdminResellerReports from "../screens/admin/AdminResellerReports";
import AddCorporate from "../screens/admin/AddCorporate";
import ClaimForm from "../screens/ClaimForm";
import ProductDetail from "../screens/ProductDetail";
import Claims from "../screens/Claims";
import ClaimDetail from "../screens/ClaimDetail";
import Leads from "../screens/Leads";
import Employees from "../screens/mlm/Employee";
import Terms from "../screens/Terms";
import Report from "../screens/reports/Report";
import SalesReport from "../screens/reports/SalesReport";
import EmployeeReport from "../screens/reports/EmployeeReport";
import ResellerReport from "../screens/reports/ResellerReport";
import ExcelUploader from "../screens/excelUploader/Index";
import PolicyUploader from "../screens/policyUploader/Index";
import Roles from "../screens/Roles";
import PasswordlessLogin from "../screens/PasswordlessLogin";
import ComissionReport from "../screens/reports/ComissionReport";
import BusinessReport from "../screens/reports/BusinessReport";
import AddRole from "../screens/admin/AddRole";
import ResellerClaimReport from "../screens/reports/ResellerClaimReport";
import LeadCapture from "../screens/leadCapture/CustomerLeadCapture";
import SubscriptionReport from "../screens/reports/SubscriptionReport";
import LeadCaptureReport from "../screens/reports/CustomerLeadCaptureReport";
import ResellerLeadCapture from "../screens/leadCapture/ResellerLeadCapture";
import ResellerLeadCaptureReport from "../screens/reports/ResellerLeadCaptureReport";
import AddProducts from "../screens/addProduct/AddProducts";
import CloneProducts from "../screens/addProduct/CloneProducts";
import DueComissionReport from "../screens/reports/DueComissionReport";
import ResellerDataReport from "../screens/reports/ResellerDataReport";
import SubscriptionPreview from "../screens/SubscriptionPreview";
import FinalView from "../screens/preview/FinalView";
import ResellerUploadDateReport from "../screens/reports/ResellerUploadDateReport";
import ResellerUploadDateTeamReport from "../screens/reports/ResellerUploadDateTeamReport";
import ProductWiseSalesReport from "../screens/reports/ProductWiseSalesReport";
import ResellerProductWiseSalesReport from "../screens/reports/ResellerProductWiseSalesReport";
import CreditLifeExcelUploader from "../screens/creditLifeUploader/Index";
import ResellerTeamDataReportFilter from "../screens/reports/ResellerTeamDataReportFilter";
import ResellerAppointmentReport from "../screens/reports/ResellerAppointmentReport";
import PolicyExpireReport from "../screens/reports/PolicyExpireReport";
import HomePageChemist from "../screens/chemist/HomePageChemist";
import CustomerInformation from "../screens/chemist/CustomerInformation";
import Appointment from "../screens/appointments";
import Inventory from "../screens/Inventory/Inventory";
import UpdateCustomerInfo from "../screens/chemist/UpdateCustomerInfo";
import ActiveSession from "../screens/profile/ActiveSession";
import ResellerDocUpload from "../screens/resellerDocUpload/index";
import SalesDashboard from "../screens/salesDashboard";
import PreviousSalesDashboard from "../screens/PreviousSalesDashboard";
import LevelSalesReport from "../screens/reports/LevelSalesReport.js";
import SalesResellerReport from "../screens/reports/SalesResellerReport/index.js";
import HierarchySalesReport from "../screens/reports/HierarchySalesReport.js";
import ExcelUploadPolicyReport from "../components/excelPolicyUploadReport/index.js";
import GroupExcelUploader from "../screens/groupExcelUploader/Index";
export class AppRoutes extends Component {
  render() {
    if (this.props.user.is_admin) {
      return (
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>

          <Route path="/" exact={true}>
            <AdminDashboard />
          </Route>

          <Route path="/resellers">
            <AdminResellers />
          </Route>
          <Route path="/reseller/:resellerId">
            <AdminResellers />
          </Route>

          <Route path="/reseller-products">
            <AdminResellerProducts />
          </Route>

          <Route path="/reseller-reports">
            <AdminResellerReports />
          </Route>

          <Route path="/add-mlm">
            <AddMLM />
          </Route>

          <Route path="/add-mfi">
            <AddMFI />
          </Route>

          <Route path="/add-corporate">
            <AddCorporate />
          </Route>

          <Route path="/roles">
            <Roles />
          </Route>

          <Route path="/add-product">
            <AddProducts />
          </Route>
          <Route path="/clone-product">
            <CloneProducts />
          </Route>
          <Route path="/add-role">
            <AddRole />
          </Route>

          <Route
            path="*"
            render={() => {
              return <Redirect to="/" />;
            }}
          />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/profile">
          <BasicProfile />
        </Route>
        <Route path="/banking">
          <BankingProfile />
        </Route>
        <Route path="/sales-dashboard">
          <SalesDashboard />
        </Route>
        <Route path="/previous-dashboard">
          <PreviousSalesDashboard />
        </Route>
        <Route path="/change-password">
          <ChangePassword />
        </Route>
        {/* <Route path="/manage-tokens">
      );
    }else
      return (
        <Switch>
          <Route path="/register">
            <Register />
          </Route>
         
          <Route path="/profile">
            <BasicProfile />
          </Route>
          <Route path="/banking">
            <BankingProfile />
          </Route>
          <Route path="/change-password">
            <ChangePassword />
          </Route>
          {/* <Route path="/manage-tokens">
               <AccessTokens />
            </Route> */}
        <Route path="/chemist">
          <HomePageChemist />
        </Route>
        <Route path="/customer-info">
          <CustomerInformation />
        </Route>
        <Route exact path="/subscription/:appId">
          <Application />
        </Route>
        <Route path="/update-kyc/:appId">
          <KYCUpdate />
        </Route>
        <Route path="/subscribe">
          <Subscription />
        </Route>

        <Route path="/excel-uploader">
          <ExcelUploader />
        </Route>
        <Route path="/group-excel-uploader">
          <GroupExcelUploader />
        </Route>

        <Route path="/policy-uploader">
          <PolicyUploader />
        </Route>
        <Route path="/credit-life-uploader">
          <CreditLifeExcelUploader />
        </Route>
        <Route path="/lead-capture">
          <LeadCapture />
        </Route>
        <Route path="/reseller-lead-capture">
          <ResellerLeadCapture />
        </Route>
        <Route path="/reports" exact={true}>
          <Report />
        </Route>
        <Route path="/reports/sales">
          <SalesReport />
        </Route>
        <Route path="/reports/policy-expire">
          <PolicyExpireReport />
        </Route>
        <Route path="/reports/emp-sales">
          <EmployeeReport />
        </Route>
        <Route path="/reports/reseller-sales">
          <ResellerReport />
        </Route>
        <Route path="/reports/commissions">
          <ComissionReport />
        </Route>
        <Route path="/reports/due-commissions">
          <DueComissionReport />
        </Route>
        <Route path="/reports/data-report">
          <ResellerDataReport />
        </Route>
        <Route path="/reports/upload-date-report">
          <ResellerUploadDateReport />
        </Route>
        <Route path="/reports/upload-date-team-report">
          <ResellerUploadDateTeamReport />
        </Route>
        <Route path="/reports/team-data-report-filter">
          <ResellerTeamDataReportFilter />
        </Route>
        <Route path="/reports/product-wise-sales-report">
          <ProductWiseSalesReport />
        </Route>
        <Route path="/reports/reseller-product-wise-sales-report">
          <ResellerProductWiseSalesReport />
        </Route>
        <Route path="/reports/business">
          <BusinessReport />
        </Route>
        <Route path="/reports/business/:id">
          <BusinessReport />
        </Route>
        <Route path="/reports/claims">
          <ResellerClaimReport />
        </Route>
        <Route path="/reports/subscriptions">
          <SubscriptionReport />
        </Route>
        <Route path="/reports/lead-capture">
          <LeadCaptureReport />
        </Route>
        <Route path="/reports/reseller-lead-capture">
          <ResellerLeadCaptureReport />
        </Route>
        <Route path="/reports/reseller-appointment">
          <ResellerAppointmentReport />
        </Route>
        <Route path="/reports/update-customer-info">
          <UpdateCustomerInfo />
        </Route>
        <Route path="/subscriptions">
          <Subscriptions />
        </Route>
        <Route path="/subscription/preview/:id">
          <SubscriptionPreview />
        </Route>
        <Route path="/final-view/:id">
          <FinalView />
        </Route>
        <Route path="/leads">
          <Leads />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <Route exact path="/add-employee">
          <AddEmployee />
        </Route>
        <Route path="/employees">
          <Employees />
        </Route>
        <Route path="/employee/:id">
          <Employees />
        </Route>
        <Route path="/" exact={true}>
          <Home />
        </Route>
        <Route exact={true} path="/product/:productId">
          <ProductDetail />
        </Route>
        <Route path="/claim/:memberId/:policyId" exact={true}>
          <ClaimForm />
        </Route>
        <Route path="/claimEdit/:claimId/:subClaimId">
          <ClaimForm />
        </Route>
        <Route path="/claims" exact={true}>
          <Claims />
        </Route>
        <Route path="/claims/:claimId">
          <ClaimDetail />
        </Route>
        <Route path="/roles">
          <Roles></Roles>
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/auth/:token">
          <PasswordlessLogin />
        </Route>
        <Route path="/appointment">
          <Appointment />
        </Route>
        <Route path="/inventory">
          <Inventory />
        </Route>
        <Route path="/active-session">
          <ActiveSession />
        </Route>
        <Route path="/reseller-document-upload">
          <ResellerDocUpload />
        </Route>
        <Route path="/reports/level-sales-report">
          <LevelSalesReport />
        </Route>
        <Route path="/reports/reseller-sales-report">
          <SalesResellerReport />
        </Route>
        <Route path="/reports/hierarchy-sales-report">
          <HierarchySalesReport />
        </Route>
        <Route path="/reports/hierarchy-sales-report/:id">
          <HierarchySalesReport />
        </Route>
        <Route path="/excel-history">
          <ExcelUploadPolicyReport />
        </Route>
        <Route
          path="*"
          render={() => {
            return <Redirect to="/" />;
          }}
        />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <AppRoutes {...props} />;
});
