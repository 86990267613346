import moment from "moment";
import { toast } from "react-toastify";

export const downloadPrescription = async (byte) => {
  var blob = new Blob([byte], { type: "application/pdf" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = "prescription";
  link.download = fileName;
  link.click();
  toast.success("Download Prescription Successful");
  return true;
};

export function convertToDays(duration) {
  const startDate = moment(duration?.start_date);
  const endDate = moment(duration?.end_date);
  const durations = endDate.diff(startDate, "days");
  return durations ? durations : 0;
}

// timer in minutes and second from given start date
export const calculateElapsedTime = (startDate) => {
  const currentDateTime = new Date();
  const timeDifference = currentDateTime - new Date(startDate);
  const elapsedMinutes = Math.floor(timeDifference / (1000 * 60));
  const elapsedSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  return `${elapsedMinutes}m:${elapsedSeconds}s `;
};

export const dateTimeFormate = (dateString) => {
  const dateParts = dateString.split(" "); // Split the date and time

  // Parse the date part
  const datePartsDate = dateParts[0].split("-");
  const year = parseInt(datePartsDate[2], 10);
  const month = parseInt(datePartsDate[1], 10) - 1; // Months are zero-indexed in JavaScript
  const day = parseInt(datePartsDate[0], 10);

  // Parse the time part
  const timeParts = dateParts[1].split(":");
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const seconds = parseInt(timeParts[2], 10);

  // Create a Date object
  const dateObject = new Date(year, month, day, hours, minutes, seconds);

  // Format the time in AM/PM format
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }).format(dateObject);
  return `${dateObject.toDateString()} ${formattedTime}`;
};

export const getPreviewUrl = (fileName, image) => {
  const fileType = getFileType(image);
  return `data:${fileType};base64,${fileName}`;
};

export const getFileType = (fileName) => {
  const splittedFileType = fileName?.split(".");
  const len = splittedFileType?.length;
  if (len) {
    const ext = splittedFileType[len - 1];
    if (["jpg", "jpeg", "png"].includes(ext)) {
      return `image/${ext}`;
    }
    return "application/pdf";
  }
  return "";
};

let fcmToken = null;
export const getFcmToken = () => fcmToken;
export const setFcmToken = (value) => {
  fcmToken = value;
};
