
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppBackButton, AppHeading } from '../components/AppComponents'
import Main from './Main'
import { ApiGet, setError } from '../lib/AppHelper'
import { withRouter } from 'react-router-dom'
import { AppStore } from '../lib/AppStore'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faExclamationTriangle, faRupeeSign, faTimes, faUser, faWallet } from '@fortawesome/free-solid-svg-icons'

export class ProductDetail extends Component {

  BasicBtn = React.createRef();
  MessageRef = React.createRef();

  state = {
    params: null,
    item: null,
    errors: {},
  }

  render() {

    const { item } = this.state;
    if (item === null) return false

    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to={`/`}>Back to Dashboard</AppBackButton>
        <div className="w-full px-6 flex ">
          <div className="flex-1">
            <AppHeading> {item.name}</AppHeading>

            <p className="font-medium pt-10">
              <FontAwesomeIcon className="text-primary text-lg mr-1" icon={faUser} /> Product Benefits</p>
            <ul className="pt-4 ul-height">

              {item.benefits.benefits && item.benefits.benefits.map(detail => (
                <li>
                  {detail.flag === 'Y' ?
                    (<FontAwesomeIcon className="text-green-500 text-sm" icon={faCheck} />) :
                    (detail.flag === 'N' ? <FontAwesomeIcon className="text-primary text-sm" icon={faTimes} />
                      : false)
                  } {detail.description}
                </li>
              ))}
            </ul>

            <hr className="mt-4" />
            <div className="pt-4">
              <p className="font-medium">
                <FontAwesomeIcon className="text-green-800 text-lg mr-1 mt-2" icon={faRupeeSign} />
                Deductible
              </p>
              <ul className="pt-4 ul-height">

                {item.benefits.deductible && item.benefits.deductible.map(detail => (
                  <li>
                    {detail.flag === 'Y' ?
                      (<FontAwesomeIcon className="text-green-500 text-sm" icon={faCheck} />) :
                      (detail.flag === 'N' ? <FontAwesomeIcon className="text-primary text-sm" icon={faTimes} />
                        : false)
                    } {detail.description}
                  </li>
                ))}
              </ul>
            </div>

            <hr className="mt-4" />
            <div className="pt-4">
              <p className="font-medium">
                <FontAwesomeIcon className="text-red-800 text-lg mr-1 mt-2" icon={faExclamationTriangle} />
                Exclusions
              </p>
              <ul className="pt-4 ul-height">

                {item.benefits.exclusions && item.benefits.exclusions.map(detail => (
                  <li>
                    {detail.flag === 'Y' ?
                      (<FontAwesomeIcon className="text-green-500 text-sm" icon={faCheck} />) :
                      (detail.flag === 'N' ? <FontAwesomeIcon className="text-primary text-sm" icon={faTimes} />
                        : false)
                    } {detail.description}
                  </li>
                ))}
              </ul>
            </div>

            <hr className="mt-4" />

            <div className="pt-4">
              <p className="font-medium">
                <FontAwesomeIcon className="text-blue-800 text-lg mr-1 mt-2" icon={faClock} />
                Wait Period
              </p>
              <ul className="pt-4 ul-height">
                {item.benefits.wait_period && item.benefits.wait_period.map(detail => (
                  <li>
                    {detail.flag === 'Y' ?
                      (<FontAwesomeIcon className="text-green-500 text-sm" icon={faCheck} />) :
                      (detail.flag === 'N' ? <FontAwesomeIcon className="text-primary text-sm" icon={faTimes} />
                        : false)
                    } {detail.description}
                  </li>
                ))}
              </ul>
            </div>

            <hr className="mt-4" />
            <div className="pt-4">
              <p className="font-medium">
                <FontAwesomeIcon className="text-blue-800 text-lg mr-1 mt-2" icon={faWallet} />
                Cancellation & Refund
              </p>
              <ul className="pt-4 ul-height">
                {item.benefits.cancellation_refund && item.benefits.cancellation_refund.map(detail => (
                  <li>
                    {detail.flag === 'Y' ?
                      (<FontAwesomeIcon className="text-green-500 text-sm" icon={faCheck} />) :
                      (detail.flag === 'N' ? <FontAwesomeIcon className="text-primary text-sm" icon={faTimes} />
                        : false)
                    }
                    {detail.description}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Main >
    )
  }

  async componentDidMount() {
    const id = this.props.match.params.productId;
    await this.getProduct(id)

  }

  getProduct = async (id) => {
    AppStore.dispatch({ type: 'LOADING', loading: true })
    const response = await ApiGet(`product/${id}`);
    if (response.status === 'success') {
      this.setState({ item: response.data })
    } else {
      await setError(response.message);
      // this.props.history.push("/");
    }
    AppStore.dispatch({ type: 'LOADING', loading: false })
  }

}


const mapStateToProps = (state) => {
  const { loading } = state
  return { loading: loading }
};

const ProductDetailConnect = connect(mapStateToProps)(props => {
  return <ProductDetail {...props} />
})

export default withRouter(ProductDetailConnect)

