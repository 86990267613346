import React, { Component, Fragment } from "react";
import { InputGroup } from "../../components/form/InputGroup";
import _ from "lodash";
import { DuplicateIcon } from "@heroicons/react/outline";
import paymentImg from "./../../payment-img.png";
import ClipboardJS from "clipboard";
import { Transition } from "@headlessui/react";
import { connect } from "react-redux";
import { FaWhatsapp } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { AppStore } from "../../lib/AppStore";
import {
  ApiGet,
  ApiPost,
  getData,
  setError,
  setMessage,
} from "../../lib/AppHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CouponAlert } from "../../components/AppComponents";
import { faCheckCircle, faHourglass } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import Button from "../../components/Button";
import { Modal } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";

export class Payment extends Component {
  CopyLinkBtn = React.createRef();

  state = {
    errors: {},
    copied: false,
    paymentSuccess: false,
    paymentUnderProcess: false,
    params: {
      coupon_code: "",
    },
    coupon_status: "",
    item: {
      status: false,
      message: "",
      response: false,
    },
    is_secured_login: "",
    payment: "",
    psp: "",
    open: false,
  };

  checkInterval = null;

  render() {
    const {
      errors,
      copied,
      paymentSuccess,
      paymentUnderProcess,
      params,
      item,
      is_secured_login,
      payment,
      open,
    } = this.state;
    const { application, child_policy_approve } = this.props;
    return (
      <div>
        <div className="w-full px-6 md:flex-row flex flex-col justify-between space-y-4">
          {paymentSuccess === false && paymentUnderProcess === false ? (
            <>
              <div>
                {application?.make_policy ? (
                  <>
                    <h4 className="text-md mb-6 mt-6">
                      Payment link is sent to the registered mobile number. +91-
                      {application?.mobile}
                    </h4>
                    <InputGroup
                      error={errors.name}
                      label="Payment link"
                      placeholder=""
                      attrs={{
                        value: application?.payment_link.replace(
                          /(^\w+:|^)\/\//,
                          ""
                        ),
                        readOnly: true,
                      }}
                      action={
                        <>
                          <div
                            tabIndex={0}
                            data-clipboard-text={application?.payment_link}
                            ref={this.CopyLinkBtn}
                            className="focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none cursor-pointer text-primary shadow-sm border-primary focus:bg-primary-50 px-3 flex items-center justify-center rounded-xl border ml-3 position-relative"
                          >
                            <Transition
                              as={Fragment}
                              show={copied}
                              enter="transform transition duration-75"
                              enterFrom="opacity-0 scale-50"
                              enterTo="opacity-100 scale-100"
                              leave="transform duration-75 transition"
                              leaveFrom="opacity-100 scale-100 "
                              leaveTo="opacity-0 scale-50"
                            >
                              <span
                                className="bg-gray-700 text-white px-2 py-1 rounded-md text-xs transition-opacity"
                                style={{ position: "absolute", top: -30 }}
                              >
                                Copied!
                              </span>
                            </Transition>
                            <DuplicateIcon width={20} />
                          </div>
                          <button
                            className="ml-2 w-24 h-12 rounded-full bg-red-600 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-white"
                            onClick={() => {
                              window.open(application?.payment_link, "_blank");
                            }}
                          >
                            Pay
                          </button>
                        </>
                      }
                    />
                    <div className="flex space-x-3">
                      <button
                        onClick={this.sendSMS}
                        title="Send SMS"
                        className="w-12 h-12 rounded-full bg-gray-600 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        <FiSend size={20} className="text-white" />
                      </button>
                      <button
                        onClick={this.sendWhatsapp}
                        title="Send On Whatsapp"
                        className="w-12 h-12 rounded-full bg-green-500 flex justify-center items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        <FaWhatsapp size={24} className="text-white" />
                      </button>
                    </div>
                    <div className="flex mt-10 flex-col items-center">
                      <img
                        alt="payment-img"
                        className="w-40 h-40 rounded-full"
                        src={paymentImg}
                      />
                      <p className="text-gray-400 text-sm mb-2 mt-3 uppercase">
                        Payment is pending
                      </p>
                    </div>
                  </>
                ) : application?.make_policy == false &&
                  child_policy_approve ? (
                  <>
                    <p>Activate Policy for +91- {application?.mobile}</p>
                    <br />
                    <Button
                      title="Activate Policy"
                      attrs={{
                        type: "button",
                        onClick: this.handleOpen,
                      }}
                    />
                  </>
                ) : (
                  <div className="py-10">
                    <h4>
                      Thank you. Customer details have been saved, Kindly
                      connect with head office to activate the benefits.
                    </h4>
                  </div>
                )}
              </div>

              {is_secured_login == "N" ? (
                <div className="w-80">
                  <InputGroup
                    label="Enter coupon code"
                    placeholder="XXX"
                    starMark={true}
                    prefix=""
                    attrs={{
                      value: params.coupon_code,
                      onChange: (e) => this.onChangeHandler(e, "coupon_code"),
                    }}
                    onEnter={this.submit}
                    error={errors.coupon_code}
                  />

                  {!item.status ? (
                    <Button
                      title="Apply Coupon"
                      attrs={{ type: "button", onClick: this.submit }}
                    ></Button>
                  ) : (
                    <>
                      <button
                        onClick={this.submitCouponClick}
                        className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                      >
                        Submit
                      </button>
                      <button
                        onClick={this.cancelClick}
                        className="bg-transparent hover:bg-red-500 text-red-800 font-semibold hover:text-white space-x-2 ml-2 py-2 px-4 border border-red-400 hover:border-transparent rounded"
                      >
                        Cancel
                      </button>
                    </>
                  )}

                  {item.response ? (
                    <p style={{ paddingTop: "10px" }}>
                      <CouponAlert
                        status={item.status}
                        message={item.message}
                      />
                    </p>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
        </div>

        {paymentSuccess ? (
          <div className="grid grid-cols-6 gap-4 mt-10">
            <div className="col-start-2 col-span-3 col-end-6">
              <h4 className="p-10 bg-green-500 text-white text-center ">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ fontSize: 60 }}
                ></FontAwesomeIcon>
              </h4>
              <div
                className="p-10 bg-slate-300 text-center text-md"
                style={{ background: "#f3f4f6" }}
              >
                <h4>
                  <div className="pb-4" style={{ fontSize: 30 }}>
                    Thank you!
                  </div>
                  Your payment is received successfully and Subscription is
                  activated.
                </h4>
                <Link to="/">
                  <button
                    type="button"
                    className="mt-5 bg-transparent hover:bg-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent text-red rounded"
                  >
                    Proceed
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ) : paymentUnderProcess ? (
          <div className="grid grid-cols-6 gap-4 mt-10">
            <div
              className="col-start-2 col-span-3 col-end-6"
              style={{ background: "#ffc107" }}
            >
              <h4 className="p-10 text-white text-center ">
                <FontAwesomeIcon
                  icon={faHourglass}
                  style={{ fontSize: 60 }}
                ></FontAwesomeIcon>
              </h4>
              <div className="p-10 bg-slate-300 text-center text-md">
                <h4>
                  <div
                    className="pb-4"
                    style={{ fontSize: 30, fontWeight: "bold" }}
                  >
                    Under Process!
                  </div>
                  {payment ? (
                    <>
                      <p>{payment.message}</p>
                      <p>Your Transaction ID is {payment.transaction_id}</p>
                    </>
                  ) : (
                    "Please wait while this payment gets processed ."
                  )}
                </h4>
              </div>
            </div>
          </div>
        ) : null}

        <Modal open={open} onClose={this.handleClose} size="xs">
          <Modal.Body>
            <p style={{ textAlign: "center" }}>
              <RemindIcon style={{ color: "#ffb300", fontSize: 36 }} />
            </p>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to Activate Policy?
            </p>
          </Modal.Body>
          <Modal.Footer
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <>
              <Button
                size={"sm"}
                attrs={{
                  onClick: this.makePolicy,
                }}
                title="Yes, I'm sure"
              ></Button>

              <Button
                size={"sm"}
                attrs={{
                  onClick: this.handleClose,
                }}
                title="cancel"
              ></Button>
            </>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  submit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ item: {} });
    const response = await ApiPost("validate-coupon", {
      coupon_code: this.state.params.coupon_code,
      application_id: this.props.application?.application_id,
    });

    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
        setError(response.message);
      } else {
        setMessage(response.message);
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (response.data) {
        this.setState({
          item: {
            status: response.data.status,
            message: response.data.message,
            response: true,
          },
        });
      }
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  makePolicy = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ item: {} });
    this.setState({ open: false });
    let applicationId = this.props.application?.application_id;
    const response = await ApiGet(`create-policy/${applicationId}`);
    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
        setError(response.message);
      } else {
        setMessage(response.message);
      }
    } else {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (response.data) {
        setMessage(response.message);
        this.props.history.push(`/subscription/${applicationId}`);
      }
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  checkPaymentStatus = async () => {
    const { application } = this.props;
    if (application?.payment?.status === 3) {
      this.setState({ paymentUnderProcess: true });
      this.setState({ transactionId: application?.payment?.transaction_id });
      let paymentData = JSON.parse(this.props.application?.payment?.data);
      this.setState({ payment: paymentData });
    }
  };

  componentDidMount() {
    const _this = this;
    this.checkPaymentStatus();
    this.getData();
    // const clipboard = new ClipboardJS(this.CopyLinkBtn.current);
    // clipboard.on("success", () => {
    //   _this.setState({ copied: true }, () => {
    //     setTimeout(() => {
    //       _this.setState({ copied: false });
    //     }, 2000);
    //   });
    // });
    this.checkInterval = setInterval(this.check, 5000);
  }

  cancelClick = async () => {
    this.setState({ item: {} });
  };

  submitCouponClick = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("apply-coupon", {
      coupon_code: this.state.params.coupon_code,
      application_id: this.props.application?.application_id,
    });

    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
    } else {
      this.check();
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  componentWillUnmount() {
    clearInterval(this.checkInterval);
  }

  onCompleteCountdown = () => {
    this.props.onError();
  };

  countdownRenderer = ({ minutes, seconds }) => {
    return `${_.padStart(minutes, 2, "0")}:${_.padStart(seconds, 2, "0")}`;
  };

  sendSMS = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("payment/send-link", {
      id: this.props.application?.id,
    });
    if (response.status === "success") {
      await setMessage(response.data.message);
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  sendWhatsapp = () => {
    const { application } = this.props;
    let url =
      `https://api.whatsapp.com/send?text=` +
      encodeURIComponent(
        `Dear ${application?.name}, The payment link for aiqahealth subscription is ${application?.payment_link} Click on the link to make the payment. You can call us on 6262306306 if you face any issues.`
      );

    window.open(url);
  };

  getData = async () => {
    this.setState({ is_secured_login: await getData("is_secured_login") });
  };

  check = async () => {
    const response = await ApiGet(
      `payment/check/${this.props.application?.application_id}`
    );
    if (response.status === "success") {
      if (response.data?.data?.payment?.status === 1) {
        this.setState({ paymentSuccess: true });
      }
      if (response.data?.data?.payment?.status == 3) {
        this.setState({ paymentUnderProcess: true });
        this.setState({ payment: JSON.parse(response.data.data.payment.data) });
      }
    } else {
      this.props.onError(response.message);
    }
  };
}

const mapStateToProps = (state) => {
  const {
    loading,
    user: {
      permissions: { child_policy_approve },
    },
  } = state;
  return { loading: loading, child_policy_approve: child_policy_approve };
};

const PaymentViewConnect = connect(mapStateToProps)((props) => {
  return <Payment {...props} />;
});

export default withRouter(PaymentViewConnect);
