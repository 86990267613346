import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AmountText, AppHeading } from "../components/AppComponents";
import {
  DaughterIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "../components/AppIcons";
import Button from "../components/Button";
import { InputCounter } from "../components/form/InputCounter";
import InputGroup from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import { InputToggle } from "../components/form/InputToggle";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import {
  ApiGet,
  JavaApiGet,
  JavaApiPut,
  validateDate,
  validateEmail,
  validateMobile,
  validateName,
  setMessage,
  setError,
} from "../lib/AppHelper";
import FamilyDetails from "./subscription/FamilyDetails";
import { withRouter } from "react-router-dom";

const PlanOption = ({ title = "", features = [], amount = "1,123" }) => {
  return (
    <div className="bg-primary-50 border border-warning rounded-xl p-5">
      <p className="font-semibold">{title}</p>
      <ul className="list-disc pl-4 text-xs my-3">
        {features.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      <div className="font-semibold">
        <AmountText amount={amount} />
      </div>
    </div>
  );
};

export class SubscriptionPreview extends Component {
  BasicBtn = React.createRef();
  MessageRef = React.createRef();

  state = {
    params: {
      // product: "mutual_aid:1",
      max_members: 0,

      product: 96,
      product_type: "",
      members: {
        holder: true,
        spouse: false,
        daughter: false,
        son: false,
        parents: false,
      },
      members_count: {
        daughter: 1,
        son: 1,
        parents: {
          self: 1,
          spouse: 0,
        },
      },
      name: "",
      phone: "",
      email: "",
      gender: "m",
      dob: "",
      pinCode: "",
      state: "",
      govtIDType: "",
      govtIDNumber: "",
      members_detail: {},
    },
    max: "",
    min: "",
    step: 1,
    questions: [],
    answers: {},
    govt_ids: [
      {
        label: "Select Id Type",
        value: "",
      },
      {
        label: "AADHAR",
        value: "aadhar",
      },
      {
        label: "Voter ID",
        value: "voter",
      },
      {
        label: "Passport",
        value: "passport",
      },
      {
        label: "Driving License",
        value: "driving_licence",
      },
    ],
  };

  steps = 2;

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isReady = () => {
    const { step, params } = this.state;
    const selected_product = _.find(
      this.props.products,
      (item) => params.product == item.id
    );
    params.product_type = selected_product
      ? selected_product.product.product_type
      : "";

    if (selected_product) {
      params.max_members = selected_product.product.max_members;
    }

    if (step === 1) {
      if (!params?.members_detail?.self?.name) {
        return false;
      } else if (!validateName(params?.members_detail?.self?.name)) {
        return false;
      }
      if (
        !params?.members_detail?.self?.phone ||
        params?.members_detail?.self?.phone.length !== 10
      )
        return false;
      if (
        !params?.members_detail?.self?.email ||
        !this.validateEmail(params?.members_detail?.self?.email)
      )
        return false;
      if (!params?.members_detail?.self?.govtIDType) return false;
      if (!params?.members_detail?.self?.govtIDNumber) return false;
      if (
        !params?.members_detail?.self?.dob ||
        !moment(params?.members_detail?.self?.dob).isValid() ||
        moment().diff(params?.members_detail?.self?.dob, "years") >= 100
      )
        return false;
      if (
        !params?.members_detail?.self?.pinCode ||
        params?.members_detail?.self?.pinCode.length !== 6
      )
        return false;
      if (!params?.members_detail?.self?.state) return false;
      const max_members = selected_product?.product?.max_members;
      const count = this.getSelectedCount();
      if (count > max_members) return false;
    }

    if (step === 2) {
      const members_details = params.members_detail;
      let invalid = false;
      _.forEach(members_details, (members_detail) => {
        _.forEach(members_detail, (detail, prop) => {
          if (prop === "height" || prop === "weight")
            detail = parseInt(detail, 10);

          if (
            members_detail.govtIDType != "" &&
            members_detail.govtIDNumber == ""
          ) {
            invalid = true;
            return false;
          } else if (
            members_detail.govtIDType == "" &&
            members_detail.govtIDNumber != ""
          ) {
            invalid = true;
            return false;
          } else if (members_detail.name == "") {
            invalid = true;
            return false;
          } else if (members_detail.dob == "") {
            invalid = true;
            return false;
          } else if (members_detail.gender == "") {
            invalid = true;
            return false;
          }
        });
        if (invalid) return false;
      });
      if (invalid) return false;
    }

    if (!params?.members_detail?.self?.state) return false;
    const max_members = selected_product?.product?.max_members;
    const count = this.getSelectedCount();
    if (count > max_members) return false;
    return true;
  };

  getSelectedCount = () => {
    const { params } = this.state;

    let count = 0;

    for (let prop in params.members) {
      if (params.members[prop] === true) {
        if (prop in params.members_count) {
          if (prop === "parents") {
            count = count + params.members_count[prop].self;
            count = count + params.members_count[prop].spouse;
          } else {
            count = count + params.members_count[prop];
          }
        } else {
          count = count + 1;
        }
      }
    }
    return count;
  };

  render() {
    const { products } = this.props;
    const { params, step, govt_ids } = this.state;
    const selected_count = this.getSelectedCount();
    const selected_product = _.find(
      products,
      (item) => params.product === item.id
    );
    const ready = this.isReady();
    const items = [];
    _.each(products, (prod) => {
      items.push({
        value: prod.id,
        label: (
          <PlanOption
            amount={prod.price}
            title={prod.name}
            features={[
              `${prod.product.max_members} Members`,
              `${prod.product.description ? prod.product.description : ""}`,
              ..._.map(prod.inclusions, "title"),
            ]}
          />
        ),
      });
    });

    const memberDetailHandle = (key) => {
      const memberDetails = this.state.params?.members_detail;
      delete memberDetails[key];
      this.onChangeHandler(
        {
          target: {
            value: memberDetails,
          },
        },
        "members_detail"
      );
    };

    const memberLabelClickHandler = (key, count) => {
      const memberDetails = this.state.params?.members_detail;
      for (let i = count; i >= 1; i--) {
        delete memberDetails[`${key}_${i}`];
      }

      this.onChangeHandler(
        {
          target: {
            value: memberDetails,
          },
        },
        "members_detail"
      );

      this.onChangeHandler(
        {
          target: {
            value: 0,
          },
        },
        `members_count.${key}`
      );
    };

    return (
      <Main MessageRef={this.MessageRef}>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <div>
              <AppHeading>
                {selected_product ? selected_product.name : ""}
              </AppHeading>

              <InputToggle
                label="Primary Member"
                value={true}
                attrs={{}}
              ></InputToggle>

              <InputToggle
                label="Spouse"
                value={params.members.spouse}
                attrs={{
                  onClick: () => {
                    if (params.members.spouse) {
                      this.onChangeHandler(
                        { target: { value: !params.members.spouse } },
                        "members.spouse"
                      );
                      memberDetailHandle("spouse");
                    } else {
                      this.onChangeHandler(
                        { target: { value: !params.members.spouse } },
                        "members.spouse"
                      );
                      this.setState({ step: 1 });
                    }
                  },
                }}
              >
                <RingIcon className="mr-3" size={20} />
              </InputToggle>

              <div className="relative">
                <InputToggle
                  label="Daughter"
                  value={params.members.daughter}
                  attrs={{
                    onClick: () => {
                      if (params.members.daughter) {
                        this.onChangeHandler(
                          { target: { value: !params.members.daughter } },
                          "members.daughter"
                        );
                        memberLabelClickHandler(
                          "daughter",
                          params.members_count.daughter
                        );
                        this.onChangeHandler(
                          {
                            target: {
                              value: 0,
                            },
                          },
                          `members_count.daughter`
                        );
                      } else {
                        this.onChangeHandler(
                          { target: { value: !params.members.daughter } },
                          "members.daughter"
                        );
                        this.onChangeHandler(
                          {
                            target: {
                              value: 1,
                            },
                          },
                          `members_count.daughter`
                        );
                        this.setState({ step: 1 });
                      }
                    },
                  }}
                >
                  <DaughterIcon className="mr-3" size={20} />
                </InputToggle>

                {params.members.daughter ? (
                  <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                    <InputCounter
                      min={0}
                      max={3}
                      value={params.members_count.daughter}
                      onMinus={() => {
                        memberDetailHandle(
                          `daughter_${params.members_count?.daughter}`
                        );

                        if (params.members_count.daughter - 1 === 0) {
                          this.onChangeHandler(
                            { target: { value: !params.members.daughter } },
                            "members.daughter"
                          );
                        } else {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count?.daughter - 1,
                              },
                            },
                            "members_count.daughter"
                          );
                        }
                      }}
                      onPlus={() => {
                        this.onChangeHandler(
                          {
                            target: {
                              value: params.members_count.daughter + 1,
                            },
                          },
                          "members_count.daughter"
                        );
                        this.setState({ step: 1 });
                      }}
                    />
                  </div>
                ) : (
                  false
                )}
              </div>

              <div className="relative">
                <InputToggle
                  label="Son"
                  value={params.members.son}
                  attrs={{
                    onClick: () => {
                      if (params.members.son) {
                        memberLabelClickHandler(
                          "son",
                          params.members_count?.son
                        );
                        this.onChangeHandler(
                          {
                            target: {
                              value: 0,
                            },
                          },
                          `members_count.son`
                        );
                      } else {
                        this.onChangeHandler(
                          {
                            target: {
                              value: 1,
                            },
                          },
                          `members_count.son`
                        );
                        this.setState({ step: 1 });
                      }

                      this.onChangeHandler(
                        { target: { value: !params.members.son } },
                        "members.son"
                      );
                    },
                  }}
                >
                  <SonIcon className="mr-3" size={20} />
                </InputToggle>

                {params.members.son ? (
                  <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                    <InputCounter
                      min={0}
                      max={3}
                      value={params.members_count.son}
                      onMinus={() => {
                        memberDetailHandle(`son_${params.members_count?.son}`);
                        if (params.members_count.son - 1 === 0) {
                          this.onChangeHandler(
                            { target: { value: !params.members.son } },
                            "members.son"
                          );
                        } else {
                          this.onChangeHandler(
                            {
                              target: { value: params.members_count.son - 1 },
                            },
                            "members_count.son"
                          );
                        }
                      }}
                      onPlus={() => {
                        this.onChangeHandler(
                          { target: { value: params.members_count.son + 1 } },
                          "members_count.son"
                        );
                        this.setState({ step: 1 });
                      }}
                    />
                  </div>
                ) : (
                  false
                )}
              </div>

              <div className="relative">
                <InputToggle
                  label="Parents"
                  value={params.members.parents}
                  attrs={{
                    onClick: () => {
                      this.onChangeHandler(
                        { target: { value: !params.members.parents } },
                        "members.parents"
                      );
                      this.setState({ step: 1 });
                    },
                  }}
                >
                  <ParentsIcon className="mr-3" size={20} />
                </InputToggle>
              </div>
              {params.members.parents ? (
                <div className="flex -mt-2 justify-between">
                  <div className="w-32">
                    <InputCounter
                      mini={false}
                      label="My Parents"
                      min={0}
                      max={2}
                      value={params.members_count?.parents?.self}
                      onMinus={() => {
                        memberDetailHandle(
                          `self_parent_${params.members_count?.parents?.self}`
                        );

                        this.onChangeHandler(
                          {
                            target: {
                              value: params.members_count?.parents?.self
                                ? params.members_count?.parents?.self - 1
                                : 0,
                            },
                          },
                          "members_count.parents.self"
                        );
                      }}
                      onPlus={() => {
                        this.onChangeHandler(
                          {
                            target: {
                              value: params.members_count?.parents?.self
                                ? params.members_count?.parents?.self + 1
                                : 0 + 1,
                            },
                          },
                          "members_count.parents.self"
                        );
                        this.setState({ step: 1 });
                      }}
                      className="border rounded-xl mt-2"
                    />
                  </div>
                  <div className="w-32">
                    <InputCounter
                      mini={false}
                      label="Spouse Parents"
                      min={0}
                      max={2}
                      value={params.members_count?.parents?.spouse}
                      onMinus={() => {
                        memberDetailHandle(
                          `spouse_parent_${params.members_count?.parents?.self}`
                        );
                        this.onChangeHandler(
                          {
                            target: {
                              value: params.members_count?.parents?.spouse
                                ? params.members_count?.parents?.spouse - 1
                                : 0,
                            },
                          },
                          "members_count.parents.spouse"
                        );
                      }}
                      onPlus={() => {
                        this.onChangeHandler(
                          {
                            target: {
                              value: params.members_count?.parents?.spouse
                                ? params.members_count?.parents?.spouse + 1
                                : 0 + 1,
                            },
                          },
                          "members_count.parents.spouse"
                        );
                        this.setState({ step: 1 });
                      }}
                      className="border rounded-xl mt-2"
                    />
                  </div>
                </div>
              ) : (
                false
              )}
            </div>

            <div>
              <AppHeading className="mb-3">Primary Member Holder</AppHeading>
              <InputGroup
                label="Primary Member Name"
                requiredMark="true"
                placeholder="Enter name..."
                attrs={{
                  maxLength: 32,
                  value: params?.members_detail?.self?.name,
                  onChange: (e) => {
                    // name validation ragx
                    if (
                      /^[a-zA-Z\s]+$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      this.onChangeHandler(e, "members_detail.self.name");
                    }
                  },
                }}
                validateError={(value) => {
                  if (validateName(value)) {
                    return false;
                  } else {
                    return "Please enter valid name";
                  }
                }}
              />
              <InputGroup
                label="Phone no"
                placeholder="Enter phone no..."
                prefix="+91"
                requiredMark="true"
                disabled={true}
                attrs={{
                  value: params?.members_detail?.self?.phone,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "members_detail.self.phone"
                      );
                    }
                  },
                  maxLength: 10,
                }}
                validateError={(value) => {
                  if (validateMobile(value)) {
                    return false;
                  } else {
                    return "Please enter valid mobile number";
                  }
                }}
              />
              <InputGroup
                label="Email"
                placeholder="Enter email..."
                requiredMark="true"
                attrs={{
                  maxLength: 64,
                  value: params?.members_detail?.self?.email,
                  onChange: (e) =>
                    this.onChangeHandler(e, "members_detail.self.email"),
                }}
                validateError={(value) => {
                  if (validateEmail(value)) {
                    return false;
                  } else {
                    return "please enter correct email ID";
                  }
                }}
              />
              <InputRadio
                label="Gender"
                requiredMark="true"
                name="gender"
                buttonHidden={false}
                options={[
                  { label: "Male", value: "m" },
                  { label: "Female", value: "f" },
                  { label: "Others", value: "o" },
                ]}
                value={params?.members_detail?.self?.gender}
                attrs={{
                  onChange: (e) =>
                    this.onChangeHandler(e, "members_detail.self.gender"),
                }}
              />
              <InputGroup
                label="Date of Birth"
                requiredMark="true"
                placeholder="DOB"
                attrs={{
                  type: "date",
                  value: params?.members_detail?.self?.dob,
                  onChange: (e) =>
                    this.onChangeHandler(e, "members_detail.self.dob"),
                  min: this.state.max,
                  max: this.state.min,
                }}
                valdiateOnChange={true}
                validateError={(value) => {
                  if (validateDate(value)) {
                    return false;
                  } else {
                    return "Please enter valid date";
                  }
                }}
              />
              <InputSelect
                requiredMark="true"
                label="Govt ID Type"
                attrs={{
                  value: params?.members_detail?.self?.govtIDType,
                  onChange: (e) => {
                    this.onChangeHandler(
                      e,
                      "members_detail.self.govtIDType",
                      "members_detail.self.govtIDNumber"
                    );
                  },
                }}
                options={govt_ids}
              ></InputSelect>

              <InputGroup
                label="Govt ID Number"
                placeholder="Enter Govt ID Number..."
                requiredMark="true"
                attrs={{
                  disabled: params?.members_detail?.self?.govtIDType === "",
                  value: params?.members_detail?.self?.govtIDNumber,
                  onChange: (e) => {
                    if (this.state.params.governmentIdType === "aadhar") {
                      if (e.target.value.length < 13) {
                        this.onChangeHandler(
                          {
                            target: {
                              value: e.target.value.replace(/\D/, ""),
                            },
                          },
                          "members_detail.self.govtIDNumber"
                        );
                      }
                    } else if (this.state.params.governmentIdType === "voter") {
                      if (e.target.value.length < 15) {
                        this.onChangeHandler(
                          {
                            target: {
                              value: e.target.value.replace(/\D/, ""),
                            },
                          },
                          "members_detail.self.govtIDNumber"
                        );
                      }
                    } else if (
                      this.state.params.governmentIdType === "passport"
                    ) {
                      if (e.target.value.length < 15) {
                        this.onChangeHandler(
                          {
                            target: {
                              value: e.target.value.replace(/\D/, ""),
                            },
                          },
                          "members_detail.self.govtIDNumber"
                        );
                      }
                    } else if (
                      this.state.params.governmentIdType === "driving_licence"
                    ) {
                      if (e.target.value.length < 18) {
                        this.onChangeHandler(
                          {
                            target: {
                              value: e.target.value.replace(/\D/, ""),
                            },
                          },
                          "members_detail.self.govtIDNumber"
                        );
                      }
                    } else {
                      if (e.target.value.length < 20) {
                        this.onChangeHandler(
                          {
                            target: {
                              value: e.target.value.replace(/\D/, ""),
                            },
                          },
                          "members_detail.self.govtIDNumber"
                        );
                      }
                    }
                  },
                  maxLength: 20,
                }}
              />

              <InputGroup
                label="pinCode"
                placeholder="Enter pincode..."
                requiredMark="true"
                attrs={{
                  maxLength: 6,
                  value: params?.members_detail?.self?.pinCode,
                  onChange: (e) => {
                    this.onChangeHandler(
                      { target: { value: e.target.value.replace(/\D/, "") } },
                      "members_detail.self.pinCode"
                    );
                    if (e.target.value.length == 6) this.checkPin();
                  },
                  onBlur: this.checkPin,
                }}
                onEnter={this.checkPin}
              />
              {params?.members_detail?.self?.state ? (
                <InputGroup
                  label="Your State"
                  placeholder="Enter state..."
                  attrs={{
                    value: params?.members_detail?.self?.state,
                    readOnly: true,
                  }}
                />
              ) : (
                false
              )}
            </div>

            {step == 2 ? (
              <div>
                <AppHeading>Member Details</AppHeading>
                <FamilyDetails
                  value={params}
                  onChange={(v) =>
                    this.onChangeHandler(
                      { target: { value: v } },
                      "members_detail"
                    )
                  }
                  govt_ids={this.state.govt_ids}
                />
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            {params.max_members && selected_count > params.max_members ? (
              <p className="text-center text-xs font-semibold text-red-500 my-2">
                Maximum {params.max_members} members are allowed.
              </p>
            ) : (
              false
            )}
            <div>
              <Button
                attrs={{
                  ref: this.BasicBtn,
                  disabled: !ready,
                  type: "button",
                  onClick: this.next,
                }}
                size="md"
                title={step === 2 ? "Update" : "Continue to members"}
              />
            </div>
          </div>
        </div>
      </Main>
    );
  }

  checked_pin = "";

  checkPin = async () => {
    if (!this.state.params?.members_detail?.self?.pinCode) return;
    if (this.state.params?.members_detail?.self?.pinCode === this.checked_pin)
      return;
    this.checked_pin = this.state.params?.members_detail?.self?.pinCode;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(
      `check-pin/${this.state.params?.members_detail?.self?.pinCode}`
    );

    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler(
        { target: { value: response.data } },
        "members_detail.self.state"
      );
      this.BasicBtn.current.focus();
    } else {
      this.MessageRef.current.show(response.message, "error");
      this.onChangeHandler(
        { target: { value: null } },
        "members_detail.self.state"
      );
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
  createDate = (years) => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - years);
    var ndate = new Date(date),
      mnth = ("0" + (ndate.getMonth() + 1)).slice(-2),
      day = ("0" + ndate.getDate()).slice(-2);
    return [ndate.getFullYear(), mnth, day].join("-");
  };
  onChangeHandler = (e, key, para) => {
    const { params } = this.state;
    if (para) {
      _.set(params, para, "");
    }
    _.set(params, key, e.target.value);
    this.setState({ params: params });
  };

  onAnsChangeHandler = (e, key) => {
    const { answers } = this.state;
    _.set(answers, key, parseInt(e.target.value, 10));
    this.setState({ answers: answers });
  };

  next = () => {
    if (this.state.step < this.steps) {
      this.setState({ step: this.state.step + 1 });
    } else {
      this.doSubmit();
    }
  };

  prev = () => {
    this.setState({ cancelPayment: false, step: this.state.step - 1 });
  };

  doSubmit = async () => {
    let keys = Object.keys(this.state?.params?.members_detail);
    let member = keys.map((elem) => {
      return this.state?.params?.members_detail[elem];
    });
    const applicationId = this.state?.params?.application_id;
    const data = {
      productId: this.state?.params?.product,
      productType: this.state?.params?.product_type,
      resellerId: this.state?.params?.resellerId,
      pincode: this.state?.params?.members_detail["self"].pinCode,
      applicationId: applicationId,
      members: member,
    };
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await JavaApiPut(
      "enrollment-service/reseller/updateApplication",
      data,
      null,
      "java"
    );

    if (response.status === "OK") {
      this.props.history.push({
        pathname: `/final-view/${applicationId}`,
      });
      setMessage("Member detail has been updated successfully");
    } else {
      setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevState.step !== this.state.step) {
      this.setProgress();
    }
  }

  componentDidMount() {
    this.setProgress();
    this.getQuestions();
    this.getApplication();
  }

  getApplication = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const applicationId = this.props.match.params.id;
    const response = await JavaApiGet(
      `enrollment-service/application/getApplicationPreViewDetails/${applicationId}`
    );

    if (response.code == 200) {
      let item = response.responseObject;
      const params = {
        params: {
          max_members: 0,
          application_id: item.applicationId,
          product: item.productId,
          product_type: "",
          members: item.members_parent,
          members_count: item.members_count,
          name: item.name,
          phone: item.mobile,
          email: item.email,
          gender: item.gender,
          dob: item.dob,
          pinCode: item.pinCode,
          state: item.state ? item.state : "",
          stgage: "FINAL",
          govtIDType: item.govtIDType,
          govtIDNumber: item.govtIDNumber,
          members_detail: item.members_detail,
          resellerId: item.resellerId,
        },
        max: "",
        min: "",
      };

      this.setState(params);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  setProgress = () => {
    let progress = 4;

    switch (this.state.step) {
      case 1:
        progress = this.steps === 5 ? 20 : 25;
        break;
      case 2:
        progress = this.steps === 5 ? 40 : 100;
        break;
      case 3:
        progress = this.steps === 5 ? 60 : 75;
        break;
      case 4:
        progress = this.steps === 5 ? 80 : 100;
        break;
      default:
        progress = this.steps === 5 ? 100 : 100;
    }

    AppStore.dispatch({ type: "PROGRESS", progress: progress });
  };

  getQuestions = async () => {
    // AppStore.dispatch({ type: 'LOADING', loading: true })
    let answers = {};
    const response = await ApiGet(`questions`);
    if (response.status === "success") {
      _.forEach(response.data, (question) => {
        _.set(answers, `ans-${question.medicalQuestionId}`, 0);
      });

      this.setState({ questions: response.data, answers: answers }, () => {
        // AppStore.dispatch({ type: 'LOADING', loading: false })
      });
    } else {
      // AppStore.dispatch({ type: 'LOADING', loading: false })
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, products } = state;
  return { loading: loading, products: products };
};

const SubscriptionPreviewConnect = connect(mapStateToProps)((props) => {
  return <SubscriptionPreview {...props} />;
});

export default withRouter(SubscriptionPreviewConnect);
