import React from "react";
import { InputGroup } from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import Button from "../components/Button";
import { Formik } from "formik";

const AddMember = ({ application }) => {
  // const onClickHandle = () => {

  // }
  return (
    <div className="px-1">
      <div style={{ display: "flex", marginBottom: "5px" }}>
        <p className="text-lg" style={{ fontWeight: "450" }}>
          Product name
        </p>
        <span
          style={{
            marginTop: "2px",
            marginLeft: "20px",
            textTransform: "capitalize",
            fontWeight: "600",
          }}
        >
          {application.product_display_name}
        </span>
      </div>
      <Formik
        validateOnChange={false}
        // onSubmit={(value) => onClickHandle(value)}
        initialValues={{
          name: "",
          dob: "",
          gender: "",
          patient_type: "",
          gov_id_number: "",
          gov_id_type: "",
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <form>
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Name
            </p>
            <InputGroup placeholder="Full Name" requiredMark="true" />
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Gender
            </p>
            <InputRadio
              requiredMark="true"
              name="gender"
              options={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Others", value: "others" },
              ]}
            />
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Date of birth
            </p>
            <InputGroup
              placeholder="Date of Birth (DD/MM/YYYY)"
              requiredMark="true"
            />
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Member type
            </p>
            <InputSelect
              requiredMark="true"
              options={[
                {
                  label: "Select Id Type",
                  value: "",
                },
                {
                  label: "Parent",
                  value: "parent",
                },
                {
                  label: "Spouse",
                  value: "spouse",
                },
                {
                  label: "Daughter",
                  value: "daughter",
                },
                {
                  label: "Son",
                  value: "son",
                },
              ]}
            />
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Govt ID type
            </p>
            <InputSelect
              requiredMark="true"
              options={[
                {
                  label: "Select Id Type",
                  value: "",
                },
                {
                  label: "AADHAR",
                  value: "aadhar",
                },
                {
                  label: "Voter ID",
                  value: "voter",
                },
                {
                  label: "Passport",
                  value: "passport",
                },
                {
                  label: "Driving License",
                  value: "driving_license",
                },
              ]}
            />
            <p className="text-lg" style={{ fontWeight: "450" }}>
              Government ID number
            </p>
            <InputGroup
              requiredMark="true"
              placeholder="Govt ID Number (XXX-XX-XXXX)"
            />
            <Button title="Add Member" />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddMember;
