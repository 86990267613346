import { PencilIcon, XIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import Main from "./../Main";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { InputRadio } from "../../components/form/InputRadio";
import { ApiGet, ApiPost, removeData, setError } from "../../lib/AppHelper";

export class BankingProfile extends Component {
  state = {
    edit: false,
    init_params: {
      pan: this.props.user.banking.pan,
      payment_mode: this.props.user.banking.payment_mode,
      bank_name: this.props.user.banking.bank_name,
      account_number: this.props.user.banking.account_number,
      ifsc_code: this.props.user.banking.ifsc,
      upi_id: this.props.user.banking.upi,
    },
    params: {
      pan: this.props.user.banking.pan,
      payment_mode: this.props.user.banking.payment_mode,
      bank_name: this.props.user.banking.bank_name,
      account_number: this.props.user.banking.account_number,
      ifsc_code: this.props.user.banking.ifsc,
      upi_id: this.props.user.banking.upi,
    },
    errors: {},
  };

  PanInput = React.createRef();

  render() {
    const { edit, params, errors } = this.state;

    return (
      <Main>
        <AppRow>
          <div className="flex-1 max-w-min mr-5">
            <ProfileSidebar />
          </div>
          <div className="flex-auto">
            <div className="max-w-xl">
              <InputGroup
                error={errors.bank_name}
                label="PAN Number"
                placeholder="Enter PAN..."
                attrs={{
                  value: params.pan,
                  onChange: (e) => this.onChangeHandler(e, "pan"),
                  ref: this.PanInput,
                  readOnly: !edit,
                }}
              />

              {edit ? (
                <InputRadio
                  label="Payment in"
                  name="payment_mode"
                  options={[
                    { label: "Account NEFT", value: "neft" },
                    { label: "UPI", value: "upi" },
                  ]}
                  value={params.payment_mode}
                  attrs={{
                    onChange: (e) => this.onChangeHandler(e, "payment_mode"),
                  }}
                />
              ) : (
                false
              )}

              <div
                className={`${params.payment_mode === "neft" ? "" : "hidden"}`}
              >
                <InputGroup
                  error={errors.bank_name}
                  label="Bank Name"
                  placeholder="Enter Bank Name..."
                  attrs={{
                    value: params.bank_name,
                    onChange: (e) => this.onChangeHandler(e, "bank_name"),
                    readOnly: !edit,
                  }}
                />

                <InputGroup
                  error={errors.account_number}
                  label="Account Number"
                  placeholder="Enter Account Number..."
                  attrs={{
                    value: params.account_number,
                    onChange: (e) => this.onChangeHandler(e, "account_number"),
                    readOnly: !edit,
                  }}
                />

                <InputGroup
                  error={errors.ifsc_code}
                  label="IFSC Code"
                  placeholder="Enter IFSC Code..."
                  attrs={{
                    value: params.ifsc_code,
                    onChange: (e) => this.onChangeHandler(e, "ifsc_code"),
                    readOnly: !edit,
                  }}
                />
              </div>
              <div
                className={`${params.payment_mode === "upi" ? "" : "hidden"}`}
              >
                <InputGroup
                  error={errors.upi_id}
                  label="Enter UPI ID"
                  placeholder="Enter UPI ID..."
                  attrs={{
                    value: params.upi_id ? params.upi_id : "",
                    onChange: (e) => this.onChangeHandler(e, "upi_id"),
                    readOnly: !edit,
                  }}
                />
              </div>
              <div className="mt-8">
                <div className="flex">
                  <div>
                    <Button
                      attrs={{ onClick: this.toggleEdit }}
                      title={!edit ? "Edit Details" : "Cancel Edit"}
                      display="inline-block"
                      outline={true}
                    >
                      {edit ? (
                        <XIcon width={16} className="mr-1" />
                      ) : (
                        <PencilIcon width={16} className="mr-1" />
                      )}
                    </Button>
                  </div>
                  <div className="flex-1 pl-5">
                    {edit ? (
                      <Button
                        attrs={{ type: "button", onClick: this.updateBanking }}
                        size="md"
                        title={"Update Details"}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
      </Main>
    );
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit }, () => {
      if (this.state.edit) {
        this.PanInput.current.focus();
      } else {
        this.setState({ params: this.state.init_params });
      }
    });
  };

  updateBanking = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const { bank_name, account_number, ifsc_code, upi_id, payment_mode } =
        this.state.params;
      const response = await ApiPost("register/banking", {
        update_only: 1,
        bank_name: bank_name,
        account_number: account_number,
        ifsc_code: ifsc_code,
        upi_id: upi_id,
        payment_mode: payment_mode,
      });

      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        const response = await ApiGet("me");
        if (response.status === "success") {
          AppStore.dispatch({ type: "USER_UPDATED", user: response.data });
        } else {
          await removeData("access_token");
          await removeData("auth_token");
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <BankingProfile {...props} />;
});
