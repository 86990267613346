import {
  ArrowLeftIcon,
  DocumentIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AppHeading } from "../components/AppComponents";
import Button from "../components/Button";
import { InputFile } from "../components/form/InputFile";
import InputGroup from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import { setResellerToken } from "../helpers/Helper";
import {
  ApiGet,
  ApiPost,
  AppLogout,
  removeData,
  setData,
  setError,
  setMessage,
} from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import { toast } from "react-toastify";

const qualifications = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: "Primary",
  },
  {
    value: 2,
    label: "Secondary",
  },
  {
    value: 3,
    label: "Higher Secondary",
  },
  {
    value: 4,
    label: "Under-Graduate",
  },
  {
    value: 5,
    label: "Post-Graduate",
  },
  {
    value: 6,
    label: "Doctoral studies",
  },
  {
    value: 7,
    label: "Graduate",
  },
];

const experiences = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: "Less than 1 year",
  },
  {
    value: 2,
    label: "1-3 Years",
  },
  {
    value: 3,
    label: "3-5 Years",
  },
  {
    value: 4,
    label: "5-8 Years",
  },
  {
    value: 5,
    label: "Less than 10 years",
  },
  {
    value: 6,
    label: "More than 10 years",
  },
];

const incomes = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: "Less than 10,000",
  },
  {
    value: 2,
    label: "< 25,000",
  },
  {
    value: 3,
    label: "< 50,000",
  },
  {
    value: 4,
    label: "More than 50,000",
  },
  {
    value: 5,
    label: "Less than 1 lakh",
  },
  {
    value: 6,
    label: "More than 1 lakh",
  },
];

export class Register extends Component {
  MessageRef = React.createRef();
  BasicBtn = React.createRef();
  checked_pin = _.get(this.props.user, "basic.pincode", "");

  state = {
    params: {
      mobile: this.props.user.mobile,
      email: this.props.user.email ? this.props.user.email : "",
      name: this.props.user.name ? this.props.user.name : "",
      dob: _.get(this.props, "user.basic.dob", ""),
      // gender: this.props.user.gender ? this.props.user.gender : "m",
      pin: _.get(this.props, "user.basic.pincode", ""),
      state: _.get(this.props, "user.basic.state", ""),
      qualification: _.get(this.props, "user.professional.qualification", ""),
      profession: _.get(this.props, "user.professional.profession", ""),
      invesment_work: _.get(
        this.props,
        "user.professional.invesment_work",
        "full"
      ),
      income: _.get(this.props, "user.professional.income", 0),
      experience: _.get(this.props, "user.professional.experience", 0),
      payment_mode: _.get(this.props, "user.banking.payment_mode", "neft"),
      pan: _.get(this.props, "user.banking.pan", ""),
      tin: _.get(this.props, "user.banking.tin", ""),
      document: "",
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      upi_id: _.get(this.props, "user.banking.upi", ""),
      // photo_base64: null,
      photo: "",
      governmentIdType: _.get(this.props, "user.banking.upi", ""),
      governmentId: "",
    },
    govt_ids: [
      {
        label: "Select Id Type",
        value: "",
      },
      {
        label: "AADHAR",
        value: "aadhar",
      },
      {
        label: "Voter ID",
        value: "voter",
      },
      {
        label: "Passport",
        value: "passport",
      },
      {
        label: "Driving License",
        value: "driving_licence",
      },
      {
        label: "Pan No",
        value: "pan",
      },
    ],
    step: 1,
    errors: {},
    permissions: this.props.user.permissions,
  };

  isReady = () => {
    const { step, params, permissions } = this.state;
    if (step === 1) {
      if (
        permissions?.hide_reseller_pincode &&
        permissions?.hide_reseller_email &&
        params.mobile &&
        params.name
      ) {
        return true;
      } else if (params.mobile && params.email && params.name && params.pin)
        return true;
    }
    if (permissions?.mandatory_reseller_government_id) {
      if (step == 2 && params.governmentIdType && params.governmentId) {
        return true;
      }
    } else if (step === 2) return true;

    return false;
  };

  render() {
    const { loading, user } = this.props;
    const { params, step, errors, govt_ids, permissions } = this.state;
    const ready = this.isReady();
    return (
      <Main MessageRef={this.MessageRef}>
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            <AppHeading>Registration</AppHeading>
            {step > 1 ? (
              <div
                onClick={this.prev}
                className="flex items-center text-primary cursor-pointer pt-4"
              >
                <ArrowLeftIcon width={17} className="mr-3" />
                {"Back"}
              </div>
            ) : (
              false
            )}
            {/* Basic Information */}
            {step === 1 ? (
              <div>
                <h4 className="mt-1 text-md mb-6">
                  Please enter basic details
                </h4>
                <InputGroup
                  starMark={true}
                  label="Phone no"
                  placeholder="0000000000"
                  prefix="+91"
                  attrs={{ maxLength: 10, value: user.mobile, readOnly: true }}
                />
                <InputGroup
                  starMark={true}
                  error={errors.name}
                  label="Emp Name \ Company Name"
                  placeholder="Name"
                  attrs={{
                    value: params.name,
                    onChange: (e) => this.onChangeHandler(e, "name"),
                    autoFocus: true,
                  }}
                />
                {/* <InputGroup
                  // starMark={true}
                  error={errors.dob}
                  label="Date of Birth"
                  placeholder=""
                  attrs={{
                    type: "date",
                    value: params.dob,
                    onChange: (e) => this.onChangeHandler(e, "dob"),
                  }}
                /> */}
                {permissions?.hide_reseller_email ? null : (
                  <InputGroup
                    starMark={permissions?.hide_reseller_email ? false : true}
                    error={errors.email}
                    label="Email"
                    placeholder="Enter email..."
                    attrs={{
                      value: params.email,
                      onChange: (e) => this.onChangeHandler(e, "email"),
                    }}
                  />
                )}
                {/* <InputRadio
                  starMark={true}
                  error={errors.gender}
                  label="Your Gender"
                  name="gender"
                  options={[
                    { label: "Male", value: "m" },
                    { label: "Female", value: "f" },
                  ]}
                  value={params.gender}
                  attrs={{ onChange: (e) => this.onChangeHandler(e, "gender") }}
                /> */}
                {permissions?.hide_reseller_pincode ? null : (
                  <>
                    <InputGroup
                      starMark={
                        permissions?.hide_reseller_pincode ? false : true
                      }
                      error={errors.pin}
                      label="Pincode"
                      placeholder="Pincode"
                      attrs={{
                        maxLength: 6,
                        value: params.pin,
                        onChange: (e) => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: e.target.value.replace(/\D/, ""),
                              },
                            },
                            "pin"
                          );
                          if (e.target.value.length == 6) this.checkPin();
                        },
                        onBlur: this.checkPin,
                      }}
                      onEnter={this.checkPin}
                    />

                    {params.state ? (
                      <InputGroup
                        label="Your State"
                        placeholder="Enter state..."
                        attrs={{ value: params.state, readOnly: true }}
                      />
                    ) : (
                      false
                    )}
                  </>
                )}
                {/* <InputFile
                  starMark={true}
                  error={errors.photo}
                  label="Your Photo"
                  placeholder={
                    params.photo_base64 ? (
                      <img alt="" className="w-32" src={params.photo_base64} />
                    ) : (
                      <div className="text-center items-center flex-col w-32 inline-flex">
                        <PlusCircleIcon className="text-primary" width={24} />
                        <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                          Upload photo
                          <br />
                          max 5 MB
                        </p>
                      </div>
                    )
                  }
                  attrs={{
                    onChange: this.onPhotoChange,
                    accept: "image/*",
                    // capture: "environment"
                  }}
                /> */}
              </div>
            ) : (
              false
            )}

            {/* Educational Information */}
            {/* {step === 2 ? (
              <div>
                <h4 className="text-md mb-6 mt-6">
                  Please enter employment details
                </h4> 
                <InputSelect
                  starMark={true}
                  error={errors.qualification}
                  label="Highest Qualification"
                  attrs={{
                    value: params.qualification,
                    onChange: (e) => this.onChangeHandler(e, "qualification"),
                    autoFocus: loading ? false : true,
                  }}
                  options={qualifications}
                /> */}

            {/* <InputGroup
                  starMark={true}
                  error={errors.profession}
                  label="Your Occupation"
                  placeholder=""
                  attrs={{
                    value: params.profession,
                    onChange: (e) => this.onChangeHandler(e, "profession"),
                  }}
                /> */}

            {/* <InputRadio
                  starMark={true}
                  error={errors.invesment_work}
                  label="Nature of Employment"
                  name="invesment_work"
                  options={[
                    { label: "Full-time", value: "full" },
                    { label: "Part-time", value: "part" },
                  ]}
                  value={params.invesment_work}
                  attrs={{
                    onChange: (e) => this.onChangeHandler(e, "invesment_work"),
                  }}
                /> */}

            {/* <InputSelect
                  error={errors.income}
                  label="Income"
                  attrs={{
                    value: params.income,
                    onChange: (e) => this.onChangeHandler(e, "income"),
                  }}
                  options={incomes}
                />

                <InputSelect
                  starMark={true}
                  error={errors.experience}
                  label="Years of experience"
                  attrs={{
                    value: params.experience,
                    onChange: (e) => this.onChangeHandler(e, "experience"),
                  }}
                  options={experiences}
                /> 
               </div>
            ) : (
              false
            )} */}

            {/* Banking Information */}
            {step === 2 ? (
              <div>
                <h4 className="mt-1 text-md mb-6">
                  Please enter details to complete registration
                </h4>
                {/* 
                <InputGroup
                  // starMark={true}
                  error={errors.pan}
                  label="PAN Number"
                  placeholder="Enter PAN..."
                  attrs={{
                    maxLength: 10,
                    value: params.pan,
                    onChange: (e) => this.onChangeHandler(e, "pan"),
                    autoFocus: loading ? false : true,
                  }}
                /> */}
                <>
                  <InputSelect
                    width="full"
                    requiredMark={
                      permissions?.mandatory_reseller_government_id
                        ? true
                        : false
                    }
                    label="Govt ID Type"
                    error={errors.governmentIdType}
                    attrs={{
                      value: params.governmentIdType,
                      onChange: (e) => {
                        e.target.value === ""
                          ? this.onChangeHandler(e, "governmentId")
                          : this.onChangeHandler(e, "governmentIdType");
                        this.onChangeHandler(e, "governmentIdType");
                      },
                    }}
                    options={govt_ids}
                  ></InputSelect>
                  <InputGroup
                    label="Govt ID Number"
                    placeholder="Enter Govt ID Number..."
                    error={errors.governmentId}
                    requiredMark={
                      permissions?.mandatory_reseller_government_id
                        ? true
                        : false
                    }
                    attrs={{
                      disabled: params.governmentIdType === "",
                      value: params.governmentId,
                      onChange: (e) => {
                        if (this.state.params.governmentIdType === "aadhar") {
                          if (e.target.value.length < 13) {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value.replace(/\D/, ""),
                                },
                              },
                              "governmentId"
                            );
                          }
                        } else if (
                          this.state.params.governmentIdType === "voter"
                        ) {
                          if (e.target.value.length < 15) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else if (
                          this.state.params.governmentIdType === "passport"
                        ) {
                          if (e.target.value.length < 15) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else if (
                          this.state.params.governmentIdType ===
                          "driving_licence"
                        ) {
                          if (e.target.value.length < 18) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else if (
                          this.state.params.governmentIdType === "pan"
                        ) {
                          if (e.target.value.length < 11) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else {
                          if (e.target.value.length < 20) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        }
                      },
                      maxLength: 20,
                    }}
                  />
                </>

                {/* <InputGroup
                  // starMark={true}
                  error={errors.tin}
                  label="TIN Number"
                  placeholder="Enter TIN..."
                  attrs={{
                    minLength: 11,
                    maxLength: 14,
                    value: params.tin,
                    onChange: (e) => this.onChangeHandler(e, "tin"),
                    autoFocus: loading ? false : true,
                  }}
                /> */}

                <div className="flex-row w-32 inline-flex">
                  <InputFile
                    error={errors.document}
                    label="Identity Proof"
                    placeholder={
                      params.document ? (
                        <div className="text-center items-center flex-col w-32 inline-flex">
                          <DocumentIcon className="text-primary" width={24} />
                          <p className="mt-2 whitespace-nowrap text-center text-sm text-primary max-w-full truncate">
                            <span>{params.document.name}</span>
                          </p>
                        </div>
                      ) : (
                        <div className="text-center items-center flex-col w-32 inline-flex">
                          <PlusCircleIcon className="text-primary" width={24} />
                          <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                            Upload any
                            <br />
                            Govt ID
                          </p>
                        </div>
                      )
                    }
                    attrs={{
                      onChange: this.onDocChange,
                      accept: "image/*,.pdf",
                    }}
                  />
                </div>
                {/* 
                <InputRadio
                  starMark={true}
                  label="Payment in"
                  name="payment_mode"
                  options={[
                    { label: "Account NEFT", value: "neft" },
                    { label: "UPI", value: "upi" },
                  ]}
                  value={params.payment_mode}
                  attrs={{
                    onChange: (e) => this.onChangeHandler(e, "payment_mode"),
                  }}
                /> */}

                <div
                  className={`${
                    params.payment_mode === "neft" ? "" : "hidden"
                  }`}
                >
                  {/* <InputGroup
                    // starMark={true}
                    error={errors.bank_name}
                    label="Bank Name"
                    placeholder="Enter bank name..."
                    attrs={{
                      value: params.bank_name,
                      onChange: (e) => this.onChangeHandler(e, "bank_name"),
                    }}
                  /> */}

                  {/* <InputGroup
                    // starMark={true}
                    error={errors.account_number}
                    label="Account Number"
                    placeholder="Enter Account Number..."
                    attrs={{
                      value: params.account_number,
                      onChange: (e) =>
                        this.onChangeHandler(e, "account_number"),
                    }}
                  /> */}

                  {/* <InputGroup starMark={true} error={errors.ifsc_code} label="IFSC Code" labelLink={{ title: 'Find IFSC', onClick: () => { } }} placeholder="" attrs={{ value: params.ifsc_code, onChange: (e) => this.onChangeHandler(e, 'ifsc_code') }} /> */}
                  {/* <InputGroup
                    // starMark={true}
                    error={errors.ifsc_code}
                    label="IFSC Code"
                    placeholder="Enter IFSC code..."
                    attrs={{
                      value: params.ifsc_code,
                      onChange: (e) => this.onChangeHandler(e, "ifsc_code"),
                    }}
                  /> */}
                </div>
                <div
                  className={`${params.payment_mode === "upi" ? "" : "hidden"}`}
                >
                  <InputGroup
                    starMark={true}
                    error={errors.upi_id}
                    label="Enter UPI ID"
                    placeholder="Enter UPI ID..."
                    attrs={{
                      value: params.upi_id,
                      onChange: (e) => this.onChangeHandler(e, "upi_id"),
                    }}
                  />
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            <div>
              <Button
                attrs={{
                  disabled: !ready,
                  type: "button",
                  onClick: this.next,
                  ref: this.BasicBtn,
                }}
                size="md"
                title={step === 3 ? "Register" : "Save and Continue"}
              />
              {step > 1 ? (
                <button
                  onClick={this.prev}
                  className="w-full flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500"
                >
                  Back
                </button>
              ) : (
                false
              )}
            </div>
            <p className="text-sm text-center mt-6">
              <Link
                to="/terms"
                className="text-primary underline hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm px-1"
              >
                Terms &amp; Condition
              </Link>
            </p>
          </div>
        </div>
      </Main>
    );
  }

  checkPin = async () => {
    if (!this.state.params.pin) return;
    if (this.state.params.pin === this.checked_pin) return;
    this.checked_pin = this.state.params.pin;
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`check-pin/${this.state.params.pin}`);
    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.onChangeHandler({ target: { value: null } }, "state");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  next = () => {
    switch (this.state.step) {
      case 1:
        return this.saveBasic();
      // case 2:
      //   return this.saveProfessional();
      case 2:
        return this.saveBanking();
      default:
        return false;
    }
  };

  prev = () => {
    if (this.state.step == 3) {
      this.setState({ step: this.state.step - 2 });
    } else {
      this.setState({ step: this.state.step - 1 });
    }
  };

  doSubmit = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    setTimeout(() => {
      AppStore.dispatch({ type: "LOADING", loading: false });
    }, 3000);
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevState.step !== this.state.step) {
      this.setProgress();
    }
  }

  componentDidMount() {
    const { user } = this.props;

    let step = 1;
    step = user?.banking ? step : 3;
    step = user?.professional ? step : user.reporting_to == 2 ? step : 2;
    step = user?.basic ? step : 1;

    this.setState({ step: step }, () => {
      this.setProgress();
    });
  }

  setProgress = () => {
    AppStore.dispatch({
      type: "PROGRESS",
      progress: 100 / (4 - this.state.step),
    });
  };

  saveBasic = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const { name, dob, photo, email, gender, pin, state } = this.state.params;

      const data = new FormData();
      data.append("name", name);
      data.append("dob", dob);
      // data.append("gender", gender);
      data.append("email", email);
      data.append("pin", pin);
      data.append("state", state);
      data.append("photo", photo);

      const response = await ApiPost("register/basic", data);
      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ step: 2 });
      }
    });
  };

  saveProfessional = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: {} }, async () => {
      const { qualification, profession, invesment_work, experience, income } =
        this.state.params;
      const response = await ApiPost("register/professional", {
        qualification: qualification,
        profession: profession,
        invesment_work: invesment_work,
        experience: experience,
        income: income,
      });

      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ step: 3 });
      }
    });
  };

  saveBanking = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const {
        bank_name,
        account_number,
        ifsc_code,
        upi_id,
        payment_mode,
        pan,
        tin,
        governmentId,
        governmentIdType,
        document,
      } = this.state.params;

      const data = new FormData();
      // data.append("bank_name", bank_name);
      // data.append("account_number", account_number);
      // data.append("ifsc_code", ifsc_code);
      // data.append("upi_id", upi_id);
      data.append("payment_mode", payment_mode);
      // data.append("pan", pan);
      // data.append("tin", tin);
      data.append("governmentId", governmentId);
      data.append("governmentIdType", governmentIdType);
      data.append("document", document);

      const response = await ApiPost("register/banking", data);

      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        if (response.status == "success") {
          toast.success("Registration completed successfully! please login.");
        }
        if ("token" in response.data && "auth_token" in response.data) {
          await setData("access_token", response.data.token, async () => {
            const me = await ApiGet("me");
            if (me.status === "success") {
              AppStore.dispatch({
                type: "SIGN_IN",
                user: me.data,
                token: response.data.token,
              });
              AppStore.dispatch({ type: "PROGRESS", progress: 0 });
              AppStore.dispatch({ type: "LOADING", loading: false });
              setResellerToken(me.data?.reseller_id_by_token || null);
              this.props.history.push("/");
            } else {
              await removeData("access_token");
              await removeData("auth_token");
              await setError(response.message);
            }
          });
          await setData("auth_token", response.data?.authToken);
        } else {
          setMessage(response.data.message, async () => {
            await AppLogout();
            this.props.history.push("/login");
          });
        }
      }
    });
  };

  onPhotoChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1 + 10000000) {
        await setError("File size exceeded 5MB");
      } else {
        const { params } = this.state;

        _.set(
          params,
          "photo_base64",
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, "photo", event.target.files[0]);

        this.setState({ params: params });
      }
    }
  };

  onDocChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const { params } = this.state;

      _.set(params, "document", event.target.files[0]);

      this.setState({ params: params });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const RegisterConnect = connect(mapStateToProps)((props) => {
  return <Register {...props} />;
});

export default withRouter(RegisterConnect);
