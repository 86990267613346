import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AppBackButton, AppHeading } from "../../components/AppComponents";
import Button from "../../components/Button";
import { InputGroup } from "../../components/form/InputGroup";
import {
  ApiGet,
  ApiPost,
  setData,
  setError,
  setMessage,
} from "../../lib/AppHelper";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import InputSelect from "./../../components/formikForm/InputSelect";

export class AddEmployee extends Component {
  state = {
    errors: {},
    // params: { ...this.props.location.state },
    params: {
      email: "",
      mfiPartner: "",
      mobile: "",
      products: [],
      referralPartner: "",
      reporting_to: "",
      name: "",
      ...this.props.location.state,
    },
    permissions: [],
    employees: [],
    products: [],
    referralPartner: [],
    mfiPartner: [],
  };
  MessageRef = React.createRef();

  render() {
    const { products, permissions } = this.props;
    const { errors, params, employees, referralPartner, mfiPartner } =
      this.state;
    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to="/">Back to Dashboard</AppBackButton>
        {permissions?.reseller_add ? (
          <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
            <div>
              <AppHeading>Add Team</AppHeading>
              <h4 className="text-md mb-6 mt-6">Please enter details</h4>

              <div className="mb-5">
                <label className="flex text-sm text-gray-700 justify-left">
                  Select Reporting To{" "}
                  <span className="text-lg text-red-500">*</span>
                </label>
                <div className="mt-2 flex space-x-4">
                  <select
                    onChange={(e) => this.onChangeHandler(e, "reporting_to")}
                    className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                  >
                    <option value="">Me</option>
                    {employees?.map((option, index) => {
                      return (
                        <option value={option.id} key={index}>
                          {option.name} ({option.mobile})
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <InputGroup
                error={errors.name}
                label="Name"
                starMark={true}
                placeholder="Enter name..."
                attrs={{
                  value: params.name,
                  onChange: (e) => this.onChangeHandler(e, "name"),
                }}
                onEnter={this.submit}
              />

              <InputGroup
                label="Mobile no"
                placeholder="0000000000"
                starMark={true}
                prefix="+91"
                attrs={{
                  maxLength: 10,
                  value: params.mobile,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "mobile"
                      );
                    }
                  },
                  autoFocus: true,
                }}
                error={errors.mobile}
                onEnter={this.submit}
              />
              {permissions?.hide_reseller_email ? null : (
                <InputGroup
                  error={errors.email}
                  label="Email"
                  type="email"
                  placeholder="Enter email address... "
                  attrs={{
                    maxLength: 64,
                    value: params.email,
                    onChange: (e) => this.onChangeHandler(e, "email"),
                  }}
                  onEnter={this.submit}
                />
              )}
              {referralPartner.length > 0 &&
                permissions?.add_referral_partner && (
                  <div className="mb-5">
                    <label className="flex text-sm text-gray-700 justify-left">
                      Add Referral Partner
                    </label>
                    <div className="mt-2 flex space-x-4">
                      <select
                        onChange={(e) =>
                          this.onChangeHandler(e, "referralPartner")
                        }
                        className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                      >
                        <option value="">Select</option>
                        {referralPartner?.map((option, index) => {
                          return (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

              {permissions?.aiqa_center_head_office && (
                <div className="mb-5">
                  <label className="flex text-sm text-gray-700 justify-left">
                    Aiqa Center Branch
                  </label>
                  <div className="mt-2 flex space-x-4">
                    <select
                      onChange={(e) => this.onChangeHandler(e, "mfiPartner")}
                      className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                    >
                      <option value="">Select</option>
                      {mfiPartner?.map((option, index) => {
                        return (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {permissions?.add_member_product && (
                <div>
                  <p className="mb-3">Assign Products</p>
                  <div className="box-border h-40 w-100 p-4 border-2 overflow-auto">
                    {products?.length > 0 &&
                      products?.map((item, index) => (
                        <div className="flex" key={index}>
                          <input
                            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="checkbox"
                            onChange={(e) => this.productAdd(e)}
                            value={item.id}
                            id={item.id}
                          />
                          <label
                            className="form-check-label inline-block text-gray-800"
                            htmlFor={item.id}
                          >
                            {item.name}
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              <Button
                className="mt-5"
                attrs={{ type: "button", onClick: this.submit }}
                size="md"
                title={"Submit"}
              />
            </div>
          </div>
        ) : (
          <div className="text-red-500">
            <h4>You don't have permission to Add Team Member</h4>
          </div>
        )}
      </Main>
    );
  }

  submit = async () => {
    const { user } = this.props;
    const { permissions } = user;

    if (permissions && !permissions.reseller_add) {
      return setError("Yo do not have permission to add team");
    }
    const { params } = this.state;
    params["products"] = this.state.products;
    this.setState({ params: params });

    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiPost("create/employee", this.state.params);
    if (response.status === "error") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      if (!_.isEmpty(response.errors)) {
        this.setState({ errors: response.errors });
        setError(response.message);
      } else {
        setMessage(response.message);
      }
    } else {
      setMessage(response.message, () => {
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.props.history.push("/employees");
      });
    }
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  productAdd = (e) => {
    let newData = e.target.value;
    if (e.target.checked) {
      if (this.state.products == []) this.setState({ products: newData });
      else this.setState({ products: [...this.state.products, newData] });
    } else {
      const data = this.state.products.filter((e) => {
        if (e === newData) {
        } else {
          return e;
        }
      });
      this.setState({ products: data });
    }
  };

  async componentDidMount() {
    await this.getTeam();
    await this.getReferralPartner();
    await this.getMfi();
  }

  getReferralPartner = async () => {
    if (this.props.permissions?.add_referral_partner) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("referral-partner");
      if (response.status === "success") {
        this.setState({ referralPartner: response.data });
      } else {
        await setError(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getMfi = async () => {
    if (this.props.permissions?.aiqa_center_head_office) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      const response = await ApiGet("get-mfi");
      if (response.status === "success") {
        this.setState({ mfiPartner: response.data });
      } else {
        await setError(response.message);
      }
      AppStore.dispatch({ type: "LOADING", loading: false });
    }
  };

  getTeam = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    } else {
      await setError(response.message);
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user, products } = state;
  return {
    loading: loading,
    user: user,
    products: products,
    permissions: user.permissions,
  };
};

const AddEmployeeConnect = connect(mapStateToProps)((props) => {
  return <AddEmployee {...props} />;
});

export default withRouter(AddEmployeeConnect);
