import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AmountText, AppHeading } from "../components/AppComponents";
import {
  DaughterIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "../components/AppIcons";
import Button from "../components/Button";
import { InputCounter } from "../components/form/InputCounter";
import InputGroup from "../components/form/InputGroup";
import { InputRadio } from "../components/form/InputRadio";
import { InputSelect } from "../components/form/InputSelect";
import { InputToggle } from "../components/form/InputToggle";
import { AppStore } from "../lib/AppStore";
import Main from "./Main";
import paymentImg from "./../payment-img.png";
import {
  ArrowLeftIcon,
  DuplicateIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import {
  ApiGet,
  ApiPost,
  JavaApiPostGovtId,
  getResellerId,
  getResellerLanguage,
  getRsellerPinCode,
  setMessage,
  validateDate,
  validateEmail,
  validateMobile,
  validateName,
} from "../lib/AppHelper";
import FamilyDetails from "./subscription/FamilyDetails";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { PDFDocument } from "pdf-lib";
import jsPDF from "jspdf";
import { InputFile } from "../components/form/InputFile";
import { InputTextArea } from "../components/form/InputTextArea";
import { trimObjectValues } from "../utils/constants";

const PlanOption = ({ title = "", features = [], amount = "1,123" }) => {
  return (
    <div className="bg-primary-50 border border-warning rounded-xl p-5">
      <p className="font-semibold">{title}</p>
      <ul className="list-disc pl-4 text-xs my-3">
        {features.map((item, index) => {
          return <li key={index}>{item}</li>;
        })}
      </ul>
      <div className="font-semibold">
        <AmountText amount={amount} />
      </div>
    </div>
  );
};

export class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        max_members: 0,
        product: "",
        product_type: "",
        bookNow: false,
        language: getResellerLanguage() ? getResellerLanguage() : "Hindi",
        members: {
          holder: true,
          spouse: false,
          daughter: false,
          // sister: false,
          son: false,
          parents: false,
          // daughter_in_law: false,
          // mother_in_law: false,
          // sister_in_law: false,
          // son_in_law: false,
          // father_in_law: false,
          // brother_in_law: false,
        },
        members_count: {
          daughter: 1,
          son: 1,
          sister: 1,
          parents: {
            self: 1,
            spouse: 0,
          },
          daughter_in_law: 1,
          mother_in_law: 1,
          sister_in_law: 1,
          son_in_law: 1,
          father_in_law: 1,
          brother_in_law: 1,
        },
        name: "",
        phone: "",
        email: "",
        gender: "m",
        dob: "",
        pincode: "",
        address: "",
        state: "",
        governmentIdType: "",
        governmentId: "",
        members_detail: {
          self: {
            height: null,
            weight: null,
          },
        },
        back_photo_base64: null,
        front_photo_base64: null,
        back_photo: "",
        front_photo: "",
        main_photo: "",
      },
      max: "",
      min: "",
      step: 1,
      isEmailRequired: true,
      questions: [],
      answers: {},
      govt_ids: [
        {
          label: "Select Id Type",
          value: "",
        },
        {
          label: "AADHAR",
          value: "aadhar",
        },
        {
          label: "Voter ID",
          value: "voter",
        },
        {
          label: "Passport",
          value: "passport",
        },
        {
          label: "Driving License",
          value: "driving_licence",
        },
      ],
      languages: [
        {
          label: "Hindi",
          value: "Hindi",
        },
        {
          label: "English",
          value: "English",
        },
        {
          label: "Marathi",
          value: "marathi",
        },
        {
          label: "Tamil",
          value: "Tamil",
        },
        {
          label: "Telugu",
          value: "Telugu",
        },
        {
          label: "Kannada",
          value: "Kannada",
        },
        {
          label: "Bengali",
          value: "Bengali",
        },
        {
          label: "Oriya",
          value: "Oriya",
        },
      ],
      eClinic: false,
    };
  }

  steps = 5;
  BasicBtn = React.createRef();
  MessageRef = React.createRef();
  frontSide = React.createRef(null);
  backSide = React.createRef(null);
  checked_pin = "";

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isReady = () => {
    const { step, params, answers } = this.state;
    const selected_product = _.find(
      this.props.products,
      (item) => params.product == item.id
    );
    params.product_type = selected_product
      ? selected_product.product.product_type
      : "";

    if (selected_product) {
      params.max_members = selected_product.product.max_members;
    }

    if (step === 1 && !params.product) return false;

    if (
      step === 2 &&
      params.members.parents === true &&
      params.members_count.parents?.self +
        params.members_count.parents?.spouse ===
        0
    )
      return false;

    if (step === 2) {
      if (!selected_product) {
        return false;
      }
      const max_members = selected_product.product.max_members;
      const count = this.getSelectedCount();
      if (count > max_members) return false;
    }

    if (step === 3) {
      if (!params.name) {
        return false;
      } else if (!params.name) {
        return false;
      }
      if (!params.phone || params.phone.length !== 10) return false;
      if (this.state.isEmailRequired) {
        if (!params.email || !this.validateEmail(params.email)) return false;
      }
      if (!params.governmentIdType) return false;
      if (!params.governmentId) return false;
      if (
        !params.dob ||
        !moment(params.dob).isValid() ||
        moment().diff(params.dob, "years") >= 100
      )
        return false;
      if (!params.pincode || params.pincode.length !== 6) return false;
      if (!params.state) return false;
    }

    if (step === 4) {
      const members_details = params.members_detail;
      let invalid = false;
      _.forEach(members_details, (members_detail) => {
        _.forEach(members_detail, (detail, prop) => {
          if (prop === "height" || prop === "weight")
            detail = parseInt(detail, 10);
          if (
            members_detail.govtIDType != "" &&
            members_detail.govtIDNumber == ""
          ) {
            invalid = true;
            return false;
          } else if (
            members_detail.govtIDType == "" &&
            members_detail.govtIDNumber != ""
          ) {
            invalid = true;
            return false;
          } else if (members_detail.name == "") {
            invalid = true;
            return false;
          } else if (members_detail.dob == "") {
            invalid = true;
            return false;
          } else if (members_detail.gender == "") {
            invalid = true;
            return false;
          }
        });
        if (invalid) return false;
      });
      if (invalid) return false;
    }

    if (step === 5) {
      let yes = false;
      _.forEach(answers, (answer) => {
        if (answer) yes = true;
      });
      if (yes) return false;
    }

    return true;
  };

  getSelectedCount = () => {
    const { params } = this.state;
    let count = 0;
    for (let prop in params.members) {
      if (params.members[prop] === true) {
        if (prop in params.members_count) {
          if (prop === "parents") {
            count = count + params.members_count[prop].self;
            count = count + params.members_count[prop].spouse;
          } else {
            count = count + params.members_count[prop];
          }
        } else {
          count = count + 1;
        }
      }
    }

    return count;
  };

  textCapitalize = (str) => {
    let arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };
  convertImageToPDF(imageSrc, imageName) {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const imageWidth = 100; // Adjust the image width as needed
    const imageHeight = 100; // Adjust the image height as needed
    const centerX = (pageWidth - imageWidth) / 2;
    const centerY = (pageHeight - imageHeight) / 2;

    // Add the text "SACHIN" above the image
    doc.setFontSize(14);
    doc.text(imageName, pageWidth / 2, centerY - 10, { align: "center" });

    // Add the image to the center
    doc.addImage(imageSrc, "JPEG", centerX, centerY, imageWidth, imageHeight);
    return doc;
  }

  mergePDFs = async (pdf1Data, pdf2Data) => {
    const pdfDoc1 = await PDFDocument.load(pdf1Data);
    const pdfDoc2 = await PDFDocument.load(pdf2Data);

    const mergedPdf = await PDFDocument.create();

    const [page1] = await mergedPdf.copyPages(pdfDoc1, [0]);
    mergedPdf.addPage(page1);

    if (pdfDoc2.getPageCount() > 0) {
      const [page2] = await mergedPdf.copyPages(pdfDoc2, [0]);
      mergedPdf.addPage(page2);
    }

    return await mergedPdf.save();
  };

  onPhotoChange = async (event, side) => {
    const formData = new FormData();

    if (event.target.files && event.target.files[0]) {
      const { params } = this.state;
      if (side == "Front") {
        _.set(
          params,
          "front_photo_base64",
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, "front_photo", event.target.files[0]);
        this.frontSide.current = this.convertImageToPDF(
          URL.createObjectURL(event.target.files[0]),
          this.textCapitalize(
            `${this.state.params.governmentIdType} front photo`
          )
        );
      } else {
        _.set(
          params,
          "back_photo_base64",
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, "back_photo", event.target.files[0]);
        this.backSide.current = this.convertImageToPDF(
          URL.createObjectURL(event.target.files[0]),
          this.textCapitalize(
            `${this.state.params.governmentIdType} back photo`
          )
        );
      }
      if (
        this.state.params.governmentIdType === "aadhar" ||
        this.state.params.governmentIdType === "voter"
      ) {
        if (this.frontSide.current !== null && this.backSide.current !== null) {
          this.mergePDFs(
            this.frontSide.current.output("arraybuffer"),
            this.backSide.current.output("arraybuffer")
          )
            .then(async (mergedPdfData) => {
              const blob = new Blob([mergedPdfData], {
                type: "application/pdf",
              });

              formData.append("file", blob, "merged.pdf");
              formData.append("resellerId", getResellerId());
              const response = await JavaApiPostGovtId(
                "enrollment-service/documentUploader/uploadGlobalDocuments",
                formData
              );
              if (response.code == 200) {
                _.set(params, "main_photo", response.message);
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        if (this.frontSide.current !== null) {
          const blob = new Blob([this.frontSide.current], {
            type: "application/pdf",
          });

          formData.append("file", blob, "merged.pdf");
          formData.append("resellerId", getResellerId());

          const response = await JavaApiPostGovtId(
            "enrollment-service/documentUploader/uploadGlobalDocuments",
            formData
          );
          if (response.code == 200) {
            _.set(params, "main_photo", response.message);
          }
        }
      }
      this.setState({ params: params });
    }
  };

  render() {
    const { products } = this.props;
    const { params, step, questions, answers, govt_ids, languages, eClinic } =
      this.state;
    const selected_count = this.getSelectedCount();
    const selected_product = _.find(
      products,
      (item) => params.product === item.id
    );

    const ready = this.isReady();
    const items = [];
    _.each(products, (prod) => {
      items.push({
        value: prod.id,
        label: (
          <PlanOption
            amount={prod.price}
            title={prod.name}
            features={[
              `${prod.product?.max_members} Members`,
              `${prod.product?.description ? prod.product?.description : ""}`,
              ..._.map(prod.inclusions, "title"),
            ]}
          />
        ),
      });
    });

    return (
      <Main MessageRef={this.MessageRef}>
        {this.props.products.length === 1 && step < 3 ? (
          false
        ) : step > 1 ? (
          <div
            onClick={this.prev}
            className="flex items-center text-primary pb-3 cursor-pointer"
          >
            <ArrowLeftIcon width={17} className="mr-3" />
            <p>{"Back"}</p>
          </div>
        ) : (
          false
        )}
        <div className="w-full px-6 max-w-md md:flex-grow-0 flex-1 flex flex-col justify-between space-y-6">
          <div>
            {step === 1 ? (
              <div>
                <AppHeading>New Member</AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">Please select the product</h4> */}
                <InputRadio
                  value={params.product}
                  buttonHidden={true}
                  attrs={{
                    onChange: (e) => {
                      this.onChangeHandler(e, "product");
                    },
                  }}
                  inline={false}
                  label={false}
                  options={items}
                />
              </div>
            ) : (
              false
            )}

            {step === 2 ? (
              <div>
                <AppHeading>
                  {selected_product ? selected_product.name : ""}
                </AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">
                  Please select family members
                </h4> */}

                <InputToggle
                  label="Primary Member"
                  value={true}
                  attrs={{}}
                ></InputToggle>

                <InputToggle
                  label="Spouse"
                  value={params.members.spouse}
                  attrs={{
                    onClick: () => {
                      this.onChangeHandler(
                        { target: { value: !params.members.spouse } },
                        "members.spouse"
                      );
                    },
                  }}
                >
                  <RingIcon className="mr-3" size={20} />
                </InputToggle>

                <div className="relative">
                  <InputToggle
                    label="Daughter"
                    value={params.members.daughter}
                    attrs={{
                      onClick: () => {
                        this.onChangeHandler(
                          { target: { value: !params.members.daughter } },
                          "members.daughter"
                        );
                      },
                    }}
                  >
                    <DaughterIcon className="mr-3" size={20} />
                  </InputToggle>

                  {params.members.daughter ? (
                    <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                      <InputCounter
                        min={0}
                        max={3}
                        value={params.members_count.daughter}
                        onMinus={() => {
                          if (params.members_count.daughter - 1 === 0) {
                            this.onChangeHandler(
                              { target: { value: !params.members.daughter } },
                              "members.daughter"
                            );
                          } else {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: params.members_count.daughter - 1,
                                },
                              },
                              "members_count.daughter"
                            );
                          }
                        }}
                        onPlus={() => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count.daughter + 1,
                              },
                            },
                            "members_count.daughter"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div className="relative">
                  <InputToggle
                    label="Son"
                    value={params.members.son}
                    attrs={{
                      onClick: () => {
                        this.onChangeHandler(
                          { target: { value: !params.members.son } },
                          "members.son"
                        );
                      },
                    }}
                  >
                    <SonIcon className="mr-3" size={20} />
                  </InputToggle>

                  {params.members.son ? (
                    <div className="w-28 absolute top-0 right-0 h-full flex pr-2">
                      <InputCounter
                        min={0}
                        max={3}
                        value={params.members_count.son}
                        onMinus={() => {
                          if (params.members_count.son - 1 === 0) {
                            this.onChangeHandler(
                              { target: { value: !params.members.son } },
                              "members.son"
                            );
                          } else {
                            this.onChangeHandler(
                              {
                                target: { value: params.members_count.son - 1 },
                              },
                              "members_count.son"
                            );
                          }
                        }}
                        onPlus={() => {
                          this.onChangeHandler(
                            { target: { value: params.members_count.son + 1 } },
                            "members_count.son"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>

                <div className="relative">
                  <InputToggle
                    label="Parents"
                    value={params.members.parents}
                    attrs={{
                      onClick: () => {
                        this.onChangeHandler(
                          { target: { value: !params.members.parents } },
                          "members.parents"
                        );
                      },
                    }}
                  >
                    <ParentsIcon className="mr-3" size={20} />
                  </InputToggle>
                </div>

                {params.members.parents ? (
                  <div className="flex -mt-2 justify-between">
                    <div className="w-32">
                      <InputCounter
                        mini={false}
                        label="My Parents"
                        min={0}
                        max={2}
                        value={params.members_count.parents.self}
                        onMinus={() => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.self - 1,
                              },
                            },
                            "members_count.parents.self"
                          );
                        }}
                        onPlus={() => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.self + 1,
                              },
                            },
                            "members_count.parents.self"
                          );
                        }}
                        className="border rounded-xl mt-2"
                      />
                    </div>
                    <div className="w-32">
                      <InputCounter
                        mini={false}
                        label="Spouse Parents"
                        min={0}
                        max={2}
                        value={params.members_count.parents.spouse}
                        onMinus={() => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.spouse - 1,
                              },
                            },
                            "members_count.parents.spouse"
                          );
                        }}
                        onPlus={() => {
                          this.onChangeHandler(
                            {
                              target: {
                                value: params.members_count.parents.spouse + 1,
                              },
                            },
                            "members_count.parents.spouse"
                          );
                        }}
                        className="border rounded-xl mt-2"
                      />
                    </div>
                  </div>
                ) : (
                  false
                )}
              </div>
            ) : (
              false
            )}

            {step === 3 ? (
              <div>
                <AppHeading className="mb-3">Primary Member Holder</AppHeading>
                <InputGroup
                  label="Primary Member Name"
                  requiredMark="true"
                  placeholder="Enter name..."
                  attrs={{
                    maxLength: 64,
                    value: params.name,
                    onChange: (e) => {
                      // name validation regex
                      if (
                        /^[a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        this.onChangeHandler(e, "name");
                      }
                    },
                  }}
                  validateError={(value) => {
                    if (value) {
                      return false;
                    } else {
                      return "Please enter name";
                    }
                  }}
                />
                <InputGroup
                  label="Phone no"
                  prefix="+91"
                  placeholder="Enter phone number..."
                  requiredMark="true"
                  type="tel"
                  // error={errors?.phone}
                  attrs={{
                    value: params.phone,
                    onChange: (e) => {
                      const integerRegex = /^-?\d+$/;
                      const isValidInteger = integerRegex.test(e.target.value);
                      if (isValidInteger || e.target.value == "") {
                        this.onChangeHandler(
                          {
                            target: { value: e.target.value.replace(/\D/, "") },
                          },
                          "phone"
                        );
                      }
                    },
                    maxLength: 10,
                  }}
                  validateError={(value) => {
                    if (validateMobile(value)) {
                      return false;
                    } else {
                      return "Please enter valid mobile number";
                    }
                  }}
                />
                {this.state.isEmailRequired ? (
                  <InputGroup
                    label="Email"
                    placeholder="Enter email..."
                    requiredMark="true"
                    attrs={{
                      value: params.email,
                      onChange: (e) => this.onChangeHandler(e, "email"),
                    }}
                    validateError={(value) => {
                      if (validateEmail(value)) {
                        return false;
                      } else {
                        return "please enter correct email ID";
                      }
                    }}
                  />
                ) : (
                  false
                )}

                <InputRadio
                  label="Gender"
                  requiredMark="true"
                  name="gender"
                  buttonHidden={false}
                  options={[
                    { label: "Male", value: "m" },
                    { label: "Female", value: "f" },
                    { label: "Others", value: "o" },
                  ]}
                  value={params.gender}
                  attrs={{ onChange: (e) => this.onChangeHandler(e, "gender") }}
                />
                <InputGroup
                  label="Date of Birth"
                  requiredMark="true"
                  placeholder="DOB"
                  attrs={{
                    type: "date",
                    value: params.dob,
                    onChange: (e) => this.onChangeHandler(e, "dob"),
                    min: this.state.max,
                    max: this.state.min,
                  }}
                  valdiateOnChange={true}
                  validateError={(value) => {
                    if (validateDate(value)) {
                      return false;
                    } else {
                      return "Please enter valid date";
                    }
                  }}
                />
                <InputSelect
                  requiredMark="true"
                  label="Govt ID Type"
                  width="full"
                  attrs={{
                    value: params.governmentIdType,
                    onChange: (e) => {
                      e.target.value === ""
                        ? this.onChangeHandler(e, "governmentId")
                        : this.onChangeHandler(e, "governmentIdType");
                      this.onChangeHandler(e, "governmentIdType");
                    },
                  }}
                  options={govt_ids}
                ></InputSelect>

                <InputGroup
                  label="Govt ID Number"
                  placeholder="Enter Govt ID Number..."
                  requiredMark="true"
                  attrs={{
                    disabled: params.governmentIdType === "",
                    value: params.governmentId,
                    onChange: (e) => {
                      if (
                        /^[a-zA-Z0-9\-_]*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (this.state.params.governmentIdType === "aadhar") {
                          if (e.target.value.length < 13) {
                            this.onChangeHandler(
                              {
                                target: {
                                  value: e.target.value.replace(/\D/, ""),
                                },
                              },
                              "governmentId"
                            );
                          }
                        } else if (
                          this.state.params.governmentIdType === "voter"
                        ) {
                          if (e.target.value.length < 15) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else if (
                          this.state.params.governmentIdType === "passport"
                        ) {
                          if (e.target.value.length < 15) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else if (
                          this.state.params.governmentIdType ===
                          "driving_licence"
                        ) {
                          if (e.target.value.length < 18) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        } else {
                          if (e.target.value.length < 20) {
                            this.onChangeHandler(e, "governmentId");
                          }
                        }
                      }
                    },
                    maxLength: 20,
                  }}
                />
                {this.state.params.governmentIdType && (
                  <>
                    <div
                      style={{ display: "flex", gap: "10px", display: "none" }}
                    >
                      <InputFile
                        // starMark="true"
                        label={this.textCapitalize(
                          `${this.state.params.governmentIdType} Front Photo`
                        )}
                        placeholder={
                          this.state.params.front_photo_base64 ? (
                            <img
                              alt=""
                              className="w-32"
                              src={
                                this.state.params.front_photo_base64
                                  ? this.state.params.front_photo_base64
                                  : this.state.params.front_photo_base64
                              }
                            />
                          ) : (
                            <div className="text-center items-center flex-col w-32 inline-flex">
                              <PlusCircleIcon
                                className="text-primary"
                                width={24}
                              />
                              <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                Upload photo
                                <br />
                                max 5 MB
                              </p>
                            </div>
                          )
                        }
                        attrs={{
                          // disabled: !edit,
                          onChange: (event) =>
                            this.onPhotoChange(event, "Front"),
                          accept: "image/*",
                          capture: "environment",
                        }}
                      />
                      {(this.state.params.governmentIdType === "aadhar" ||
                        this.state.params.governmentIdType === "voter") && (
                        <InputFile
                          // starMark={
                          //   this.state.params.governmentIdType === "aadhar" ||
                          //   this.state.params.governmentIdType === "voter"
                          //     ? true
                          //     : false
                          // }
                          label={this.textCapitalize(
                            `${this.state.params.governmentIdType} Back Photo`
                          )}
                          placeholder={
                            this.state.params.back_photo_base64 ||
                            this.state.params.back_photo_base64 ? (
                              <img
                                alt=""
                                className="w-32"
                                src={
                                  this.state.params.back_photo_base64
                                    ? this.state.params.back_photo_base64
                                    : this.state.params.back_photo_base64
                                }
                              />
                            ) : (
                              <div className="text-center items-center flex-col w-32 inline-flex">
                                <PlusCircleIcon
                                  className="text-primary"
                                  width={24}
                                />
                                <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                  Upload photo
                                  <br />
                                  max 5 MB
                                </p>
                              </div>
                            )
                          }
                          attrs={{
                            // disabled: !edit,
                            onChange: (event) =>
                              this.onPhotoChange(event, "Back"),
                            accept: "image/*",
                            capture: "environment",
                          }}
                        />
                      )}
                    </div>
                  </>
                )}

                <InputGroup
                  label="Pincode"
                  placeholder="Enter Pincode..."
                  requiredMark="true"
                  // error={errors?.pin}
                  type="tel"
                  attrs={{
                    maxLength: 6,
                    value: params.pincode,
                    onChange: (e) => {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "pincode"
                      );
                      if (e.target.value.length == 6) this.checkPin();
                    },
                    onBlur: this.checkPin,
                  }}
                  onEnter={this.checkPin}
                />
                {params.state ? (
                  <div style={{ display: "none" }}>
                    <InputTextArea
                      label="Your Address"
                      placeholder=""
                      attrs={{
                        value: params.address,
                        onChange: (e) => this.onChangeHandler(e, "address"),
                      }}
                    />
                  </div>
                ) : (
                  false
                )}
                {params.state ? (
                  <InputGroup
                    label="Your State"
                    placeholder="Enter state..."
                    attrs={{ value: params.state, readOnly: true }}
                  />
                ) : (
                  false
                )}
                {eClinic && (
                  <InputSelect
                    requiredMark="true"
                    label="Language"
                    attrs={{
                      value: params.language,
                      onChange: (e) => {
                        this.onChangeHandler(e, "language");
                      },
                    }}
                    options={languages}
                  ></InputSelect>
                )}
              </div>
            ) : (
              false
            )}

            {step === 4 ? (
              <div>
                <AppHeading>Member Details</AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">
                  Please enter family details
                </h4> */}

                <FamilyDetails
                  value={params}
                  textCapitalize={this.textCapitalize}
                  convertImageToPDF={this.convertImageToPDF}
                  mergePDFs={this.mergePDFs}
                  onChange={(v) =>
                    this.onChangeHandler(
                      { target: { value: v } },
                      "members_detail"
                    )
                  }
                  govt_ids={this.state.govt_ids}
                />
              </div>
            ) : (
              false
            )}

            {step === 5 ? (
              <div>
                <AppHeading>Questions</AppHeading>
                {/* <h4 className="text-md mb-6 mt-6">
                  Please answer the following questions
                </h4> */}
                {questions.map((question) => {
                  return (
                    <InputRadio
                      key={question.medicalQuestionId}
                      label={question.question}
                      name={`ques-${question.medicalQuestionId}`}
                      hidden={false}
                      options={[
                        { label: "Yes", value: 1 },
                        { label: "No", value: 0 },
                      ]}
                      value={answers[`ans-${question.medicalQuestionId}`]}
                      attrs={{
                        onChange: (e) =>
                          this.onAnsChangeHandler(
                            e,
                            `ans-${question.medicalQuestionId}`
                          ),
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              false
            )}

            {step === 8 ? (
              <div>
                <AppHeading>Payment</AppHeading>
                <h4 className="text-md mb-6 mt-6">
                  Payment is sent to registered mobile number +91-9650320189
                </h4>
                <InputGroup
                  action={
                    <div
                      tabIndex={0}
                      className="focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:outline-none rounded-xl cursor-pointer text-primary shadow-sm border-primary focus:bg-primary-50 px-3 flex items-center justify-center border-gray-300 rounded-xl border ml-3 shadow-sm"
                    >
                      <DuplicateIcon width={20} />
                    </div>
                  }
                  label="Payment link"
                  placeholder=""
                  attrs={{
                    value: "pytm.hsloremipsum/aiqa-health",
                    readOnly: true,
                  }}
                />
                <div className="flex mt-10 flex-col items-center">
                  <img
                    alt=""
                    className="w-40 h-40 rounded-full"
                    src={paymentImg}
                  />
                  <p className="text-gray-200 text-sm mb-2 mt-3">
                    PAGE EXPIRE IN
                  </p>
                  <p className="text-xl font-semibold">00:59 mins</p>
                  <Button
                    size="sm"
                    attrs={{
                      onClick: () => this.setState({ cancelPayment: true }),
                    }}
                    className="mt-10 max-w-min px-6"
                    outline={true}
                    title="Go Back"
                  >
                    <ArrowLeftIcon width={16} className="mr-2" />
                  </Button>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
          <div>
            {params.max_members && selected_count > params.max_members ? (
              <p className="text-center text-xs font-semibold text-red-500 my-2">
                Maximum {params.max_members} members are allowed.
              </p>
            ) : (
              false
            )}
            <div>
              {this.state.eClinic ? (
                <Button
                  attrs={{
                    ref: this.BasicBtn,
                    disabled: !ready,
                    type: "button",
                    onClick: this.next,
                  }}
                  size="md"
                  title={step === 3 ? "Save and Continue" : "Continue"}
                />
              ) : (
                <Button
                  attrs={{
                    ref: this.BasicBtn,
                    disabled: !ready,
                    type: "button",
                    onClick: this.next,
                  }}
                  size="md"
                  title={step === this.steps ? "Save and Continue" : "Continue"}
                />
              )}
              {this.props.products.length === 1 && step < 3 ? (
                false
              ) : step > 1 ? (
                <button
                  onClick={this.prev}
                  className="w-full flex items-center justify-center px-6 py-2 mt-3 border border-transparent text-base rounded-xl text-gray-700 bg-none hover:bg-none active:bg-none focus:outline-none focus:ring-2 focus:ring-primary-200 disabled:text-gray-300 hover:text-primary-500"
                >
                  Back
                </button>
              ) : (
                false
              )}
            </div>
            <p className="text-sm text-center mt-6 cursor-pointer">
              <div
                onClick={() => {
                  this.props.history.push("/terms");
                }}
              >
                <span className="text-primary underline hover:text-primary-700 focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm px-1">
                  Terms &amp; Condition
                </span>
              </div>
            </p>
          </div>
        </div>
      </Main>
    );
  }

  checkPin = async () => {
    if (!this.state.params.pincode) return;
    if (this.state.params.pincode === this.checked_pin) return;
    this.checked_pin = this.state.params.pincode;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(`check-pin/${this.state.params.pincode}`);

    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.MessageRef.current.show(response.message, "error");
      this.onChangeHandler({ target: { value: null } }, "state");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  createDate = (years) => {
    var date = new Date();
    date.setFullYear(date.getFullYear() - years);
    var ndate = new Date(date),
      mnth = ("0" + (ndate.getMonth() + 1)).slice(-2),
      day = ("0" + ndate.getDate()).slice(-2);
    return [ndate.getFullYear(), mnth, day].join("-");
  };

  onChangeHandler = (e, key) => {
    const { user } = this.props;
    const { permissions } = user;
    const { params } = this.state;
    _.set(params, key, e.target.value);
    this.setState({ params: params }, () => {
      if (key === "product") {
        const selected_product = _.find(
          this.props.products,
          (item) => this.state.params.product === item.id
        );
        if (
          selected_product &&
          selected_product.mutual_aid &&
          this.steps !== 5
        ) {
          this.steps = 5;
          this.setProgress();
        } else if (
          selected_product &&
          !selected_product.mutual_aid &&
          this.steps !== 4
        ) {
          this.steps = 4;
          this.setProgress();
        }
      }
    });
    if (key === "product") {
      this.props.products.map((elem) => {
        if (elem.product_id == e.target.value) {
          this.setState({
            min: this.createDate(
              elem.product.p_member_min_age ? elem.product.p_member_min_age : 15
            ),
            max: this.createDate(
              elem.product.p_member_max_age ? elem.product.p_member_max_age : 80
            ),
          });
          this.setState({ isEmailRequired: !elem.is_email_not_required });
          // this.setState({ questions: [...elem.medicalQuestion] });
          if (
            (permissions?.appointment_booking || permissions?.aiqa_center) &&
            elem?.is_aiqa_center
          ) {
            let pinCode = getRsellerPinCode();
            this.setState({ eClinic: true });
            this.onChangeHandler({ target: { value: true } }, "bookNow");
            this.onChangeHandler({ target: { value: pinCode } }, "pincode");
            this.checkPin();
          } else {
            this.setState({ eClinic: false });
            this.onChangeHandler({ target: { value: false } }, "bookNow");
            this.onChangeHandler({ target: { value: "" } }, "pincode");
            this.onChangeHandler({ target: { value: null } }, "state");
          }
        }
      });
    }
  };

  onAnsChangeHandler = (e, key) => {
    const { answers } = this.state;
    _.set(answers, key, parseInt(e.target.value, 10));
    this.setState({ answers: answers });
  };

  next = () => {
    if (this.state.step < this.steps) {
      if (
        this.state.eClinic &&
        this.state.step == 1 &&
        this.state.params.max_members == 1
      ) {
        this.setState({ step: 3 });
      } else {
        if (this.state.eClinic && this.state.step == 3) {
          this.doSubmit();
        } else {
          this.setState({ step: this.state.step + 1 });
        }
      }
    } else {
      this.doSubmit();
    }
  };

  prev = () => {
    if (
      this.state.eClinic &&
      this.state.step == 3 &&
      this.state.params.max_members == 1
    ) {
      this.setState({ cancelPayment: false, step: 1 });
    } else {
      this.setState({ cancelPayment: false, step: this.state.step - 1 });
    }
  };

  doSubmit = async () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    let data = { ...this.state.params };
    let trimedData = trimObjectValues(data);
    if (!this.state.isEmailRequired) {
      delete trimedData.email;
    }
    const response = await ApiPost("subscribe", {
      ...trimedData,
      // medAnswers: this.state.questions,
    });
    if (response.status === "success") {
      await setMessage(response.data.message);
      if (this.state.eClinic) {
        if (response.data.bookNow) {
          toast.success("Appointment created successfully");
        } else {
          toast.error(
            "Appointment not created because Doctor not available at this time slot"
          );
        }
      }
      this.props.history.replace({
        pathname: `/subscription/${response.data.application_id}`,
      });
    } else {
      this.MessageRef.current.show(response.message, "error");
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  componentDidUpdate(prevProp, prevState) {
    if (prevState.step !== this.state.step) {
      this.setProgress();
    }
    if (prevProp.products.length != this.props.products.length) {
      if (this.props.products.length === 1) {
        const data = { target: { value: this.props.products[0].product_id } };
        this.onChangeHandler(data, "product");
        this.next();
      }
    }
  }

  componentDidMount(prevProps) {
    this.setProgress();
    this.getQuestions();
    const location = this.props?.history?.location?.state;
    if (location) {
      if (location?.search_type == "name") {
        this.setState({
          ...this.state,
          params: {
            ...this.state.params,
            name: location.search,
          },
        });
      } else if (location?.search_type == "mobile") {
        this.setState({
          ...this.state,
          params: {
            ...this.state.params,
            phone: location.search,
          },
        });
      }
    }
    if (this.props.products.length === 1) {
      const data = { target: { value: this.props.products[0].product_id } };
      this.onChangeHandler(data, "product");
      this.next();
    }
  }

  setProgress = () => {
    let progress = 0;
    if (this.state.eClinic) {
      switch (this.state.step) {
        case 1:
          progress = this.steps === 5 ? 20 : 25;
          break;
        case 2:
          progress = this.steps === 5 ? 40 : 50;
          break;
        case 3:
          if (this.state.eClinic) progress = 100;
          break;
        default:
          progress = this.steps === 5 ? 100 : 100;
      }
    } else {
      switch (this.state.step) {
        case 1:
          progress = this.steps === 5 ? 20 : 25;
          break;
        case 2:
          progress = this.steps === 5 ? 40 : 50;
          break;
        case 3:
          progress = this.steps === 5 ? 60 : 75;
          break;
        case 4:
          progress = this.steps === 5 ? 80 : 100;
          break;
        default:
          progress = this.steps === 5 ? 100 : 100;
      }
    }
    AppStore.dispatch({ type: "PROGRESS", progress: progress });
  };

  getQuestions = async () => {
    let answers = {};
    const response = await ApiGet(`questions`);
    if (response.status === "success") {
      _.forEach(response.data, (question) => {
        _.set(answers, `ans-${question.medicalQuestionId}`, 0);
      });
      this.setState({ questions: response.data, answers: answers });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, products, user } = state;
  return { loading: loading, products: products, user: user };
};

const SubscriptionConnect = connect(mapStateToProps)((props) => {
  return <Subscription {...props} />;
});

export default withRouter(SubscriptionConnect);
