import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getResellerId,
  getResellerLanguage,
  JavaApiGet,
  postJoinCallAction,
  PythonCareApiPost,
} from "../lib/AppHelper";
import moment from "moment";
import Button from "./Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Modal, Loader } from "rsuite";
import RemindIcon from "@rsuite/icons/legacy/Remind";
import { toast } from "react-toastify";
import Doctor from "../images/doctor.png";
import Pharma from "../images/parma.png";
import { useSelector } from "react-redux";
import TimerFromStartDate from "../utils/TimerFromStartDate";
import GetHeader from "./GetHeader";

export const MemberIconWithName = ({
  aiqaCenter,
  member,
  bookAppointmentData,
  size = 30,
  setExpanded,
  expanded,
  policyId,
  product_id,
  start_date,
  claimAdd,
  eligibleForClaim,
  componentDidMount,
}) => {
  const permissions = useSelector((state) => state?.user?.permissions);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isBookAppointment, setIsBookAppointment] = useState(false);
  const [appointmentBookedDetails, setAppointmentBookedDetails] = useState("");
  const [patientId, setPatientId] = useState("");
  const [insuredMemberId, setinsuredMemberId] = useState("");
  const [blackOutDays, setBlackOutDays] = useState("");
  const [appointmentData, setAppointmentData] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let subClaimPolicy = false;

  if (member?.sub_policy?.length > 0) {
    for (let index = 0; index < member.sub_policy.length; index++) {
      subClaimPolicy =
        member?.sub_policy[index]?.product_claim_detail?.eligible_for_claim ==
        "Y"
          ? true
          : subClaimPolicy;
    }
  }

  useEffect(() => {
    JavaApiGet("enrollment-service/NonServiceableDay").then((res) => {
      let data = [...res?.responseObject];
      if (data.length) {
        data.map((elem) => {
          if (elem.nonServiceableDate == moment().format("YYYY-MM-DD") && elem?.blockAppointments ) {
            setBlackOutDays(
              `Sorry! we are not operational on ${moment(
                elem.nonServiceableDate
              ).format("ll")}.`
            );
          }
        });
      }
    });
  }, []);

  const showFirstLetter = (gender) => {
    if (!gender) return " ";
    return gender.charAt(0).toUpperCase();
  };

  const showDateOfBirth = (dob) => {
    if (!dob) return " ";
    return moment(dob).format("DD-MM-YYYY");
  };

  useEffect(() => {
    setIsBookAppointment(bookAppointmentData?.book_appointment);
    let relation = member?.relation.toUpperCase();
    if (relation === "SELF" && bookAppointmentData?.appointment?.length) {
      setPatientId(bookAppointmentData?.id);
      setinsuredMemberId(member?.insured_member_id);
      if (bookAppointmentData?.appointment?.length) {
        setAppointmentData(bookAppointmentData?.appointment[0]);
        setAppointmentBookedDetails(
          `Appointment Date/Time ${
            bookAppointmentData?.appointment[0]?.appointment_date &&
            moment(
              bookAppointmentData?.appointment[0]?.appointment_date
            ).format("DD/MM/YYYY")
          } ${
            bookAppointmentData?.appointment[0]?.appointment_time &&
            moment(bookAppointmentData?.appointment[0]?.appointment_time, [
              "HH:mm",
            ]).format("hh:mm a")
          } `
        );
      }
    } else if (bookAppointmentData && bookAppointmentData?.members) {
      bookAppointmentData?.members?.map((item) => {
        if (item?.insured_member_id == member?.insured_member_id) {
          setPatientId(item?.id);
          setinsuredMemberId(member?.insured_member_id);
          if (item?.appointment?.length) {
            setAppointmentData(item?.appointment[0]);
            setAppointmentBookedDetails(
              `Appointment Date/Time ${
                item?.appointment[0]?.appointment_date &&
                moment(item?.appointment[0]?.appointment_date).format(
                  "DD/MM/YYYY"
                )
              } ${
                item?.appointment[0]?.appointment_time &&
                moment(item?.appointment[0]?.appointment_time, [
                  "HH:mm",
                ]).format("hh:mm a")
              }`
            );
          }
        }
      });
    }
  }, [member?.insured_member_id, bookAppointmentData]);

  const bookAppointment = async () => {
    setLoading(true);
    let payload = {
      appointment_date: moment().format("YYYY-MM-DD"),
      appointment_time: moment().format("HH:mm"),
      appointment_type: "video_call",
      call_type: "connect_now",
      channel: "personal_clinic",
      created_from: "reseller",
      doctor_specialty: 1,
      patient: patientId,
      self_unpaid_appointment: false,
      type: "patient",
      language: getResellerLanguage() || "Hindi",
      application_id: member?.application_id,
      insured_member_id: insuredMemberId
        ? insuredMemberId
        : member?.insured_member_id,
      reseller_id: getResellerId(),
    };
    const data = await PythonCareApiPost("eclinic-appointment/", payload);
    if (data.status === "SUCCESS") {
      handleClose();
      toast.success("Appointment created successfully");
      setAppointmentData(data?.data);
      setAppointmentBookedDetails(
        `Appointment Date/Time ${
          data?.data?.appointment_date &&
          moment(data?.data?.appointment_date).format("DD/MM/YYYY")
        } ${
          data?.data?.appointment_time &&
          moment(data?.data?.appointment_time, ["HH:mm"]).format("hh:mm a")
        } `
      );
      componentDidMount();
    } else {
      if (data?.message) toast.error(data?.message);
      if (data?.data) toast.error(data?.data);
      else toast.error("Appointment not created");
    }
    setLoading(false);
    handleClose();
  };

  const handleJoinCall = (item) => {
    const data = {
      appointment_id: item.id,
      aiqa_center_joined: true,
      aiqa_center_id: getResellerId(),
    };
    postJoinCallAction(data);
    window.open(
      item?.meet_url,
      "popUpWindow",
      "height=300,width=700,left=50,top=50,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes"
    );
  };

  const showRelation = (item) => {
    if (!item) return " ";
    item = item.replaceAll("_", " ");
    item = item.replaceAll("-", " ");
    return item;
  };

  return (
    <>
      <div className="block md:flex justify-between">
        <div>
          {GetHeader(member, size)}
          <p className="pl-10 text-medium text-md">{member?.name}</p>
          <p className="pl-10 text-xs">
            {showRelation(member?.relation)} | {showFirstLetter(member?.gender)}{" "}
            | {showDateOfBirth(member?.dob)}
          </p>
        </div>

        <div className="flex justify-center items-center mt-4 md:mt-0">
          <div className="flex w-full justify-center content-center items-center flex-col md:flex-row">
            {(permissions?.center_coordinator || aiqaCenter) &&
              isBookAppointment &&
              !appointmentBookedDetails && (
                <Button
                  size={"sm"}
                  title="Book Appointment"
                  attrs={{
                    onClick: () => handleOpen(),
                  }}
                />
              )}

            {(permissions?.center_coordinator || aiqaCenter) &&
            appointmentBookedDetails ? (
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className="md:w-56 w-full text-center"
              >
                {appointmentBookedDetails}
              </Typography>
            ) : (
              false
            )}

            {permissions?.center_coordinator &&
              appointmentData?.status == "call_initiated" && (
                <div>
                  <div className="flex w-full items-center justify-center">
                    <div className="hidden md:flex mr-2 gap-3">
                      <div className="flex">
                        <img
                          src={Doctor}
                          className="bg-white rounded-full border border-red-500 h-10 w-10"
                          alt="A"
                        />
                        <span
                          style={{
                            backgroundColor: appointmentData?.doctor_joined
                              ? "#37D510"
                              : "#6B6B6B",
                          }}
                          className="h-3 w-3 rounded-full absolute ml-7"
                        />
                      </div>
                      <div className="flex">
                        <img
                          src={Pharma}
                          className="bg-white rounded-full border border-red-500 h-10 w-10"
                          alt="A"
                        />
                        <span
                          style={{
                            backgroundColor: appointmentData?.pharmacist_joined
                              ? "#37D510"
                              : "#6B6B6B",
                          }}
                          className="h-3 w-3 rounded-full absolute ml-7"
                        />
                      </div>
                    </div>
                    <div
                      style={{ backgroundColor: "#F7B500" }}
                      className="mt-3 md:mt-0 h-12 md:h-16 md:w-48 text-white rounded-2xl flex items-center justify-center w-full"
                    >
                      <div className="flex md:block justify-between items-center md:justify-center md:w-36 w-60 pl-4 pr-4">
                        <div>On Going Call</div>
                        <span className="float-right text-xs ml-5">
                          {
                            <TimerFromStartDate
                              startDate={appointmentData?.call_join_time}
                            />
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="md:hidden w-full m-2 flex gap-4">
                    <div>
                      <div className="flex items-center mb-4">
                        <div
                          className="h-4 w-4 rounded-full"
                          style={{ backgroundColor: "#37D510" }}
                        />
                        <label
                          for="default-checkbox"
                          className="ms-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Doctor
                        </label>
                      </div>
                      <div class="flex items-center mb-4">
                        <div
                          className="h-4 w-4 rounded-full"
                          style={{ backgroundColor: "#6B6B6B" }}
                        />
                        <label
                          for="default-checkbox"
                          className="ms-2 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Pharmacist
                        </label>
                      </div>
                    </div>
                    {/* <div>
                      {
                        <TimerFromStartDate
                          startDate={appointmentData?.call_join_time}
                        />
                      }
                    </div> */}
                  </div>
                </div>
              )}

            {(permissions?.center_coordinator || aiqaCenter) &&
            appointmentData?.status == "pending" &&
            appointmentData.meet_url ? (
              <Button
                size={"sm"}
                title="Join Call"
                attrs={{
                  onClick: () => handleJoinCall(appointmentData),
                  style: { backgroundColor: "#564CC8", width: "150px" },
                }}
                className="mt-3 md:mt-0"
              />
            ) : (
              false
            )}

            {eligibleForClaim?.toLowerCase() === "y" && (
              <Link
                to={{
                  pathname: `/claim/${member?.id}/${policyId}`,
                  state: {
                    memberId: member?.id,
                    policyId,
                    start_date,
                    productId: product_id,
                  },
                }}
                style={{ marginLeft: 10, marginRight: 10 }}
                className="mt-3 md:mt-0"
              >
                {subClaimPolicy && claimAdd ? (
                  <Button size={"sm"} title="Raise Claim" />
                ) : (
                  false
                )}
              </Link>
            )}
          </div>
          {eligibleForClaim?.toLowerCase() === "y" &&
            claimAdd &&
            subClaimPolicy && (
              <div
                style={{ width: 10, fontSize: "x-large" }}
                onClick={() => {
                  setExpanded((expanded) => !expanded);
                }}
                className="md:block hidden ml-2"
              >
                {expanded ? "-" : "+"}
              </div>
            )}
        </div>
      </div>
      {expanded ? (
        <div>
          {member?.claim.length > 0 &&
            member.claim.map((item) => {
              return (
                <div key={item.claim_id} style={{ margin: "10px" }}>
                  <Card sx={{ borderColor: "error.main" }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Bank Name : {item.bank_name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Account Holder Name : {item.account_holder_name}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Claim Reason : {item.claim_reason}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Total Claim Amount : {item.total_claim_amount}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Payment Status :
                        {item.payment_status
                          ? item.payment_status
                          : "Not found"}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.secondary"
                        gutterBottom
                      >
                        Claim Status : {item.status ? item.status : "Not found"}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
        </div>
      ) : (
        ""
      )}

      <Modal open={open} onClose={handleClose} size="xs">
        <Modal.Body>
          <p style={{ textAlign: "center" }}>
            <RemindIcon style={{ color: "#ffb300", fontSize: 36 }} />
          </p>
          <p style={{ textAlign: "center" }}>
            {blackOutDays
              ? blackOutDays
              : "Are you sure you want to book Appointment?"}
          </p>
        </Modal.Body>
        <Modal.Footer
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {loading ? (
            <Loader size="md" />
          ) : (
            <>
              {!blackOutDays && (
                <Button
                  size={"sm"}
                  attrs={{
                    onClick: () => bookAppointment(),
                  }}
                  title="Yes, I'm sure"
                ></Button>
              )}
              <Button
                size={"sm"}
                attrs={{
                  onClick: () => handleClose(),
                }}
                title={!blackOutDays ? "No, cancel" : "cancel"}
              ></Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
