import { PencilIcon, PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import Main from "./../Main";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { InputRadio } from "../../components/form/InputRadio";
import { ApiGet, ApiPost, removeData, setError } from "../../lib/AppHelper";
import { InputFile } from "../../components/form/InputFile";
import _ from "lodash";
import { InputDate } from "../../components/form/InputDate";

export class BasicProfile extends Component {
  state = {
    edit: false,
    init_params: {
      name: this.props.user.name,
      dob: this.props.user.basic.dob,
      mobile: this.props.user.mobile,
      email: this.props.user.email,
      gender: this.props.user.basic.gender,
      pin: this.props.user.basic.pincode,
      state: this.props.user.basic.state,
      photo_base64: null,
      photo: "",
    },
    params: {
      name: this.props.user.name,
      dob: this.props.user.basic.dob,
      mobile: this.props.user.mobile,
      email: this.props.user.email,
      gender: this.props.user.basic.gender,
      pin: this.props.user.basic.pincode,
      state: this.props.user.basic.state,
      photo_base64: null,
      photo: "",
    },
    errors: {},
  };

  checked_pin = this.props.user.basic.pincode;

  MobileInput = React.createRef();
  BasicBtn = React.createRef();

  render() {
    const { edit, params, errors } = this.state;
    const { user } = this.props;

    return (
      <Main>
        <AppRow>
          <div className="flex-1 max-w-min mr-5">
            <ProfileSidebar />
          </div>
          <div className="flex-auto">
            <div className="max-w-xl">
              <InputFile
                label="Your Photo"
                placeholder={
                  params.photo_base64 || this.props.user?.basic?.photo_url ? (
                    <img
                      alt=""
                      className="w-32"
                      src={
                        params.photo_base64
                          ? params.photo_base64
                          : this.props.user?.basic?.photo_url
                      }
                    />
                  ) : (
                    <div className="text-center items-center flex-col w-32 inline-flex">
                      <PlusCircleIcon className="text-primary" width={24} />
                      <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                        Upload photo
                        <br />
                        max 5 MB
                      </p>
                    </div>
                  )
                }
                attrs={{
                  disabled: !edit,
                  onChange: this.onPhotoChange,
                  accept: "image/*",
                  capture: "environment",
                }}
              />

              <InputGroup
                error={errors.mobile}
                label="Phone"
                prefix="+91"
                placeholder=""
                attrs={{
                  maxLength: 10,
                  value: params.mobile,
                  readOnly: !edit,
                  disabled: true,
                  onChange: (e) => {
                    const integerRegex = /^-?\d+$/;
                    const isValidInteger = integerRegex.test(e.target.value);
                    if (isValidInteger || e.target.value == "") {
                      this.onChangeHandler(
                        { target: { value: e.target.value.replace(/\D/, "") } },
                        "mobile"
                      );
                    }
                  },
                  ref: this.MobileInput,
                }}
              />

              <InputGroup
                error={errors.name}
                label="Name"
                placeholder="Enter name..."
                attrs={{
                  readOnly: !edit,
                  value: params.name,
                  onChange: (e) => this.onChangeHandler(e, "name"),
                }}
              />

              <InputDate
                error={errors.dob}
                label="Date of Birth"
                placeholder="DOB"
                attrs={{
                  value: params.dob ? new Date(params.dob) : "",
                  readOnly: !edit,
                  onChange: (e) => this.onChangeHandlerDate(e, "dob"),
                }}
              />
              {/* <InputGroup label="Date of Birth" attrs={{ value: '05/06/2000', readOnly: true }} /> */}

              <InputGroup
                error={errors.email}
                label="Email Address"
                placeholder="Enter email..."
                attrs={{
                  maxLength: 64,
                  value: params.email,
                  readOnly: !edit,
                  onChange: (e) => this.onChangeHandler(e, "email"),
                }}
              />
              {!edit ? (
                <InputGroup
                  label="Gender"
                  attrs={{ value: user.basic.gender_text, readOnly: true }}
                />
              ) : (
                <InputRadio
                  error={errors.gender}
                  className="h-10"
                  label="Gender"
                  name="gender"
                  options={[
                    { label: "Male", value: "m" },
                    { label: "Female", value: "f" },
                  ]}
                  value={params.gender}
                  attrs={{ onChange: (e) => this.onChangeHandler(e, "gender") }}
                />
              )}

              <InputGroup
                error={errors.pin}
                label="Pincode"
                placeholder="Enter pincode..."
                attrs={{
                  value: params.pin,
                  readOnly: !edit,
                  onChange: (e) => {
                    this.onChangeHandler(
                      { target: { value: e.target.value.replace(/\D/, "") } },
                      "pin"
                    );
                  },
                  onBlur: this.checkPin,
                }}
                onEnter={this.checkPin}
              />

              {params.state ? (
                <InputGroup
                  label="State"
                  placeholder="Enter state..."
                  attrs={{ value: params.state, readOnly: true }}
                />
              ) : (
                false
              )}

              <div className="mt-8">
                <div className="flex">
                  <div>
                    <Button
                      attrs={{ onClick: this.toggleEdit }}
                      title={!edit ? "Edit Profile" : "Cancel Edit"}
                      display="inline-block"
                      outline={true}
                    >
                      {edit ? (
                        <XIcon width={16} className="mr-1" />
                      ) : (
                        <PencilIcon width={16} className="mr-1" />
                      )}
                    </Button>
                  </div>
                  <div className="flex-1 pl-5">
                    {edit ? (
                      <Button
                        attrs={{
                          type: "button",
                          onClick: this.updateProfile,
                          ref: this.BasicBtn,
                        }}
                        size="md"
                        title={"Update Profile"}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
      </Main>
    );
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit }, () => {
      if (this.state.edit) {
        this.MobileInput.current.focus();
      } else {
        this.setState({ params: this.state.init_params });
      }
    });
  };

  checkPin = async () => {
    if (!this.state.params.pin) return;
    if (this.state.params.pin === this.checked_pin) return;
    this.checked_pin = this.state.params.pin;

    AppStore.dispatch({ type: "LOADING", loading: true });

    const response = await ApiGet(`check-pin/${this.state.params.pin}`);

    if (response.status === "success" && response.data !== null) {
      this.onChangeHandler({ target: { value: response.data } }, "state");
      this.BasicBtn.current.focus();
    } else {
      this.onChangeHandler({ target: { value: null } }, "state");
    }

    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  updateProfile = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });

    this.setState({ errors: {} }, async () => {
      const { name, dob, photo, mobile, email, gender, pin, state } =
        this.state.params;

      const data = new FormData();
      data.append("mobile", mobile);
      data.append("name", name);
      data.append("dob", dob);
      data.append("gender", gender);
      data.append("email", email);
      data.append("pin", pin);
      data.append("state", state);
      data.append("photo", photo);

      const response = await ApiPost("register/basic", data);
      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        const response = await ApiGet("me");
        if (response.status === "success") {
          AppStore.dispatch({ type: "USER_UPDATED", user: response.data });
        } else {
          await removeData("access_token");
          await removeData("auth_token");
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };

  onPhotoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const { params } = this.state;

      _.set(params, "photo_base64", URL.createObjectURL(event.target.files[0]));
      _.set(params, "photo", event.target.files[0]);

      this.setState({ params: params });
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <BasicProfile {...props} />;
});
