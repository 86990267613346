import React from 'react'

export default function InputLabel({ label = "", labelLink = null, requiredMark = null, starMark = false }) {

  if (!label) return false;
  return (
    // <label className="flex text-sm text-gray-700 justify-between">
    <label className="flex text-sm text-gray-700">
      {label} {requiredMark || starMark ? <span className="text-lg text-red-500">*</span> : false}
      {labelLink !== null ? <span tabIndex={0} className="cursor-pointer underline focus:ring-2 focus:outline-none focus:ring-offset-1 focus:ring-primary rounded-sm hover:text-primary" onClick={labelLink.onClick}>{labelLink.title}</span> : false}
    </label>
  )
}
