import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const navigation = [
  { title: "Profile", link: "/profile" },
  { title: "Bank Details", link: "/banking" },
  // { title: 'Manage Tokens', 'link': '/manage-tokens' },
  {
    title: "Logout",
    link: "/logout",
    state: { logout_from_all_device: false },
  },
];

const admin_navigation = [
  {
    title: "Logout",
    link: "/logout",
    state: { logout_from_all_device: false },
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserAvatar = ({ avatar = null, size = 8 }) => {
  if (avatar === null) {
    return (
      <div
        className={`h-${size} w-${size} rounded-full bg-gray-200 flex justify-center items-center text-gray-600`}
      >
        <UserIcon width={14} />
      </div>
    );
  }
  return (
    <img
      className={`h-${size} w-${size} rounded-full`}
      style={{ width: 50, height: 50 }}
      src={avatar}
      alt=""
    />
  );
};

const MobileMenu = () => {
  const user = useSelector((state) => state.user);
  const nav = user.is_admin ? admin_navigation : navigation;

  return (
    <div className="pt-4 pb-3 border-t border-gray-50">
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          <UserAvatar avatar={user?.basic?.photo_url} />
        </div>
        <div className="ml-3">
          <div className="text-base leading-none">{user.name}</div>
          <div className="text-sm leading-none text-gray-300">{user.email}</div>
        </div>
      </div>
      {/* {user?.referral_code && (
        <div className="mt-5 p-1 px-3 mx-2  items-center md:ml-6 ">
          Referral Code : {user?.referral_code}
        </div>
      )} */}
      <div className="mt-3 px-2 space-y-1">
        {nav.map((item, index) => (
          <Link
            key={index}
            to={{ pathname: item.link, state: item.state }}
            className="block px-3 py-2 rounded-md text-base hover:text-white hover:bg-primary"
          >
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

const DesktopMenu = () => {
  const user = useSelector((state) => state.user);
  const nav = user.is_admin ? admin_navigation : navigation;

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={[
                "flex items-center text-sm focus:outline-none space-x-2 py-1 border px-3 rounded-xl border-gray-300",
                open ? "bg-primary-50 border-primary" : "",
              ].join(" ")}
            >
              <UserAvatar avatar={user?.basic?.photo_url} size={6} />
              <span>{user.name}</span>
              <ChevronDownIcon
                width={16}
                style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
                className={open ? "text-primary" : ""}
              />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            >
              {nav.map((item, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <Link
                      to={{ pathname: item.link, state: item.state }}
                      className={classNames(
                        active ? "text-white bg-primary" : "",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      {item.title}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default function UserMenu({ mobile = false }) {
  if (mobile) return <MobileMenu />;
  return <DesktopMenu />;
}
