import React from "react";
import { Tag, TagGroup } from "rsuite";
const filtersArr = [
  // { label: "NEW", value: "NEW" },
  { label: "DRAFT", value: "DRAFT" },
  { label: "IN PROGRESS", value: "IN_PROGRESS" },
  { label: "REJECTED", value: "REJECTED" },
  { label: "QUERY", value: "QUERY" },
  { label: "APPROVED", value: "Approved" },
  { label: "SETTLED", value: "SETTLED" },
];
const colors = ["red", "orange", "yellow", "green", "cyan", "blue", "violet"];
export const TagFilter = ({
  filters = filtersArr,
  field,
  setFilterValues,
  selectedValues,
  multiple = false,
}) => {
  return (
    <TagGroup
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        margin: 8,
      }}
    >
      {filters.map((filter, i) => {
        const { label, value } = filter;
        let isSelectedValue = false;
        if (multiple && selectedValues.indexOf(value) > -1) {
          isSelectedValue = true;
        } else if (multiple === false) {
          isSelectedValue = value === selectedValues;
        }
        return (
          <div
            key={i}
            style={{
              borderRadius: 30,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              if (field && setFilterValues) {
                if (!multiple) {
                  setFilterValues({ [field]: value });
                } else {
                  let updatedValue = [...selectedValues];
                  if (updatedValue.indexOf(value) > -1) {
                    updatedValue.splice(updatedValue.indexOf(value), 1);
                    setFilterValues({ [field]: [...updatedValue] });
                  } else {
                    setFilterValues({
                      [field]: [...updatedValue, value],
                    });
                  }
                }
              }
            }}
          >
            <button
              style={{
                margin: 7,
                boxShadow: `${
                  isSelectedValue ? "#dfecfb" : "rgba(0, 0, 0, 0.24)"
                } 0px 3px 8px`,
                borderColor: isSelectedValue ? "red" : "transparent",
                color: isSelectedValue ? "red" : "black",
                borderWidth: 1,
                borderRadius: 30,
              }}
              className="bg-default-500 active:bg-default-600 uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
            >
              <i className="fas fa-heart"></i> {label}
            </button>

            {/* <Tag
              style={{
                margin: 8,
                boxShadow: `${isSelectedValue ? "#f8f8f8" : "rgba(0, 0, 0, 0.24)"
                  } 0px 3px 8px`,
                borderColor: isSelectedValue ? "red" : "transparent",
                borderWidth: 1,
              }}
            >
              {label}
            </Tag> */}
          </div>
        );
      })}
    </TagGroup>
  );
};
