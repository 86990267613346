import React, { memo } from "react";
import {
  convertNumberToShortFormat,
  convertObjectToArray,
  customHeader,
  percentageCalculation,
} from "./constant";
import { Tooltip, Zoom } from "@mui/material";

const TableHeader = [
  "Duration",
  "Total count",
  "Total amount (Rs/-)",
  "Percentage",
];

const SummaryTableVertical = ({ salesData }) => {
  const [summaryData, setSummaryData] = React.useState([]);
  React.useEffect(() => {
    const formattedData = salesData.data.reduce((accumulator, reseller) => {
      Object.keys(reseller.comparisonData).forEach((year) => {
        const { policyCount, policyAmount } = reseller.comparisonData[year];
        if (!accumulator[year]) {
          accumulator[year] = {
            policyCount: 0,
            policyAmount: 0,
          };
        }
        accumulator[year].policyCount += policyCount || 0;
        accumulator[year].policyAmount += policyAmount || 0;
      });
      return accumulator;
    }, {});

    setSummaryData([formattedData]);
  }, [salesData]);
  return (
    <table>
      {convertObjectToArray(summaryData).length > 0
        ? convertObjectToArray(summaryData).map((row, index) => {
            return (
              <tr
                style={{
                  marginBottom: "5px",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              >
                <th style={{ fontWeight: "600" }}>{TableHeader[index]}</th>
                {row.map((data, dataIndex) => {
                  return (
                    <td
                      style={{
                        padding: "5px",
                        border: "1px solid #ccc",
                        width: "200px",
                        fontWeight: "600",
                        background: index === 0 ? "#db2228" : "",
                        color: index === 0 ? "white" : "",
                        textAlign: "center",
                      }}
                    >
                      {index === 0 ? (
                        customHeader(data)
                      ) : index === 3 && dataIndex > 0 ? (
                        percentageCalculation(
                          row[dataIndex - 1],
                          row[dataIndex]
                        )
                      ) : index === 3 && dataIndex === 0 ? (
                        ""
                      ) : (
                        <Tooltip
                          title={data.toLocaleString("en-IN")}
                          placement="top"
                          arrow
                          TransitionComponent={Zoom}
                        >
                          {convertNumberToShortFormat(data)}
                        </Tooltip>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })
        : ""}
    </table>
  );
};

export default memo(SummaryTableVertical);
