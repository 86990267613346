import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  AppBackButton,
  AppHeading,
  ClaimStatus,
} from "../components/AppComponents";
import { withRouter } from "react-router";
import Main from "./Main";
import { ApiGet, ROLE_STATUS_MAPPING } from "../lib/AppHelper";
import PaginationData from "./admin/components/PaginationData";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusSquare,
  faRupeeSign,
} from "@fortawesome/free-solid-svg-icons";
import { TagFilter } from "./TagFilter";
import { Popover } from "rsuite";
import moment from "moment";

const DefaultPopover = React.forwardRef(({ content, ...props }, ref) => {
  return (
    <Popover ref={ref} {...props}>
      <p>{content}</p>
    </Popover>
  );
});

export class Claims extends React.PureComponent {
  BasicBtn = React.createRef();

  state = {
    status: [],
  };

  setFilterValues = (values) => {
    this.setState(values);
  };

  render() {
    const { user } = this.props;
    const { permissions } = user;

    return (
      <Main MessageRef={this.MessageRef}>
        <AppBackButton to="/">Back to Dashboard</AppBackButton>

        <div className="flex justify-between">
          <AppHeading className="pl-4">Claims</AppHeading>
          {permissions && permissions.claim_add ? (
            <div>
              <Link to="subscriptions">
                <button
                  title="Add Claim"
                  className="float-right bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded flex"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                  <p className="px-2"> Add Claim</p>
                </button>
              </Link>
            </div>
          ) : (
            false
          )}
        </div>
        {permissions && permissions.claim_view ? (
          <div className="w-full flex flex-col justify-between space-y-4">
            <div className="container my-5 mx-auto px-1">
              <div className="flex flex-wrap justify-between">
                <TagFilter
                  setFilterValues={this.setFilterValues}
                  selectedValues={this.state.status}
                  field={"status"}
                  multiple={true}
                />
              </div>

              <div className="flex flex-wrap">
                <div className=" flex justify-center items-center">
                  <div className="relative ml-3">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="h-12 w-80 pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
                      placeholder="Search Name ..."
                      onKeyPress={(e) => {
                        if (e.target.value.length > 2 && e.key === "Enter") {
                          this.setState({ name: this.name });
                          this.setState({ page: 1 });
                        }
                      }}
                      onChange={(e) => {
                        if (!e.target.value) {
                          this.setState({ name: e.target.value });
                          this.setState({ page: 1 });
                        } else {
                          this.name = e.target.value;
                        }
                      }}
                    />
                    <div className="absolute top-2 right-2">
                      <button
                        className="h-8 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600"
                        onClick={() => {
                          if (this.name.length > 3) {
                            this.setState({ name: this.name });
                            this.setState({ page: 1 });
                          }
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div className=" flex justify-center items-center">
                  <div className="relative ml-3">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </span>
                    <input
                      type="number"
                      className="h-12 w-80 pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
                      placeholder="Search Mobile..."
                      onKeyPress={(e) => {
                        if (e.target.value.length > 2 && e.key === "Enter") {
                          this.setState({ mobile: this.mobile });
                          this.setState({ page: 1 });
                        }
                      }}
                      onChange={(e) => {
                        if (!e.target.value) {
                          this.setState({ mobile: e.target.value });
                          this.setState({ page: 1 });
                        } else {
                          this.mobile = e.target.value;
                        }
                      }}
                    />
                    <div className="absolute top-2 right-2">
                      <button
                        className="h-8 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600"
                        onClick={() => {
                          if (this.mobile.length > 3) {
                            this.setState({ mobile: this.mobile });
                            this.setState({ page: 1 });
                          }
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>

                <div className=" flex justify-center items-center">
                  <div className="relative ml-3">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        className="w-6 h-6"
                      >
                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="h-12 w-80 pl-10 pr-20 rounded-lg z-0 focus:shadow focus:outline-none"
                      placeholder="Search Loan Code..."
                      onKeyPress={(e) => {
                        if (e.target.value.length > 2 && e.key === "Enter") {
                          this.setState({ loan_code: this.searchValue });
                          this.setState({ page: 1 });
                        }
                      }}
                      onChange={(e) => {
                        if (!e.target.value) {
                          this.setState({ loan_code: e.target.value });
                          this.setState({ page: 1 });
                        } else {
                          this.searchValue = e.target.value;
                        }
                      }}
                    />
                    <div className="absolute top-2 right-2">
                      <button
                        className="h-8 w-20 text-white rounded-lg bg-red-500 hover:bg-red-600"
                        onClick={() => {
                          if (this.searchValue.length > 3) {
                            this.setState({ loan_code: this.searchValue });
                            this.setState({ page: 1 });
                          }
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <PaginationData
                request={ApiGet}
                renderItem={(item) => <ClaimCard item={item} />}
                endPoint={"claims"}
                filterValues={this.state}
              />
            </div>
          </div>
        ) : (
          <div className="p-10 text-red-500">
            <h4>You do not have permission to view claim</h4>
          </div>
        )}
      </Main>
    );
  }
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};
const ClaimsConnect = connect(mapStateToProps)((props) => {
  return <Claims {...props} />;
});

export default withRouter(ClaimsConnect);

export const ClaimCard = ({ item }) => {
  const {
    sub_claims,
    claim_id,
    name,
    claim_status,
    claim_reason,
    bill_amount,
    hospital_name,
    loan_code,
    mobile,
    start_date
  } = item;
  let new_start_date = start_date ? moment(start_date).format("DD-MM-YYYY") : '';
  return (
    <Link to={"/claims/" + claim_id}>
      <div className=" p-2 sm:w-80 lg:w-96">
        <article
          className="rounded-lg shadow-lg border-red"
          style={{ minHeight: 220, minWidth: 330 }}
        >
          <header className="flex items-center justify-between leading-tight p-2 md:p-4">
            <h1 className="text-lg text-black font-bold capitalize mr-4 pl-1">
              {name ? name : "Name empty"}
            </h1>
            <p className="text-grey-darker text-sm">
              <ClaimStatus status={claim_status} />
              {/* {claim_status ==="DRAFT" ? (
                <Link to={`claim/${claim_id}`}>
                  <FontAwesomeIcon icon={faEdit} />
                </Link>
              ) : (
                false
              )} */}
            </p>
          </header>
          <body className="text-sm items-center justify-between leading-tight p-4">
            <p className="pt-2">{claim_reason}</p>
            <p className="pt-2">
              {hospital_name ? hospital_name : "Hospital name not available"}
            </p>

            <p className="pt-2 ">
              Total claimed Amount:
              <span className="pl-2">
                <FontAwesomeIcon icon={faRupeeSign} />
                {" " + bill_amount}
              </span>
            </p>
            {loan_code && (
              <p className="pt-2 ">
                Loan Code:
                <span className="pl-2">{loan_code}</span>
              </p>
            )}
            {mobile && (
              <p className="pt-2 ">
                Mobile:
                <span className="pl-2">{mobile}</span>
              </p>
            )}

            {sub_claims.map((subClaim, index) => {
              const {
                sub_claim_id,
                claim_id,
                actual_status,
                product_name,
                remarks,
                claim_approved_amount,
              } = subClaim;
              return (
                <>
                  <div
                    key={index}
                    className={"flex mt-6 align-center"}
                    style={{ alignItems: "center" }}
                  >
                    {product_name}
                    <p className="text-grey-darker text-sm mx-4">
                      {ROLE_STATUS_MAPPING[actual_status] === "Queried" ||
                        actual_status === "DRAFT" ? (
                        <Link
                          to={{
                            pathname: `claimEdit/${claim_id}/${sub_claim_id}`,
                            state: { start_date: new_start_date }
                          }}
                          style={{ marginLeft: 10 }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                      ) : (
                        false
                      )}
                    </p>
                    {claim_approved_amount && (
                      <p className="pt-2 ">
                        Approved Amount:
                        <span className="pl-2">
                          <FontAwesomeIcon icon={faRupeeSign} />
                          {" " + claim_approved_amount}
                        </span>
                      </p>
                    )}
                  </div>
                  {/* {actual_status ? <div className="mt-1 flex flex-wrap justify-between">Sub-Claim Status: <ClaimStatus status={actual_status} /></div> : ''} */}
                  {remarks ? <p className="mt-1">Remarks: {remarks}</p> : ""}
                </>
              );
            })}
          </body>
          {/* <footer className="pb-4 my-2 justify-between text-right ">
            <Link to={"/claims/" + claim_id}>
              <button className="text-xs mr-3 bg-transparent hover:bg-red-300  font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded">
                View Claim
              </button>
            </Link>
          </footer> */}
        </article>
      </div>
    </Link>
  );
};
