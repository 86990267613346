export const headers = [
  { name: "Reseller_id", mandatory: false, type: "number" },
  { name: "Product_id", mandatory: false, type: "number" },
  { name: "SrNo", mandatory: false },
  { name: "Customer_Id", mandatory: true },
  { name: "Loan_Code", mandatory: false },
  { name: "Disbursementdate", mandatory: true },
  { name: "State", mandatory: false },
  { name: "Pincode", mandatory: true, type: "number" },
  { name: "Cust_Name", mandatory: true, type: "string" },
  { name: "Cust_Dob", mandatory: true },
  { name: "Cust_Gender", mandatory: true },
  { name: "Mobile", mandatory: true, type: "number" },
  { name: "Email_Id", mandatory: false },
  { name: "Cust_Id_Type", mandatory: true },
  { name: "Cust_Id_Number", mandatory: true },
  { name: "Insured_PMJAY_ID", mandatory: false },
  { name: "Coinsured_Name", mandatory: false },
  { name: "Coinsured_Dob", mandatory: false },
  { name: "Coinsured_Gender", mandatory: false },
  { name: "Coinsured_Relation", mandatory: false },
  { name: "Coinsured_Id_Type", mandatory: false },
  { name: "Coinsured_Id_Number", mandatory: false },
  { name: "Coinsured_PMJAY_ID", mandatory: false },
  { name: "Family_1_name", mandatory: false },
  { name: "Family_1_DOB", mandatory: false },
  { name: "Family_1_Gender", mandatory: false },
  { name: "Family_1_Relation", mandatory: false },
  { name: "FAMILY_1_PMJAY_ID", mandatory: false },
  { name: "Family_2_name", mandatory: false },
  { name: "Family_2_DOB", mandatory: false },
  { name: "Family_2_Gender", mandatory: false },
  { name: "Family_2_Relation", mandatory: false },
  { name: "FAMILY_2_PMJAY_ID", mandatory: false },
  { name: "Family_3_name", mandatory: false },
  { name: "Family_3_DOB", mandatory: false },
  { name: "Family_3_Gender", mandatory: false },
  { name: "Family_3_Relation", mandatory: false },
  { name: "FAMILY_3_PMJAY_ID", mandatory: false },
  { name: "Family_4_name", mandatory: false },
  { name: "Family_4_DOB", mandatory: false },
  { name: "Family_4_Gender", mandatory: false },
  { name: "Family_4_Relation", mandatory: false },
  { name: "FAMILY_4_PMJAY_ID", mandatory: false },
  { name: "Policy_Start_Date", mandatory: false },
  { name: "Branch_Code", mandatory: false, type: "string" },
  { name: "ABO_ID", mandatory: false },
];
