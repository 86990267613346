import React, { Component } from "react";
import { Link } from "react-router-dom";
import AdminMain from "./AdminMain";
import {
  RiUserStarFill,
  RiUserAddFill,
  RiUserFollowFill,
  RiUserSettingsFill,
  RiUserFollowLine,
  RiFileUserFill,
  RiShoppingBasketFill,
} from "react-icons/ri";
import { checkScope } from "../../lib/AppHelper";

const DashboardIcon = ({ title, to, icon, classes = null }) => {
  return (
    <Link
      to={to}
      className={
        classes +
        " ml-0 pl-0 mb-5 border border-primary rounded-xl w-40 h-40 flex justify-center items-center md:justy-hover:bg-primary-50"
      }
    >
      <div>
        <div className="flex justify-center items-center text-primary">
          {icon}
        </div>
        <div className="text-xs text-center uppercase font-bold mt-4">
          {title}
        </div>
      </div>
    </Link>
  );
};

export class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scope: checkScope('clone-product'),
    }
  }
  MessageRef = React.createRef();
  render() {
    return (
      <AdminMain MessageRef={this.MessageRef}>
        <div className="flex flex-wrap gap-10">
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Manage</p>
                <p>Resellers</p>
              </React.Fragment>
            }
            to="resellers"
            icon={<RiUserStarFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Add</p>
                <p>MLM Reseller</p>
              </React.Fragment>
            }
            to="add-mlm"
            icon={<RiUserAddFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Add</p>
                <p>MFI Reseller</p>
              </React.Fragment>
            }
            to="add-mfi"
            icon={<RiUserFollowFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Add</p>
                <p>Corporate</p>
              </React.Fragment>
            }
            to="add-corporate"
            icon={<RiUserFollowLine size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Reseller's</p>
                <p>Products</p>
              </React.Fragment>
            }
            to="reseller-products"
            icon={<RiUserSettingsFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Reseller's</p>
                <p>Report</p>
              </React.Fragment>
            }
            to="reseller-reports"
            icon={<RiFileUserFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Add/Assign</p>
                <p>Reseller's Roles</p>
              </React.Fragment>
            }
            to="roles"
            icon={<RiFileUserFill size={40} />}
          />
          <DashboardIcon
            title={
              <React.Fragment>
                <p>Add</p>
                <p>Product</p>
              </React.Fragment>
            }
            to="add-product"
            icon={<RiShoppingBasketFill size={40} />}
          />
          {this.state.scope &&
            <DashboardIcon
              title={
                <React.Fragment>
                  <p>Clone</p>
                  <p>Product</p>
                </React.Fragment>
              }
              to="clone-product"
              icon={<RiShoppingBasketFill size={40} />}
              className="flex-1"
            />
          }
        </div>
      </AdminMain>
    );
  }
}

export default AdminDashboard;