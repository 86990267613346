import React from "react";

export default function Button({
  title = "button",
  type = "primary",
  outline = false,
  size = "md",
  attrs = {},
  className = "",
  display = "block",
  children,
  icon = "",
}) {
  let padding = 6;
  let font_size = "base";
  switch (size) {
    case "lg":
      padding = 6;
      font_size = "md";
      break;
    case "md":
      padding = 4;
      font_size = "base";
      break;
    case "sm":
      padding = 2;
      font_size = "sm";
      break;
    default:
      padding = 4;
      font_size = "base";
  }

  // px-6 py-3 px-4 py-2 px-2 py-1

  return (
    <div className={`rounded-xl ${display}`}>
      <button
        disabled={attrs.disabled}
        className={[
          `w-full shadow whitespace-nowrap flex items-center justify-center px-${padding} py-${
            padding / 2
          } border border-transparent text-${font_size} rounded-xl ${
            outline
              ? `border-${type} text-${type} bg-${type}-50 hover:text-white hover:bg-${type} active:bg-${type}-700`
              : `text-white bg-${type} hover:bg-${type}-700 active:bg-${type}-800`
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${type}-500 disabled:bg-gray-200 disabled:text-gray-400`,
          className,
        ].join(" ")}
        {...attrs}
      >
        {icon ? (
          <img src={icon} width={20} height={20} style={{ marginRight: 8 }} />
        ) : null}
        {children}
        {title}
      </button>
    </div>
  );
}
