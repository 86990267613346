import React, { Component } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/outline";
import _ from "lodash";
import {
  DaughterIcon,
  FemaleIcon,
  MaleIcon,
  ParentsIcon,
  RingIcon,
  SonIcon,
} from "../../components/AppIcons";
import { InputGroup } from "../../components/form/InputGroup";
import { InputRadio } from "../../components/form/InputRadio";
import {
  JavaApiPostGovtId,
  getResellerId,
  validateName,
  validateNumber,
} from "../../lib/AppHelper";
import { InputSelect } from "../../components/form/InputSelect";
import moment from "moment";
import { InputFile } from "../../components/form/InputFile";

export class FamilyDetails extends Component {
  state = {
    params: null,
  };
  frontSide = React.createRef(null);
  backSide = React.createRef(null);
  render() {
    const members = this.makeForm();
    const { params } = this.state;
    const { govt_ids } = this.props;

    if (params === null) return false;

    return (
      <div className="w-full">
        {members.map((member) => {
          return (
            <Disclosure defaultOpen={member.key === "self"} key={member.key}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-5 border-b border-gray-100 border-dashed last:border-b-0 font-medium text-left focus:outline-none focus:ring-2 focus:ring-primary-200 focus:ring-offset-2">
                    <div className="flex">
                      <span>{member.icon}</span>
                      <span className="ml-2">{member.title}</span>
                    </div>
                    <ChevronRightIcon
                      className={`${
                        open ? "transform rotate-90" : ""
                      } p-1 rounded-full w-6 h-6 text-primary shadow`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm">
                    {member.relation === "self" ? (
                      false
                    ) : (
                      <InputGroup
                        label="Name"
                        placeholder="Enter name..."
                        attrs={{
                          value: params[member.key]?.name,
                          onChange: (e) => {
                            if (
                              /^(?!\.)([a-zA-Z.\s]+)$/.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              this.onChangeHandler(e, `${member.key}.name`);
                            }
                          },
                          maxLength: 64,
                        }}
                        validateError={(value) => {
                          if (value) {
                            return false;
                          } else {
                            return "Please enter name";
                          }
                        }}
                      />
                    )}

                    {member.relation === "self" ? (
                      false
                    ) : (
                      <InputGroup
                        label="Date of Birth"
                        attrs={{
                          type: "date",
                          value: params[member.key]?.dob,
                          onChange: (e) =>
                            this.onChangeHandler(e, `${member.key}.dob`),
                          max: moment().format("YYYY-MM-DD"),
                        }}
                      />
                    )}

                    {member.relation === "self" ? (
                      false
                    ) : (
                      <>
                        <InputSelect
                          width="full"
                          label="Govt ID Type"
                          attrs={{
                            value: params[member?.key]?.govtIDType,
                            onChange: (e) => {
                              this.onChangeHandler(
                                e,
                                `${member.key}.govtIDType`,
                                `${member.key}.govtIDNumber`
                              );
                            },
                          }}
                          options={govt_ids}
                        ></InputSelect>
                        {params[member.key]?.govtIDType ? (
                          <InputGroup
                            label="Govt ID Number"
                            placeholder="Enter Gov ID Number..."
                            attrs={{
                              value: params[member.key]?.govtIDNumber,
                              onChange: (e) => {
                                if (
                                  /^[a-zA-Z0-9\-_]*$/.test(e.target.value) ||
                                  e.target.value === ""
                                ) {
                                  if (
                                    params[member.key].govtIDType === "aadhar"
                                  ) {
                                    if (e.target.value.length < 13) {
                                      e.target.value = e.target.value.replace(
                                        /\D/,
                                        ""
                                      );
                                      this.onChangeHandler(
                                        e,
                                        `${member.key}.govtIDNumber`
                                      );
                                    }
                                  } else if (
                                    params[member.key].govtIDType === "voter"
                                  ) {
                                    if (e.target.value.length < 15) {
                                      this.onChangeHandler(
                                        e,
                                        `${member.key}.govtIDNumber`
                                      );
                                    }
                                  } else if (
                                    params[member.key].govtIDType === "passport"
                                  ) {
                                    if (e.target.value.length < 15) {
                                      this.onChangeHandler(
                                        e,
                                        `${member.key}.govtIDNumber`
                                      );
                                    }
                                  } else if (
                                    params[member.key].govtIDType ===
                                    "driving_licence"
                                  ) {
                                    if (e.target.value.length < 18) {
                                      this.onChangeHandler(
                                        e,
                                        `${member.key}.govtIDNumber`
                                      );
                                    }
                                  } else {
                                    if (e.target.value.length < 20) {
                                      this.onChangeHandler(
                                        e,
                                        `${member.key}.govtIDNumber`
                                      );
                                    }
                                  }
                                }
                              },
                              maxLength: 20,
                            }}
                          />
                        ) : (
                          false
                        )}
                        {params[member.key]?.govtIDType && (
                          <>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                display: "none",
                              }}
                            >
                              <InputFile
                                label={this.props.textCapitalize(
                                  `${
                                    params[member.key]?.govtIDType
                                  } Front Photo`
                                )}
                                placeholder={
                                  params[member.key]?.front_photo_base64 ? (
                                    <img
                                      alt=""
                                      className="w-32"
                                      src={
                                        params[member.key]?.front_photo_base64
                                          ? params[member.key]
                                              ?.front_photo_base64
                                          : params[member.key]
                                              ?.front_photo_base64
                                      }
                                    />
                                  ) : (
                                    <div className="text-center items-center flex-col w-32 inline-flex">
                                      <PlusCircleIcon
                                        className="text-primary"
                                        width={24}
                                      />
                                      <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                        Upload photo
                                        <br />
                                        max 5 MB
                                      </p>
                                    </div>
                                  )
                                }
                                attrs={{
                                  // disabled: !edit,
                                  onChange: (event) =>
                                    this.onPhotoChange(
                                      event,
                                      member.key,
                                      "Front"
                                    ),
                                  accept: "image/*",
                                  capture: "environment",
                                }}
                              />
                              {(params[member.key]?.govtIDType === "aadhar" ||
                                params[member.key]?.govtIDType === "voter") && (
                                <InputFile
                                  label={this.props.textCapitalize(
                                    `${
                                      params[member.key]?.govtIDType
                                    } Back Photo`
                                  )}
                                  placeholder={
                                    params[member.key]?.back_photo_base64 ||
                                    params[member.key]?.back_photo_base64 ? (
                                      <img
                                        alt=""
                                        className="w-32"
                                        src={
                                          params[member.key]?.back_photo_base64
                                            ? params[member.key]
                                                ?.back_photo_base64
                                            : params[member.key]
                                                ?.back_photo_base64
                                        }
                                      />
                                    ) : (
                                      <div className="text-center items-center flex-col w-32 inline-flex">
                                        <PlusCircleIcon
                                          className="text-primary"
                                          width={24}
                                        />
                                        <p className="mt-2 whitespace-nowrap text-center text-sm text-primary">
                                          Upload photo
                                          <br />
                                          max 5 MB
                                        </p>
                                      </div>
                                    )
                                  }
                                  attrs={{
                                    // disabled: !edit,
                                    onChange: (event) =>
                                      this.onPhotoChange(
                                        event,
                                        member.key,
                                        "Back"
                                      ),
                                    accept: "image/*",
                                    capture: "environment",
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {_.indexOf(["self"], member.relation) === -1 ? (
                      <InputRadio
                        label="Gender"
                        requiredMark="true"
                        name={`${member.key}.gender`}
                        options={[
                          { label: "Male", value: "m" },
                          { label: "Female", value: "f" },
                          { label: "Other", value: "o" },
                        ]}
                        value={params[member.key]?.gender}
                        attrs={{
                          onChange: (e) =>
                            this.onChangeHandler(e, `${member.key}.gender`),
                        }}
                      />
                    ) : null}

                    <InputGroup
                      label="Height (cms)"
                      // requiredMark="true"
                      attrs={{
                        min: 0,
                        type: "number",
                        value: params[member.key]?.height,
                        placeholder: "e.g. 0.00",
                        onChange: (e) =>
                          this.onChangeHandler(e, `${member.key}.height`),
                      }}
                      validateError={(value) => {
                        if (validateNumber(value)) {
                          return false;
                        } else {
                          return "Please enter valid Height";
                        }
                      }}
                    />

                    <InputGroup
                      label="Weight (kgs)"
                      // requiredMark="true"
                      attrs={{
                        type: "number",
                        min: 0,
                        value: params[member.key]?.weight,
                        placeholder: "e.g. 0.00",
                        onChange: (e) => {
                          this.onChangeHandler(e, `${member.key}.weight`);
                        },
                      }}
                      validateError={(value) => {
                        if (validateNumber(value)) {
                          return false;
                        } else {
                          return "Please enter valid Weight";
                        }
                      }}
                    />
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          );
        })}
      </div>
    );
  }

  componentDidMount() {
    const members = this.makeForm();

    let value = this.props.value.members_detail;
    _.forEach(members, (member) => {
      if (member.relation !== "self") {
        _.set(
          value,
          `${member.key}.relation`,
          _.get(value, `${member.key}.relation`, member.relation)
        );
        _.set(
          value,
          `${member.key}.name`,
          _.get(value, `${member.key}.name`, ``)
        );
        _.set(
          value,
          `${member.key}.gender`,
          _.get(value, `${member.key}.gender`, ``)
        );
        _.set(
          value,
          `${member.key}.dob`,
          _.get(value, `${member.key}.dob`, ``)
        );
        _.set(
          value,
          `${member.key}.govtIDType`,
          _.get(value, `${member.key}.govtIDType`, ``)
        );
        _.set(
          value,
          `${member.key}.govtIDNumber`,
          _.get(value, `${member.key}.govtIDNumber`, ``)
        );
      }
      if (_.indexOf(["self", "daughter", "son"], member.relation) === -1) {
        _.set(
          value,
          `${member.key}.gender`,
          _.get(value, `${member.key}.gender`, `m`)
        );
      }
      _.set(
        value,
        `${member.key}.height`,
        _.get(value, `${member.key}.height`, ``)
      );
      _.set(
        value,
        `${member.key}.weight`,
        _.get(value, `${member.key}.weight`, ``)
      );
    });

    this.setState({ params: value }, () => {
      this.props.onChange(value);
    });
  }

  makeForm = () => {
    const { value } = this.props;
    const {
      spouse,
      daughter,
      son,
      parents,
      // daughter_in_law,
      // sibling,
      // sister,
      // father_in_law,
      // mother_in_law,
      // son_in_law,
      // sister_in_law,
      // brother_in_law,
    } = value.members;
    const count = value.members_count;
    const members = [];

    members.push({
      key: "self",
      relation: "self",
      title: `Primary Member (${value.name})`,
      icon:
        value.gender === "m" ? (
          <MaleIcon size={24} />
        ) : (
          <FemaleIcon size={24} />
        ),
    });

    if (spouse) {
      members.push({
        key: "spouse",
        relation: "spouse",
        title: "Spouse",
        icon: <RingIcon size={24} />,
      });
    }

    if (daughter) {
      for (let i = 1; i <= count.daughter; i++) {
        members.push({
          key: `daughter_${i}`,
          relation: "daughter",
          title: count.daughter > 1 ? `Daughter ${i}` : `Daughter`,
          icon: <DaughterIcon size={24} />,
        });
      }
    }

    if (son) {
      for (let i = 1; i <= count.son; i++) {
        members.push({
          key: `son_${i}`,
          relation: "son",
          title: count.son > 1 ? `Son ${i}` : `Son`,
          icon: <SonIcon size={24} />,
        });
      }
    }

    // if (sibling) {
    //   for (let i = 1; i <= count.sibling; i++) {
    //     members.push({
    //       key: `sibling_${i}`,
    //       relation: "sibling",
    //       title: count.sibling > 1 ? `Sibling ${i}` : `Sibling`,
    //       icon: <SonIcon size={24} />,
    //     });
    //   }
    // }

    // if (father_in_law) {
    //   members.push({
    //     key: "father_in_law",
    //     relation: "father_in_law",
    //     title: "Father in low",
    //     icon: <ParentsIcon size={24} />,
    //   });
    // }

    // if (mother_in_law) {
    //   members.push({
    //     key: "mother_in_law",
    //     relation: "mother_in_law",
    //     title: "Mother in low",
    //     icon: <ParentsIcon size={24} />,
    //   });
    // }

    // if (son_in_law) {
    //   for (let i = 1; i <= count.son_in_law; i++) {
    //     members.push({
    //       key: `son_in_law_${i}`,
    //       relation: "son_in_law",
    //       title: count.son_in_law > 1 ? `Son in law ${i}` : `Son in law`,
    //       icon: <SonIcon size={24} />,
    //     });
    //   }
    // }

    // if (sister_in_law) {
    //   for (let i = 1; i <= count.sister_in_law; i++) {
    //     members.push({
    //       key: `sister_in_law_${i}`,
    //       relation: "sister_in_law",
    //       title:
    //         count.sister_in_law > 1 ? `Sister in law ${i}` : `Sister in law`,
    //       icon: <DaughterIcon size={24} />,
    //     });
    //   }
    // }

    // if (brother_in_law) {
    //   for (let i = 1; i <= count.brother_in_law; i++) {
    //     members.push({
    //       key: `brother_in_law_${i}`,
    //       relation: "brother_in_law",
    //       title:
    //         count.brother_in_law > 1 ? `Brother in law ${i}` : `Brother in law`,
    //       icon: <SonIcon size={24} />,
    //     });
    //   }
    // }

    // if (sister) {
    //   for (let i = 1; i <= count.sister; i++) {
    //     members.push({
    //       key: `sister_${i}`,
    //       relation: "sister",
    //       title: count.sister > 1 ? `Sister ${i}` : `Sister`,
    //       icon: <DaughterIcon size={24} />,
    //     });
    //   }
    // }

    // if (daughter_in_law) {
    //   for (let i = 1; i <= count.daughter_in_law; i++) {
    //     members.push({
    //       key: `daughter_in_law_${i}`,
    //       relation: "daughter_in_law",
    //       title:
    //         count.daughter_in_law > 1
    //           ? `Daughter in low ${i}`
    //           : `Daughter in low`,
    //       icon: <DaughterIcon size={24} />,
    //     });
    //   }
    // }

    if (parents) {
      for (let i = 1; i <= count.parents?.self; i++) {
        members.push({
          key: `self_parent_${i}`,
          relation: "parent",
          title:
            count.parents.self > 1 ? `Parent ${i} (Self)` : `Parent (Self)`,
          icon: <ParentsIcon size={24} />,
        });
      }

      for (let i = 1; i <= count.parents?.spouse; i++) {
        members.push({
          key: `spouse_parent_${i}`,
          relation: "spouse_parent",
          title:
            count.parents.spouse > 1
              ? `Parent ${i} (Spouse)`
              : `Parent (Spouse)`,
          icon: <ParentsIcon />,
        });
      }
    }

    return members;
  };

  onChangeHandler = (e, key, para) => {
    const { params } = this.state;
    if (para) {
      _.set(params, para, "");
    }
    _.set(params, key, e.target.value);
    this.setState({ params: params }, () => {
      this.props.onChange(this.state.params);
    });
  };
  onPhotoChange = async (event, key, side) => {
    const formData = new FormData();
    if (event.target.files && event.target.files[0]) {
      const { params } = this.state;
      if (side == "Front") {
        _.set(
          params,
          `${key}.front_photo_base64`,
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, `${key}.front_photo`, event.target.files[0]);
        this.frontSide.current = this.props.convertImageToPDF(
          URL.createObjectURL(event.target.files[0]),
          this.props.textCapitalize(`${key} front photo`)
        );
      } else {
        _.set(
          params,
          `${key}.back_photo_base64`,
          URL.createObjectURL(event.target.files[0])
        );
        _.set(params, `${key}.back_photo`, event.target.files[0]);
        this.backSide.current = this.props.convertImageToPDF(
          URL.createObjectURL(event.target.files[0]),
          this.props.textCapitalize(`${key} back photo`)
        );
      }
      if (
        params[key]?.govtIDType === "aadhar" ||
        params[key]?.govtIDType === "voter"
      ) {
        if (this.frontSide.current !== null && this.backSide.current !== null) {
          this.props
            .mergePDFs(
              this.frontSide.current.output("arraybuffer"),
              this.backSide.current.output("arraybuffer")
            )
            .then(async (mergedPdfData) => {
              const blob = new Blob([mergedPdfData], {
                type: "application/pdf",
              });

              formData.append("file", blob, "merged.pdf");
              formData.append("resellerId", getResellerId());
              const response = await JavaApiPostGovtId(
                "enrollment-service/documentUploader/uploadGlobalDocuments",
                formData
              );
              if (response.code == 200) {
                _.set(params, `${key}.main_photo`, response.message);
              }
            })
            .catch((err) => console.log(err));
        }
      } else {
        if (this.frontSide.current !== null) {
          const blob = new Blob([this.frontSide.current], {
            type: "application/pdf",
          });

          formData.append("file", blob, "merged.pdf");
          formData.append("resellerId", getResellerId());

          const response = await JavaApiPostGovtId(
            "enrollment-service/documentUploader/uploadGlobalDocuments",
            formData
          );
          if (response.code == 200) {
            _.set(params, `${key}.main_photo`, response.message);
          }
        }
      }
      this.setState({ params: params }, () => {
        this.props.onChange(this.state.params);
      });
    }
  };
}

export default FamilyDetails;
