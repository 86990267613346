import React, { useCallback, useEffect, useState } from "react";
import Button from "../../components/Button";
import { ApiGet } from "../../lib/AppHelper";
import { UserCard } from "../UserCard";
import { AppStore } from "../../lib/AppStore";
import { useSelector } from "react-redux";

export const SelectSubPolicyForm = ({
  step,
  members,
  setStep,
  setValue,
  onSubmit,
  value,
  field,
  state,
  subValue,
  subField,
  isMobile,
}) => {
  const loading = useSelector((state) => state.loading);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (state.memberId && state.policyId) {
      AppStore.dispatch({ type: "LOADING", loading: true });
      getSubPolicies();
    }
  }, [state.memberId, state.policyId]);
  const getSubPolicies = async () => {
    await ApiGet("member-subpolicy", {
      member_id: state.memberId,
      policy_id: state.policyId,
    })
      .then((res) => {
        setData(res.data);
      })
      .finally(() => {
        AppStore.dispatch({ type: "LOADING", loading: false });
      });
  };

  const [error, setError] = useState(null);
  const handleRadioChange = useCallback(
    (event) => {
      const item = event.target.value;
      let checkedValue = Number(item["sub_policy_id"]);
      let subCheckedValue = Number(item["sub_product_id"]);
      let productSpecification = item["product_specification"];
      let productSpecificationStatus = item["product_specification_status"];
      let max_claim_amount = item["max_claim_amount"];

      setValue({
        [field]: [checkedValue],
        [subField]: [subCheckedValue],
        productSpecification: productSpecification,
        productSpecificationStatus: productSpecificationStatus,
        maxClaimAmount: max_claim_amount,
      });
    },
    [setValue, value]
  );
  const onClick = () => {
    if (value.length) {
      setError(false);
      return onSubmit();
    } else {
      setError("Please select ");
    }
  };

  return loading ? (
    <div
      style={{ height: "200px", display: "grid", placeItems: "center" }}
    ></div>
  ) : data && data.length ? (
    <div style={{ width: isMobile ? "100%" : "40%" }}>
      <h4>Please select the Sub Policy</h4>
      {data.map((item) => (
        <UserCard
          key={item}
          item={item}
          valueField={"sub_policy_id"}
          labelField={"name"}
          selectedValue={value}
          handleRadioChange={handleRadioChange}
        />
      ))}
      <div style={{ color: "red", marginLeft: 20, marginRight: 20 }}>
        {error}
      </div>

      <Button
        attrs={{
          //   ref: this.BasicBtn,
          type: "button",
          onClick,
        }}
        options={members}
        size="md"
        className={"mt-5"}
        title={"Continue"}
      />
    </div>
  ) : (
    <h4>Member don't have any sub policy. Please avail any subpolicy first</h4>
  );
};
