import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import InputLabel from "./InputLabel";
import { DatePicker } from "rsuite";

export function InputDate({
  loading = false,
  requiredMark = "",
  starMark = false,
  label = "",
  maxDate = "",
  minDate = "",
  labelLink = null,
  placeholder = "",
  attrs = {},
  onEnter = () => {},
  error = "",
  isError = false,
  validateError,
  valdiateOnChange,
  formate = "dd-MM-yyyy",
}) {
  const [showError, setShowError] = useState(isError);
  let loading_disabled = loading ? { disabled: true } : {};

  const handleOnBlur = () => {
    attrs.onBlur && attrs.onBlur();
    if (requiredMark && !attrs.value) {
      setShowError(true);
    } else if (validateError && typeof validateError === "function") {
      const err = validateError(attrs.value);
      if (err) {
        setShowError(err);
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false);
    }
  };

  const handleOnChange = (e) => {
    attrs.onChange && attrs.onChange(e);
    if (valdiateOnChange) {
      if (requiredMark && !attrs.value) {
        setShowError(true);
      } else if (validateError && typeof validateError === "function") {
        const err = validateError(attrs.value);
        if (err) {
          setShowError(err);
        } else {
          setShowError(false);
        }
      } else {
        setShowError(false);
      }
    }
  };

  useEffect(() => {
    setShowError(isError);
  }, [isError]);

  return (
    <div className="mb-5">
      <InputLabel
        label={label}
        labelLink={labelLink}
        requiredMark={requiredMark}
        starMark={starMark}
      />

      <div className="mt-2 relative">
        <div className="flex">
          <DatePicker
            disabledDate={(date) => {
              if (maxDate && maxDate < date) {
                return true;
              }
              if (minDate && minDate > date) {
                return true;
              }
              return false;
            }}
            // defaultValue={new Date(value)}
            className={[
              `focus:ring-0 rounded-xl shadow-sm focus:border-primary focus:bg-primary-50 block w-full $ text-base border-gray-300 disabled:bg-gray-100`,
              attrs?.readOnly ? "border-primary bg-primary-50" : "",
            ].join(" ")}
            placeholder={placeholder}
            onKeyPress={(e) => e.key === "Enter" && onEnter()}
            {...attrs}
            {...loading_disabled}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            cleanable={false}
            format={formate}
          />
        </div>
      </div>
      {error ? (
        <p className="mt-2 text-xs text-red-500">{error.join(" ")}</p>
      ) : (
        false
      )}
      {showError ? (
        <p className="mt-2 text-xs text-red-500">
          {typeof showError === "string" ? showError : "This field is required"}
        </p>
      ) : (
        false
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return { loading: loading };
};

export default connect(mapStateToProps)((props) => {
  return <InputDate {...props} />;
});
