import React, { Component } from "react";
import { Pagination } from "rsuite";

export class ErrorDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      limit: 10,
    };
  }
  render() {
    const { data } = this.props;
    const totalData = parseInt(data.length);
    return (
      <>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="text-center">
                <th className="px-6 py-3" scope="col">
                  <strong>Header</strong>
                </th>
                <th className="px-6 py-3" scope="col">
                  <strong>Index Number</strong>
                </th>
                <th className="px-6 py-3" scope="col">
                  <strong>Mismatch Data</strong>
                </th>
                <th className="px-6 py-3" scope="col">
                  <strong>Error Message</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length <= 0 ? (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center">
                  <td colSpan={4} className="px-6 py-4">
                    No Data Found
                  </td>
                </tr>
              ) : null}
              {data.map((elem, index) => {
                if (
                  index >= (this.state.activePage - 1) * this.state.limit &&
                  index < this.state.activePage * this.state.limit
                ) {
                  return (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 text-center"
                      key={index}
                    >
                      <td className="px-6 py-4">{elem.header}</td>
                      <td className="px-6 py-4">{elem.index}</td>
                      <td className="px-6 py-4 underline red">{`${elem.data}`}</td>
                      <td className="px-6 py-4">{elem.err}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>

          <Pagination
            total={totalData}
            limit={this.state.limit}
            activePage={this.state.activePage}
            prev
            last
            next
            first
            maxButtons={5}
            onChangePage={(page) => {
              this.setState({ activePage: page });
            }}
            className="m-2 relative float-right mr-5"
          />
        </div>
      </>
    );
  }
}

export default ErrorDataTable;
