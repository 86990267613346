import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import { JavaApiPost, getResellerId } from "../../lib/AppHelper";
import { LinearProgress, Typography } from "@mui/material";
import Main from "../../screens/Main";
import { AppBackButton } from "../AppComponents";
import { useHistory, useLocation } from "react-router-dom";
import { useRef } from "react";

const ExcelUploadPolicyReport = (props) => {
  const location = useLocation();
  const ref = useRef(null);
  const renderComponent = location?.state && location?.state?.componentTo;
  const MessageRef = React.useRef();
  const history = useHistory();
  const [filters, setFilters] = useState({
    // status: "",
    // startDate: moment().format("YYYY-MM-DD"),
    // endDate: moment().format("YYYY-MM-DD"),
    page: 0,
    limit: 10,
  });
  const [totalCount, setTotalCount] = useState(10);
  const [uploadsRecords, setUploadsRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [onGoingUploads, setOnGoingUploads] = useState([]);

  // const filterValue = [
  //   { label: "Success", value: "success" },
  //   { label: "Failed", value: "failed" },
  // ];

  const header = [
    { label: "File Name", dataKey: "fileName" },
    // {
    //   label: "Policy Status",
    //   dataKey: "status",
    //   cell: (row) => {
    //     if (row?.status?.toUpperCase() === "PENDING")
    //       return (
    //         <LinearProgress
    //           color="success"
    //           size={40}
    //           thickness={4}
    //           style={{ height: "10px", borderRadius: "10px" }}
    //         />
    //       );
    //     else return <p>{row.status}</p>;
    //   },
    // },
    {
      label: "Mail Status",
      dataKey: "realTimeStatus",
      cell: (row) => {
        if (row?.realTimeStatus?.toUpperCase() === "PENDING")
          return (
            <div style={{ position: "relative" }}>
              <LinearProgress
                color="error"
                size={40}
                thickness={4}
                style={{ height: "17px", borderRadius: "10px" }}
              />
              <Typography
                style={{
                  position: "absolute",
                  color: "white",
                  top: -3,
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: 14,
                }}
              >
                Processing
              </Typography>
            </div>
          );
        else return <p>{row?.realTimeStatus}</p>;
      },
    },
    { label: "Successful", dataKey: "successCount" },
    { label: "Failed", dataKey: "failCount" },
    {
      label: "Uploaded On",
      dataKey: "createdOn",
      type: "date",
      format: "DD-MM-YYYY",
    },
    { label: "Uploaded By", dataKey: "resellerName" },
  ];

  const fetchUploadedRecord = async () => {
    !ref.current && setLoading(true);
    const resellerId = getResellerId();
    const data = {
      ...filters,
      resellerId: resellerId,
    };
    const res = await JavaApiPost(
      "enrollment-service/uploader/getExcelUploaderHistoryData",
      data
    );
    if (res?.responseObject) {
      setUploadsRecords([...res.responseObject]);
      setTotalCount(res.count);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // const fetchOngoingRecord = async () => {
  //   const resellerId = getResellerId();
  //   const data = {
  //     resellerId: resellerId,
  //     page: 0,
  //     limit: 4,
  //     status: "",
  //   };
  //   const res = await JavaApiPost(
  //     `enrollment-service/uploader/getExcelInProgressUploaderDetails`,
  //     data
  //   );
  //   if (res?.responseObject) {
  //     setOnGoingUploads([...res.responseObject]);
  //   } else {
  //     setOnGoingUploads([]);
  //   }
  // };

  useEffect(() => {
    let timer;
    ref.current = true;
    clearInterval(timer);
    fetchUploadedRecord();
    // fetchOngoingRecord();
    timer = setInterval(() => {
      // fetchOngoingRecord();
      fetchUploadedRecord();
    }, 5000);
    return () => {
      clearInterval(timer);
      ref.current = false;
    };
  }, [filters]);

  // const onFilterChange = (e) => {
  //   const value = e.target.value;
  //   setFilters({
  //     ...filters,
  //     status: value,
  //   });
  // };

  // const onChangeHandlerDate = (e, key) => {
  //   const value = moment(e).format("YYYY-MM-DD");
  //   setFilters({
  //     ...filters,
  //     [key]: value,
  //   });
  // };

  const onPageHandler = (page) => {
    setFilters({
      ...filters,
      page: page - 1,
    });
  };
  return (
    <Main MessageRef={MessageRef}>
      <AppBackButton to="excel-uploader" />
      {/* {onGoingUploads.length > 0 ? (
        <div className="border-2 p-1">
          <div className="p-2 h-12 flex items-center text-white bg-primary">
            Current Ongoing Uploads
          </div>
          <div className="mt-4 p-4">
            {onGoingUploads.map((item, index) => {
              return (
                <div key={index} className="mt-2">
                  <CustomProgressBar>
                    <div className="w-full p-1 h-8 flex justify-between">
                      <p>{item.fileName}</p>
                      <p>
                        {item.createdOn &&
                          moment(item.createdOn).format("DD-MM-YYYY")}
                      </p>
                      <p>{item.realTimeStatus}</p>
                    </div>
                  </CustomProgressBar>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        false
      )} */}

      <div className="flex flex-row-reverse flex-end mt-10">
        {/* <div className="flex gap-4">
          <InputDate
            label="From"
            attrs={{
              onChange: (e) => onChangeHandlerDate(e, "startDate"),
              value: new Date(filters.startDate),
            }}
          />
          <InputDate
            label="To"
            attrs={{
              onChange: (e) => onChangeHandlerDate(e, "endDate"),
              value: new Date(filters.endDate),
            }}
          />
        </div> */}
        <button
          className="bg-primary-500 text-white p-2 rounded-xl mt-10"
          onClick={() => {
            history.push(renderComponent);
          }}
        >
          Upload Excel
        </button>
      </div>
      <Table
        data={uploadsRecords}
        headers={header}
        page={filters.page + 1}
        limit={filters.limit}
        total={totalCount}
        onPageChange={onPageHandler}
        loader={loading}
      />
    </Main>
  );
};

export default ExcelUploadPolicyReport;
