import React, { memo, useEffect, useState } from "react";
import "rsuite-table/lib/less/index.less";
import { Pagination } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Link } from "react-router-dom";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { AppStore } from "../../../lib/AppStore";

const PaginationData = memo(
  ({
    request,
    data,
    limit = 15,
    pagination = true,
    filterValues = {},
    loading,
    endPoint,
    extraData,
    renderItem,
    dataType,
    handleGetSearch,
    pageType = "",
    permissions,
  }) => {
    if (limit < 1) {
      limit = 1;
    }
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [tableData, setTableData] = useState(data);
    const [errorMessage, setErrorMessage] = useState();
    useEffect(() => {
      const start = limit * (page - 1);

      if (!data) {
        (async () => {
          setErrorMessage("");
          try {
            AppStore.dispatch({ type: "LOADING", loading: true });
            const res = await request(endPoint, {
              start,
              limit,
              page: filterValues?.loan_code ? 1 : page,
              ...filterValues,
            });

            if (res.status == "error") {
              setErrorMessage(res.message);
            } else {
              if (res.data.total) {
                setTotalCount(res.data.total);
              }
              handleGetSearch && handleGetSearch(res.data.total);
              if (res.data.results) {
                setTableData(res.data.results);
              } else {
                setTableData(res.data);
              }
            }
          } catch (err) {
          } finally {
            AppStore.dispatch({ type: "LOADING", loading: false });
          }
        })();
      } else {
        const newData = [...data];
        const update = newData.slice(start, limit * page);
        setTotalCount(data?.length);
        setTableData(update);
      }
    }, [setTableData, setTotalCount, request, page, data, filterValues]);
    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        className="mt-2"
      >
        {tableData && tableData?.length === 0 ? (
          <div className="text-center">
            <p className="text-xl">You do not have any item</p>
          </div>
        ) : (
          <div className="flex flex-wrap justify-center md:justify-between lg:justify-center lg:-ml-10">
            {tableData &&
              tableData.length >= 0 &&
              tableData.map((item, index) => {
                if (renderItem) {
                  return renderItem(item);
                }
                return (
                  <div
                    className="my-4 px-1 w-80 md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3"
                    key={index}
                  >
                    <article
                      className={` overflow-hidden rounded-lg shadow-lg ${
                        moment(
                          item.end_date ? item.end_date : item.application_date
                        ).isBefore()
                          ? "bg-red-100"
                          : ""
                      }`}
                    >
                      <Link to={`/subscription/${item.application_id}`}>
                        <header className="flex items-center justify-between leading-tight pl-4">
                          <h1 className="text-lg font-bold capitalize pt-2">
                            {item.customer_name
                              ? item.customer_name
                              : item.name}
                          </h1>
                          {pageType == "leads" ? (
                            <div className="pr-3">
                              <span className="">
                                {moment(
                                  item.start_date
                                    ? item.start_date
                                    : item.application_date
                                ).format("DD, MMM YYYY")}
                              </span>
                            </div>
                          ) : (
                            <p className="text-grey-darker text-sm">
                              <div className="pr-3">
                                <span className="text-green-600">
                                  {moment(
                                    item.start_date
                                      ? item.start_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>{" "}
                                <br></br>
                                <span className="text-red-600">
                                  {" "}
                                  {moment(
                                    item.end_date
                                      ? item.end_date
                                      : item.application_date
                                  ).format("DD, MMM YYYY")}
                                </span>
                              </div>
                            </p>
                          )}
                        </header>

                        <body
                          className={`flex items-center justify-between leading-tight p-4  ${
                            item.stage != "DRAFT" ? "pt-4" : "md:p-3"
                          }`}
                        >
                          <div className="text-sm text-clip overflow-hidden ...">
                            {/* {item.product_name} */}
                            {item?.gov_id_type && item?.gov_id_number && (
                              <p
                                style={{
                                  marginBottom: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    marginRight: "10px",
                                  }}
                                >
                                  {item?.gov_id_type ? item?.gov_id_type : ""}
                                </span>
                                {" : "}
                                {item?.gov_id_number.length > 9
                                  ? new Array(
                                      item?.gov_id_number?.length - 3
                                    ).join("x") +
                                    item?.gov_id_number?.substr(
                                      item?.gov_id_number?.length - 4,
                                      4
                                    )
                                  : item?.gov_id_number}
                              </p>
                            )}
                            <p
                              style={{
                                marginBottom: "10px",
                                paddingRight: "16px",
                                width: "250px",
                              }}
                            >
                              {item.product_display_name
                                ? item.product_display_name
                                : item?.product_name}
                            </p>
                            <p>{item?.loan_code}</p>
                            {permissions &&
                            permissions?.show_reseller_name_on_member_card
                              ? item?.reseller_name && (
                                  <p>Reseller Name : {item?.reseller_name}</p>
                                )
                              : null}
                          </div>

                          <a
                            className="flex items-center no-underline text-black"
                            href={`tel:+91${item.mobile}`}
                          >
                            <p
                              className="ml-2 text-sm"
                              style={
                                item.stage == "DRAFT" ? { color: "#fff" } : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPhoneSquare}
                                className={
                                  item.stage == "DRAFT"
                                    ? "text-strike"
                                    : "text-primary"
                                }
                              />
                              <span> {item.mobile}</span>
                            </p>
                          </a>
                        </body>
                      </Link>
                    </article>
                  </div>
                );
              })}
          </div>
        )}
        {pagination && tableData && tableData?.length > 0 ? (
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={4}
              size="xs"
              layout={["total", "-", "pager", "skip"]}
              total={totalCount}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        ) : null}
      </div>
    );
  }
);

export default PaginationData;
