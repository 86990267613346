import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import useMobileDetect from "../useMobileDetect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

export default function PdfViewer({ myFile, pdfData }) {
  const [numPages, setNumPages] = useState(null);
  const { isMobile } = useMobileDetect();
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={pdfData ? { data: pdfData } : myFile}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={
        <div className="h-32 w-32 flex justify-center items-center">
          <CircularProgress />
        </div>
      }
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page
          scale={isMobile() ? 0.4 : 1.4}
          key={`page_${index + 1}`}
          pageNumber={index + 1}
        />
      ))}
    </Document>
  );
}
