import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AppBackButton } from "../../components/AppComponents";
import { AppStore } from "../../lib/AppStore";
import Main from "../Main";
import { ApiGet, CLAIM_STATUS, downloadExcelFile } from "../../lib/AppHelper";
import moment from "moment";
import { InputSelect } from "../../components/form/InputSelect";
import Button from "../../components/Button";
import InputDate from "../../components/form/InputDate";
import { Pagination } from "rsuite";
import { RefreshIcon } from "@heroicons/react/solid";

export class ResellerClaimReport extends Component {
  MessageRef = React.createRef();
  state = {
    total: 0,
    limit: 10,
    layout: ["total", "-", "pager", "skip"],
    reports: [],
    params: {
      from: moment().format("YYYY-MM-DD"),
      to: moment().format("YYYY-MM-DD"),
      status: "",
      page: 1,
      count: 10,
      employee_id: "",
    },
    employees: [],
    toMaxDate: new Date(),
  };

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  onChangeHandlerDate = (e, key) => {
    const { params } = this.state;
    if (key === "from") {
      let date = new Date(e).toISOString().split("T")[0];
      const date1 = new Date(date);
      const date2 = new Date(params.to);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 31) {
        let todate = new Date(moment(date1).add(30, "d"))
          .toISOString()
          .split("T")[0];
        params["to"] = todate;
        let todateNew = new Date(todate);
        this.setState({ toMaxDate: todateNew });
      }
    }
    params[key] = new Date(e).toISOString().split("T")[0];
    this.setState({ params: params });
  };

  handleClickReset = () => {
    const { params } = this.state;
    params["to"] = moment().format("YYYY-MM-DD");
    params["from"] = moment().format("YYYY-MM-DD");
    params["employee_id"] = "";
    params["status"] = "";
    this.setState({ toMaxDate: new Date() });
    this.setState({ params: params });
  };

  render() {
    const { params, reports, employees, toMaxDate } = this.state;
    const { user } = this.props;
    const { permissions } = user;
    return (
      <Main MessageRef={this.MessageRef}>
        {permissions && permissions?.claim_report_view ? (
          <>
            <div className="grid md:grid-cols-2 gap-2">
              <div>
                <AppBackButton to="/">Back</AppBackButton>
              </div>
              <div className="text-right">
                <div style={{ maxWidth: "30%" }} className="float-right">
                  <Button
                    attrs={{ type: "button", onClick: this.downloadClick }}
                    size="md"
                    title={"Download"}
                  />
                </div>
              </div>
            </div>

            <div className="grid md:grid-cols-5 gap-4">
              <div>
                <label className="flex text-sm text-gray-700 justify-left pb-2">
                  Select Team
                </label>
                <select
                  onChange={(e) => this.onChangeHandler(e, "employee_id")}
                  value={params.employee_id}
                  className="focus:ring-0 focus:border-primary focus:bg-primary-50 block w-full px-4 text-base border-gray-300 rounded-xl disabled:bg-gray-100"
                >
                  <option value="">Me</option>
                  {employees.map((option, index) => {
                    return (
                      <option value={option.id} key={option.id}>
                        {option.name} ({option.mobile})
                      </option>
                    );
                  })}
                </select>
              </div>
              <div>
                <InputSelect
                  requiredMark=""
                  value={params.status}
                  attrs={{
                    value: params.status,
                    onChange: (e) => this.onChangeHandler(e, "status"),
                  }}
                  name="status"
                  label="Claim Type"
                  options={CLAIM_STATUS}
                />
              </div>
              <div>
                <InputDate
                  label="From"
                  value={params.from}
                  placeholder="from"
                  maxDate={new Date(params.to)}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "from"),
                    value: new Date(params.from),
                  }}
                />
              </div>
              <div>
                <InputDate
                  label="To"
                  placeholder="To"
                  minDate={new Date(params.from)}
                  maxDate={new Date()}
                  attrs={{
                    onChange: (e) => this.onChangeHandlerDate(e, "to"),
                    value: new Date(params.to),
                  }}
                />
              </div>

              <div
                className="flex flex-row"
                style={{ maxWidth: "50%", paddingTop: "22px" }}
              >
                <button
                  onClick={this.handleClickReset}
                  className="text-white mb-6 hover:bg-primary-700 bg-red-500 border-0 rounded-lg"
                >
                  <RefreshIcon width={25} className="m-1" />
                </button>

                <Button
                  className="ml-5"
                  attrs={{ type: "button", onClick: this.handleClick }}
                  size="md"
                  title={"Search"}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="overflow-x-auto">
                    <table className="min-w-full border" id="table-to-xls">
                      <thead className="border-b">
                        <tr>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Policy Start Date
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Claim Register Date
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Product
                          </th>
                          <th className="text-sm font-large px-6 py-4 whitespace-nowrap text-left">
                            Name
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Total Days
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Claim Amount
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Approved Amount
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            UTR Number
                          </th>
                          <th className="text-sm  font-large px-6 py-4 whitespace-nowrap text-left">
                            Status
                          </th>
                        </tr>
                      </thead>
                      {reports.map((record, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              className={`border-b ${
                                record.internal_status == "SETTELED" ||
                                record.actual_status == "SETTELED" ||
                                record.actual_status == "SETTELED"
                                  ? "bg-green-100"
                                  : ""
                              }
                          ${
                            record.internal_status == "REJECT"
                              ? "bg-red-100"
                              : ""
                          }
                          ${
                            record.external_status == "QUERY"
                              ? "bg-yellow-100"
                              : ""
                          }`}
                              key={index}
                            >
                              <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                {record?.policy_start_date &&
                                  moment(record.policy_start_date).format(
                                    "DD, MMM YYYY"
                                  )}
                              </td>
                              <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                                {record?.claim_register_date &&
                                  moment(record.claim_register_date).format(
                                    "DD, MMM YYYY"
                                  )}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.product_display_name
                                  ? record.product_display_name
                                  : record.product_name}
                              </td>
                              <td className="text-sm capitalize text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.customer_name}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.eligible_days}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.claim_amount}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.approved_amount}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.transaction_number
                                  ? record.transaction_number
                                  : "NA"}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {record.external_status
                                  ? record.external_status
                                  : record.internal_status}
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                      {reports.length === 0 ? (
                        <tr>
                          <td
                            colSpan="9"
                            height="50"
                            className="text-red-500 font-light text-center"
                          >
                            Your result is empty
                          </td>
                        </tr>
                      ) : (
                        false
                      )}
                    </table>
                    <div style={{ padding: 20 }}>
                      <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={2}
                        size="xs"
                        layout={this.state.layout}
                        total={this.state.total}
                        limit={this.state.limit}
                        activePage={this.state.params.page}
                        onChangePage={this.onPageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div>
            <AppBackButton to="/">Back</AppBackButton>
            <div className="text-red-500">
              <h4>You do not have permission to view Claim Report</h4>
            </div>
          </div>
        )}
      </Main>
    );
  }

  componentDidMount() {
    this.getReports();
    this.getTeam();
  }

  handleClick = () => {
    this.onPageChange(1);
  };

  onPageChange = (page) => {
    if (page === false) return false;
    this.setState({ params: { ...this.state.params, page: page } }, () => {
      this.getReports(this.state.params);
    });
  };

  getTeam = async () => {
    const response = await ApiGet(`team`);
    if (response.status === "success") {
      this.setState({ employees: response.data });
    }
  };

  downloadClick = async () => {
    let payload = {
      from: this.state.params.from,
      to: this.state.params.to,
      reportType: "download",
      status: this.state.params.status,
      employee_id: this.state.params.employee_id,
    };
    AppStore.dispatch({ type: "LOADING", loading: true });
    await downloadExcelFile(
      "report/reseller-claims",
      payload,
      "claimReport.xlsx"
    );
    AppStore.dispatch({ type: "LOADING", loading: false });
  };

  getReports = async (request) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    const response = await ApiGet("report/reseller-claims", request);
    if (response.status === "success") {
      if (response.data.data) {
        const items = Object.values(response.data.data);
        this.setState({ reports: items });
        this.setState({ count: response.data });
        this.setState({ total: response.data.total });
      } else {
        this.state.reports = [];
      }
    }
    AppStore.dispatch({ type: "LOADING", loading: false });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

const ResellerClaimReportConnect = connect(mapStateToProps)((props) => {
  return <ResellerClaimReport {...props} />;
});

export default withRouter(ResellerClaimReportConnect);
