import React, { Component } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import AppMenu from "../components/AppMenu";
import Logo from "../components/Logo";
import UserMenu from "../components/UserMenu";
import AppSpinner from "../components/AppSpinner";
import { connect } from "react-redux";
import { AppMessage } from "../components/AppMessage";
import { Link, withRouter } from "react-router-dom";
import Button from "../components/Button";
import { ApiGet, AppLogout, getError, getMessage } from "../lib/AppHelper";
import { AppStore } from "../lib/AppStore";
import SimpleBackdrop from "../components/common/Backdrop";
import useOnlineStatus from "@rehooks/online-status";

function OnlineStatus() {
  const onlineStatus = useOnlineStatus();
  return (
    !onlineStatus && (
      <div
        className={`focus:outline-none focus:ring-0 relative py-3 pl-4 pr-10 leading-normal 
              ${onlineStatus ? "text-green-700" : "text-primary-700"} ${
          onlineStatus ? "bg-green-100" : "bg-primary-100"
        }`}
      >
        <h1>You are currently {onlineStatus ? "online" : "offline"}</h1>
      </div>
    )
  );
}

export class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisiable: false,
    };
    this.handleDragEnter = this.handleDragEnter.bind(this);
  }
  loadingRef = React.createRef();

  handleDragEnter(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.current === 1)
      AppStore.dispatch({ type: "BACKDROP", backdrop: true });
  }

  render() {
    const {
      loading,
      progress,
      MessageRef = null,
      user,
      onDrop,
      onDragOver,
      onDragLeave,
      current,
    } = this.props;
    const mainStyle = loading ? { pointerEvents: "none" } : {};

    return (
      <>
        {this.props.backdrop && current === 1 && (
          <SimpleBackdrop
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
          />
        )}
        <div style={{ height: "100vh" }} onDragEnter={this.handleDragEnter}>
          <OnlineStatus />
          {loading ? (
            <div
              tabIndex={0}
              ref={this.loadingRef}
              className="fixed w-screen h-screen bg-primary-50 z-50 opacity-60 flex items-center justify-center"
            >
              <AppSpinner />
            </div>
          ) : (
            false
          )}
          {MessageRef !== null ? <AppMessage ref={MessageRef} /> : false}
          <div style={mainStyle}>
            <Disclosure as="nav" className="border-b border-gray-400">
              {({ open }) => (
                <>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          <Link to="/">
                            <Logo />
                          </Link>
                        </div>
                        <AppMenu />
                      </div>
                      <div className="invisible md:visible md:flex">
                        <div className="flex">
                          <div className="ml-4 flex items-center md:ml-6 flex-1">
                            {user?.status === 1 &&
                            user.basic &&
                            user.banking ? (
                              <UserMenu />
                            ) : user ? (
                              <Button
                                attrs={{ onClick: () => AppLogout() }}
                                title="Logout"
                              />
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="bg-primary inline-flex items-center justify-center p-2 rounded-xl text-white hover:text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-200 focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>
                  <Disclosure.Panel className="md:hidden">
                    <AppMenu mobile={true} />
                    {user?.status ? (
                      <UserMenu mobile={true} />
                    ) : user ? (
                      <Button
                        attrs={{ onClick: () => AppLogout() }}
                        title="Logout"
                      />
                    ) : (
                      false
                    )}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            {this.props.history?.location?.pathname === "/subscribe" && (
              <div
                className="h-1 rounded-xl bg-primary transition-all border-primary"
                style={{ width: `${progress}%` }}
              ></div>
            )}

            <main>
              <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
                {/* Replace with your content */}
                <div className="px-4 py-6 sm:px-0">{this.props.children}</div>
                {/* /End replace */}
              </div>
            </main>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    this.getProducts();
    this.checkMessages();
  }

  componentDidUpdate(prevProp, prevState) {
    if (prevProp.loading !== this.props.loading && this.props.loading) {
      this.loadingRef.current.focus();
    }
  }

  getProducts = async () => {
    const { user, products } = this.props;
    if (user === null) return;
    if (user?.status !== 1 || products?.length > 0) return;
    const response = await ApiGet("products");
    if (response.status === "success") {
      AppStore.dispatch({
        type: "PRODUCTS",
        products: response.data,
        productLoading: false,
      });
    }
  };

  checkMessages = async () => {
    const error = await getError();
    if (error && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(error, "error");
    }

    const message = await getMessage();
    if (message && this.props.MessageRef?.current) {
      this.props.MessageRef?.current.show(message, "success");
    }
  };
}

const mapStateToProps = (state) => {
  const { loading, productLoading, user, progress, products, backdrop } = state;
  return {
    loading: loading,
    productLoading: productLoading,
    user: user,
    progress: progress,
    products: products,
    backdrop: backdrop,
  };
};

const MainConnect = connect(mapStateToProps)((props) => {
  return <Main {...props} />;
});

export default withRouter(MainConnect);
