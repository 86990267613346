import React, { useEffect, useState } from "react";
import { ApiGet, AppLogout } from "../../../lib/AppHelper";
import Main from "../../Main";
import { AppRow } from "../../../components/AppComponents";
import ProfileSidebar from "../ProfileSidebar";
import Button from "../../../components/Button";
import Chrome from "./SessionImages/chrome.png";
import Mobile from "./SessionImages/mobile.png";
import Windows from "./SessionImages/windows.png";
import Map from "./SessionImages/map.png";
import { Tooltip } from "@mui/material";
import Firefox from "./SessionImages/firefox.png";
import Safari from "./SessionImages/safari.png";
import MicrosoftEdge from "./SessionImages/microsoft.png";
import OtpDialog from "./OtpDialog";
import mac from "./SessionImages/mac.png";
import Android from "./SessionImages/android.png";
import Laptop from "./SessionImages/laptop.png";
import Linux from "./SessionImages/linux.png";
import { toast } from "react-toastify";
import { AppStore } from "../../../lib/AppStore";
import { convertToAMPM } from "../../../helpers/Helper";

const ImageMapping = {
  Chrome: Chrome,
  Edge: MicrosoftEdge,
  Safari: Safari,
  Firefox: Firefox,
  Windows: Windows,
  "OS X": mac,
  iOS: mac,
  AndroidOS: Android,
  desktop: Laptop,
  phone: Mobile,
  Linux: Linux,
};
const ActiveSession = () => {
  const [devices, setDevices] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [open, setOpen] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllDevice = async () => {
    const response = await ApiGet(`login-session`);
    setDevices(response?.data);
    const updatedLocationData = await Promise.all(
      response?.data.map(async (item) => {
        return await getLocation(item.login_latitude, item.login_longitude);
      })
    );
    setLocationData(updatedLocationData);
  };

  useEffect(() => {
    getAllDevice();
  }, []);

  const getLocation = async (lat, long) => {
    if (!lat && !long) return [];
    const res = await fetch(
      `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${long}&key=860f54eff55a4015b91e0cbac8519c8b`
    );
    const data = await res.json();
    return data.results[0];
  };

  const showAddress = (lat, long) => {
    if (!lat && !long) return;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
      "popUpWindow",
      "height=500,width=1000,left=50,top=50,resizable=yes,scrollbars=yes,menubar=no,location=no,directories=no, status=yes"
    );
  };
  const callApi = async (item) => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    setLoading(true);
    const response = await ApiGet(`auth-otp`);
    if (response.status === "success") {
      AppStore.dispatch({ type: "LOADING", loading: false });
      setLoading(false);
      setOpen(item);
      toast.success(response.message);
    }
  };

  return (
    <Main>
      <AppRow>
        <div className="flex-1 max-w-min mr-5">
          <ProfileSidebar />
        </div>
        <div className="flex-auto">
          <table className="min-w-full ">
            <tbody>
              <tr>
                <td>
                  <table className="min-w-full">
                    <thead className="border-b">
                      <tr className="bg-primary" style={{ color: "white" }}>
                        <th className="text-sm font-large px-3 py-4 whitespace-nowrap text-center">
                          SR.NO
                        </th>
                        <th className="text-sm font-large px-4 py-4 whitespace-nowrap text-center">
                          Browser
                        </th>
                        <th className="text-sm font-large px-4 py-4 whitespace-nowrap text-center">
                          Device
                        </th>
                        <th className="text-sm  font-large px-4 py-4 whitespace-nowrap text-center">
                          OS
                        </th>
                        <th className="text-sm  font-large px-4 py-4 whitespace-nowrap text-left">
                          Location
                        </th>
                        <th className="text-sm  font-large px-4 py-4 whitespace-nowrap text-left">
                          Last Login
                        </th>
                        <th className="text-sm  font-large px-4 py-4 whitespace-nowrap text-center">
                          Revoke
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices?.map((item, index) => {
                        return (
                          <tr className="border-b" key={index}>
                            <td
                              className="text-sm  font-bigger px-6 py-4 whitespace-nowrap"
                              style={{ fontWeight: "bold" }}
                            >
                              {index + 1}
                            </td>
                            <td className="text-sm  font-bigger px-6 py-4 whitespace-nowrap">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  src={ImageMapping[item?.login_browser]}
                                  style={{ height: "30px" }}
                                  alt="chrome"
                                />
                                <span>{item?.login_browser}</span>
                              </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  src={ImageMapping[item?.login_device]}
                                  style={{ height: "30px" }}
                                  alt="phone"
                                />
                                <span style={{ textTransform: "capitalize" }}>
                                  {item?.login_device}
                                </span>
                              </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  src={ImageMapping[item?.login_os]}
                                  style={{ height: "30px" }}
                                  alt="Windows"
                                />
                                <span>{item?.login_os}</span>
                              </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                              <Tooltip
                                title={locationData[index]?.formatted}
                                placement="top"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    cursor: "pointer",
                                    gap: "10px",
                                  }}
                                  onClick={() =>
                                    showAddress(
                                      item.login_latitude,
                                      item.login_longitude
                                    )
                                  }
                                >
                                  <img
                                    src={Map}
                                    style={{ height: "30px" }}
                                    alt="Location"
                                  />
                                  <span>
                                    {locationData[index]?.components
                                      ?.city_district || "Somewhere"}
                                  </span>
                                </div>
                              </Tooltip>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{item?.created_at?.split(" ")[0]}</span>
                                <span>
                                  {convertToAMPM(
                                    item?.created_at?.split(" ")[1]
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className="text-sm text-gray-900 font-light px-4 py-4 whitespace-nowrap">
                              <Button
                                title={
                                  item?.current_device ? "Logout" : "Revoke"
                                }
                                size="sm"
                                outline={true}
                                attrs={{
                                  onClick: () => {
                                    if (item?.current_device) AppLogout();
                                    else {
                                      callApi(item);
                                    }
                                  },
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>

          <OtpDialog
            open={open}
            setOpen={setOpen}
            callApi={callApi}
            getAllDevice={getAllDevice}
          />
        </div>
      </AppRow>
    </Main>
  );
};

export default ActiveSession;
