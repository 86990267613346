import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { lte, range } from "lodash";
import { Tooltip, Zoom } from "@mui/material";

export const primaryColor = "#DB2228";
export const ASC = "ASC";
export const DESC = "DESC";
export const dashboard = "Dashboard";
export const comparison = "Comparison";

export const TotalSale = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Yesterday",
    value: "yesterday",
  },
  {
    label: "This week",
    value: "thisWeek",
  },
  {
    label: "This month",
    value: "thisMonth",
  },
  {
    label: "This Year",
    value: "thisYear",
  },
  {
    label: "Previous Week",
    value: "previousWeek",
  },
  {
    label: "Previous Month",
    value: "previousMonth",
  },
  {
    label: "Previous Year",
    value: "previousYear",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export const ProductType = (data = []) => {
  if (data?.length === 0) return;
  let result = Object.keys(data?.productType)?.map((key) => ({
    label: key?.toLowerCase(),
    value: data?.productType[key],
  }));
  return result;
};

export const tenureType = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => ({
    label: `${item?.tenure} ${item?.tenureType}`,
    value: `${item?.tenure}-${item?.tenureType}`,
  }));
  return result;
};

export const salesPersonList = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => ({
    label: item?.name,
    value: item.id,
  }));
  return result;
};

export const allProductValue = (data) => {
  if (data?.length === 0) return;
  let result = data?.map((item) => item.value);

  return result;
};

export const ViewData = (data) => {
  if (data?.length === 0) return;
  let result = Object.keys(data?.viewedBy)?.map((key) => ({
    label: key?.toLowerCase().replace(" by", " date"),
    value: data?.viewedBy[key],
  }));
  return result;
};

export const ResellerType = [
  { label: "Silver", value: "SILVER" },
  { label: "Gold", value: "GOLD" },
  { label: "Platinum", value: "PLATINUM" },
];
export const PaymentPartner = [
  {
    label: "Hospido",
    value: "HOSPIDO",
  },
  {
    label: "Doc",
    value: "DOC",
  },
];
export const reportType = [
  {
    label: "YEAR",
    value: "year",
  },
  {
    label: "MONTH",
    value: "month",
  },
  {
    label: "WEEKLY",
    value: "week",
  },
  {
    label: "DAYS",
    value: "day",
  },
];
const monthsLabel = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const WeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label);

  const formattedStartDate = startDate.format("YYYY-MM-DD");
  return [formattedStartDate, currentDate.format("YYYY-MM-DD")];
};

export const LastWeekDates = (label) => {
  const currentDate = moment();

  const startDate = currentDate.clone().startOf(label).subtract(1, label);

  const endDate = currentDate.clone().startOf(label).subtract(1, "day");
  const formattedStartDate = startDate.format("YYYY-MM-DD");
  const formattedEndDate = endDate.format("YYYY-MM-DD");
  return [formattedStartDate, formattedEndDate];
};

export const findLabelByValue = (data, inputValue, key, Value = "value") => {
  if (data?.length === 0 || inputValue === "") return;
  const result = data?.find((item) => item[Value] === inputValue);
  return result ? result[key] : null;
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};
export const downloadExcel = (data) => {
  if (data?.length === 0) return;
  const ws = XLSX.utils.json_to_sheet(data);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const headers = Object.keys(data[0]);
  headers?.forEach((header, index) => {
    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    ws[cellAddress].v = header.toUpperCase();
    ws[cellAddress].s = {
      fill: { bgColor: { indexed: 64, rgb: "FFFF00" } },
      font: { bold: true, color: { rgb: "FFFFFF" } },
    };
  });

  const excelBlob = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "binary",
  });
  const blob = new Blob([s2ab(excelBlob)], {
    type: "application/octet-stream",
  });
  saveAs(blob, "table_data.xlsx");
};

export const SvgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      viewBox="0 0 384 512"
      class="svgIcon"
    >
      <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
    </svg>
  );
};

export const filterData = (salesData, last90Data) => {
  if (salesData.length === 0 || last90Data.length === 0) return []; // Handling undefined or null inputs

  let data = salesData.filter((item) => {
    return last90Data.some((i) => i?.resellerId === item?.resellerId);
  });

  if (data.length > 0) return data;
};

export const percentageCalculation = (oldSale, newSale) => {
  if (oldSale === 0 || newSale === 0) {
    return percentageIcon("NA", newSale);
  } else if (newSale === 0) {
    return percentageIcon("NA", oldSale);
  } else if (oldSale === newSale) {
    return percentageIcon("equal", oldSale);
  } else {
    const percentageChange = ((newSale - oldSale) / oldSale) * 100;
    if (percentageChange > 5)
      return percentageIcon("increase", percentageChange.toFixed(2));
    else if (percentageChange < -5)
      return percentageIcon(
        "decrease",
        Math.abs(Number(percentageChange).toFixed(2))
      );
    else return percentageIcon("equal", percentageChange.toFixed(2)); // Calculate and return percentage change.
  }
};

export const percentageIcon = (type = "increase", newValue) => {
  if (type === "NA") return <span>N/A</span>;
  else if (type === "equal")
    return (
      <span>
        <TrendingFlatIcon style={{ color: "grey" }} />
        &nbsp;0 %
      </span>
    );
  else if (type === "increase")
    return (
      <span>
        <TrendingUpIcon style={{ color: "green" }} />
        &nbsp;{newValue}%
      </span>
    );
  else if (type === "decrease")
    return (
      <span>
        <TrendingDownIcon style={{ color: "red" }} />
        &nbsp;{newValue}%
      </span>
    );
  else
    return (
      <span>
        <TrendingFlatIcon style={{ color: "grey" }} />
        &nbsp;{newValue}%
      </span>
    );
};

export const convertNumberToShortFormat = (number) => {
  if (number >= 10000000) {
    return (
      <span style={{ fontSize: "larger", color: "#DB2228", fontWeight: "600" }}>
        {(number / 10000000).toFixed(2)} Cr.
      </span>
    );
  } else if (number >= 100000) {
    return (
      <span style={{ fontSize: "larger", color: "blue", fontWeight: "600" }}>
        {(number / 100000).toFixed(2)} L
      </span>
    );
  } else if (number >= 10000) {
    return (
      <span style={{ fontSize: "larger", color: "green", fontWeight: "600" }}>
        {(number / 1000).toFixed(1)} K
      </span>
    );
  } else {
    return <span>{number.toString()}</span>;
  }
};

export const customHeader = (header) => {
  if (!header.includes("-")) return header;
  let newHeader;
  if (header?.split("-")[1]?.length === 2) {
    newHeader = `${header?.split("-")[0]}-${
      monthsLabel[header?.split("-")[1]]
    }`;
  } else {
    newHeader = header;
  }
  return newHeader;
};

export const setFormDate = (type) => {
  let date;
  if (type === "year")
    date = new Date(
      moment().clone().startOf("year").subtract(1, "year").format("YYYY-MM-DD")
    ).getFullYear();
  else if (type === "month")
    date = new Date(
      moment()
        .clone()
        .startOf("month")
        .subtract(11, "month")
        .format("YYYY-MM-DD")
    );
  else if (type === "week")
    date = new Date(moment().subtract(1, "month").format("YYYY-MM-DD"));
  else date = new Date(moment().subtract(6, "days").format("YYYY-MM-DD"));

  return date;
};

export const yearCalendarData = () => {
  return range(2018, moment().add(1, "year").year()).map((y) => ({
    label: y.toString(),
    value: y,
  }));
};

export const dateBlock = (format, date) => {
  const momentDate = moment(date);
  switch (format) {
    case "year":
      return momentDate.add(1, "year").isAfter(moment().subtract(1, "day"))
        ? moment().subtract(1, "day")
        : momentDate;
    case "month":
      return momentDate.add(11, "month").isAfter(moment().subtract(1, "day"))
        ? moment().subtract(1, "day")
        : momentDate.clone().endOf("month");
    case "week":
      return momentDate.add(1, "month").isAfter(moment().subtract(1, "day"))
        ? moment().subtract(1, "day")
        : momentDate;
    default:
      return momentDate.add(6, "days").isAfter(moment().subtract(1, "day"))
        ? moment().subtract(1, "day")
        : momentDate;
  }
};

export const dateFormate = {
  week: "dd/MM/yyyy",
  month: "MMM yyyy",
  year: "MMM yyyy",
  day: "dd/MM/yyyy",
};

export const customTooltip = ({ children, value }) => {
  return (
    <Tooltip
      title={value.toLocaleString("en-IN")}
      placement="top"
      arrow
      TransitionComponent={Zoom}
    >
      {children}
    </Tooltip>
  );
};

export const convertObjectToArray = (obj) => {
  let data = obj[0];
  const header = [];
  const policyCounts = [];
  const policyAmounts = [];

  for (let key in data) {
    header.push(key);
    policyCounts.push(data[key].policyCount);
    policyAmounts.push(data[key].policyAmount);
  }

  return [header, policyCounts, policyAmounts, policyAmounts];
};
