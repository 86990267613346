import { PencilIcon, XIcon } from "@heroicons/react/outline";
import React, { Component } from "react";
import { AppRow } from "../../components/AppComponents";
import Button from "../../components/Button";
import Main from "./../Main";
import { InputGroup } from "../../components/form/InputGroup";
import ProfileSidebar from "./ProfileSidebar";
import { AppStore } from "../../lib/AppStore";
import { connect } from "react-redux";
import { ApiPost, setError, setMessage } from "../../lib/AppHelper";
import _ from "lodash";

export class ChangePassword extends Component {
  state = {
    edit: false,
    init_params: {
      email: this.props.user.email,
      oldPassword: this.props.user.basic.oldPassword,
      password: this.props.user.basic.password,
      rePassword: this.props.user.rePassword,
    },
    params: {
      email: this.props.user.email,
      oldPassword: this.props.user.basic.oldPassword,
      password: this.props.user.basic.password,
      rePassword: this.props.user.rePassword,
    },
    errors: {},
  };

  checked_password = this.props.user.basic.password;

  BasicBtn = React.createRef();

  render() {
    const { edit, params, errors } = this.state;
    const { user } = this.props;

    return (
      <Main>
        <AppRow>
          <div className="flex-1 max-w-min mr-5">
            <ProfileSidebar />
          </div>
          <div className="flex-auto">
            <div className="max-w-xl">
              <InputGroup
                error={errors.email}
                label="Email Address"
                attrs={{
                  maxLength: 64,
                  value: params.email,
                  readOnly: true,
                  onChange: (e) => this.onChangeHandler(e, "email"),
                }}
              />
              {user.is_password ? (
                <InputGroup
                  error={errors.oldPassword}
                  label="Old Password"
                  placeholder="Enter old password..."
                  attrs={{
                    readOnly: !edit,
                    value: params.oldPassword,
                    onChange: (e) => this.onChangeHandler(e, "oldPassword"),
                    type: "password",
                  }}
                />
              ) : (
                false
              )}
              <InputGroup
                error={errors.password}
                label="New Password"
                placeholder="Enter new password..."
                attrs={{
                  readOnly: !edit,
                  value: params.password,
                  onChange: (e) => this.onChangeHandler(e, "password"),
                  type: "password",
                }}
              />

              <InputGroup
                error={errors.rePassword}
                label="Re Password"
                placeholder=""
                attrs={{
                  readOnly: !edit,
                  value: params.rePassword,
                  onChange: (e) => this.onChangeHandler(e, "rePassword"),
                  type: "password",
                }}
              />

              <div className="mt-8">
                <div className="flex">
                  <div>
                    <Button
                      attrs={{ onClick: this.toggleEdit }}
                      title={!edit ? "Edit Password" : "Cancel Edit"}
                      display="inline-block"
                      outline={true}
                    >
                      {edit ? (
                        <XIcon width={16} className="mr-1" />
                      ) : (
                        <PencilIcon width={16} className="mr-1" />
                      )}
                    </Button>
                  </div>
                  <div className="flex-1 pl-5">
                    {edit ? (
                      <Button
                        attrs={{
                          type: "button",
                          onClick: this.updatePassword,
                          ref: this.BasicBtn,
                        }}
                        size="md"
                        title={"Update Password"}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppRow>
      </Main>
    );
  }

  onChangeHandler = (e, key) => {
    const { params } = this.state;
    params[key] = e.target.value;
    this.setState({ params: params });
  };

  componentDidMount() {
    AppStore.dispatch({ type: "LOADING", loading: false });
    AppStore.dispatch({ type: "PROGRESS", progress: 0 });
  }

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit }, () => {
      this.setState({ params: this.state.init_params });
    });
  };

  updatePassword = () => {
    AppStore.dispatch({ type: "LOADING", loading: true });
    this.setState({ errors: {} }, async () => {
      const { email, oldPassword, password, rePassword } = this.state.params;
      const request = {
        email: email,
        oldPassword: oldPassword,
        password: password,
        rePassword: rePassword,
      };

      const response = await ApiPost("register/change-password", request);
      if (response.status === "error") {
        this.setState({ errors: response.errors }, () => {
          AppStore.dispatch({ type: "LOADING", loading: false });
        });
      } else {
        if (response.status === "success") {
          await setMessage(response.data.message);
          const someProperty = { ...this.props };
          someProperty.user.is_password = true;
          this.setState(someProperty);
        } else {
          await setError(response.message);
        }
        AppStore.dispatch({ type: "LOADING", loading: false });
        this.setState({ edit: false });
      }
    });
  };
}

const mapStateToProps = (state) => {
  const { loading, user } = state;
  return { loading: loading, user: user };
};

export default connect(mapStateToProps)((props) => {
  return <ChangePassword {...props} />;
});
