import React, { Component } from "react";
import {
  InputGroup,
  SelectPicker,
  Button,
  Form,
  Schema,
  AutoComplete,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Field = React.forwardRef((props, ref) => {
  const { name, message, label, accepter, error, style, ...rest } = props;
  return (
    <Form.Group
      controlId={`${name}-10`}
      ref={ref}
      className={error ? "has-error" : ""}
      style={{ ...style }}
    >
      <Form.Control
        name={name}
        accepter={accepter}
        errorMessage={error}
        {...rest}
      />
    </Form.Group>
  );
});

export class Search extends Component {
  formRef = React.createRef();

  state = {
    formError: {},
    count: 0,
    formValue: {
      search_type: "name",
    },
    isSearchValue: false,
    checkTrigger: "change",
    model: Schema.Model({
      search_type: Schema.Types.StringType().isRequired(
        "This field is required."
      ),
      search: Schema.Types.StringType().isRequired("This field is required."),
    }),
    params: {},
    errors: {},
    option: {},
    handleSubmit: "",
    members: [],
    options: [
      {
        name: "Member Name",
        value: "name",
      },
      {
        name: "Member ID",
        value: "member_id",
      },
      {
        name: "Loan Code",
        value: "loan_code",
      },
      {
        name: "Mobile Number",
        value: "mobile",
      },
      {
        name: "Aadhaar Number",
        value: "aadhaar_id",
      },
    ],
  };

  handleSubmit = (value) => {
    this.setState({ isSearchValue: true });
    if (!this.formRef.current.check()) {
      return;
    }
    const { setValue } = this.props;
    setValue && setValue(this.state.formValue);
  };

  setFormValue = (e) => {
    this.setState({ formValue: e });
  };
  setFormError = (e) => {
    this.setState({ formError: e });
  };

  handleCancel = () => {
    const { setValue } = this.props;

    this.setState({ formValue: "" });
    setTimeout(() => {
      setValue && setValue(this.state.formValue);
    }, 100);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.formValue.search_type !== this.state.formValue.search_type) {
      this.setState({
        ...this.state,
        formValue: {
          ...this.state.formValue,
          search: "",
        },
      });
    }
  }

  render() {
    const {
      options,
      model,
      formValue,
      formError,
      checkTrigger,
      members,
      count,
      isSearchValue,
    } = this.state;
    return (
      <div className="search">
        <Form
          formValue={formValue}
          ref={this.formRef}
          onChange={this.setFormValue}
          onCheck={this.setFormError}
          formError={formError}
          formDefaultValue={formValue}
          model={model}
          checkTrigger={checkTrigger}
        >
          <div
            className="border"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Field
              name="search_type"
              searchable={false}
              defaultValue="name"
              cleanable={false}
              data={options}
              accepter={SelectPicker}
              labelKey="name"
              valueKey="value"
              onSelect={(_v, item) => {
                this.setState({ option: item });
              }}
              style={{ "z-index": 0 }}
            />
            <Field
              autoComplete="off"
              name="search"
              accepter={AutoComplete}
              error={formError.search}
              style={{
                width: "90%",
              }}
              data={members}
              placeholder="Search Member..."
              maxLength={
                formValue.search_type == "mobile"
                  ? 10
                  : formValue.search_type == "aadhaar_id"
                  ? 12
                  : 50
              }
              onChange={(e) => {
                if (formValue.search_type == "mobile") {
                  this.setState({
                    formValue: {
                      ...formValue,
                      search: e.replace(/[^0-9 ]/g, ""),
                    },
                  });
                }
                if (formValue.search_type == "aadhaar_id") {
                  this.setState({
                    formValue: {
                      ...formValue,
                      search: e.replace(/[^0-9 ]/g, ""),
                    },
                  });
                } else {
                  this.setState({
                    formValue: { ...formValue, search: e },
                  });
                }
              }}
              value={formValue.search}
            />

            <InputGroup.Button
              className="rounded-tr-lg rounded-br-lg"
              style={{ height: 41 }}
              type="submit"
              onClick={this.handleSubmit}
            >
              <SearchIcon />
            </InputGroup.Button>

            {isSearchValue ? (
              <InputGroup.Button
                style={{ height: 41, color: "red" }}
                type="button"
                onClick={this.handleCancel}
                className="rounded-tr-md rounded-br-md"
              >
                <FontAwesomeIcon icon={faTimesCircle}> </FontAwesomeIcon>
              </InputGroup.Button>
            ) : (
              false
            )}
          </div>
        </Form>
      </div>
    );
  }
}

const styles = {
  marginBottom: 20,
  flexDirection: "row",
};
