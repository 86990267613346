import moment from "moment";
import React from "react";
import { Pagination, Placeholder } from "rsuite";

const Table = ({
  data,
  headers,
  loader = false,
  limit,
  total,
  page,
  onPageChange,
}) => {
  const handleData = (data) => {
    if (data.length == total && data.length >= limit) {
      const startIndex = (page - 1) * limit;
      const endIndex = startIndex + limit;
      const paginatedData = data.slice(startIndex, endIndex);
      return paginatedData;
    } else {
      return data;
    }
  };

  return (
    <div className="flex flex-col relative">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-x-auto">
            <table
              className="w-full table-auto border-collapse"
              id="data-table"
            >
              <thead className="border-b">
                <tr>
                  {headers?.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className="text-sm  font-large px-6 py-4 whitespace-nowrap text-center"
                      >
                        {(item?.label ? item?.label : item?.name)
                          .replace(/[-_]/g, " ")
                          .replace(/\b\w/g, (match) => match.toUpperCase())}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {loader ? (
                <td colSpan={headers.length}>
                  <Placeholder.Paragraph rows={5} rowHeight={8} active />
                </td>
              ) : (
                <tbody>
                  {data?.length ? (
                    handleData(data)?.map((row, rowIndex) => (
                      <tr key={rowIndex} className="border-2">
                        {headers.map((header, colIndex) => {
                          return (
                            <td
                              className={`text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center  ${
                                header.columnColor?.indexOf(rowIndex) > -1
                                  ? "bg-yellow-300"
                                  : ""
                              }`}
                              key={colIndex}
                            >
                              {header?.cell
                                ? header?.cell(row)
                                : header.type == "date"
                                ? row[header?.dataKey]
                                  ? moment(row[header?.dataKey]).isValid()
                                    ? moment(row[header?.dataKey]).format(
                                        header.format
                                      )
                                    : row[header?.dataKey]
                                  : ""
                                : `${
                                    row[header?.dataKey] ||
                                    row[header?.dataKey] == 0
                                      ? `${row[header?.dataKey]}`
                                          ?.replace(/[-_]/g, " ")
                                          ?.replace(/\b\w/g, (match) =>
                                            match.toUpperCase()
                                          )
                                      : ""
                                  }`}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr className="border-2">
                      <td
                        colSpan="9"
                        height="50"
                        className="text-red-500 font-light text-center"
                      >
                        Your result is empty
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={4}
                size="xs"
                layout={["total", "-", "pager", "skip"]}
                total={total}
                limit={limit}
                activePage={page}
                onChangePage={onPageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
